import React, { useState, useRef } from 'react'
import { Form, Input, Button } from 'antd'
import { requiredMessage, inputPlace, successMessage, errorMessage, assignMessage } from '../../../utils/helpers'
import { AUTH } from '../../../utils/apis'
import axios from 'axios'

const ChangePassword = (props) => {
    const { history, user, authActions, language, selectedLanguage } = props

    const [loading, setLoading] = useState(false)
    const [btnText, setBtnText] = useState(selectedLanguage?.title_UpdatePassword)
    const antdForm = useRef(null)

    const onFinish = (values) => {
        values = { ...values, ...user }
        setLoading(true)
        setBtnText(selectedLanguage?.title_Updating)
        axios.post(AUTH.CHANGE_PASSWORD, values)
            .then((res) => {
                const { data } = res
                setLoading(false)
                setBtnText(selectedLanguage?.title_UpdatePassword)
                if (data.success) {
                    successMessage(assignMessage(data?.message, language?.language))
                    antdForm.current.resetFields(['password', 'newPassword', 'confirm'])
                }
                else {
                    errorMessage(assignMessage(data?.message, language?.language))
                }
            })
            .catch((e) => {
                setLoading(false)
                setBtnText(selectedLanguage?.title_UpdatePassword)
                errorMessage(assignMessage(e?.message, language?.language))
            })
    }

    return (

        <div style={{ background: 'black' }}>
            <h1 className='new-screen-title orang-color' >
                {selectedLanguage?.title_Security}
            </h1>
            <Form
                name='form'
                onFinish={onFinish}
                layout={'vertical'}
                requiredMark={false}
                ref={antdForm}
            >

                <div className='form-two-input-flex'>
                    <div className='form-two-input-flex-inner-input'>
                        <Form.Item
                            name='password'
                            label={<p className='p-admin-title-text'>{selectedLanguage?.label_CurrentPassword}</p>}
                            rules={[
                                {
                                    required: true,
                                    message: selectedLanguage?.error_PleaseInputYourCurrentPassword
                                },
                                {
                                    min: 8,
                                    message: selectedLanguage?.error_PasswordMustBeAtLeastEightCharacters
                                }
                            ]}
                        >
                            <Input.Password
                                className='form-input-new-design-big-slam'
                                placeholder={selectedLanguage?.place_InputYourCurrentPasswordHere}
                            />
                        </Form.Item>
                    </div>
                    <div className='form-two-input-flex-inner-input'>
                        <Form.Item
                            name='confirm'
                            label={<p className='p-admin-title-text'>{selectedLanguage?.label_ConfirmPassword}</p>}
                            hasFeedback
                            rules={[
                                {
                                    required: true,
                                    message: selectedLanguage?.error_PleaseEnterYourConfirmPassword,
                                },
                                ({ getFieldValue }) => ({
                                    validator(rule, value) {
                                        if (!value || getFieldValue('password') === value) {
                                            return Promise.resolve()
                                        }
                                        return Promise.reject(selectedLanguage?.error_TheTwoPasswordsThatYouEnteredDoNotMatch)
                                    },
                                })
                            ]}
                        >
                            <Input.Password
                                placeholder={selectedLanguage?.placeHolder_InputYourConfirmPassword}
                                className='form-input-new-design-big-slam'
                            />
                        </Form.Item>
                    </div>
                </div>
                <div className='form-input-single-input'>
                    <Form.Item
                        name='newPassword'
                        label={<p className='p-admin-title-text'>{selectedLanguage?.label_NewPassword}</p>}
                        rules={[
                            {
                                required: true,
                                message: selectedLanguage?.error_PleaseInputYourNewPassword
                            },
                            {
                                min: 8,
                                message: selectedLanguage?.error_newPasswordMustBeAtLeastCharacters
                            }
                        ]}
                    >
                        <Input.Password
                            className='form-input-new-design-big-slam'
                            placeholder={selectedLanguage?.placeHolder_InputYourNewPasswordHere}
                        />
                    </Form.Item>
                </div>
                <Form.Item>
                    <Button
                        type='primary'
                        htmlType='submit'
                        loading={loading}
                    >
                        {btnText}
                    </Button>
                </Form.Item>
            </Form>
        </div>
    )
}

export default ChangePassword