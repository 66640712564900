import { Button, Popconfirm, Table } from 'antd'
import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { ADMIN } from '../../../utils/apis'
import { assignMessage, convertTitle, errorMessage, successMessage } from '../../../utils/helpers'
const GameSuggestion = (props) => {
    const { selectedLanguage, isEnglish, language } = props
    const [allGameSuggestion, setAllGameSuggestion] = useState([])
    const [loading, setLoading] = useState(false)
    const [deleteLoading, setDeleteLoading] = useState({})
    const [approveLoading, setApproveLoading] = useState({})

    const getAllGameSuggestion = () => {
        setLoading(true)
        axios.get(ADMIN.GET_GAME_SUGGESTION)
            .then((res) => {
                const { data } = res
                setLoading(false)
                if (data?.success) {
                    setAllGameSuggestion(data?.data || [])
                }
                else {
                    setAllGameSuggestion([])
                }
            })
            .catch((e) => {
                setLoading(false)
                errorMessage(assignMessage(e?.message, language?.language))
            })
    }

    const approveGameSuggestion = (obj, id) => {
        setApproveLoading({ [id]: true })
        axios.post(ADMIN.APPROVE_GAME_SUGGESTION, obj)
            .then((res) => {
                const { data } = res
                setApproveLoading({})
                if (data.success) {
                    successMessage(assignMessage(data?.message, language?.language))
                    getAllGameSuggestion()
                } else {
                    errorMessage(assignMessage(data?.message, language?.language))
                }
            }).catch((e) => {
                setApproveLoading({})
                errorMessage(assignMessage(e?.message, language?.language))
            })
    }

    const deleteGameSuggestion = (id) => {
        setDeleteLoading({ [id]: true })
        let obj = { _id: id }
        axios.post(ADMIN.DELETE_GAME_SUGGESTION, obj)
            .then((res) => {
                const { data } = res
                setDeleteLoading({})
                if (data?.success) {
                    successMessage(assignMessage(data?.message, language?.language))
                    getAllGameSuggestion()
                }
                else {
                    errorMessage(assignMessage(data?.message, language?.language))
                }
            })
            .catch((e) => {
                setDeleteLoading({})
                errorMessage(assignMessage(e?.message, language?.language))
            })
    }


    useEffect(() => {
        getAllGameSuggestion()
    }, [deleteLoading])

    const columns = [
        {
            title: selectedLanguage?.title_ID,
            dataIndex: '_id',
            key: 'id',
            render: (e) => {
                let Number = e.substr(e.length - 5)
                return (
                    <p className='p_button_style'>{Number}</p>
                )
            }
        },
        {
            title: selectedLanguage?.title_CreatedBy,
            dataIndex: 'userId',
            key: 'userId',
            render: (e) => e?.name || '---'
        },
        {
            title: selectedLanguage?.title_Name,
            dataIndex: 'name',
            key: 'name',
            render: (e) => e || '----'
        },
        {
            title: selectedLanguage?.text_gameType,
            dataIndex: 'gameType',
            key: 'gameType',
            render: (e) => e || '----'
        },
        {
            title: selectedLanguage?.title_Description,
            dataIndex: 'description',
            key: 'description',
            render: (e) => e || '----'
        },
        {
            title: selectedLanguage?.title_PlayingTimeAvgmin,
            dataIndex: 'playingTime',
            key: 'playingTime',
            render: (e) => {
                return (
                    <p style={{ width: '180px', margin: '0px' }}>{e}{' '}{selectedLanguage?.Text_Min}</p>
                )
            }
        },
        {
            title: '',
            render: (e) => {
                let obj = {
                    playingTime: e?.playingTime.trim(),
                    name: e?.name.trim(),
                    description: e?.description.trim(),
                    gameType: e?.gameType.trim(),
                    id: e?._id
                }
                return (
                    <span style={{ display: 'flex', }}>
                        <Button
                            loading={approveLoading[e?._id]}
                            onClick={() => {
                                approveGameSuggestion(obj, e?._id)
                            }}
                            type='primary'
                        >{selectedLanguage?.buttontext_CreateGame}</Button>
                        &nbsp;
                        &nbsp;
                        <Popconfirm
                            title={`${selectedLanguage?.textDelete} ${convertTitle(`${e?.name} `)} ${selectedLanguage?.text_gameSuggestion} ?`}
                            okText={selectedLanguage?.textDelete}
                            cancelText={selectedLanguage?.textCancel}
                            okButtonProps={{
                                type: 'primary',
                                className: 'form-button'
                            }}
                            cancelButtonProps={{ style: { borderRadius: 4 } }}
                            onConfirm={() => {
                                deleteGameSuggestion(e?._id)
                            }}
                        >
                            <Button
                                loading={deleteLoading[e?._id]}
                                type='primary'
                            >{selectedLanguage?.buttonText_Delete}</Button>
                        </Popconfirm>
                    </span>
                )
            }
        },
    ]

    return (
        <div className='new-main-component-screen-div'>
            <h1 className='new-screen-title'>
                {selectedLanguage?.title_GameSuggestion}
            </h1>
            <div className="custom-table-container">
                <Table
                    locale={{ emptyText: selectedLanguage?.txt_NoDataFound }}
                    className='new-layout-table'
                    dataSource={allGameSuggestion || []}
                    columns={columns}
                    pagination={false}
                    loading={loading}
                    scroll={{ x: 800 }}
                />
            </div>
        </div>
    )
}

export default GameSuggestion