import React, { useEffect, useState } from 'react'
import NoData from '../../../assets/noDataImae.png'
import { Button, Popconfirm, Table } from 'antd'
import { assignMessage, errorMessage, getCompetitionMatches, successMessage } from '../../../utils/helpers'
import axios from 'axios'
import { POST } from '../../../utils/apis'

const ReceivedMatches = (props) => {
    const { selectedLanguage, user, matches, userActions, language } = props
    const [deleteLoading, setDeleteLoading] = useState({})
    const [acceptloading, setAcceptloading] = useState({})
    console.log("received-matches", matches)

    const rejectMatchRequest = (data) => {
        setDeleteLoading({ [data?._id]: true })
        let obj = {
            _id: data?._id,
            userId: user?._id,
            code: data?.code,
        }
        axios.post(POST?.REJECT_COMPETITION_MATCH_REQUEST, obj)
            .then((res) => {
                const { data } = res
                setDeleteLoading({})
                if (data?.success) {
                    successMessage(assignMessage(data?.message, language?.language))
                    getCompetitionMatches(user?._id, userActions)
                }
                else {
                    errorMessage(assignMessage(data?.message, language?.language))
                }
            })
            .catch((e) => {
                setDeleteLoading({})
                errorMessage(assignMessage(e?.message, language?.language))
            })
    }

    const acceptMatchRequest = (data) => {

        setAcceptloading({ [data?._id]: true })

        let obj = {
            _id: data?._id,
            userId: user?._id,
            code: data?.code,
            competitionId: data?.competition?._id
        }

        axios.post(POST?.ACCEPT_COMPETITION_MATCH_REQUEST, obj)
            .then((res) => {
                const { data } = res
                setAcceptloading({})
                if (data?.success) {
                    successMessage(assignMessage(data?.message, language?.language))
                    getCompetitionMatches(user?._id, userActions)
                }
                else {
                    errorMessage(assignMessage(data?.message, language?.language))
                }
            })
            .catch((e) => {
                setAcceptloading({})
                errorMessage(assignMessage(e?.message, language?.language))
            })
    }

    const columns = [
        {
            title: selectedLanguage?.label_CompetitionName,
            render: (e) => {
                return (
                    <p>{e?.competition?.competitionName}</p>
                )
            }
        },
        {
            title: selectedLanguage?.text__Game,
            render: (e) => {
                return (
                    <p>{e?.gameId?.name}</p>
                )
            }
        },
        {
            title: selectedLanguage?.text__Matchtype,
            render: (e) => {
                return (
                    <p>{e?.matchType}</p>
                )
            }
        },
        {
            title: selectedLanguage?.text__Matchdate,
            render: (e) => {
                return (
                    <p>{e?.matchDate}</p>
                )
            }
        },
        {
            title: selectedLanguage?.text__Matchtime,
            render: (e) => {
                return (
                    <p>{e?.matchTime}</p>
                )
            }
        },
        {
            title: selectedLanguage?.text_Sendername,
            render: (e) => {
                return (
                    <p>{e?.senderId?.name}</p>
                )
            }
        },
        {
            title: selectedLanguage?.text__Status,
            render: (e) => {
                return (
                    <>
                        {e?.isAccepted ?
                            <p className='accept-status'>{selectedLanguage?.sts_Accepted}</p>
                            : e?.isRejected ?
                                <p className='reject-status'>{selectedLanguage?.sts_rejected}</p>
                                :
                                <p className='pending-stats'>{selectedLanguage?.sts_pending}</p>
                        }
                    </>
                )
            }
        },
        {
            title: selectedLanguage?.title_Action,
            render: (e) => {
                return (
                    <>
                        {
                            e?.isAccepted ?
                                <div style={{ display: 'flex' }} >
                                    <Button disabled={true} style={{ opacity: 0.2 }}
                                        className='accept_m_btn'
                                    >{selectedLanguage?.btn_Accept}</Button>
                                </div >
                                :
                                <div style={{ display: 'flex' }}>
                                    <Button
                                        disabled={e?.isAccepted || e?.isRejected}
                                        // className={e?.isAccepted || e?.isRejected ? '' : 'accept_m_btn'}
                                        style={{ marginRight: '10px' }}
                                        loading={acceptloading[e?._id]}
                                        onClick={() => {
                                            acceptMatchRequest(e)
                                        }}
                                    >{selectedLanguage?.btn_Accept}</Button>
                                    {
                                        <Popconfirm
                                            disabled={e?.isAccepted || e?.isRejected}
                                            title={`${selectedLanguage?.text__Areyousureyourejectthematchrequest}?`}
                                            okText={selectedLanguage?.text__Reject}
                                            cancelText={selectedLanguage?.textCancel}
                                            okButtonProps={{
                                                type: 'primary',
                                                className: 'form-button'
                                            }}
                                            cancelButtonProps={{ style: { borderRadius: 4 } }}
                                            onConfirm={() => {
                                                rejectMatchRequest(e)
                                            }}
                                        >
                                            <Button
                                                disabled={e?.isAccepted || e?.isRejected}
                                                type='primary'
                                                loading={deleteLoading[e?._id]}
                                                danger
                                            >{selectedLanguage?.text__Reject}</Button>
                                        </Popconfirm>}
                                </div>
                        }
                    </>
                )
            },
        },
    ]




    return (
        <>
            <div style={{ marginTop: '20px', borderBottom: `1px dashed black` }}>
                <div className="custom-table-container">
                    <Table
                        locale={{ emptyText: selectedLanguage?.txt_NoDataFound }}
                        className='new-layout-table'
                        dataSource={matches}
                        columns={columns}
                        pagination={false}
                        scroll={{ x: 1000 }}
                    />
                </div>
            </div>
        </>
    )
}

export default ReceivedMatches