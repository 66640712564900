import React, { useEffect, useState } from 'react'
import { Skeleton } from 'antd'
import axios from 'axios'
import { Form, Button, Input, message } from 'antd'
import { AUTH, GET } from '../../utils/apis'
import { allPaths } from '../../utils/constants'
import { Page404 } from '../../Config/routes'
import EnglishLanguage from '../../Languages/EnglishLanguage.json'
import NlLanguage from '../../Languages/NetherlandsLanguage.json'
import { useSelector } from 'react-redux'
const UpdatePassword = (props) => {
    const { history, language, selectedLanguage } = props
    const isEnglish = language?.language === 'english'
    const [state, setState] = useState({
        loading: true,
        success: false,
        buttonLoading: false,
        user: null
    })

    useEffect(() => {
        const { match } = props
        const { token } = match.params
        axios.get(`${GET.VERIFY_TOKEN}/${token}`)
            .then((result) => {
                const { data } = result
                if (data.success) {
                    updateState({ message: isEnglish ? data.message : data.messageNl, success: data.success, loading: false, user: data.user })
                }
                else {
                    updateState({ message: isEnglish ? data.message : data.messageNl, success: data.success, loading: false })
                }
            })
            .catch(e => {
                updateState({ message: selectedLanguage?.backendMsg_SomethingWentWrongPleaseRefreshThisPage, success: false, loading: false })
            })
    }, [])

    const updateState = (obj) => {
        setState({ ...state, ...obj })
    }

    const onFinish = (values) => {
        const { user } = state

        let redirectRoute = allPaths?.LOGIN

        if (user) {
            values.email = user?.email
            values.userType = 'player'

            axios.post(AUTH.UPDATE_PASSWORD, values)
                .then((result) => {
                    const { data } = result
                    if (data.success) {
                        message.success(selectedLanguage?.backendMsg_PasswordSuccessfullyChanged)
                        setTimeout(() => {
                            history.push(redirectRoute)
                        }, 500)
                    }
                    else {
                        message.error(data.message)
                    }
                })
                .catch((err) => {
                    message.error(selectedLanguage?.backendMsg_OopsSomethingWentWrong)
                })
        }
        else {
            message.error(selectedLanguage?.backendMsg_OopsSomethingWentWrong)
        }
    }

    const onFinishFailed = (err) => {

    }

    return (
        <div style={{ height: '100%', background: '#FBFBFB' }}>
            {!state.loading ? <div className='background-layout'>
                {state.success ?
                    <div className='auth-screen-main-div' style={{ marginTop: '20px', }}>
                        <p className='heading head-center'>{selectedLanguage?.title_UpdatePassword}</p>

                        <Form
                            name='basic'
                            initialValues={{
                                remember: true,
                            }}
                            onFinish={onFinish}
                            onFinishFailed={onFinishFailed}
                        >
                            <div className='form-two-input-flex'>
                                <div className='form-two-input-flex-inner-input'>
                                    <Form.Item
                                        name='password'
                                        hasFeedback
                                        rules={[
                                            {
                                                required: true,
                                                message: selectedLanguage?.error_PleaseEnterYourPassword,
                                            },
                                            {
                                                min: 8,
                                                message: selectedLanguage?.error_PasswordMustBeAtLeastEightCharacters
                                            }
                                        ]}
                                    >
                                        <Input.Password
                                            className='form-input-new-style'
                                            placeholder={selectedLanguage?.PlaceHolder_PleaseEnterYourPasswordHere} />
                                    </Form.Item>
                                </div>
                                <div className='form-two-input-flex-inner-input'>
                                    <Form.Item
                                        name='confirmPassword'
                                        hasFeedback
                                        rules={[
                                            {
                                                required: true,
                                                message: selectedLanguage?.error_PleaseEnterYourConfirmPassword,
                                            },
                                            ({ getFieldValue }) => ({
                                                validator(rule, value) {
                                                    if (!value || getFieldValue('password') === value) {
                                                        return Promise.resolve()
                                                    }
                                                    return Promise.reject(selectedLanguage?.error_TheTwoPasswordsThatYouEnteredDoNotMatch)
                                                },
                                            })
                                        ]}
                                    >
                                        <Input.Password
                                            className='form-input-new-style'
                                            placeholder={selectedLanguage?.placeHolder_InputYourConfirmPassword} />
                                    </Form.Item>
                                </div>
                            </div>

                            <Form.Item>
                                <Button
                                    className='addproduct-text form-input-new-style'
                                    style={{ height: '45px', width: '100%', marginTop: '10px' }}
                                    htmlType='submit'
                                    loading={state.buttonLoading}
                                >
                                    {selectedLanguage?.buttonText_UpdatePassword}
                                </Button>
                            </Form.Item>
                        </Form>
                    </div> : <Page404 {...props} />}
            </div> : <Skeleton paragraph={{ rows: 22 }} />
            }
        </div>)
}

export default UpdatePassword
