import React, { useEffect, useState } from 'react'
import SentMatches from './SentMatches'
import ReceivedMatches from './ReceivedMatches'
import { getCompetitionMatches } from '../../../utils/helpers'
import { useSelector } from 'react-redux'
import io from 'socket.io-client'

const CompetetionMatches = (props) => {
    const { selectedLanguage, user, userActions } = props

    const sentCompetitionMatches = useSelector(state => state.userReducer?.sentCompetitionMatches)
    const receiveCompetitionMatches = useSelector(state => state.userReducer?.receiveCompetitionMatches)

    useEffect(() => {
        getCompetitionMatches(user?._id, userActions)
        getSocketInfo()
    }, [])

    let origin = `https://wgpplaza.com`
    if (process.env.NODE_ENV === 'development') {
        origin = `http://localhost:4000`
    }

    console.log('origin', origin)
    const socket = io(origin, { reconnect: true });

    socket.on('connect', () => {
        console.log('connected')
    })

    const getSocketInfo = () => {
        socket.on('matchRequestResponse', (d) => {
            const { documentKey, operationType, updateDescription } = d
            getCompetitionMatches(user?._id, userActions)
        })
    }
    return (
        <div className='new-main-component-screen-div'>
            <div>
                <h1 className='new-screen-title'>
                    {selectedLanguage?.text_Sentcompetitionmatches}
                </h1>
                <SentMatches matches={sentCompetitionMatches} sentCompetitionMatches={sentCompetitionMatches} {...props} />
            </div>
            <div>
                <h1 className='new-screen-title'>
                    {selectedLanguage?.text_Receivedcompetitionmatches}
                </h1>
                <ReceivedMatches matches={receiveCompetitionMatches} receiveCompetitionMatches={receiveCompetitionMatches}  {...props} />
            </div>
        </div >
    )
}

export default CompetetionMatches