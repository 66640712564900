import React, { useEffect, useState } from 'react'
import { IoIosArrowBack } from 'react-icons/io'
import { GET, POST } from '../../../utils/apis'
import { Button, Image, Input, Modal, Popconfirm, Spin, Table } from 'antd'
import axios from 'axios'
import { convertTitle, errorMessage, successMessage, myTournametSetting, infoMessage, isWithinDisabledPeriod, isDisabledSubscribe, adjustDataForRank, getUserData, getAllTournaments, convertNumber, assignMessage, } from '../../../utils/helpers'
import CreatePlayerTournament from './CreateTournaments'
import TournamentGraph from './TournamentGraph'
import { useSelector } from 'react-redux'
import DollerTournamentSubscribeModal from './DollerTournamentSubscribe'
import io from 'socket.io-client'
import { BsInfoCircleFill } from 'react-icons/bs'
import { allPaths } from '../../../utils/constants'
import { useHistory } from 'react-router-dom'
import NoData from '../../../assets/noDataImae.png'


const PlayerTournamentSelect = (props) => {
    const { selectedLanguage, language, user, userActions, getUser } = props
    const history = useHistory()
    const [tournament, setTournament] = useState([])
    const [currentSubscribeData, setCurrentSubscribeData] = useState({})
    const [currentPromotedSubscribeData, setCurrentPromotedSubscribeData] = useState({})
    const [currentWithdrawalData, setCurrentWithdrawalData] = useState({})
    const [loadingPromoted, setLoadingPromoted] = useState(false)
    const [loading, setLoading] = useState(false)
    const [PrivateLoading, setPrivateLoading] = useState(false)
    const [deleteLoading, setDeleteLoading] = useState({})
    const [editdata, setEditData] = useState({})
    const [subscribeLoading, setSubscribeLoading] = useState({})
    const [withdrawalLoading, setWithdrawalLoading] = useState({})
    const [PrivateTournamentData, setPrivateTournamentData] = useState({})
    const [dollerTournamentData, setDollerTournamentData] = useState({})
    const [privateCodeValue, setPrivateCodeValue] = useState('')
    const [CouponCodeValue, setCouponCodeValue] = useState('')
    const [showCouponInput, setShowCouponInput] = useState(false)
    const [viewTournament, setviewTournament] = useState({})
    const userRanks = useSelector(state => state.userReducer.userRanks)
    const tournaments = useSelector(state => state.userReducer.tournaments)
    console.log('tournament', tournaments)
    useEffect(() => {
        getUserData(user?._id, userActions)
        // getAllTournament()
        getAllTournaments(user?._id, userActions)
        getData()
        getSocketInfo()
    }, [])

    const getData = () => {
        getUserData(user?._id, userActions)
        getAllTournaments(user?._id, userActions)
    }


    let origin = `https://wgpplaza.com`
    if (process.env.NODE_ENV === 'development') {
        origin = `http://localhost:4000`
    }

    const socket = io(origin, { reconnect: true });

    socket.on('connect', () => {
        console.log('connected')
    })

    const getSocketInfo = () => {
        socket.on('tournamentResponse', (d) => {
            const { documentKey, operationType, updateDescription } = d
            getAllTournaments(user?._id, userActions)

        })
    }

    const editSuccess = () => {
        setEditData({})
    }


    const deleteTournament = (id) => {
        setDeleteLoading({ [id]: true })
        let obj = {
            _id: id,
            userId: user?._id
        }

        axios.post(POST?.DELETE_TOURNAMENT, obj)
            .then((res) => {
                const { data } = res
                setDeleteLoading({})
                if (data?.success) {
                    successMessage(assignMessage(data?.message, language?.language))
                    getData()
                }
                else {
                    errorMessage(assignMessage(data?.message, language?.language))
                }
            })
            .catch((e) => {
                setDeleteLoading({})
                errorMessage(assignMessage(e?.message, language?.language))
            })
    }

    console.log('currentSubscribeData', currentSubscribeData)
    const handleOKSubscribeModal = () => {
        tournamentSubscribe(currentSubscribeData)
        setCurrentSubscribeData({})
    }
    const handleCancelSubscribeModal = () => {
        setCurrentSubscribeData({})
    }
    const handleOKWithdrawalModal = () => {
        tournamentWithdrawal(currentWithdrawalData)
        setCurrentWithdrawalData({})
    }
    const handleCancelWithdrawalModal = () => {
        setCurrentWithdrawalData({})
    }
    const handleOKPromotedSubModal = () => {
        if (showCouponInput) {
            if (!CouponCodeValue) {
                return errorMessage(selectedLanguage?.text_pleaseentercouponcode)
            }
            else {
                tournamentSubscribeWithCoupon(currentPromotedSubscribeData)
            }
        }
        else {
            tournamentSubscribe(currentPromotedSubscribeData)
            setCurrentPromotedSubscribeData({})
        }
    }
    const handleCancelPromotedSubModal = () => {
        setCurrentPromotedSubscribeData({})
    }


    let tournamentSubscribe = (data) => {
        let gameId = data?.gameId?._id
        let participationFee = data?.participationFee
        let rankingNeeded = data?.rankingNeeded
        let tournamentId = data?._id
        let isFreeInvitedUser = data?.invitesContacts?.includes(user?._id)
        let isPrivate = data?.isPrivate
        let isPrivateCodeFound = data?.privateCode
        let gameIdExist = userRanks?.find((v) => v?.gameId?._id?.toString() === gameId)?._id

        if (!gameIdExist) {
            return errorMessage(`${selectedLanguage?.text_Add} ${data?.gameId?.name} ${selectedLanguage?.text_inyourgamesettings}!`)
        }
        if (isPrivate && isPrivateCodeFound) {
            setPrivateTournamentData(data)
            return true
        }

        setSubscribeLoading({ [data?._id]: true })

        let obj = {
            userId: user?._id,
            tournamentId: tournamentId,
            pointDeduct: isFreeInvitedUser ? participationFee : participationFee + participationFee,
            gameId,
            participationFee,
            rankingNeeded,
            isInvited: isFreeInvitedUser
        }

        axios.post(POST?.TOURNAMENT_PARTICIPATE, obj)
            .then((res) => {
                const { data } = res
                setSubscribeLoading({})
                if (data?.success) {
                    successMessage(assignMessage(data?.message, language?.language))
                    getData()

                }
                else {
                    errorMessage(assignMessage(data?.message, language?.language))
                }
            })
            .catch((e) => {
                setSubscribeLoading({})
                errorMessage(assignMessage(e?.message, language?.language))
            })
    }


    let handlePrivateSubscription = (data) => {
        let gameId = data?.gameId?._id
        let participationFee = data?.participationFee
        let rankingNeeded = data?.rankingNeeded
        let tournamentId = data?._id
        let isFreeInvitedUser = data?.invitesContacts?.includes(user?._id)
        setPrivateLoading(true)
        if (privateCodeValue === data?.privateCode) {

            let obj = {
                userId: user?._id,
                tournamentId: tournamentId,
                pointDeduct: isFreeInvitedUser ? participationFee : participationFee + participationFee,
                gameId,
                participationFee,
                rankingNeeded,
                CouponCodeValue: CouponCodeValue,
                isInvited: isFreeInvitedUser

            }
            axios.post(POST?.TOURNAMENT_PARTICIPATE, obj)
                .then((res) => {
                    const { data } = res
                    setPrivateLoading(false)
                    if (data?.success) {
                        successMessage(assignMessage(data?.message, language?.language))
                        handleClose()
                        getData()

                    }
                    else {
                        errorMessage(assignMessage(data?.message, language?.language))
                    }
                })
                .catch((e) => {
                    setPrivateLoading(false)
                    errorMessage(assignMessage(e?.message, language?.language))
                })
        }
        else {
            setTimeout(() => {
                setPrivateLoading(false)
                errorMessage(selectedLanguage?.error_ThePrivateCodeisIncorrectorWrong)
            }, 1500);
        }

    }

    const tournamentWithdrawal = (e) => {
        const participationObj = e?.tournamentParticipate?.find(obj => obj.userId._id === user?._id);
        console.log(participationObj, "==obj")
        setWithdrawalLoading({ [e?._id]: true })
        axios.post(POST.CANCEL_PARTICIPATE, participationObj)
            .then((res) => {
                const { data } = res
                setWithdrawalLoading({})
                if (data?.success) {
                    successMessage(assignMessage(data?.message, language?.language))
                    getUser()
                    getData()
                }
                else {
                    errorMessage(assignMessage(data?.message, language?.language))
                }
            })
            .catch((e) => {
                setWithdrawalLoading({})
                errorMessage(assignMessage(e?.message, language?.language))
            })
    }

    let tournamentSubscribeWithCoupon = (data) => {
        let gameId = data?.gameId?._id
        let participationFee = data?.participationFee
        let rankingNeeded = data?.rankingNeeded
        let tournamentId = data?._id
        let isFreeInvitedUser = data?.invitesContacts?.includes(user?._id)
        let isPrivate = data?.isPrivate
        let isPrivateCodeFound = data?.privateCode
        if (isPrivate && isPrivateCodeFound) {
            setPrivateTournamentData(data)
            return true
        }

        setLoadingPromoted(true)

        let obj = {
            userId: user?._id,
            tournamentId: tournamentId,
            pointDeduct: isFreeInvitedUser ? participationFee : participationFee + participationFee,
            gameId,
            participationFee,
            rankingNeeded,
            CouponCodeValue: CouponCodeValue,
            isInvited: isFreeInvitedUser
        }

        axios.post(POST?.TOURNAMENT_PARTICIPATE_WITH_COUPON, obj)
            .then((res) => {
                const { data } = res
                setLoadingPromoted(false)
                if (data?.success) {
                    successMessage(assignMessage(data?.message, language?.language))
                    setCurrentPromotedSubscribeData({})
                    getData()

                }
                else {
                    errorMessage(assignMessage(data?.message, language?.language))
                }
            })
            .catch((e) => {
                setLoadingPromoted(false)
                errorMessage(assignMessage(e?.message, language?.language))
            })
    }

    const columns = [
        {
            title: selectedLanguage?.title_Game,
            dataIndex: 'gameId',
            key: 'gameName',
            render: (e) => {
                return (<p style={{ width: '100px' }}>{e?.name || '----'}</p>)
            }
        },
        {
            title: selectedLanguage?.title_GameType,
            dataIndex: 'gameId',
            key: 'gameType',
            render: (e) => {
                return (
                    <p style={{ width: '160px' }}>{e?.gameType || '----'}</p>
                )
            }
        },
        {
            title: selectedLanguage?.text_TournamentName,
            dataIndex: 'tournamentName',
            key: 'tournamentName',
            render: (e) => {
                return (
                    <p style={{ width: '160px' }}>{e || '----'}</p>
                )
            }
        },
        {
            title: selectedLanguage?.text_TournamentType,
            dataIndex: 'tournamentType',
            key: 'tournamentType',
            render: (e) => {
                return (
                    <p style={{ width: '160px' }}>{e || '----'}</p>
                )
            }
        },
        {
            title: selectedLanguage?.label_TournamentDate,
            dataIndex: 'tournamentDate',
            key: 'tournamentDate',
            render: (e) => {
                return (
                    <p style={{ width: '120px' }}>{e || '----'}</p>
                )
            }
        },
        {
            title: selectedLanguage?.label_TimeToPlayFirstRound,
            dataIndex: 'timeFirstRound',
            key: 'timeFirstRound',
            render: (e) => {
                return (
                    <p style={{ width: '150px' }}>{e || '----'}</p>
                )
            }
        },
        {
            title: selectedLanguage?.title_OrganizerName,
            key: 'organizerName',
            render: (e) => {
                return (
                    <p style={{ width: '160px' }}>{e?.ownerType === 'admin' ?
                        e?.ownerType : `${e?.ownerPlayerId?.name}-${e?.ownerType}`}</p>
                )
            }
        },
        {
            title: selectedLanguage?.title_RankingNeeded,
            dataIndex: 'rankingNeeded',
            key: 'rankingNeeded',
            render: (e) => {
                return (
                    <p style={{ width: '120px' }}>{e || '----'}</p>
                )
            }
        },
        {
            title: selectedLanguage?.title_Fee,
            render: (e) => {
                return (
                    e?.invitesContacts?.includes(user?._id) ?
                        < div style={{ display: 'flex', flexDirection: 'column' }}>
                            <p style={{
                                width: '80px',
                                color: '#ec671b',
                                background: ' #ec671b38',
                                padding: '4px 10px',
                                textAlign: 'center',
                                borderRadius: ' 20px'
                            }}>{selectedLanguage?.info_Free}</p>
                            <p style={{
                                width: '120px',
                            }}>{selectedLanguage?.info_youAreInvited}</p>
                        </div> :
                        < div style={{ display: 'flex', flexDirection: 'column' }}>
                            <p style={{ width: '120px' }}>{e?.isStake ? `$ ${e?.participationMoney}-${selectedLanguage?.text_money}` : `${e?.participationFee}-${selectedLanguage?.text_points}`}</p>
                            {e?.iAmInvited &&
                                <p style={{
                                    width: '120px',
                                }}>{selectedLanguage?.info_youAreInvited}</p>
                            }
                        </div>

                )
            }
        },
        {
            title: selectedLanguage?.title_MaxPlayers,
            dataIndex: 'maximumPlayer',
            key: 'maximumPlayer',
            render: (e) => {
                return (
                    <p style={{ width: '120px' }}>{e || 0}</p>
                )
            }
        },
        {
            title: selectedLanguage?.title_Registered,
            dataIndex: 'tournamentParticipate',
            key: 'tournamentParticipate',
            render: (e) => {
                return (
                    <p style={{ width: '120px' }}>{e?.length || 0}</p>
                )
            }
        },
        {
            title: selectedLanguage?.Text_invitesContact,
            dataIndex: 'invitesContacts',
            key: 'invitesContacts',
            render: (e) => {
                return (
                    <p style={{ width: '120px' }}>{e?.length || '---'}</p>
                )
            }
        },
        {
            title: selectedLanguage?.text_Private,
            key: 'isPrivate',
            render: (e) => {
                return (
                    <>
                        {
                            e?.isPrivate && e?.ownerType === 'admin' ? selectedLanguage?.text_AdminPrivate :
                                e?.isPrivate && e?.ownerType === 'player' ? selectedLanguage?.text_playerPrivate : selectedLanguage?.text_public
                        }
                    </>
                )
            }
        },
        {
            title: selectedLanguage?.text_Promotion,
            render: (e) => {
                return (
                    <>
                        {e?.isPromoter && e?.promoterId ?
                            <p className='p-promoted'>{selectedLanguage?.text_promoted}</p> :
                            <p className='p-not-promoted'>{selectedLanguage?.text_notpromoted}</p>
                        }</>
                )
            }
        },
        {
            title: selectedLanguage?.Info,
            render: (e) => {
                return (
                    <BsInfoCircleFill size={20}
                        color='#ec671b'
                        onClick={() => {
                            history?.push({ pathname: allPaths?.TOURNAMENT_PARTICIPATION, state: e });
                        }} />
                )
            }
        },
        {
            title: selectedLanguage?.title_Action,
            key: 'Action',
            render: (e) => {
                let isSubscribed = e?.tournamentParticipate?.some(item => item.userId._id === user?._id);
                let timeFirstRound = e?.timeFirstRound
                let tournamentDate = e?.tournamentDate
                const disabledDateTime = tournamentDate + ' ' + timeFirstRound
                const disabledBefore = e?.withdrawTimeInHours
                const isPast = isDisabledSubscribe(tournamentDate, timeFirstRound);
                const isWithinPeriod = isWithinDisabledPeriod(disabledDateTime, disabledBefore);
                return (
                    <div style={{ display: 'flex' }}>
                        {
                            (isPast || e?.tournamentHistory?.length) ?
                                <Button
                                    onClick={() => { setviewTournament(e) }}
                                    type='primary'
                                > {selectedLanguage?.button_View}</Button> :
                                null
                        }
                        &nbsp;
                        {isSubscribed ?
                            <Button
                                type='primary'
                                danger
                                onClick={() => {
                                    setCurrentWithdrawalData(e)
                                }}
                                disabled={isWithinPeriod}
                                loading={withdrawalLoading[e?._id]}
                            > {selectedLanguage?.buttonText_Withdrawal}</Button>
                            :
                            e?.tournamentParticipate?.length == e?.maximumPlayer ?
                                <p style={{
                                    background: '#00aeff',
                                    padding: '5px',
                                    width: '120px',
                                    color: 'white',
                                    borderRadius: '1px',
                                    textAlign: 'center'
                                }}>{selectedLanguage?.buttonText_Fullybocked}</p>
                                :
                                isPast ?
                                    <Button
                                        loading={subscribeLoading[e?._id]}
                                        type='primary'
                                    > {selectedLanguage?.buttonText_Subscribe}</Button>
                                    :
                                    <Button
                                        type='primary'
                                        loading={subscribeLoading[e?._id]}
                                        onClick={() => {
                                            if (e?.isStake) {
                                                setDollerTournamentData(e)
                                            }
                                            else if (e?.isPromoter && e?.promoterId) {
                                                setCurrentPromotedSubscribeData(e)
                                            }
                                            else {
                                                setCurrentSubscribeData(e)
                                            }
                                        }}
                                    > {selectedLanguage?.buttonText_Subscribe}
                                    </Button>}

                        {e?.ownerPlayerId?._id === user?._id ?
                            e?.tournamentParticipate?.length ? null :
                                <div style={{ display: 'flex' }}>
                                    &nbsp;
                                    <Button
                                        type='primary'
                                        onClick={() => {
                                            setEditData(e)
                                        }}
                                    >{selectedLanguage?.buttonText_Edit}
                                    </Button >
                                    &nbsp;
                                    {e?.tournamentParticipate?.length ? null : <Popconfirm
                                        title={`${selectedLanguage?.textDelete} ${convertTitle(`${e?.tournamentName}`)} ${selectedLanguage?.title_Tournament} ?`}
                                        okText={selectedLanguage?.textDelete}
                                        cancelText={selectedLanguage?.textCancel}
                                        okButtonProps={{
                                            type: 'primary',
                                            className: 'form-button'
                                        }}
                                        cancelButtonProps={{ style: { borderRadius: 4 } }}
                                        onConfirm={() => {
                                            deleteTournament(e?._id)
                                        }}
                                    >
                                        <Button
                                            type='primary'
                                            loading={deleteLoading[e?._id]}
                                        >{selectedLanguage?.buttonText_Delete}
                                        </Button>
                                    </Popconfirm>}
                                </div> :
                            null
                        }
                    </div>
                )
            }
        }
    ]

    const handleClose = () => {
        setPrivateTournamentData({})
        setPrivateCodeValue('')
    }

    function handlePrivateCodeChange(event) {
        const { value } = event.target;
        const trimmedValue = value.trim()
        setPrivateCodeValue(trimmedValue);
    }
    function handlecouponCodeChange(e) {
        let newCode = e?.target?.value?.replace(/[^A-Za-z0-9]/g, '')
        newCode = newCode?.replace(/(.{3})/g, '$1-')
        newCode = newCode?.replace(/-$/, '')
        setCouponCodeValue(newCode);
    }

    return (
        <div >

            {viewTournament?._id &&
                <TournamentGraph onCancel={() => setviewTournament({})} viewTournament={viewTournament} {...props} />}
            {
                editdata?._id ?

                    <div style={{ marginTop: '40px', background: 'black' }}>
                        <div style={{ display: 'flex', marginLeft: '20px', justifyContent: 'flex-start', alignItems: 'center' }}>
                            <IoIosArrowBack
                                onClick={() => {
                                    setEditData({})
                                }}
                                style={{ marginRight: '5px', color: 'white', marginTop: 2 }}
                                size={28}
                                className='orang-color'
                            />
                            <p className='new-screen-title orang-color'
                                style={{ color: 'white' }}
                            >{selectedLanguage?.button_updateTournament}</p>
                        </div>
                        <CreatePlayerTournament {...props} edit={editdata} editSuccess={editSuccess} />
                    </div>
                    :
                    <div className='new-main-component-screen-div'>
                        <h1 className='new-screen-title'>
                            {selectedLanguage?.title_Tournament}
                        </h1>

                        <div style={{ marginTop: '20px' }}>
                            <div className="custom-table-container">
                                <Table
                                    locale={{ emptyText: selectedLanguage?.txt_NoDataFound }}
                                    className='new-layout-table'
                                    dataSource={tournaments}
                                    columns={columns}
                                    pagination={false}
                                    scroll={{ x: 800 }}
                                    loading={loading}
                                />
                            </div>
                        </div>


                        {/* private subscribe */}
                        <Modal
                            open={PrivateTournamentData?._id}
                            handleClose={handleClose}
                            onCancel={handleClose}
                            footer={null}
                            className='pop-info-modal'
                            style={{ top: '10px' }}
                        >
                            <p className='p-question'>{selectedLanguage?.text_SubscribePrivateTournament}</p>
                            <div className='modal-content-div'>
                                <span  >
                                    <p className='p-none-margin-bolder' style={{ color: 'white' }}>
                                        {selectedLanguage?.text_youNeedPrivateCodetoSubscribePrivateTournament}
                                    </p>
                                    <p className='p-none-margin-bolder' style={{ color: 'white' }}>
                                        {selectedLanguage?.text_ContactAdminToGetPrivateCode}
                                    </p>
                                </span>
                                <div style={{ marginTop: '15px' }}>
                                    <p style={{ margin: '0px', padding: '4px', color: 'white' }}>{selectedLanguage?.text_PrivateCode}</p>
                                    <Input
                                        value={privateCodeValue}
                                        onChange={handlePrivateCodeChange}
                                        className='form-input-new-design-big-slam'
                                        placeholder={selectedLanguage?.text_EnterPrivateCode}
                                    />
                                    <>
                                        {!privateCodeValue ?
                                            <div style={{ display: 'flex', justifyContent: 'flex-end', }}>
                                                <button
                                                    type='primary'
                                                    className='same-button-not-allowed'
                                                >
                                                    {selectedLanguage?.btn_Subscribe}
                                                </button>
                                            </div>
                                            :
                                            <div style={{ display: 'flex', justifyContent: 'flex-end', }}>
                                                <Button
                                                    type='primary'
                                                    disabled={!privateCodeValue}
                                                    className='addproduct-text '
                                                    style={{ height: '45px', width: '180px', marginTop: '10px' }}
                                                    htmlType='submit'
                                                    loading={PrivateLoading}
                                                    onClick={() => {
                                                        handlePrivateSubscription(PrivateTournamentData)
                                                    }}
                                                >
                                                    {selectedLanguage?.btn_Subscribe}
                                                </Button>
                                            </div>
                                        }
                                    </>
                                </div>
                            </div>
                        </Modal>
                        {/* private subscribe */}
                        {/* ------------------------------------------------------ */}
                        {/* confirm subscribe */}
                        <Modal
                            open={currentSubscribeData?._id}
                            footer={null}
                            className='pop-info-modal'
                            style={{ top: '10px' }}
                        >
                            <div>
                                <p className='p-question'>{selectedLanguage?.text_AreYouSureYouWantToSubscribeTournament}</p>
                                <p className='p-opt'>{selectedLanguage?.text_IfYouSubscribePointsWillBeDeductFromYourAccount}</p>
                                <p className='p-opt'>{selectedLanguage?.text_point}:{currentSubscribeData?.participationFee}</p>
                                <p className='p-opt'>{selectedLanguage?.text_penaltyPoint}:{currentSubscribeData?.participationFee}</p>
                                {currentSubscribeData?.invitesContacts?.includes(user?._id) &&
                                    <p className='p-opt'> {selectedLanguage?.txt_WeOnlyDeductPalentyPoints_YouAreFreeInvitedInThisTournamemt}</p>
                                }
                                <p className='p-opt'>{selectedLanguage?.text_TotalPoint}:
                                    {currentSubscribeData?.invitesContacts?.includes(user?._id)
                                        ? currentSubscribeData?.participationFee
                                        : currentSubscribeData?.participationFee + currentSubscribeData?.participationFee
                                    }
                                </p>
                            </div>
                            <div style={{ display: 'flex', marginTop: 10 }}>
                                <button className='cancel-btn' onClick={handleCancelSubscribeModal}>{selectedLanguage?.text_Cancel}</button>
                                <button className='confirm-btn' onClick={handleOKSubscribeModal}>{selectedLanguage?.btn_Subscribe}</button>
                            </div>

                        </Modal>
                        {/* confirm subscribe */}
                        {/* ------------------------------------------------------ */}
                        {/* Withdrawal/cancel subscribe */}
                        <Modal
                            open={currentWithdrawalData?._id}
                            footer={null}
                            className='pop-info-modal'
                            style={{ top: '10px', }}
                        >
                            <div>
                                <p className='p-question'>{selectedLanguage?.text_AreYouSureYouWantToWithdrawalCancelATournamet}</p>
                                {currentWithdrawalData?.promoterId?._id &&
                                    <div style={{ marginTop: '10px', marginBottom: '10px' }}>
                                        <p>{selectedLanguage?.text_advertisement}</p>
                                        <Image style={{ width: '100%', boxShadow: 'darkgrey 0px 0px 9px', borderRadius: '8px' }} src={currentWithdrawalData?.promoterId?.tournamentFile?.url} />
                                    </div>}
                                <p className='p-opt'>{selectedLanguage?.text_byWithdrawalYouWillGetPointMoneyHalfOfPalenty}</p>
                            </div>
                            <div style={{ display: 'flex', marginTop: 10 }}>
                                <button className='cancel-btn' onClick={handleCancelWithdrawalModal}>{selectedLanguage?.text_Cancel}</button>
                                <button className='confirm-btn' onClick={handleOKWithdrawalModal}>{selectedLanguage?.buttonText_Withdrawal}</button>
                            </div>
                        </Modal>
                        {/* Withdrawal/cancel subscribe*/}
                        {/* ------------------------------------------------------ */}

                        {/* promoted subscribe */}
                        <Modal
                            open={currentPromotedSubscribeData?._id}
                            footer={null}
                            className='pop-info-modal'
                            style={{ top: '10px' }}
                        >
                            <div>
                                <p className='p-question'>{selectedLanguage?.text_AreYouSureYouWantToSubscribeTournament}</p>
                                {currentPromotedSubscribeData?.promoterId?._id &&
                                    <div style={{ marginTop: '10px', marginBottom: '10px' }}>
                                        <p>{selectedLanguage?.text_advertisement}</p>
                                        <Image style={{ width: '100%', boxShadow: 'darkgrey 0px 0px 9px', borderRadius: '8px' }} src={currentPromotedSubscribeData?.promoterId?.tournamentFile?.url} />
                                    </div>
                                }
                                <p className='p-opt'>{selectedLanguage?.text_IfYouSubscribePointsWillBeDeductFromYourAccount}</p>
                                <p className='p-opt'>{selectedLanguage?.text_point}:{currentPromotedSubscribeData?.participationFee}</p>
                                <p className='p-opt'>{selectedLanguage?.text_penaltyPoint}:{currentPromotedSubscribeData?.participationFee}</p>
                                <p className='p-opt'>{selectedLanguage?.text_TotalPoint}:{currentPromotedSubscribeData?.participationFee + currentPromotedSubscribeData?.participationFee}</p>
                            </div>
                            <div style={{ background: 'black', padding: 4 }}>
                                <p style={{ marginLeft: '4px', color: '#ec671b', cursor: 'pointer' }}
                                    onClick={() => setShowCouponInput(!showCouponInput)}>{!showCouponInput ? selectedLanguage?.text_DoYouCanCouponCode : selectedLanguage?.text_idonthavecouponcode}</p>
                                {showCouponInput &&
                                    <Input
                                        value={CouponCodeValue}
                                        onChange={handlecouponCodeChange}
                                        className='form-input-new-design-big-slam'
                                        placeholder={'###-###-###'}
                                    />}
                            </div>
                            <div style={{ display: 'flex', marginTop: 10 }}>
                                <button className='cancel-btn' onClick={handleCancelPromotedSubModal}>{selectedLanguage?.text_Cancel}</button>
                                <button className='confirm-btn' onClick={handleOKPromotedSubModal}>{loadingPromoted && <Spin />}{selectedLanguage?.btn_Subscribe}</button>
                            </div>
                        </Modal>

                        {/* promoted subscribe */}

                        {dollerTournamentData?._id &&
                            <DollerTournamentSubscribeModal
                                data={dollerTournamentData}
                                handleClose={() => setDollerTournamentData({})}
                                {...props} />
                        }


                    </div >
            }
        </div >
    )
}


export default PlayerTournamentSelect