import React, { useEffect, useState } from 'react'
import { FaInfoCircle } from "react-icons/fa"
import { Button, Form, Input, Modal, Select } from 'antd'
import axios from 'axios'
import { POST } from '../../../utils/apis'
import { assignMessage, errorMessage, successMessage } from '../../../utils/helpers'

const SendInvitation = (props) => {
    const { Option } = Select
    const { selectedLanguage, language, user, visible, handleOk, handleSuccess, myGameSetting, refferalPoints } = props
    const [loading, setLoading] = useState(false)
    const [form] = Form.useForm()

    const onFinish = (values) => {
        values.userId = user?._id
        values.point = refferalPoints?.point || 0
        setLoading(true)
        axios.post(POST?.INVITE_FRIEND_TO_WGP, values)
            .then((res) => {
                const { data } = res
                setLoading(false)
                if (data?.success) {
                    successMessage(assignMessage(data?.message, language?.language))
                    handleSuccess()
                    form.resetFields()
                }
                else {
                    errorMessage(assignMessage(data?.message, language?.language))
                }
            })
            .catch((e) => {
                setLoading(false)
                errorMessage(assignMessage(e?.message, language?.language))
            })
    }

    return (
        <div>
            <Modal
                open={visible}
                onOk={handleOk}
                onCancel={handleOk}
                className='pop-info-modal'
                footer={null}>
                <div>
                    <p className='p-question'>
                        {selectedLanguage?.title_SendInvitationYourFriend}
                    </p>
                    <span className='p-none-margin-bolder'
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            paddingTop: '10px',
                            paddingTop: '10px',
                            color: 'white',
                            background: '#3a3a3a'
                        }}>
                        <FaInfoCircle size={20} style={{ color: '#ec671b', marginRight: '10px' }} />
                        {selectedLanguage?.text_YouGet}
                        <span style={{
                            paddingLeft: 4,
                            paddingRight: 4,
                            textAlign: 'center',
                            color: '#ec671b',
                            margin: '0px'
                        }}> {refferalPoints?.point || '--'}
                        </span>
                        {' '}{selectedLanguage?.tableText_Points}

                    </span>
                    <span className='p-none-margin-bolder'
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            paddingTop: '10px',
                            paddingLeft: '25px',
                            color: 'white',
                            background: '#3a3a3a'
                        }}>{selectedLanguage?.text_pointWhenTheInvitedFreindGetRegistered}
                    </span>
                    <div className='form-div-in-modal'>
                        <Form
                            name='form'
                            form={form}
                            onFinish={onFinish}
                            layout={'vertical'}
                        >
                            <div className='form-two-input-flex-inner-input' style={{ width: '100%' }}>
                                <Form.Item
                                    name='gameId'
                                    hasFeedback
                                    label={<p
                                        className='p-admin-title-text'
                                    >{selectedLanguage?.text_SelectGameInWhichPointsYouWant}</p>}
                                    rules={[
                                        {
                                            required: true,
                                            message: selectedLanguage?.error_pleaseSelectGame
                                        }
                                    ]}
                                >
                                    <Select
                                        className='form-input-new-design-big-slam'
                                        notFoundContent={selectedLanguage?.txt_NoDataFound}
                                        placeholder={selectedLanguage?.placeHolder_SelectGamefortournament}>
                                        {myGameSetting?.length && myGameSetting?.map((v, i) => {
                                            return (
                                                <Option value={v?.gameId?._id}>{`${v?.gameId?.name} - ${v?.gameId?.gameType} - ${v?.gameId?.description} `}</Option>
                                            )
                                        })}
                                    </Select>
                                </Form.Item>
                            </div>
                            <div className='form-two-input-flex-inner-input' style={{ width: '100%' }}>
                                <Form.Item
                                    name='email'
                                    label={<p
                                        className='p-admin-title-text'
                                    >{selectedLanguage?.text_EnterYourFriendEmail}</p>}
                                    rules={[
                                        {
                                            required: true,
                                            message: selectedLanguage?.text_PleaseEnterYourFriendEmail
                                        },
                                        {
                                            type: 'email',
                                            message: selectedLanguage?.error_EmailIsNotValid
                                        },
                                    ]}
                                >
                                    <Input
                                        className='form-input-new-design-big-slam'
                                        placeholder={selectedLanguage?.placeholder_EnterYourFriendEmail}
                                    />
                                </Form.Item>
                            </div>
                            <div className='form-two-input-flex-inner-input' style={{ width: '100%' }}>
                                <Form.Item
                                    name='name'
                                    label={<p
                                        className='p-admin-title-text'
                                    >{selectedLanguage?.text_EnterYourFriendsName}</p>}
                                    rules={[
                                        {
                                            required: true,
                                            message: selectedLanguage?.err_PleaseEnterYourFriendsName
                                        },
                                    ]}
                                >
                                    <Input
                                        className='form-input-new-design-big-slam'
                                        placeholder={selectedLanguage?.placeholder_EnterYourFriendName}
                                    />
                                </Form.Item>
                            </div>
                            <div style={{ display: 'flex', justifyContent: 'flex-end', }}>
                                <Form.Item>
                                    <Button
                                        type='primary'
                                        htmlType='submit'
                                        loading={loading}
                                    >
                                        {selectedLanguage?.text_Sent}
                                    </Button>
                                </Form.Item>
                            </div>
                        </Form>
                    </div>
                </div>
            </Modal>
        </div>
    )
}
export default SendInvitation