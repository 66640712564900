const LOGIN_USER = 'LOGIN_USER'
const REMOVE_USER = 'REMOVE_USER'
const LANGUAGE = 'LANGUAGE'
const MENU_NONE = 'MENU_NONE'
const GAMES = 'GAMES'
const GAMES_SETTINGS = 'GAMES_SETTINGS'
const PLAYER_CONTACTS = 'PLAYER_CONTACTS'
const PLAYERS = 'PLAYERS'
const GAME_SUGGESTIONS = 'GAME_SUGGESTIONS'
const USER_TOURNAMENTS = 'USER_TOURNAMENTS'
const USER_RANKS = 'USER_RANKS'
const USER_PRO_RANKS = 'USER_PRO_RANKS'
const PROMOTER = 'PROMOTER'
const ADMIN_TOURNAMENT = 'ADMIN_TOURNAMENT'
const DOLLER_MATCH_REQ_SEND_FEE = 'DOLLER_MATCH_REQ_SEND_FEE'
const DOLLER_TOURNAMENT_SUB_FEE = 'DOLLER_TOURNAMENT_SUB_FEE'
const ADMIN_ALL_PLAYER = 'ADMIN_ALL_PLAYER'
const DOLLER_TOURNAMENT_ORGANIZER = 'DOLLER_TOURNAMENT_ORGANIZER'
const TOURNAMENTS = 'TOURNAMENTS'
const TOURNAMENTS_MATCHES = 'TOURNAMENTS_MATCHES'
const SENT_MATCHES = 'SENT_MATCHES'
const RECEIVE_MATCHES = 'RECEIVE_MATCHES'
const MATCH_RESULTS = 'MATCH_RESULTS'
const CASHBACK_SERVICE_FEE = 'CASHBACK_SERVICE_FEE'
const FAQ = 'FAQ'
const WALLET_TRANSACTIONS = 'WALLET_TRANSACTIONS'
const ALL_COMPETITIONS = 'ALL_COMPETITIONS'
const COMPETITIONS_RANKS = 'COMPETITIONS_RANKS'
const SENT_COMPETITION_MATCHES = 'SENT_COMPETITION_MATCHES'
const RECEIVE_COMPETITION_MATCHES = 'RECEIVE_COMPETITION_MATCHES'
const COMPETITION_MATCH_RESULTS = 'COMPETITION_MATCH_RESULTS'
const SUBSCRIBED_COMPETITION = 'SUBSCRIBED_COMPETITION'
const COMPETITION_SUB_SERVICE_FEE = 'COMPETITION_SUB_SERVICE_FEE'

export {
    LOGIN_USER,
    REMOVE_USER,
    LANGUAGE,
    MENU_NONE,
    GAMES,
    GAMES_SETTINGS,
    PLAYER_CONTACTS,
    PLAYERS,
    GAME_SUGGESTIONS,
    USER_TOURNAMENTS,
    USER_RANKS,
    PROMOTER,
    ADMIN_TOURNAMENT,
    USER_PRO_RANKS,
    DOLLER_MATCH_REQ_SEND_FEE,
    DOLLER_TOURNAMENT_SUB_FEE,
    ADMIN_ALL_PLAYER,
    DOLLER_TOURNAMENT_ORGANIZER,
    TOURNAMENTS,
    TOURNAMENTS_MATCHES,
    SENT_MATCHES,
    RECEIVE_MATCHES,
    MATCH_RESULTS,
    CASHBACK_SERVICE_FEE,
    FAQ,
    WALLET_TRANSACTIONS,
    ALL_COMPETITIONS,
    COMPETITIONS_RANKS,
    SENT_COMPETITION_MATCHES,
    RECEIVE_COMPETITION_MATCHES,
    COMPETITION_MATCH_RESULTS,
    SUBSCRIBED_COMPETITION,
    COMPETITION_SUB_SERVICE_FEE
}
