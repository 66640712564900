import { Table } from 'antd'
import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { GET } from '../../../utils/apis'
import { adjustDataForRank, assignMessage, errorMessage } from '../../../utils/helpers'

const AllPlayerCompetitionRank = (props) => {
    const { selectedLanguage, language, selectedCompetition, user } = props
    const [loading, setLoading] = useState(false)
    const [CompetitionRanks, setCompetitionRanks] = useState([])
    console.log('CompetitionRanks**', CompetitionRanks)

    useEffect(() => {
        getCompetitionRanks()
    }, [selectedCompetition])

    const getCompetitionRanks = () => {
        setLoading(true)
        axios.get(`${GET?.GET_PARTICULAR_COMPETITION_RANKS}/${selectedCompetition}`,)
            .then((res) => {
                const { data } = res
                setLoading(false)
                if (data?.success) {
                    setCompetitionRanks(adjustDataForRank(data?.data) || [])
                }
                else {
                    setCompetitionRanks([])
                }
            })
            .catch((e) => {
                setLoading(false)
                errorMessage(assignMessage(e?.message, language?.language))
            })
    }

    const columns = [
        {
            title: selectedLanguage?.title_PlayerName,
            render: (e) => {
                return (
                    <p>
                        {e?.userId?._id === user?._id ? e?.userId?.name + (selectedLanguage?.text_me) : e?.userId?.name}
                    </p>
                )
            }
        },
        {
            title: selectedLanguage?.tableText_Ranking,
            render: (e) => {
                return (
                    <p>
                        {e?.rank}
                    </p>
                )
            }
        },
        {
            title: `${selectedLanguage?.tableText_Points}-($)`,
            render: (e) => {
                return (
                    <p>
                        {e?.point}
                    </p>
                )
            }
        },
    ]
    return (
        <div className="custom-table-container">
            <Table
                locale={{ emptyText: selectedLanguage?.txt_NoDataFound }}
                className='new-layout-table'
                dataSource={CompetitionRanks}
                columns={columns}
                pagination={false}
                scroll={{ x: 1000 }}
            />
        </div>
    )
}

export default AllPlayerCompetitionRank