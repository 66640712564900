import React, { useEffect, useState } from 'react'
import { Button, Image, Input, Modal, Table } from 'antd'
import { BTW_TEX, assignMessage, convertRoundNumber, errorMessage, getGeneralData, getUserData, successMessage } from "../../../utils/helpers"
import { useSelector } from 'react-redux'
import axios from 'axios'
import { POST } from '../../../utils/apis'
import moment from 'moment'
import { GrContactInfo } from 'react-icons/gr'
import { useHistory } from 'react-router-dom'
import { allPaths, currencySign } from '../../../utils/constants'

const CompetitionSelect = (props) => {
    const { selectedLanguage, user, generalActions, language, getUser, userActions } = props
    const allCompetitions = useSelector(state => state.generalReducer.allCompetitions)
    const AdminServiceFee = useSelector(state => state?.pointSettingReducer)
    const history = useHistory()
    const tournamentServiceFee = AdminServiceFee?.competitionSubServiceFee
    const tournamentServiceBTW = convertRoundNumber((tournamentServiceFee * BTW_TEX))
    const tournamentServiceFeeWithBTW = convertRoundNumber(tournamentServiceFee + (tournamentServiceFee * BTW_TEX))
    const [loading, setLoading] = useState({})
    const [competitionData, setCompetitionData] = useState({})
    const [coupouncode, setCoupounCode] = useState('')
    const [showInput, setShowInput] = useState(false)

    const handlePrivateCodeChange = (e) => {
        let newCode = e?.target?.value?.replace(/[^A-Za-z0-9]/g, '')
        newCode = newCode?.replace(/(.{3})/g, '$1-')
        newCode = newCode?.replace(/-$/, '')
        setCoupounCode(newCode)
    }

    const handleCancelSubscribeModal = () => {
        setCompetitionData({})
    }
    const handleOKSubscribeModal = () => {
        showInput ?
            !coupouncode ?
                errorMessage(selectedLanguage?.text__PleaseEnterCouponcode) :
                CompetitionSubscription(competitionData) :
            CompetitionSubscription(competitionData)
    }

    const CompetitionSubscription = (values) => {
        setCompetitionData({})
        setLoading({ [values?._id]: true })
        if (user?.walletBalance > values?.participationMoney) {
            let obj = {
                competitionId: values?._id,
                userId: user?._id,
                gameId: values?.gameId?._id,
            }
            showInput && (obj.couponCode = coupouncode)
            axios.post(POST?.COMPETITION_SUBSCRIPTION, obj)
                .then((res) => {
                    const { data } = res
                    setLoading({})
                    if (data?.success) {
                        successMessage(assignMessage(data?.message, language?.language))
                        getUserData(user?._id, userActions)
                        getGeneralData(generalActions)
                        getUser()
                    }
                    else {
                        errorMessage(assignMessage(data?.message, language?.language))
                    }
                })
                .catch((e) => {
                    setLoading({})
                    errorMessage(assignMessage(e?.message, language?.language))
                })
        }
        else {
            errorMessage(selectedLanguage?.text_walletBalanceIsZeroOrLessThenParticipationFee)
            setLoading({})
        }
    }

    useEffect(() => {
        getUser()
        getGeneralData(generalActions)
    }, [])


    const columns = [
        {
            title: selectedLanguage?.text_Image,
            render: (e) => {
                return (
                    <Image src={e?.imageObj?.url} style={{ marginRight: 10, width: 86, height: 50 }} />
                )
            }
        },
        {
            title: selectedLanguage?.text_Name,
            render: (e) => {
                return (
                    <p className='table_p' style={{ width: '110px' }}>{e?.competitionName || '-----'}</p>
                )
            }
        },
        {
            title: selectedLanguage?.text_StartDate,
            render: (e) => {
                return (
                    <p className='table_p' style={{ width: '110px' }}>{e?.competitionDuration?.date1 || '-----'}</p>
                )
            }
        },
        {
            title: selectedLanguage?.text_EndDate,
            render: (e) => {
                return (
                    <p className='table_p' style={{ width: '110px' }}>{e?.competitionDuration?.date2 || '-----'}</p>
                )
            }
        },
        {
            title: selectedLanguage?.text_Level,
            render: (e) => {
                return (
                    <p className='table_p' style={{ width: '110px' }}>{e?.level || '-----'}</p>
                )
            }
        },
        {
            title: selectedLanguage?.label_Maximumplayer,
            render: (e) => {
                return (
                    <p className='table_p' style={{ width: '110px' }}>{e?.maximumPlayer || '-----'}</p>
                )
            }
        },
        {
            title: selectedLanguage?.title_Fee,
            render: (e) => {
                return (
                    <p className='table_p' style={{ width: '110px' }}>{currencySign} {convertRoundNumber(e?.participationMoney)}</p>
                )
            }
        },
        {
            title: selectedLanguage?.text_Result,
            render: (e) => {
                return (
                    <GrContactInfo
                        color='white'
                        size={30}
                        style={{
                            background: 'black',
                            borderRadius: '100px',
                            padding: '4px',
                            cursor: 'pointer'
                        }}
                        onClick={() => {
                            e?.isCompleted ?
                                history?.push({ pathname: allPaths?.COMPETITION_RESULT, state: e }) :
                                errorMessage(selectedLanguage?.txt_competition_is_not_compeleted_yet)
                        }} />
                )
            }
        },
        {
            title: selectedLanguage?.title_Action,
            render: (e) => {
                let isSubscribed = e?.competitionSubscribers?.some(item => item.userId?._id === user?._id);

                return (
                    <>
                        {isSubscribed || moment(e?.competitionDuration?.date1).subtract(1, 'day')?.isBefore(moment(), 'days') ?
                            <button
                                style={{
                                    height: '35px',
                                    width: '140px',
                                    marginTop: '10px',
                                    background: '#ec671b6e',
                                    border: 'none',
                                    cursor: 'not-allowed',
                                    color: 'white'
                                }}
                            >
                                {isSubscribed ? selectedLanguage?.txt_subscribed : selectedLanguage?.txt_AlreadyStarted}
                            </button>
                            :
                            e?.maximumPlayer === e?.competitionSubscribers ?
                                <p style={{
                                    background: '#00aeff',
                                    padding: '5px',
                                    width: '120px',
                                    color: 'white',
                                    borderRadius: '1px',
                                }}>{selectedLanguage?.buttonText_Fullybocked}</p>
                                : <Button
                                    className='addproduct-text'
                                    type='primary'
                                    style={{ height: '35px', width: '140px', marginTop: '10px' }}
                                    loading={loading[e?._id]}
                                    onClick={() => {
                                        setCompetitionData(e)
                                    }}
                                >
                                    {selectedLanguage?.btn_Subscribe}
                                </Button>}
                    </>

                )
            }
        },
    ]

    return (
        <div className='new-main-component-screen-div'>
            <h1 className='new-screen-title'>
                {selectedLanguage?.text_CompetitionSelect}
            </h1>

            <div style={{ marginTop: '20px' }}>
                <div className="custom-table-container">
                    <Table
                        locale={{ emptyText: selectedLanguage?.txt_NoDataFound }}
                        className='new-layout-table'
                        dataSource={allCompetitions}
                        columns={columns}
                        pagination={false}
                        scroll={{ x: 1000 }}
                    />
                </div>
            </div>
            {/* confirm subscribe */}
            <Modal
                open={competitionData?._id}
                footer={null}
                className='pop-info-modal'
                style={{ top: '10px' }}
            >
                <div>
                    <p className='p-question'>{selectedLanguage?.txt_Are_you_sure_you_want_to_subscribe_competition}</p>
                    <p className='p-opt'>{selectedLanguage?.text_IfYouSubscribePointsWillBeDeductFromYourAccount}</p>
                    <p className='p-opt'>{selectedLanguage?.txt_Fee}:{currencySign} {`${convertRoundNumber(competitionData?.participationMoney)}`}</p>
                    <p className='p-opt'>{selectedLanguage?.text_AdminserviceFee}: {currencySign} {convertRoundNumber(tournamentServiceFee || 0)}</p>
                    <p className='p-opt'>{selectedLanguage?.text_tmntyoneBTW}:{currencySign} {convertRoundNumber(tournamentServiceBTW)}</p>
                    <p className='p-opt'>{selectedLanguage?.text_AdminserviceFeeBTW}:{currencySign} {convertRoundNumber(tournamentServiceFeeWithBTW)}</p>
                    <p className='p-opt'>{selectedLanguage?.text_TotalinclBTW}:{currencySign} {convertRoundNumber((competitionData?.participationMoney) + (+tournamentServiceFeeWithBTW))}</p>
                </div>
                {!showInput ?
                    <p
                        onClick={() => {
                            setShowInput(true)
                        }}
                        style={{
                            fontWeight: 'bold',
                            margin: '0px',
                            marginTop: '10px',
                            color: '#ec671b',
                            cursor: 'pointer'
                        }}>{selectedLanguage?.txt_Do_you_have_a_coupon_code}</p>
                    :
                    <div style={{ background: 'black', padding: 4 }}>
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <p
                                style={{
                                    fontWeight: 'bold',
                                    margin: '0px',
                                    marginTop: '10px',
                                    color: '#ec671b',
                                }}
                            >{selectedLanguage?.txt_Enter_coupon_code} </p>
                            <p
                                onClick={() => {
                                    setShowInput(false)
                                }}
                                style={{
                                    fontWeight: 'bold',
                                    margin: '0px',
                                    marginTop: '10px',
                                    color: '#ec671b',
                                    cursor: 'pointer'
                                }}
                            >{selectedLanguage?.txt_Donthavecoupon}</p>
                        </div>
                        <Input
                            value={coupouncode}
                            onChange={handlePrivateCodeChange}
                            className='form-input-new-design-big-slam'
                            placeholder={'###-###-###'}
                            maxLength={11}
                        />
                    </div>
                }
                <div style={{ display: 'flex', marginTop: 10 }}>
                    <button className='cancel-btn' onClick={handleCancelSubscribeModal} >{selectedLanguage?.text_Cancel}</button>
                    <button className='confirm-btn' onClick={handleOKSubscribeModal} >{selectedLanguage?.btn_Subscribe}</button>
                </div>
            </Modal>
            {/* confirm subscribe */}
            {/* ------------------------------------------------------ */}

        </div >
    )
}

export default CompetitionSelect