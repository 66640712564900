import React, { useEffect, useState } from 'react'
import { IoIosArrowBack } from "react-icons/io"
import { ADMIN } from "../../../utils/apis"
import { Button, Popconfirm, Table } from 'antd'
import axios from 'axios'
import { assignMessage, convertRoundNumber, convertTitle, errorMessage, isDisabledSubscribe, successMessage } from '../../../utils/helpers'
import CreateTournament from './CreateTournaments'
import CopyToClipboard from 'react-copy-to-clipboard'
import TournamentGraph from '../../PlayerScreen/Tournament/TournamentGraph'
import { BsInfoCircleFill } from 'react-icons/bs'
import { allPaths, currencySign } from '../../../utils/constants'
import { useHistory } from 'react-router-dom'
const AdminSelectTournaments = (props) => {
    const history = useHistory()
    const { selectedLanguage, language, user, } = props
    const [tournament, setTournament] = useState([])
    const [MyTournament, setMyTournament] = useState([])
    const [loading, setLoading] = useState(false)
    const [deleteLoading, setDeleteLoading] = useState({})
    const [editdata, setEditData] = useState({})
    const [showAdminData, setShowAdminData] = useState(false)
    const [viewTournament, setviewTournament] = useState(false)

    const getAllTournament = () => {
        setLoading(true)
        axios.get(ADMIN?.GET_ALL_TOURNAMENT)
            .then((res) => {
                const { data } = res
                setLoading(false)
                if (data?.success) {
                    let adminData = data?.data?.filter((v) => v?.ownerAdminId)
                    setMyTournament(adminData || [])
                    setTournament(data?.data || [])
                }
                else {
                    setTournament(data?.data || [])
                }
            })
            .catch((error) => {
                setLoading(false)
                console.log(error)
            })
    }

    useEffect(() => {
        getAllTournament()
    }, [])

    const editSuccess = () => {
        setEditData({})
        getAllTournament()
    }

    const deleteTournament = (id) => {
        setDeleteLoading({ [id]: true })
        let obj = {
            _id: id,
            userId: user?._id
        }
        // console.log(obj, "=obj")
        axios.post(ADMIN?.DELETE_TOURNAMENT, obj)
            .then((res) => {
                const { data } = res
                setDeleteLoading({})
                if (data?.success) {
                    successMessage(assignMessage(data?.message, language?.language))
                    getAllTournament()
                }
                else {
                    errorMessage(assignMessage(data?.message, language?.language))
                }
            })
            .catch((e) => {
                setDeleteLoading({})
                errorMessage(assignMessage(e?.message, language?.language))
            })
    }

    const columns = [
        {
            title: selectedLanguage?.title_Game,
            dataIndex: 'gameId',
            key: 'gameName',
            render: (e) => {
                return (
                    <p style={{ width: '100px' }}>{e?.name || '----'}</p>
                )
            }
        },
        {
            title: selectedLanguage?.title_GameType,
            dataIndex: 'gameId',
            key: 'gameType',
            render: (e) => {
                return (
                    <p style={{ width: '160px' }}>{e?.gameType || '----'}</p>
                )
            }
        },
        {
            title: selectedLanguage?.text_TournamentName,
            dataIndex: 'tournamentName',
            key: 'tournamentName',
            render: (e) => {
                return (
                    <p style={{ width: '100px' }}>{e || '----'}</p>
                )
            }
        },
        {
            title: selectedLanguage?.text_TournamentType,
            dataIndex: 'tournamentType',
            key: 'tournamentType',
            render: (e) => {
                return (
                    <p style={{ width: '100px' }}>{e || '----'}</p>
                )
            }
        },
        {
            title: selectedLanguage?.label_TournamentDate,
            dataIndex: 'tournamentDate',
            key: 'tournamentDate',
            render: (e) => {
                return (
                    <p style={{ width: '120px' }}>{e || '----'}</p>
                )
            }
        },
        {
            title: selectedLanguage?.label_TimeToPlayFirstRound,
            dataIndex: 'timeFirstRound',
            key: 'timeFirstRound',
            render: (e) => {
                return (
                    <p style={{ width: '150px' }}>{e || '----'}</p>
                )
            }
        },
        {
            title: selectedLanguage?.title_OrganizerName,
            key: 'organizerName',
            render: (e) => {
                return (
                    <p style={{ width: '120px' }}>
                        {e?.ownerType === 'admin' ?
                            e?.ownerType || '----' :
                            `${e?.ownerPlayerId?.name}`}</p>
                )
            }
        },
        {
            title: selectedLanguage?.title_RankingNeeded,
            dataIndex: 'rankingNeeded',
            key: 'rankingNeeded',
            render: (e) => {
                return (
                    <p style={{ width: '120px' }}>{e || '----'}</p>
                )
            }
        },
        {
            title: selectedLanguage?.title_Fee,
            key: 'participationFee',
            render: (e) => {
                return (
                    <p style={{ width: '120px' }}>{e?.isStake ? `${currencySign} ${convertRoundNumber(e?.participationMoney)}-${selectedLanguage?.text_money}` : `${convertRoundNumber(e?.participationFee)}-${selectedLanguage?.text_points}`}</p>
                )
            }
        },
        {
            title: selectedLanguage?.title_MaxPlayers,
            dataIndex: 'maximumPlayer',
            key: 'maximumPlayer',
            render: (e) => {
                return (
                    <p style={{ width: '120px' }}>{e || 0}</p>
                )
            }
        },
        {
            title: selectedLanguage?.title_Registered,
            dataIndex: 'tournamentParticipate',
            key: 'tournamentParticipate',
            render: (e) => {
                return (
                    <p style={{ width: '120px' }}>{e?.length || 0}</p>
                )
            }
        },
        {
            title: selectedLanguage?.Text_invitesContact,
            dataIndex: 'invitesContacts',
            key: 'invitesContacts',
            render: (e) => {
                return (
                    <p style={{ width: '120px' }}>{e?.length || '---'}</p>
                )
            }
        },
        {
            title: selectedLanguage?.text_PrivateCode,
            key: 'privateCode',
            render: (e) => {
                return (
                    <>
                        {
                            e?.privateCode && e?.isPrivate ?
                                <CopyToClipboard text={e?.privateCode}
                                    onCopy={() => successMessage(selectedLanguage?.SuccessfullyCopiedtoClipboard)}>
                                    <Button>{selectedLanguage?.text_CopyCode}</Button>
                                </CopyToClipboard> : '----'
                        }
                    </>
                )
            }
        },
        {
            title: selectedLanguage?.txt_Promotion,
            render: (e) => {
                return (
                    <>
                        {e?.isPromoter && e?.promoterId ?
                            <p className='p-promoted'>{selectedLanguage?.text_promoted}</p> :
                            <p className='p-not-promoted'>{selectedLanguage?.text_notpromoted}</p>
                        }</>
                )
            }
        },
        {
            title: selectedLanguage?.Info,
            render: (e) => {
                return (
                    <BsInfoCircleFill size={20}
                        color='#ec671b'
                        onClick={() => {
                            history?.push({ pathname: allPaths?.TOURNAMENT_PARTICIPATION, state: e });
                        }} />
                )
            }
        },
        {
            title: selectedLanguage?.title_Action,
            key: 'Action',
            render: (e) => {
                let timeFirstRound = e?.timeFirstRound
                let tournamentDate = e?.tournamentDate
                const isPast = isDisabledSubscribe(tournamentDate, timeFirstRound);
                return (
                    <div style={{ display: 'flex' }}>
                        {
                            isPast &&
                            <Button
                                style={{ marginRight: 10 }}
                                onClick={() => { setviewTournament(e) }}
                                type='primary'
                            > {selectedLanguage?.button_View}</Button>
                        }
                        &nbsp;
                        {e?.tournamentParticipate?.length || e?.ownerType === 'player' ?
                            <p style={{ width: '80px' }}></p>
                            :
                            <Button
                                onClick={() => {
                                    setEditData(e)
                                }}
                                type='primary'
                            >
                                {selectedLanguage?.buttonText_Edit}
                            </Button >
                        }
                        &nbsp;
                        {e?.tournamentParticipate?.length ?
                            null : <Popconfirm
                                title={`${selectedLanguage?.textDelete} ${convertTitle(`${e?.tournamentName}`)} ${selectedLanguage?.title_Tournament} ?`}
                                okText={selectedLanguage?.textDelete}
                                cancelText={selectedLanguage?.textCancel}
                                okButtonProps={{
                                    type: 'primary',
                                    className: 'form-button'
                                }}
                                cancelButtonProps={{ style: { borderRadius: 4 } }}
                                onConfirm={() => {
                                    deleteTournament(e?._id)
                                }}
                            >
                                <Button
                                    loading={deleteLoading[e?._id]}
                                    type='primary'
                                >
                                    {selectedLanguage?.buttonText_Delete}
                                </Button>
                            </Popconfirm>
                        }
                    </div>
                )
            }
        },
    ]

    return (
        <>
            {viewTournament?._id &&
                <TournamentGraph onCancel={() => setviewTournament({})} viewTournament={viewTournament} {...props} />}
            {
                editdata?._id ?
                    <div style={{ background: 'black' }}>
                        <div style={{ display: 'flex', marginLeft: '20px', justifyContent: 'flex-start', alignItems: 'center' }}>
                            <IoIosArrowBack
                                onClick={() => {
                                    setEditData({})
                                    getAllTournament()
                                }}
                                className='orang-color'
                                style={{ marginRight: '5px', marginTop: '10px' }}
                                size={28} />
                            <h1 className='new-screen-title orang-color' >
                                {selectedLanguage?.button_updateTournament}
                            </h1>
                        </div>
                        <CreateTournament {...props} edit={editdata} editSuccess={editSuccess} />
                    </div>
                    :
                    <div className='new-main-component-screen-div'>
                        <h1 className='new-screen-title'>
                            {selectedLanguage?.title_Tournament}
                        </h1>

                        <span style={{ marginTop: '20px' }} className='button-div-flex-end'>
                            <Button type='primary' onClick={() => setShowAdminData(!showAdminData)}>{showAdminData ? selectedLanguage?.txt_All_tournament : selectedLanguage?.txt_My_Tournament}</Button>
                        </span>

                        <div className="custom-table-container">
                            <Table
                                locale={{ emptyText: selectedLanguage?.txt_NoDataFound }}
                                className='new-layout-table'
                                dataSource={showAdminData ? MyTournament : tournament}
                                columns={columns}
                                pagination={false}
                                scroll={{ x: 800 }}
                                loading={loading}
                            />
                        </div>
                    </div >
            }
        </>
    )
}

export default AdminSelectTournaments