import React, { useState } from 'react'

const AdminHome = (props) => {
    const { selectedLanguage, isEnglish } = props
    return (
        <div style={{ margin: '20px' }} >
            <div style={{ textAlign: 'center', marginTop: '40px' }}>
                <div className='new-screen-text-box'>
                    <p className='new-home-txts-1st'>{selectedLanguage?.title_AdminsettingsWGPplaza}</p>
                    <p className='new-home-txts-1st'>{selectedLanguage?.title_DontForgetToSaveNewSettings}</p>
                </div>
                <div className='three-boxes-card-div'>
                    <div className='ex-tion-card'>
                        <p className='div-tt'>{selectedLanguage?.buttonText_Tournament}</p>
                        <p>{selectedLanguage?.txt_loremispum_full}
                        </p>
                        <button
                            className='ex-tion-card-button' >
                            {selectedLanguage?.buttonText_Tournament} ➝
                        </button>
                    </div>
                    <div className='ex-tion-card'>
                        <p className='div-tt'>{selectedLanguage?.buttonText_UsersWebsite} </p>
                        <p>{selectedLanguage?.txt_loremispum_full}
                        </p>
                        <button
                            className='ex-tion-card-button' >
                            {selectedLanguage?.buttonText_UsersWebsite} ➝
                        </button>
                    </div>
                    <div className='ex-tion-card'>
                        <p className='div-tt'>{selectedLanguage?.buttonText_MetricReports} </p>
                        <p>{selectedLanguage?.txt_loremispum_full}
                        </p>
                        <button
                            className='ex-tion-card-button' >
                            {selectedLanguage?.buttonText_MetricReports} ➝
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AdminHome