import { Button, Popconfirm, Table } from 'antd';
import axios from 'axios';
import React, { useState } from 'react';
import { POST } from '../../../utils/apis';
import { assignMessage, convertNumber, convertRoundNumber, errorMessage, successMessage } from '../../../utils/helpers';
import NoData from '../../../assets/noDataImae.png'

const SentMatchRequest = (props) => {
  const { selectedLanguage, user, language, location, getUser, sentMatches } = props
  const [deleteLoading, setDeleteLoading] = useState({})

  const columns = [
    {
      title: selectedLanguage?.title_Game,
      dataIndex: 'gameId',
      render: (e) => {
        return (
          <p className='table_p' style={{ width: '110px' }}>{e?.name || '-----'}</p>
        )
      }
    },
    {
      title: selectedLanguage?.title_GameType,
      dataIndex: 'matchType',
      render: (e) => {
        return (
          <p className='table_p' style={{ width: '110px' }}>{e || '-----'}</p>
        )
      }
    },
    {
      title: selectedLanguage?.title_DateTime,
      render: (e) => {
        return (
          <p className='table_p' style={{ width: '120px' }}>
            {e?.matchDate || '-----'} | {e?.matchTime || '-----'}
          </p>
        )
      }
    },
    {
      title: selectedLanguage?.title_ReciverofMatchRequest,
      dataIndex: 'receiverId',
      render: (e) => {
        return (
          <p className='table_p' style={{ width: '120px' }}>{e?.name}</p>
        )
      }
    },
    {
      title: selectedLanguage?.text_dollerpoints,
      render: (e) => {
        return (
          <p className='table_p'>{e?.proPoints}</p>
        )
      }
    },
    {
      title: `${selectedLanguage?.title_Points}/${selectedLanguage?.text_money_small}`,
      render: (e) => {
        return (
          <p className='table_p'>{e?.isStake ? `$ ${e?.money}` : e?.points}</p>
        )
      }
    },
    {
      title: `${selectedLanguage?.title_Oponentpoints}/${selectedLanguage?.text_money_small}`,
      render: (e) => {
        return (
          <p className='table_p'>
            {e?.isStake ? `$ ${convertNumber(e?.receiverWalletBalance)}` : convertNumber(e?.receiverPoints)}
          </p>
        )
      }
    },
    {
      title: `${selectedLanguage?.title_Mypoints}/${selectedLanguage?.text_money_small}`,
      render: (e) => {
        return (
          <p className='table_p'>{e?.isStake ? `$ ${convertNumber(e?.senderWalletBalance)}` : e?.senderPoints}</p>
        )
      }
    },
    {
      title: selectedLanguage?.title_Message,
      dataIndex: 'message',
      render: (e) => {
        return (
          <p className='table_p' style={{ width: '120px' }}> {e || '----'}</p>
        )
      }
    },
    {
      title: selectedLanguage?.text__Status,
      render: (e) => {
        return (
          <>
            {e?.isAccepted ?
              <p className='accept-status'>{selectedLanguage?.sts_Accepted}</p>
              : e?.isRejected ?
                <p className='reject-status'>{selectedLanguage?.sts_rejected}</p>
                :
                <p className='pending-stats'>{selectedLanguage?.sts_pending}</p>
            }
          </>
        )
      }
    },
    {
      title: selectedLanguage?.title_Action,
      render: (e) => {
        return (
          <>
            {
              <Popconfirm
                disabled={e?.isAccepted || e?.isRejected}
                title={`${selectedLanguage?.text_AreyouwanttodeleteMatchrequestthatyousendto} ${e?.receiverId?.name}?`}
                okText={selectedLanguage?.textDelete}
                cancelText={selectedLanguage?.textCancel}
                okButtonProps={{
                  type: 'primary',
                  className: 'form-button'
                }}
                cancelButtonProps={{ style: { borderRadius: 4 } }}
                onConfirm={() => {
                  deleteMatchRequest(e)
                }}
              >
                <Button
                  disabled={e?.isAccepted || e?.isRejected}
                  type='primary'
                  loading={deleteLoading[e?._id]}
                  danger
                >{selectedLanguage?.buttonText_Delete}</Button>
              </Popconfirm>
            }
          </>
        )
      }
    }
  ]

  const deleteMatchRequest = (data) => {
    setDeleteLoading({ [data?._id]: true })
    let obj = {
      _id: data?._id,
      userId: data?.senderId,
      gameId: data?.gameId?._id,
      pointRefund: data?.points * 2,
      proPointRefund: data?.proPoints * 2,
      shuffle: data?.shuffle,
      code: data?.code,
      moneyRefund: data?.money * 2,
      isStake: data?.isStake
    }
    axios.post(POST?.DELETE_MATCH_REQUEST, obj)
      .then((res) => {
        const { data } = res
        setDeleteLoading({})
        if (data?.success) {
          successMessage(assignMessage(data?.message, language?.language))
          getUser()
        }
        else {
          errorMessage(assignMessage(data?.message, language?.language))
        }
      })
      .catch((e) => {
        setDeleteLoading({})
        errorMessage(assignMessage(e?.message, language?.language))
      })
  }

  return (
    <div style={{ paddingBottom: 10, borderBottom: `1px dashed white` }}>
      <h1 className='new-screen-title'>
        {selectedLanguage?.button_SentMatchRequest}
      </h1>
      <div style={{ marginTop: '20px' }}>
        <div className="custom-table-container">
          <Table
            locale={{ emptyText: selectedLanguage?.txt_NoDataFound }}
            className='new-layout-table'
            dataSource={sentMatches}
            columns={columns}
            pagination={false}
            scroll={{ x: 1000 }}
          />
        </div>
      </div>
    </div>
  )
}

export default SentMatchRequest