import React, { useEffect, useState } from "react";
import { Button, Form, Input, InputNumber, Modal } from "antd";
import { assignMessage, errorMessage, successMessage } from '../../../utils/helpers'
import { ADMIN } from '../../../utils/apis'
import axios from "axios";

const WithdrawPointSetting = (props) => {
    const { user, edit, selectedLanguage, language } = props
    const [visible, setVisible] = useState(false)
    const [form] = Form.useForm()
    const [loading, setLoading] = useState(false)
    const [widthrawlPoints, setWidthrawlPoints] = useState(null)


    const onFinish = (values) => {
        setLoading(true)
        values.adminId = user?._id
        widthrawlPoints?._id && (values._id = widthrawlPoints?._id)

        axios.post(widthrawlPoints?._id ? ADMIN?.EDIT_WITHDRAWAL_POINTS : ADMIN?.ADD_WITHDRAWAL_POINTS, values)
            .then((res) => {
                const { data } = res
                setLoading(false)
                if (data?.success) {
                    successMessage(assignMessage(data?.message, language?.language))
                    getWidthrawlPoints()
                }
                else {
                    errorMessage(assignMessage(data?.message, language?.language))
                }
            })
            .catch((e) => {
                setLoading(false)
                errorMessage(assignMessage(e?.message, language?.language))
            })
    }

    const handleOk = () => {
        setVisible(false)
    }

    const getWidthrawlPoints = () => {
        axios.get(ADMIN?.GET_WITHDRAWAL_POINTS)
            .then((res) => {
                const { data } = res
                if (data?.success) {
                    setWidthrawlPoints(data?.data[0])
                }
                else {
                    setWidthrawlPoints(null)
                }
            })
            .catch((e) => {
                errorMessage(assignMessage(e?.message, language?.language))
            })

    }

    useEffect(() => {
        getWidthrawlPoints()
    }, [])

    return (
        <>
            <div className="reward-point-box">
                <p className="p-none-margin-bolder-p">
                    {selectedLanguage?.text_WhenPlayerSubscribeTournamentAndWantToWidthrawl}
                </p>
                <p className="p-none-margin-bolder-p">
                    {selectedLanguage?.text_ifTournamentisPlayingForPointDeduct}  ( {widthrawlPoints?.point || '--'} ) {selectedLanguage?.txt_pointFromHisPoints}</p>
                <div style={{ marginTop: '20px' }} className='button-div-flex-end'>
                    <Button
                        type="primary"
                        onClick={() => { setVisible(true) }} >
                        {selectedLanguage?.text_UpdateWidthrawlSetting}</Button>
                </div>
            </div>
            {/* --------Modal----- */}

            <Modal
                open={visible}
                onOk={handleOk}
                onCancel={handleOk}
                className='pop-info-modal'
                footer={null}>
                <div style={{ marginTop: '10px' }}>
                    <p className='p-question'>{selectedLanguage?.text_UpdateWidthrawlSetting}</p>
                    <div style={{ display: 'flex', flexDirection: 'column' }} className='form-div-in-modal'>
                        <Form
                            name='form'
                            form={form}
                            onFinish={onFinish}
                            layout={'vertical'}
                        >
                            <div className='form-two-input-flex-inner-input newInpuHeightFull' style={{ width: '100%' }}>
                                <Form.Item
                                    name='point'
                                    initialValue={widthrawlPoints?.point}
                                    hasFeedback
                                    label={<p className='p-admin-title-text'>{selectedLanguage?.text_pointWantDeduct}</p>}
                                    rules={[
                                        {
                                            required: true,
                                            message: selectedLanguage?.text_pleaseEnterPoint
                                        }
                                    ]}
                                >
                                    <Input
                                        // Number
                                        step={0.5}    // Set the step to 0.01 for two decimal places
                                        precision={2}
                                        type='number'
                                        style={{ width: '100%' }}
                                        className='form-input-new-design-big-slam'
                                        placeholder={selectedLanguage?.text_EnterPointHere}
                                    />
                                </Form.Item>
                            </div>

                            <div style={{ display: 'flex', justifyContent: 'flex-end', height: '40px', paddingRight: '5px' }}>
                                <Form.Item>
                                    <Button
                                        type="primary"
                                        htmlType='submit'
                                        loading={loading}
                                    >
                                        {widthrawlPoints?.point ? selectedLanguage?.text_UpdatePointSetting : selectedLanguage?.text_AddPointSetting}
                                    </Button>
                                </Form.Item>
                            </div>
                        </Form>
                    </div>
                </div >
            </Modal>
        </>
    )
}



export default WithdrawPointSetting