import { Button } from "antd";
import React from "react";
import InvitePointSetting from "./InvitePointsSetting";
import TournamentOrganizePointSetting from "./TournamentOrganizePointSetting";
import WithdrawPointSetting from "./WithdrawPointSetting";
import OrganizePayCostSetting from "./OrganizePayCostSetting";
import SubscriptionFeeDollerTournament from "./SubscriptionFeeDollerTournament";
import ServiceFeeForSentDollerMatchRequest from "./ServiceFeeForSentDollerMatchRequest";
import ServiceFeeForCashBack from "./ServiceFeeForCashBack";
import ServiceFeeForCompetition from "./ServiceFeeCompetition";

const PointSetting = (props) => {
    const { user, selectedLanguage } = props
    return (
        <div className='new-main-component-screen-div' style={{ background: 'black' }}>
            <div>
                <h1 className='new-screen-title orang-color' >
                    {selectedLanguage?.text_PointRewardSetting}
                </h1>
                <div>
                    <InvitePointSetting {...props} />
                </div>
                <div className="border-bottom-dotted" />
            </div>
            <div>
                <h1 className='new-screen-title orang-color' >
                    {selectedLanguage?.text_TournamentOrganizePointSetting}
                </h1>
                <div>
                    <TournamentOrganizePointSetting {...props} />
                </div>
                <div className="border-bottom-dotted" />
            </div>
            <div>
                <h1 className='new-screen-title orang-color' >
                    {selectedLanguage?.text_WithdrawPointSetting}
                </h1>
                <div>
                    <WithdrawPointSetting {...props} />
                </div>
                <div className="border-bottom-dotted" />
            </div>
            <div>
                <h1 className='new-screen-title orang-color' >
                    {selectedLanguage?.text_OrganisorOfTournamentPayCostSetting}
                </h1>
                <div>
                    <OrganizePayCostSetting {...props} />
                </div>
                <div className="border-bottom-dotted" />
            </div>
            <div>
                <h1 className='new-screen-title orang-color' >
                    {selectedLanguage?.text_ServiceFeeForSubscribeTournaments}
                </h1>
                <div>
                    <SubscriptionFeeDollerTournament {...props} />
                </div>
                <div className="border-bottom-dotted" />
            </div>
            <div>
                <h1 className='new-screen-title orang-color' >
                    {selectedLanguage?.text_ServiceFeeForMatchRequest}
                </h1>
                <div>
                    <ServiceFeeForSentDollerMatchRequest {...props} />
                </div>
                <div className="border-bottom-dotted" />
            </div>
            <div>
                <h1 className='new-screen-title orang-color' >
                    {selectedLanguage?.txt_Service_fee_for_competition_subscription}
                </h1>
                <div>
                    <ServiceFeeForCompetition {...props} />
                </div>
                <div className="border-bottom-dotted" />
            </div>
        </div >
    )
}

export default PointSetting