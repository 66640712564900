import { FAQ } from '../types'

const reducer = (state = {}, action) => {
    switch (action.type) {
        case FAQ: {
            return { ...state, faqSetting: action.faqSetting }
        }

        default: {
            return state
        }
    }
}

export default reducer