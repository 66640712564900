import { Button, Select, Table, } from 'antd'
import React, { useEffect, useState } from 'react'
import { IoIosArrowBack } from "react-icons/io"
import { AiOutlineArrowUp, AiOutlineArrowDown } from "react-icons/ai"
import { TbEqual } from "react-icons/tb"
import { useSelector } from 'react-redux'
import { convertNumber, getGeneralData, getUserData } from '../../../utils/helpers'
import AllRankingPiont from './AllRankingPointTable'
import NoData from '../../../assets/noDataImae.png'

const PlayerRankingPiont = (props) => {
    const { Option } = Select
    const { selectedLanguage, isEnglish, user, generalActions, userActions } = props
    const games = useSelector(state => state.generalReducer.games)
    const userRanks = useSelector(state => state.userReducer.userRanks)
    const [allPlayer, setAllPlayer] = useState(false)
    const [selectedGame, setSelectedGame] = useState(games?.[0]?._id || null)

    useEffect(() => {
        getGeneralData(generalActions)
        getUserData(user?._id, userActions)
    }, [])

    const columns = [
        {
            title: selectedLanguage?.tableText_Game,
            render: (e) => {
                return (
                    <p>
                        {e?.gameId?.name}
                    </p>
                )
            }
        },
        {
            title: selectedLanguage?.tableText_Ranking,
            render: (e) => {
                return (
                    <p>
                        {e?.rank}
                    </p>
                )
            }
        },
        {
            title: selectedLanguage?.tableText_Points,
            render: (e) => {
                return (
                    <p>
                        {convertNumber(e?.point)}
                    </p>
                )
            }
        },
        {
            title: selectedLanguage?.tableText_WeeklyResult,
            render: (e) => {
                return (
                    <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
                        {!e?.weaklyPointReport?.length || e?.weaklyPointReport?.[0]?.difference === 0 ?
                            <TbEqual color='black' size={22} style={{
                                cursor: 'pointer',
                                background: 'white',
                                margin: '10px',
                                height: '30px',
                                width: '30px',
                                borderRadius: '20px',
                                padding: '4px'
                            }} /> :
                            e?.pointUp ?
                                <AiOutlineArrowUp color='green' size={22} style={{
                                    cursor: 'pointer',
                                    background: 'white',
                                    margin: '10px',
                                    height: '30px',
                                    width: '30px',
                                    borderRadius: '20px',
                                    padding: '4px'
                                }} />
                                : <AiOutlineArrowDown color='red' size={22} style={{
                                    cursor: 'pointer',
                                    background: 'white',
                                    margin: '10px',
                                    height: '30px',
                                    width: '30px',
                                    borderRadius: '20px',
                                    padding: '4px'
                                }} />}
                        <p style={{ margin: '16px' }}>{e?.weaklyPointReport?.[0]?.difference || 0}</p>
                    </div>
                )
            }
        }
    ]

    return (
        <div className='new-main-component-screen-div'>
            <div style={{ marginTop: '10px' }}>
                {allPlayer ?
                    <div style={{
                        display: 'flex',
                        justifyContent: 'flex-start',
                        alignItems: 'center'
                    }}>
                        <IoIosArrowBack
                            onClick={() => { setAllPlayer(false) }}
                            style={{ marginRight: '5px', color: 'black', marginTop: '8px' }}
                            size={28} />
                        <h1 className='new-screen-title'>
                            {selectedLanguage?.title_AllPlayerRankingAndPoints}
                        </h1>
                    </div>
                    :
                    <h1 className='new-screen-title'>
                        {selectedLanguage?.title_MyRankingAndPoints}
                    </h1>
                }
            </div>
            <span style={{ width: '98%', margin: '0px auto', marginTop: '20px', }} className='button-div-flex-end'>
                {allPlayer ?
                    <Select
                        showSearch
                        showArrow
                        allowClear={false}
                        defaultValue={selectedGame}
                        onChange={(e) => setSelectedGame(e)}
                        style={{
                            height: '35px',
                            width: '130px'
                        }}
                        notFoundContent={selectedLanguage?.txt_NoDataFound}
                        placeholder={selectedLanguage?.label_SelectGame}
                        optionFilterProp='children'
                        filterOption={(input, option) =>
                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        } >
                        {games?.length && games?.map((v, i) => <Option value={v?._id} key={i}>{v?.name}</Option>)}
                    </Select>
                    : <Button
                        type='primary'
                        onClick={() => setAllPlayer(true)}>
                        {selectedLanguage?.btnAllplayerRanks}
                    </Button>}
            </span>
            <div style={{ marginTop: '25px' }}>
                {allPlayer ?
                    <AllRankingPiont {...props} selectedGame={selectedGame} />
                    :
                    <div className="custom-table-container">
                        <Table
                            locale={{ emptyText: selectedLanguage?.txt_NoDataFound }}
                            className='new-layout-table'
                            dataSource={userRanks}
                            columns={columns}
                            pagination={false}
                            scroll={{ x: 1000 }}
                        />
                    </div>
                }
            </div>
        </div>
    )
}

export default PlayerRankingPiont