import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import WGP_logo_Text from '../../assets/WGP-white.png'
import { allPaths } from '../../utils/constants'
import ReactFlagsSelect from 'react-flags-select'
import { useDispatch, useSelector } from 'react-redux'
import { setLanguage } from "../../Redux/actions/languageAction"
import { FaFacebookF, FaInstagram, FaTwitter } from 'react-icons/fa'
const LoginHeader = (props) => {
    const history = useHistory()
    const [selected, setSelected] = useState('GB')
    const dispatch = useDispatch()
    const language = useSelector(state => state?.languageReducer)

    useEffect(() => {
        language?.language === 'netherlands' ?
            setSelected('NL') :
            language?.language === 'italy' ?
                setSelected('IT') :
                language?.language === 'german' ?
                    setSelected('DE') :
                    language?.language === 'spain' ?
                        setSelected('ES') :
                        language?.language === 'egypt' ?
                            setSelected('EG') :
                            language?.language === 'russain' ?
                                setSelected('RU') :
                                setSelected('GB')
    })

    const onchangeLanguage = (v) => {
        setSelected(v)
        if (v === 'NL') {
            dispatch(setLanguage('netherlands'))
        }
        else if (v === 'IT') {
            dispatch(setLanguage('italy'))
        }
        else if (v === 'DE') {
            dispatch(setLanguage('german'))
        }
        else if (v === 'ES') {
            dispatch(setLanguage('spain'))
        }
        else if (v === 'EG') {
            dispatch(setLanguage('egypt'))
        }
        else if (v === 'RU') {
            dispatch(setLanguage('russain'))
        }
        else {
            dispatch(setLanguage('english'))
        }
    }
    return (
        <>
            <div className="new-small-upper-header">
                <div className="new-small-upper-header-inner">
                    <span className="name"></span>
                    <span className="last-div">
                        <p className='uppr-icon-main'>
                            <span className='upper-header-icon'><FaFacebookF size={18} /></span>
                            <span className='upper-header-icon'><FaTwitter size={18} /></span>
                            <span className='upper-header-icon'><FaInstagram size={18} /></span>
                        </p>
                        <span className='round-lang-upper-header-icon'>
                            <ReactFlagsSelect
                                className='round-countries-select-update'
                                fullWidth={false}
                                showOptionLabel={false}
                                showSelectedLabel={false}
                                countries={['GB', 'NL', 'IT', 'ES', 'EG', 'RU', 'DE']}
                                selected={selected}
                                onSelect={(v) => onchangeLanguage(v)}
                            />
                        </span>
                    </span>
                </div>
            </div>
        </>
    )
}

export default LoginHeader