import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { POST } from '../../../utils/apis'
import { Button, Popconfirm, Table } from 'antd'
import { convertRoundNumber, convertTitle, createInvoicePdf, errorMessage, getUserData, successMessage } from '../../../utils/helpers'
import axios from 'axios'
import moment from 'moment'
import ViewCard from "../../Cards/ViewCard"
import NoData from '../../../assets/noDataImae.png'

const PlayerInvoices = (props) => {
    const { selectedLanguage, isEnglish, user, getUser } = props
    const [showInvoice, setShowInvoice] = useState(false)
    const [invoice, setInvoice] = useState({})


    useEffect(() => {
        getUser()
    }, [])
    console.log(user, "==user")

    const viewInvoice = (fileUrl) => {
        setInvoice({
            designName: 'Invoice',
            fileUrl
        })

        setShowInvoice(true)
    }

    const onCancel = () => {
        setInvoice({})
        setShowInvoice(false)
    }

    let columns = [
        {
            title: selectedLanguage?.text_Name,
            dataIndex: 'subscriptionName',
            key: 'subscriptionName',
            // sorter: true,
        },
        {
            title: selectedLanguage?.text_TotalAmount,
            dataIndex: 'totalAmount',
            key: 'totalAmount',
            // sorter: true,
            render: (e) => <p style={{ paddingTop: 15, marginTop: -5 }}>$ {convertRoundNumber(e)}</p>
        },
        {
            title: selectedLanguage?.text_SubscriptionDate,
            dataIndex: 'created',
            key: 'created',
            render: (e) => <p>{moment(e).format('DD-MM-YYYY')}</p>
        },
        {
            title: selectedLanguage?.text_ViewInvoice,
            key: 'invoice',
            render: (e) => <a
                onClick={() => { createInvoicePdf(e, user, 'view', viewInvoice) }}
            >{selectedLanguage?.text_View}</a>
        },
        {
            title: selectedLanguage?.text_DownloadInvoice,
            key: 'download',
            render: (e) => <a
                onClick={() => { createInvoicePdf(e, user, 'download', viewInvoice) }}
            >{selectedLanguage?.text_Download}</a>
        }
    ]

    return (
        <div className='new-main-component-screen-div'>
            <p className='new-screen-title' >
                {selectedLanguage?.text_MyInvoices}
            </p>

            <div className="custom-table-container">
                <Table
                    locale={{ emptyText: selectedLanguage?.txt_NoDataFound }}
                    className='new-layout-table'
                    dataSource={user?.invoiceHistory || []}
                    columns={columns}
                    pagination={false}
                    scroll={{ x: 700 }}
                />
            </div>

            {showInvoice ? <ViewCard card={invoice} onCancel={onCancel} showData={showInvoice} /> : null}
        </div>
    )
}

export default PlayerInvoices