import { Button, Checkbox, DatePicker, Form, Input, InputNumber, Radio, Select, Switch, TimePicker } from 'antd'
import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { FiPercent } from "react-icons/fi"
import { ADMIN } from '../../../utils/apis'
import moment from 'moment'
import { assignMessage, disabledDate, errorMessage, getGeneralData, successMessage } from "../../../utils/helpers"
import { dateFormat } from '../../../utils/constants'
import { useSelector } from 'react-redux'
import { RxLockClosed, RxLockOpen1 } from 'react-icons/rx'
import { BiMoneyWithdraw, } from 'react-icons/bi'
import { GiRank1 } from 'react-icons/gi'

const CreateTournament = (props) => {
    const { Option } = Select
    const { generalActions, selectedLanguage, isEnglish, language, user, edit, editSuccess } = props
    const games = useSelector(state => state.generalReducer.games)
    const promoter = useSelector(state => state.generalReducer?.promoter)
    const [loading, setLoading] = useState(false)
    const [type, setType] = useState(edit?.gameId?.gameType || '1-1 Match Game')
    const [tournamentDate, setTournamentDate] = useState(edit?.tournamentDate || null)
    const [time1, setTime1] = useState(edit?.timeFirstRound || null)
    const [Private, setPrivate] = useState(edit?.isPrivate ? edit?.isPrivate : false)
    const [Promoter, setPromoter] = useState(edit?.isPromoter ? edit?.isPromoter : false)
    const [stake, setStake] = useState(edit?.isStake ? true : false)
    const [repeat, setRepeat] = useState(false)
    const [form] = Form.useForm()
    const format = 'HH:mm'

    console.log('edit', edit)

    useEffect(() => {
        getGeneralData(generalActions)
    }, [])

    const onFinish = (values) => {
        values.tournamentDate = tournamentDate
        values.timeFirstRound = time1
        values.ownerType = 'admin'
        values.isPrivate = Private
        values.promoterId = values?.promoterId ? values.promoterId : null
        values.privateCode = values?.privateCode ? values.privateCode : null
        values.ownerAdminId = user?._id
        values.isStake = stake
        values.prizeOne = values?.prizeOne ? `${values.prizeOne}` : `40`
        values.prizeTwo = values?.prizeTwo ? `${values.prizeTwo}` : `20`
        values.prizeThree = values?.prizeThree ? `${values.prizeThree}` : `15`
        values.prizeFour = values?.prizeFour ? `${values.prizeFour}` : `5`
        edit?._id && (values._id = edit?._id)
        let allPrizes = Number(values?.prizeOne) + Number(values?.prizeTwo) + Number(values?.prizeThree) + Number(values?.prizeFour)
        console.log(allPrizes, 'allPrizes')
        if (allPrizes >= 100) {
            errorMessage(selectedLanguage?.text_theSumOfAllPrizesMustBeLessThenHndred)
            return true
        }

        console.log(values, "=")

        // return true

        setLoading(true)
        axios.post(edit?._id ? ADMIN.EDIT_TOURNAMENT : ADMIN.CREATE_TOURNAMENT, values)
            .then((res) => {
                const { data } = res
                setLoading(false)
                if (data?.success) {
                    successMessage(assignMessage(data?.message, language?.language))
                    form.resetFields()
                    edit?._id && editSuccess()
                }
                else {
                    errorMessage(assignMessage(data?.message, language?.language))
                }
            }).catch((e) => {
                setLoading(false)
                console.log(e)
                errorMessage(assignMessage(e?.message, language?.language))
            })
    }


    const onChangeDate = (date) => {
        setTournamentDate(moment(date).format(dateFormat))
    }
    const onChangeTime1 = (date) => {
        setTime1(moment(date).format(format))
    }

    function onChangeCheckBox(e) {
        setPrivate(e/*?.target.checked*/)
    }

    const onChangePromoter = (e) => {
        setPromoter(e?.target.checked);
    }
    function onChangeStack(e) {
        setStake(e/* .target.checked */)
    }

    return (
        <div className='new-main-component-screen-div' style={{ background: 'black' }}>
            {!edit?._id &&
                <h1 className='new-screen-title orang-color' >
                    {selectedLanguage?.tittle_Createtournament}
                </h1>
            }
            <div style={{ marginTop: '20px' }}>
                <Form
                    name='form'
                    form={form}
                    onFinish={onFinish}
                    layout={'vertical'}
                >
                    <div className='form-two-input-flex'>
                        <div className='form-two-input-flex-inner-input'>
                            <p style={{ padding: '0px', margin: '0px', marginBottom: '4px', marginLeft: '4px' }}
                                className='p-admin-title-text'
                            >{selectedLanguage?.text_SelectGameType}</p>
                            <Select
                                defaultValue={type}
                                placeholder={selectedLanguage?.text_SelectGameType}
                                className='form-input-new-design-big-slam'
                                onChange={(e) => { setType(e) }}
                                style={{ width: '100%', marginBottom: '20px', marginTop: '5px' }}
                            >
                                <Option value={'1-1 Match Game'}>{selectedLanguage?.text_OneByOneMatchGame}</Option>
                                <Option value={'Team Match Game'}>{selectedLanguage?.text_TeamMatchGame}</Option>
                            </Select>
                        </div>
                        <div className='form-two-input-flex-inner-input'>
                            <Form.Item
                                name='gameId'
                                hasFeedback
                                initialValue={edit?.gameId?._id}
                                label={<p className='p-admin-title-text'>{selectedLanguage?.label_SelectGame}</p>}
                                rules={[
                                    {
                                        required: true,
                                        message: selectedLanguage?.error_pleaseSelectGame
                                    }
                                ]}
                            >
                                <Select
                                    className='form-input-new-design-big-slam'
                                    notFoundContent={selectedLanguage?.txt_NoDataFound}
                                    placeholder={selectedLanguage?.placeHolder_SelectGamefortournament}>
                                    {games?.length && games?.map((v, i) => {
                                        return (
                                            v?.gameType === type &&
                                            <Option value={v?._id}>
                                                {`${v?.name} - ${v?.gameType} - ${v?.description} `}
                                            </Option>
                                        )
                                    })}
                                </Select>
                            </Form.Item>
                        </div>
                    </div>
                    <div className='form-input-single-input'>
                        <Form.Item
                            name='tournamentName'
                            hasFeedback
                            initialValue={edit?.tournamentName}
                            label={<p className='p-admin-title-text'>{selectedLanguage?.label_TournamentName}</p>}
                            rules={[
                                {
                                    required: true,
                                    message: selectedLanguage?.error_pleaseEnterTournamentName
                                }
                            ]}
                        >
                            <Input

                                className='form-input-new-design-big-slam'
                                placeholder={selectedLanguage?.placeHolder_EnterTournamentNameHere}
                            />
                        </Form.Item>
                    </div>
                    <div className='form-two-input-flex'>
                        <div className='form-two-input-flex-inner-input'>
                            <Form.Item
                                name='tournamentType'
                                hasFeedback
                                initialValue={edit?.tournamentType}
                                label={<p className='p-admin-title-text'>{selectedLanguage?.label_TournamentType}</p>}
                                rules={[
                                    {
                                        required: true,
                                        message: selectedLanguage?.error_pleaseEnterTournamentType
                                    }
                                ]}
                            >
                                <Input
                                    className='form-input-new-design-big-slam'
                                    placeholder={selectedLanguage?.placeHolder_EnterTournamentTypeHere}
                                />
                            </Form.Item>
                        </div>
                        <div className='form-two-input-flex-inner-input'>
                            <Form.Item
                                name='tournamentDate'
                                hasFeedback
                                initialValue={edit?.tournamentDate && moment(edit?.tournamentDate, 'DD-MM-YYYY')}
                                label={<p className='p-admin-title-text'>{selectedLanguage?.label_TournamentDate}</p>}
                                rules={[
                                    {
                                        required: true,
                                        message: selectedLanguage?.error_pleaseEnterTournamentDate
                                    }
                                ]}
                            >
                                <DatePicker
                                    disabledDate={disabledDate}
                                    onChange={onChangeDate}
                                    className='form-input-new-design-big-slam'
                                    placeholder={selectedLanguage?.placeHolder_TournamentDate}
                                    style={{ width: '100%' }}
                                    allowClear={false}
                                    format={dateFormat}
                                />
                            </Form.Item>
                        </div>
                    </div>


                    <div className='form-two-input-flex'>
                        <div className='form-two-input-flex-inner-input newInpuHeightFull'>
                            <Form.Item
                                name='maximumPlayer'
                                hasFeedback
                                initialValue={edit?.maximumPlayer}
                                label={<p className='p-admin-title-text'>{selectedLanguage?.label_Maximumplayer}</p>}
                                rules={[
                                    {
                                        required: true,
                                        message: selectedLanguage?.error_pleaseEnteraMaxPlayer
                                    },
                                    ({ getFieldValue }) => ({
                                        validator(rule, value) {
                                            if (value > 64) {
                                                return Promise.reject(selectedLanguage?.txt_Value_must_Less_then + ' 65')
                                            }
                                            if (value === null || value > 1) {
                                                return Promise.resolve()
                                            }
                                            else if (value === 0) {
                                                return Promise.reject(selectedLanguage?.text_MaximumPlayerMustBeOrMmoreThen)
                                            }
                                            else {
                                                return Promise.reject(selectedLanguage?.text_MaximumPlayerMustBeOrMmoreThen)
                                            }
                                        },
                                    })
                                ]}
                            >
                                <Input
                                    max={64}
                                    min={2}
                                    step={1}
                                    type='number'
                                    style={{ width: '100%' }}
                                    className='form-input-new-design-big-slam'
                                    placeholder={selectedLanguage?.placeHolder_EnterMaximumPlayerhere}
                                />
                            </Form.Item>
                        </div>
                        <div className='form-two-input-flex-inner-input newInpuHeightFull'>
                            <Form.Item
                                name='rankingNeeded'
                                initialValue={edit?.rankingNeeded}
                                hasFeedback
                                label={<p className='p-admin-title-text'>{selectedLanguage?.label_RankingNeeded}</p>}
                                rules={[
                                    {
                                        required: true,
                                        message: selectedLanguage?.error_pleaseEnterRankingNeeded
                                    },
                                    ({ getFieldValue }) => ({
                                        validator(rule, value) {
                                            if (value < 1) {
                                                return Promise.reject(selectedLanguage?.txt_Value_must_more_then + ' 1')
                                            }
                                            return Promise.resolve()
                                        }
                                    })
                                ]}
                            >
                                <Input
                                    min={1}
                                    type='number'
                                    style={{ width: '100%' }}
                                    className='form-input-new-design-big-slam'
                                    placeholder={selectedLanguage?.placeHolder_EnterRankingNeededHere}
                                />
                            </Form.Item>
                        </div>
                    </div>

                    <div className='form-two-input-flex'>
                        <div className='form-two-input-flex-inner-input newInpuHeightFull' style={{ width: '100%' }}>
                            <Form.Item
                                name='withdrawTimeInHours'
                                initialValue={edit?.withdrawTimeInHours}
                                hasFeedback
                                label={<p className='p-admin-title-text'>{selectedLanguage?.label_WithdrawTimeHours}</p>}
                                rules={[
                                    {
                                        required: true,
                                        message: selectedLanguage?.error_pleaseEnterWithdrawTimehours
                                    },
                                    ,
                                    ({ getFieldValue }) => ({
                                        validator(rule, value) {
                                            if (value < 24) {
                                                return Promise.reject(selectedLanguage?.txt_Value_must_more_then + ' 23')
                                            }
                                            return Promise.resolve()
                                        }
                                    })
                                ]}
                            >
                                <Input
                                    min={24}
                                    type='number'
                                    style={{ width: '100%' }}
                                    className='form-input-new-design-big-slam'
                                    placeholder={selectedLanguage?.placeHolder_EnterWithdrawtimeHourshere}
                                />
                            </Form.Item>
                        </div>
                    </div>

                    <div className='form-two-input-flex'>
                        <div className='form-two-input-flex-inner-input '>
                            <Form.Item
                                label={<p className='p-admin-title-text'>{selectedLanguage?.tournment_Stackswitch}</p>}
                                name='isStake'
                                initialValue={stake}
                                onChange={onChangeStack}
                                valuePropName='checked'>
                                <div
                                    className='form-input-new-design-big-slam'
                                    style={{ display: 'flex', alignItems: 'center', background: 'white' }}
                                >
                                    <p className='p-admin-title-text' style={{ margin: '0px 10px' }}>{selectedLanguage?.tournment_point}</p>
                                    <Switch
                                        onChange={onChangeStack}
                                        checked={stake}
                                        checkedChildren={<BiMoneyWithdraw size={19} style={{ height: 22, marginRight: 4 }} />}
                                        unCheckedChildren={<GiRank1 size={19} style={{ height: 22, marginLeft: 4 }} />} />
                                    <p className='p-admin-title-text' style={{ margin: '0px 10px' }}>{selectedLanguage?.tournment_moeny}</p>
                                </div>
                            </Form.Item>
                        </div>
                        <div className='form-two-input-flex-inner-input newInpuHeightFull'>
                            {!stake ?
                                <Form.Item
                                    name='participationFee'
                                    initialValue={edit?.participationFee}
                                    hasFeedback
                                    label={<p className='p-admin-title-text'>{selectedLanguage?.label_ParticipationFeePoints}</p>}
                                    rules={[
                                        {
                                            required: true,
                                            message: selectedLanguage?.error_pleaseEnterParticipationFee
                                        },
                                        ({ getFieldValue }) => ({
                                            validator(rule, value) {
                                                if (value < 1) {
                                                    return Promise.reject(selectedLanguage?.txt_Value_must_more_then + ' 0')
                                                }
                                                return Promise.resolve()
                                            }
                                        })
                                    ]}
                                >
                                    <Input
                                        min={1}
                                        step={0.5}
                                        type='number'
                                        style={{ width: '100%' }}
                                        className='form-input-new-design-big-slam'
                                        placeholder={selectedLanguage?.label_ParticipationFeePoints}
                                    />
                                </Form.Item> :
                                <Form.Item
                                    name='participationMoney'
                                    initialValue={edit?.participationMoney}
                                    hasFeedback
                                    label={<p className='p-admin-title-text'>{selectedLanguage?.label_ParticipationfeeMoney}</p>}
                                    rules={[
                                        {
                                            required: true,
                                            message: selectedLanguage?.error_PleaseEnterParticipationFeeMoney
                                        }, ({ getFieldValue }) => ({
                                            validator(rule, value) {
                                                if (value < 1) {
                                                    return Promise.reject(selectedLanguage?.txt_Value_must_more_then + ' 0')
                                                }
                                                return Promise.resolve()
                                            }
                                        })
                                    ]}
                                >
                                    <Input
                                        type='number'
                                        min={1}
                                        step={0.5}
                                        style={{ width: '100%' }}
                                        className='form-input-new-design-big-slam'
                                        placeholder={selectedLanguage?.placeHolder_EnterParticipationFeeMoney}
                                    />
                                </Form.Item>}
                        </div>
                    </div>
                    {stake &&
                        <div className='form-two-input-flex'>
                            <div className='form-two-input-flex-inner-input newInpuHeightFull' style={{ width: '100%' }}>
                                <Form.Item
                                    name='totalTournamentPoints'
                                    initialValue={edit?.totalTournamentPoints}
                                    hasFeedback
                                    label={<p className='p-admin-title-text'>{selectedLanguage?.text_TotalTournamentPointsPrizes}</p>}
                                    rules={[
                                        {
                                            required: true,
                                            message: selectedLanguage?.error_PleaseEnterTotalTournamentPoints
                                        },
                                        ({ getFieldValue }) => ({
                                            validator(rule, value) {
                                                if (value < 1) {
                                                    return Promise.reject(selectedLanguage?.txt_Value_must_more_then + ' 0')
                                                }
                                                return Promise.resolve()
                                            }
                                        })
                                    ]}
                                >
                                    <Input
                                        min={1}
                                        step={1}
                                        type='number'
                                        style={{ width: '100%' }}
                                        className='form-input-new-design-big-slam'
                                        placeholder={selectedLanguage?.placeholder_EnterTotalTournamentPointsHere}
                                    />
                                </Form.Item>
                            </div>
                        </div>}

                    <div className='form-two-input-flex'>
                        <div className='form-two-input-flex-inner-input'>
                            <Form.Item
                                name='timeFirstRound'
                                initialValue={edit?.timeFirstRound && moment(edit?.timeFirstRound, 'HH:mm')}
                                hasFeedback
                                label={<p className='p-admin-title-text'>{selectedLanguage?.label_TimeToPlayFirstRound}</p>}
                                rules={[
                                    {
                                        required: true,
                                        message: selectedLanguage?.error_pleaseEnterTimeToPlayFirstRound
                                    },
                                ]}
                            >
                                <TimePicker
                                    minuteStep={15}
                                    onChange={onChangeTime1}
                                    style={{ width: '100%' }}
                                    className='form-input-new-design-big-slam'
                                    placeholder={selectedLanguage?.placeHolder_EnterTimePlayFirstRoundHere}
                                    format={format} />
                            </Form.Item>
                        </div>
                    </div>


                    <div className='form-two-input-flex'>
                        <div className='form-two-input-flex-inner-input newInpuHeightFull flex-for-addon'>
                            <Form.Item
                                style={{ width: '100%' }}
                                name='prizeOne'
                                hasFeedback
                                label={<p className='p-admin-title-text'>{selectedLanguage?.label_Prizewinner}</p>}
                                initialValue={edit?.prizeOne || '40'}
                                rules={[
                                    {
                                        required: true,
                                        message: selectedLanguage?.label_Prizewinner
                                    },
                                    ({ getFieldValue }) => ({
                                        validator(rule, value) {
                                            if (value < 1) {
                                                return Promise.reject(selectedLanguage?.txt_Value_must_more_then + ' 0')
                                            }
                                            return Promise.resolve()
                                        }
                                    })
                                ]}
                            >
                                <Input
                                    min={1}
                                    step={1}
                                    style={{ width: '100%' }}
                                    type='number'
                                    className='form-input-new-design-big-slam input12345'
                                    placeholder={selectedLanguage?.label_Prizewinner}

                                />
                            </Form.Item>
                        </div>
                        <div className='form-two-input-flex-inner-input newInpuHeightFull flex-for-addon'>
                            <Form.Item
                                style={{ width: '100%' }}
                                name='prizeTwo'
                                hasFeedback
                                label={<p className='p-admin-title-text'>{selectedLanguage?.label_Prizefinalist}</p>}
                                initialValue={edit?.prizeTwo || '20'}
                                rules={[
                                    {
                                        required: true,
                                        message: selectedLanguage?.label_Prizefinalist
                                    },
                                    ({ getFieldValue }) => ({
                                        validator(rule, value) {
                                            if (value < 1) {
                                                return Promise.reject(selectedLanguage?.txt_Value_must_more_then + ' 0')
                                            }
                                            return Promise.resolve()
                                        }
                                    })
                                ]}
                            >
                                <Input
                                    min={1}
                                    step={1}
                                    style={{ width: '100%' }}
                                    type='number'
                                    className='form-input-new-design-big-slam'
                                    placeholder={selectedLanguage?.label_Prizefinalist}
                                />
                            </Form.Item>
                        </div>
                    </div>

                    <div className='form-two-input-flex'>
                        <div className='form-two-input-flex-inner-input newInpuHeightFull flex-for-addon'>
                            <Form.Item
                                style={{ width: '100%' }}
                                name='prizeThree'
                                hasFeedback
                                label={<p className='p-admin-title-text'>{selectedLanguage?.label_PrizenoThree}</p>}
                                initialValue={edit?.prizeThree || '15'}
                                rules={[
                                    {
                                        required: true,
                                        message: selectedLanguage?.label_PrizenoThree
                                    },
                                    ({ getFieldValue }) => ({
                                        validator(rule, value) {
                                            if (value < 1) {
                                                return Promise.reject(selectedLanguage?.txt_Value_must_more_then + ' 0')
                                            }
                                            return Promise.resolve()
                                        }
                                    })
                                ]}
                            >
                                <Input
                                    min={1}
                                    step={1}
                                    style={{ width: '100%' }}
                                    type='number'
                                    className='form-input-new-design-big-slam'
                                    placeholder={selectedLanguage?.label_PrizenoThree}
                                />
                            </Form.Item>
                        </div>

                        <div className='form-two-input-flex-inner-input newInpuHeightFull flex-for-addon'>
                            <Form.Item
                                style={{ width: '100%' }}
                                name='prizeFour'
                                hasFeedback
                                label={<p className='p-admin-title-text'>{selectedLanguage?.label_PrizenoFour}</p>}
                                initialValue={edit?.prizeFour || '5'}
                                rules={[
                                    {
                                        required: true,
                                        message: selectedLanguage?.label_PrizenoFour
                                    },
                                    ({ getFieldValue }) => ({
                                        validator(rule, value) {
                                            if (value < 1) {
                                                return Promise.reject(selectedLanguage?.txt_Value_must_more_then + ' 0')
                                            }
                                            return Promise.resolve()
                                        }
                                    })
                                ]}
                            >
                                <Input
                                    min={1}
                                    step={1}
                                    style={{ width: '100%' }}
                                    type='number'
                                    className='form-input-new-design-big-slam'
                                    placeholder={selectedLanguage?.label_PrizenoFour}
                                />
                            </Form.Item>
                        </div>
                    </div>

                    <div>
                        {repeat &&
                            <div className='form-two-input-flex'>
                                <div className='form-two-input-flex-inner-input'>
                                    <Form.Item
                                        name='repeatType'
                                        hasFeedback
                                        label={<p className='p-admin-title-text'>{selectedLanguage?.text_SelectRepeatType}</p>}
                                        initialValue={'weekly'}
                                    >
                                        <Radio.Group
                                            className='form-input-new-design-big-slam'
                                            style={{
                                                width: '100%',
                                                padding: '10px',
                                                display: 'flex',
                                                alignItems: 'center'
                                            }}
                                        >
                                            <Radio value={'daily'}>{<p className='p-admin-title-text'>{selectedLanguage?.txt_Daily}</p>}</Radio>
                                            <Radio value={'weekly'}>{<p className='p-admin-title-text'>{selectedLanguage?.txt_Weekly}</p>}</Radio>
                                            <Radio value={'monthly'}>{<p className='p-admin-title-text'>{selectedLanguage?.txt_Monthly}</p>}</Radio>
                                        </Radio.Group>
                                    </Form.Item>
                                </div>
                                <div className='form-two-input-flex-inner-input newInpuHeightFull'>
                                    <Form.Item
                                        name='RepeatNumbers'
                                        hasFeedback
                                        label={<p className='p-admin-title-text'>{selectedLanguage?.text_NoOfRepeats}</p>}
                                        rules={[
                                            {
                                                required: true,
                                                message: selectedLanguage?.error_NoOfRepeats
                                            },
                                            ({ getFieldValue }) => ({
                                                validator(rule, value) {
                                                    if (value < 1) {
                                                        return Promise.reject(selectedLanguage?.txt_Value_must_more_then + ' 1')
                                                    }
                                                    return Promise.resolve()
                                                }
                                            })
                                        ]}
                                        initialValue={2}
                                    >
                                        <Input
                                            className='form-input-new-design-big-slam'
                                            type='number'
                                            controls={false}
                                            min={2}
                                            step={1}
                                            precision={0}
                                            style={{ width: '100%' }}
                                        />
                                    </Form.Item>
                                </div>
                            </div>
                        }
                    </div>

                    <div className='form-two-input-flex'>
                        <div className='form-two-input-flex-inner-input '>
                            <Form.Item
                                label={<p className='p-admin-title-text'>{selectedLanguage?.CreatePrivateit}</p>}
                                name='isPrivate'
                                onChange={onChangeCheckBox}
                                initialValue={Private}
                                valuePropName='checked'>
                                <div
                                    className='form-input-new-design-big-slam'
                                    style={{ display: 'flex', alignItems: 'center' }}
                                >
                                    <p
                                        className='p-admin-title-text'
                                        style={{
                                            margin: '0px 10px'
                                        }}>{selectedLanguage?.text_public}</p>

                                    <Switch
                                        onChange={onChangeCheckBox}
                                        checked={Private}
                                        checkedChildren={
                                            <RxLockClosed
                                                size={19}
                                                style={{
                                                    height: 22,
                                                    marginRight: 4
                                                }} />
                                        }
                                        unCheckedChildren={
                                            <RxLockOpen1
                                                size={19}
                                                style={{
                                                    height: 22,
                                                    marginLeft: 4
                                                }} />
                                        }
                                    />
                                    <p
                                        className='p-admin-title-text'
                                        style={{
                                            margin: '0px 10px'
                                        }}>{selectedLanguage?.text_private}</p>
                                </div>
                            </Form.Item>
                        </div>
                    </div>

                    {Private &&
                        <div className='form-two-input-flex'>
                            <div className='form-two-input-flex-inner-input'>
                                <Form.Item
                                    name='privateCode'
                                    hasFeedback
                                    initialValue={edit?.privateCode}
                                    label={<p className='p-admin-title-text'>{selectedLanguage?.text_PrivateCode}</p>}
                                    rules={[
                                        {
                                            required: true,
                                            message: selectedLanguage?.text_EnterPrivateCode
                                        }
                                    ]}
                                >
                                    <Input
                                        disabled={edit?.privateCode}
                                        className='form-input-new-design-big-slam'
                                        placeholder={selectedLanguage?.text_EnterPrivateCode}
                                    />
                                </Form.Item>
                            </div>
                        </div>
                    }

                    <div className='form-two-input-flex'>
                        <div className='form-two-input-flex-inner-input '>
                            <Form.Item
                                label={<p className='p-admin-title-text'>{selectedLanguage?.text_Promoter}</p>}
                                name='isPromoter'
                                onChange={onChangePromoter}
                                initialValue={Promoter || edit?.isPromoter}
                                valuePropName='checked'
                            >
                                <div
                                    className='form-input-new-design-big-slam'
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center'
                                    }}
                                >
                                    <Checkbox
                                        onChange={onChangePromoter}
                                        checked={Promoter}
                                        className='form-input-new-style'
                                        style={{
                                            width: '100%',
                                            padding: '17px 14px',
                                        }}>
                                        {<p className='p-admin-title-text'>{selectedLanguage?.text_Promoter}</p>}
                                    </Checkbox>
                                </div>
                            </Form.Item>
                        </div>
                    </div>

                    {Promoter && <div className='form-two-input-flex'>
                        <div className='form-two-input-flex-inner-input '>
                            <Form.Item
                                name='promoterId'
                                hasFeedback
                                initialValue={edit?.promoterId?._id}
                                label={<p className='p-admin-title-text'>{selectedLanguage?.text_SelectPromoterToLink}</p>}
                                rules={[
                                    {
                                        required: true,
                                        message: selectedLanguage?.text_PleaseSelectPromoterToLink
                                    }
                                ]}
                            >
                                <Select
                                    className='form-input-new-design-big-slam'
                                    notFoundContent={selectedLanguage?.txt_NoDataFound}
                                    placeholder={selectedLanguage?.text_selectPromoterToLink}>
                                    {promoter?.length && promoter?.map((v, i) => {
                                        return (
                                            <Option value={v?._id}>
                                                {`${v?.name}-${v?.country}-${v?.city} `}
                                            </Option>
                                        )
                                    })}
                                </Select>
                            </Form.Item>
                        </div>
                    </div>}

                    <Form.Item>
                        <Button
                            type='primary'
                            htmlType='submit'
                            loading={loading}
                        >
                            {edit?._id ? selectedLanguage?.button_updateTournament : selectedLanguage?.btntext_Createtournament}
                        </Button>
                    </Form.Item>
                </Form>
            </div>
        </div >
    )
}

export default CreateTournament