import React from "react";
import NewVisiterHeader from "./VisiterHeader";
import { useSelector } from "react-redux";
import EnglishLanguage from '../../Languages/EnglishLanguage.json'
import NlLanguage from '../../Languages/NetherlandsLanguage.json'
import { NewFooter } from "../../NewComponents";

const VisiterHome = (props) => {
    const { language, selectedLanguage } = props 

    return (
        <div className="new-main-layout-div">
            <div className="visiter-home-main-div ">
                <div className="visiter-home-main-div-scroll sidebar-scroll_class">
                    <NewVisiterHeader selectedLanguage={selectedLanguage} />
                    <div className='new-main-component-screen-div'>
                        <p className='new-screen-title'>{selectedLanguage?.txt_HomeScreen}</p>
                        <br />
                        <br />
                        <br />
                    </div>
                    <NewFooter {...props} />
                </div>
            </div>
        </div>
    )
}

export default VisiterHome