import { Button, Form, Input, InputNumber, Select } from 'antd'
import axios from 'axios'
import React, { useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import { AUTH } from '../../../utils/apis'
import { assignMessage, errorMessage, successNotification } from '../../../utils/helpers'
import { loginUser } from '../../../Redux/actions/authActions'
import countryCodes from '../../../utils/countryCodes'
import ChangePassword from './ChangePassword'
import ReactFlagsSelect from 'react-flags-select'


const PlayerProfile = (props) => {
    const { Option } = Select
    const { selectedLanguage, isEnglish, user, language } = props
    const [form] = Form.useForm()
    const formRef = useRef(null)
    const dispatch = useDispatch()
    const [country, setCountry] = useState(user?.country || 'Netherlands')
    const [countryCode, setCountryCode] = useState(countryCodes.filter(v => v?.code === user?.country)?.[0]?.dial_code || '+31')
    const [loading, setLoading] = useState(false)
    const [selected, setSelected] = useState(
        user?.languageSetting === 'netherlands' ? 'NL' :
            user?.languageSetting === 'italy' ? 'IT' :
                user?.languageSetting === 'german' ? 'DE' :
                    user?.languageSetting === 'spain' ? 'ES' :
                        user?.languageSetting === 'egypt' ? 'EG' :
                            user?.languageSetting === 'russain' ? 'RU' : 'GB')


    const [userLanguage, setUserLanguage] = useState(user?.languageSetting)


    const updateCountry = (e) => {
        let selectedCode = countryCodes.filter((v) => v?.code === e)[0].dial_code
        setCountryCode(selectedCode)
        formRef.current.setFieldsValue({ prefix: selectedCode })
    }


    const onchangeLanguage = (v) => {
        setSelected(v)
        if (v === 'NL') {
            setUserLanguage('netherlands')
        }
        else if (v === 'IT') {
            setUserLanguage('italy')
        }
        else if (v === 'DE') {
            setUserLanguage('german')
        }
        else if (v === 'ES') {
            setUserLanguage('spain')
        }
        else if (v === 'EG') {
            setUserLanguage('egypt')
        }
        else if (v === 'RU') {
            setUserLanguage('russain')
        }
        else {
            setUserLanguage('english')
        }
    }


    const prefixSelector = () => {
        return (
            <Form.Item name='prefix' noStyle>
                <Select
                    showSearch
                    disabled
                    notFoundContent={selectedLanguage?.txt_NoDataFound}
                    style={{
                        minWidth: 80,
                        maxWidth: window.innerWidth > 500 ? 'auto' : 120,
                    }}
                    defaultValue={countryCode}
                    value={countryCode}
                    filterOption={(input, option) =>
                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                >
                    {countryCodes?.map((v, i) => (
                        <Option
                            value={v?.dial_code}
                            key={i}
                        >{`${v?.name} ${v?.dial_code}`}</Option>
                    ))}
                </Select>
            </Form.Item>
        )
    }

    const onFinish = (values) => {
        values._id = user?._id
        values.languageSetting = userLanguage
        setLoading(true)
        axios.post(AUTH.UPDATE_SETTINGS, values)
            .then((res) => {
                const { data } = res
                setLoading(false)
                if (data.success) {
                    successNotification(assignMessage(data?.message, language?.language))
                    dispatch(loginUser(data?.user))
                    return window.location.reload()
                }
                else {
                    setLoading(false)
                    errorMessage(assignMessage(data?.message, language?.language))
                }
            })
            .catch((e) => {
                setLoading(false)
                errorMessage(assignMessage(e?.message, language?.language))
            })
    }


    return (
        <div className='new-main-component-screen-div' style={{ background: 'black' }}>
            <h1 className='new-screen-title orang-color' >
                {selectedLanguage?.title_AccountSettings}
            </h1>
            <div style={{ marginTop: '20px' }}>
                <Form
                    name='form'
                    form={form}
                    onFinish={onFinish}
                    layout={'vertical'}
                    ref={formRef}
                >
                    <div className='form-two-input-flex'>

                        <div className='form-two-input-flex-inner-input'>
                            <Form.Item
                                initialValue={user?.name}
                                name='name'
                                label={<p className='p-admin-title-text'>{selectedLanguage?.label_FullName}</p>}
                                rules={[
                                    {
                                        required: true,
                                        message: selectedLanguage?.error_PleaseEnterYourFullName
                                    }
                                ]}
                            >
                                <Input
                                    className='form-input-new-design-big-slam'
                                    placeholder={selectedLanguage?.placeHolder_PleaseEnterYourFullNameHere}
                                />
                            </Form.Item>
                        </div>

                        <div className='form-two-input-flex-inner-input'>
                            <Form.Item
                                name='email'
                                initialValue={user?.email}
                                label={<p className='p-admin-title-text'>{selectedLanguage?.label_Email}</p>}
                                rules={[
                                    {
                                        required: true,
                                        message: selectedLanguage?.error_PleaseEnterYourEmail
                                    },
                                    {
                                        type: 'email',
                                        message: selectedLanguage?.error_EmailIsNotValid,
                                    },
                                ]}
                            >
                                <Input
                                    disabled
                                    className='form-input-new-design-big-slam'
                                    placeholder={selectedLanguage?.placeHolder_PleaseEnterYourEmail}
                                />
                            </Form.Item>
                        </div>
                    </div>

                    <div className='form-two-input-flex'>
                        <div className='form-two-input-flex-inner-input'>
                            <Form.Item
                                name='country'
                                label={<p className='p-admin-title-text'>{selectedLanguage?.label_Country}</p>}
                                hasFeedback
                                initialValue={country}
                                rules={[
                                    {
                                        required: true,
                                        message: selectedLanguage?.error_PleaseSelectYourCountry
                                    }
                                ]}
                            >
                                <Select
                                    showSearch
                                    showArrow
                                    allowClear={false}
                                    notFoundContent={selectedLanguage?.txt_NoDataFound}
                                    className='form-input-new-design-big-slam input-none'
                                    placeholder={selectedLanguage?.placeHolder_SelectYourCountry}
                                    optionFilterProp='children'
                                    onChange={updateCountry}
                                    filterOption={(input, option) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                >
                                    {countryCodes?.map((v, i) => <Option value={v?.code} key={i}>{isEnglish ? v?.name : v?.nameNL}</Option>)}
                                </Select>
                            </Form.Item>
                        </div>

                        <div className='form-two-input-flex-inner-input'>
                            <Form.Item
                                name='city'
                                initialValue={user?.city}
                                label={<p className='p-admin-title-text'>{selectedLanguage?.label_City}</p>}
                                hasFeedback
                                rules={[
                                    {
                                        required: true,
                                        message: selectedLanguage?.error_PleaseInputYourCity
                                    }
                                ]}
                            >
                                <Input
                                    className='form-input-new-design-big-slam'
                                    placeholder={selectedLanguage?.placeHolder_InputYourCityHere}
                                />
                            </Form.Item>
                        </div >
                    </div >

                    <div className='form-two-input-flex'>
                        <div className='form-two-input-flex-inner-input'>
                            <Form.Item
                                name='streetNumber'
                                label={<p className='p-admin-title-text'>{selectedLanguage?.StreetNumber}</p>}
                                initialValue={user?.streetNumber}
                                hasFeedback
                            >
                                <Input
                                    className='form-input-new-design-big-slam'
                                    placeholder={selectedLanguage?.InputYourStreetNumberHere}
                                />
                            </Form.Item>
                        </div>
                        <div className='form-two-input-flex-inner-input'>
                            <Form.Item
                                name='postalCodeCity'
                                initialValue={user?.postalCodeCity}
                                label={<p className='p-admin-title-text'>{selectedLanguage?.PostalCode}</p>}
                                hasFeedback
                            >
                                <Input
                                    className='form-input-new-design-big-slam'
                                    placeholder={selectedLanguage?.InputYourPostalCodeHere}
                                />
                            </Form.Item>
                        </div>
                    </div>

                    <div className='form-two-input-flex'>
                        <div className='form-two-input-flex-inner-input'>
                            <Form.Item
                                name='houseNumber'
                                initialValue={user?.houseNumber}
                                label={<p className='p-admin-title-text'>{selectedLanguage?.label_houseno}</p>}
                                hasFeedback
                            >
                                <Input
                                    className='form-input-new-design-big-slam'
                                    placeholder={selectedLanguage?.placeHolder_InputYourhousenoHere}
                                />
                            </Form.Item>
                        </div >
                        <div className='form-two-input-flex-inner-input'>
                            <Form.Item
                                name='telNo'
                                initialValue={user?.telNo}
                                label={<p className='p-admin-title-text'>{selectedLanguage?.TelNo}</p>}
                                hasFeedback
                            >
                                <Input
                                    onBeforeInput={(e) => {
                                        const inputValue = e.clipboardData?.getData('text') || e.data
                                        if (!/^\d*$/.test(inputValue) || e.target.value.length > 12) {
                                            e.preventDefault()
                                        }
                                    }}
                                    type='number'
                                    pattern='[0-9]*'
                                    min={0}
                                    addonBefore={prefixSelector()}
                                    className='form-input-new-design-big-slam'
                                    placeholder={selectedLanguage?.TelNo}
                                />
                            </Form.Item>
                        </div >
                    </div >

                    <div className='form-two-input-flex'>
                        <div className='form-two-input-flex-inner-input'>
                            <Form.Item
                                name='bankAccount'
                                initialValue={user?.bankAccount}
                                label={<p className='p-admin-title-text'>{selectedLanguage?.text_BankAccountName}</p>}
                                hasFeedback
                            >
                                <Input
                                    className='form-input-new-design-big-slam'
                                    placeholder={selectedLanguage?.placeHolder_BankAccountName}
                                />
                            </Form.Item>
                        </div >
                        <div className='form-two-input-flex-inner-input newInpuHeightFull'>
                            <Form.Item
                                name='IBAN'
                                initialValue={user?.IBAN}
                                label={<p className='p-admin-title-text'>{selectedLanguage?.text_IBAN}</p>}
                                hasFeedback
                            >
                                <Input
                                    // type='number'
                                    style={{ width: '100%' }}
                                    className='form-input-new-design-big-slam'
                                    placeholder={selectedLanguage?.placeHolder_IBAN}
                                />
                            </Form.Item>
                        </div >
                    </div >

                    <div style={{ marginBottom: '20px' }}>
                        <p style={{
                            margin: '0px',
                            color: 'white',
                            fontWeight: 'bold',
                            marginLeft: '5px',
                            marginBottom: '7px',
                        }}>
                            {selectedLanguage?.tedxt_LanguageSetting}
                        </p>
                        <div
                            className='form-input-new-design-big-slam'
                        >
                            <ReactFlagsSelect
                                className='countries-select-grey'
                                fullWidth={false}
                                showOptionLabel={false}
                                showSelectedLabel={false}
                                countries={['GB', 'NL', 'IT', 'ES', 'EG', 'RU', 'DE']}
                                selected={selected}
                                onSelect={(v) => onchangeLanguage(v)}
                            />
                        </div>
                    </div>

                    <Form.Item>
                        <Button
                            type='primary'
                            htmlType='submit'
                            loading={loading}
                        >
                            {selectedLanguage?.ButtonText_Submit}
                        </Button>
                    </Form.Item>
                </Form>
            </div>
            <div style={{ borderBottom: '1px dashed white' }} />
            <div style={{ marginTop: '40px' }}>
                <ChangePassword {...props} />
            </div>
        </div>
    )
}

export default PlayerProfile