import { Button, Image, Modal, Spin, Table } from "antd";
import React, { useEffect, useState } from "react";
import AddAdminCoupons from "./AddCoupons";
import { IoIosArrowBack } from 'react-icons/io'
import axios from "axios";
import { ADMIN } from "../../../utils/apis";
import { assignMessage, createGiftCard, errorMessage } from "../../../utils/helpers";
import HTML2Canvas from 'html2canvas'
import moment from 'moment'
import QRCode from 'qrcode.react'

const AdminCoupons = (props) => {
    const { selectedLanguage, language, user } = props
    const [showForm, setShowform] = useState(false)
    const [loading, setLoading] = useState(false)
    const [spin, setSpin] = useState({})
    const [couponsData, setCouponsData] = useState([])
    const [allcoupons, setAllcoupons] = useState([])
    const [couponCode, setCouponCode] = useState('')
    const [visible, setVisible] = useState(false)
    const [download, setDownload] = useState(false)

    const iOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream

    useEffect(() => {
        if (download) {
            androidDownloadQR()
        }
    }, [download])

    const disableModal = () => setVisible(false)

    const androidDownloadQR = async () => {
        let id = download ? 'qrCodeDownload' : 'qrCode'
        const canvas = document.getElementById(id)

        await HTML2Canvas(canvas, { allowTaint: true, useCORS: true })
            .then((data) => {
                const pngUrl = data.toDataURL()
                const a = document.createElement('a')
                a.href = pngUrl
                a.download = 'coupon-code.png'

                a.style.display = 'none'
                document.body.appendChild(a)
                a.click()
                document.body.removeChild(a)

                setDownload(false)
            })
            .catch((e) => {
                setDownload(false)
                console.log('e', e)
                errorMessage(selectedLanguage?.txt_downloaderror)
            })
    }

    console.log(couponsData, '=couponsData')
    useEffect(() => {
        getCoupousData()
    }, [])

    const getCoupousData = () => {
        setLoading(true)
        axios.get(`${ADMIN?.GET_TOURNAMENT_COUPOUN}/${user?._id}`)
            .then((res) => {
                const { data } = res
                if (data?.success) {
                    console.log(data?.data)
                    let tableData = Object.entries(data?.data)
                    tableData = tableData?.map((v, i) => {
                        console.log(1 + i)
                        return {
                            id: v[0],
                            value: v[1]
                        }
                    })
                    setLoading(false)
                    setAllcoupons([...tableData])
                }
                else {
                    console.log('else is work')
                    setLoading(false)
                }
            })
            .catch((e) => {
                setLoading(false)
                errorMessage(assignMessage(e?.message, language?.language))
            })
    }

    let columns = [
        {
            title: selectedLanguage?.text_Description,
            key: 'id',
            dataIndex: 'id',
            ellipsis: true,
            render: (e) => {
                return (
                    <p>{e ? e : '----'}</p>
                )
            }
        },
        {
            title: selectedLanguage?.title_Action,
            key: 'actions',
            render: (e) => {
                const spinFunc = () => {
                    let newSpin = { ...spin }
                    newSpin[e?.id] = false
                    setSpin(newSpin)
                }
                return (
                    <>
                        {
                            <p
                                className="link-custom-orange"
                                onClick={() => setCouponsData(e)}
                            >
                                {selectedLanguage?.text_ShowTotalPdfs}
                            </p>
                        }
                    </>
                )
            }

        }
    ]


    let couponColums = [
        {
            title: selectedLanguage?.text_IMG,
            render: (e) => {
                return (
                    <>
                        <Image style={{ height: 60 }} src={e?.imageUrl} />
                    </>
                )
            }
        },
        {
            title:  selectedLanguage?.txt_CouponQRCode,
            dataIndex: 'couponCode',
            key: 'couponCode',
            render: (e) => (
                <span>
                    <Button type='link' onClick={() => (setCouponCode(e), setVisible(true))} >{selectedLanguage?.text_View}</Button>
                    <Button type='link' onClick={() => (setCouponCode(e), setDownload(true))} >{selectedLanguage?.text_Download}</Button>
                </span>
            )
        },
        {
            title: selectedLanguage?.title_Description,
            dataIndex: 'description',
            key: 'description',
        },
        {
            title: selectedLanguage?.text_Claim,
            dataIndex: 'isClaimed',
            key: 'isClaimed',
            sorter: (a, b) => a.isClaimed - b.isClaimed,
            showSorterTooltip: false,
            render: (e) => <>{e ? 'True' : 'False'}</>
        },

    ]

    return (
        <>
            <Modal
                open={visible}
                title={selectedLanguage?.coupons_Coupons}
                onCancel={disableModal}
                footer={[
                    <Button key='back' onClick={disableModal}>
                        {selectedLanguage?.Close}
                    </Button>
                ]}
            >
                <div
                    style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center', marginBottom: 10 }}
                >
                    <div id='qrCode' style={{ textAlign: 'center' }}>
                        <QRCode
                            value={couponCode}
                            size={250}
                            level={'H'}
                            includeMargin={true}
                        />
                        <p>{couponCode}</p>
                    </div>
                    <Button type="link" onClick={androidDownloadQR}>
                        {selectedLanguage?.text_DownloadInvoice}
                    </Button>
                </div>
            </Modal>
            <div
                style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center' }}
            >
                <div
                    style={{ textAlign: 'center' }}
                    id='qrCodeDownload'
                    className='fixed-qr'
                >
                    <QRCode
                        value={couponCode}
                        size={250}
                        level={'H'}
                        includeMargin={true}
                    />
                    <p>{couponCode}</p>
                </div>
            </div>
            {!showForm ?
                <div className='new-main-component-screen-div'>
                    <h1 className='new-screen-title orang-color' >
                        {selectedLanguage?.coupons_Coupons}
                    </h1>
                    <span style={{ marginTop: '20px' }} className='button-div-flex-end'>
                        {couponsData?.id ?
                            <Button type="primary" onClick={() => { setCouponsData(null) }} >
                                {selectedLanguage?.text_Cancel}
                            </Button>
                            :
                            <Button type="primary" onClick={() => { setShowform(true) }} >
                                {selectedLanguage?.coupon_AddNewCoupons}
                            </Button>
                        }
                    </span>

                    <div className="custom-table-container">
                        <Table
                            locale={{ emptyText: selectedLanguage?.txt_NoDataFound }}
                            className='new-layout-table'
                            dataSource={couponsData?.id ? couponsData?.value : allcoupons}
                            columns={couponsData?.id ? couponColums : columns}
                            pagination={false}
                            loading={loading}
                            scroll={{ x: 550 }}
                        />
                    </div>
                </div>
                :
                <div className='new-main-component-screen-div' style={{ background: 'black' }}>
                    <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                        <IoIosArrowBack
                            onClick={() => { setShowform(false); getCoupousData() }}
                            style={{ marginRight: '5px', marginTop: '10px' }}
                            className="orang-color"
                            size={28}
                        />
                        <h1 className='new-screen-title orang-color' > {selectedLanguage?.coupon_AddNewCoupons} </h1>
                    </div>
                    <br />
                    <AddAdminCoupons {...props} />
                </div>
            }
        </>
    )
}
export default AdminCoupons