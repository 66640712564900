import React, { useEffect, useState } from 'react';
import imgForDeck from '../../assets/WGP-white.png'
import imgForMobile from '../../assets/new-logowhite.png'
import { FaFacebookF, FaInstagram, FaTwitter } from 'react-icons/fa';
import { Menu, Popconfirm, Spin } from 'antd';
import ReactFlagsSelect from 'react-flags-select';
import { IoIosArrowDown } from 'react-icons/io';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { setLanguage } from '../../Redux/actions/languageAction';
import { allPaths, currencySign, drawerRoutesAdmin, drawerRoutesUser } from '../../utils/constants';
import MenuModal from './NewMenuModal';
import { IoMdLogOut } from "react-icons/io";
import { removeUser } from '../../Redux/actions/authActions';
import { convertNumber } from '../../utils/helpers';


const NewHeader = (props) => {
    const { user, selectedLanguage } = props
    const { SubMenu } = Menu
    const dispatch = useDispatch()
    const history = useHistory()
    const [logoutLoad, setLogoutLoad] = useState(false);
    const [selected, setSelected] = useState('GB')
    const language = useSelector(state => state?.languageReducer)
    let SL_lang = user?.userType === 'player' ? user?.languageSetting : language?.language

    useEffect(() => {
        language?.language === 'netherlands' ?
            setSelected('NL') :
            language?.language === 'italy' ?
                setSelected('IT') :
                language?.language === 'german' ?
                    setSelected('DE') :
                    language?.language === 'spain' ?
                        setSelected('ES') :
                        language?.language === 'egypt' ?
                            setSelected('EG') :
                            language?.language === 'russain' ?
                                setSelected('RU') :
                                setSelected('GB')
    })


    const onchangeLanguage = (v) => {
        setSelected(v)
        if (v === 'NL') {
            dispatch(setLanguage('netherlands'))
        }
        else if (v === 'IT') {
            dispatch(setLanguage('italy'))
        }
        else if (v === 'DE') {
            dispatch(setLanguage('german'))
        }
        else if (v === 'ES') {
            dispatch(setLanguage('spain'))
        }
        else if (v === 'EG') {
            dispatch(setLanguage('egypt'))
        }
        else if (v === 'RU') {
            dispatch(setLanguage('russain'))
        }
        else {
            dispatch(setLanguage('english'))
        }
    }

    const currentPage = window?.location?.pathname
    const logout = () => {
        setLogoutLoad(true)
        setTimeout(() => {
            setLogoutLoad(false)
            dispatch(removeUser())
            history.push(allPaths.VISITER_HOME)
        }, 1500);
    }

    return (
        <div>
            <div className="new-small-upper-header">
                <div className="new-small-upper-header-inner">
                    <span className="name">
                        {user?.userType === 'player' ? ` ${currencySign} ${convertNumber(user?.walletBalance) || 0}` : 'WGPPLAZA.COM'}
                    </span>
                    <span className="last-div">
                        <p className='uppr-icon-main'>
                            <span className='upper-header-icon'><FaFacebookF size={18} /></span>
                            <span className='upper-header-icon'><FaTwitter size={18} /></span>
                            <span className='upper-header-icon'><FaInstagram size={18} /></span>
                        </p>
                        {user?.userType === 'player' ? null :
                            <span className='round-lang-upper-header-icon'>
                                <ReactFlagsSelect
                                    className='round-countries-select-update'
                                    fullWidth={false}
                                    showOptionLabel={false}
                                    showSelectedLabel={false}
                                    countries={['GB', 'NL', 'IT', 'ES', 'EG', 'RU', 'DE']}
                                    selected={selected}
                                    onSelect={(v) => onchangeLanguage(v)}
                                />
                            </span>
                        }
                        <p className='uppr-icon-main' style={{ display: 'inline' }}>
                            <Popconfirm
                                title={selectedLanguage?.txt_Are_you_sure_to_logout}
                                okText={selectedLanguage?.text_yes}
                                cancelText={selectedLanguage?.text_No}
                                okButtonProps={{
                                    type: 'primary',
                                    className: 'form-button'
                                }}
                                cancelButtonProps={{ style: { borderRadius: 4 } }}
                                onConfirm={() => {
                                    logout()
                                }}
                            >
                                <span className='upper-header-icon'>
                                    {logoutLoad ? <Spin /> : <IoMdLogOut size={18} />}
                                </span>
                            </Popconfirm>
                        </p>
                    </span>
                </div>
            </div>

            <div className="new-design-header">
                <div className="new-designer-inner">
                    <div className='new-main-header-inner-bottom' style={{ width: '100%' }}>
                        <div className='new-lower-nav'>
                            <div>
                                <img src={imgForDeck} className='new-header-img-desk' />
                                <img src={imgForMobile} className='new-header-img-mobile' />
                            </div>
                            <div className='lower-nav-left'>
                                <span className='new-menu-for-web' style={{ display: 'flex', width: '100%' }}>
                                    <Menu
                                        defaultSelectedKeys={[`${window?.location?.pathname}`]}
                                        mode='horizontal'
                                        className='me-new-top-menu'
                                    >
                                        <>
                                            {(user?._id && user?.userType === 'player' ? drawerRoutesUser : user?._id ? drawerRoutesAdmin : [])?.map((v, i) => {
                                                if (v?.isSubMenu && v?.children?.length) {
                                                    return (
                                                        <SubMenu
                                                            key={v?.route}
                                                            title={
                                                                <span className='my-menu-txtt'>
                                                                    {SL_lang === 'netherlands' ? v?.titleNl
                                                                        : SL_lang === 'italy' ? v?.titleItaly
                                                                            : SL_lang === 'spain' ? v?.titleSpain
                                                                                : SL_lang === 'egypt' ? v?.titleEgypt
                                                                                    : SL_lang === 'russain' ? v?.titleRussain
                                                                                        : SL_lang === 'german' ? v?.titleGerman
                                                                                            : v?.titleEnglish}
                                                                    <span style={{ paddingLeft: '5px', paddingTop: '3px' }}>{<IoIosArrowDown color={'white'} />} </span>
                                                                </span>}

                                                            className='new-header-sub-menu'
                                                            style={{ color: 'white' }}
                                                            >
                                                            {v?.children?.map((y, j) => {
                                                                return (
                                                                    <Menu.Item key={y?.route} >
                                                                        <Link
                                                                            to={y?.route}
                                                                        >
                                                                            <span className={y?.route === currentPage ?
                                                                                'new-menu-c-item-active' : 'new-menu-c-item'}>{SL_lang === 'netherlands' ? y?.titleNl
                                                                                    : SL_lang === 'italy' ? y?.titleItaly
                                                                                        : SL_lang === 'spain' ? y?.titleSpain
                                                                                            : SL_lang === 'egypt' ? y?.titleEgypt
                                                                                                : SL_lang === 'russain' ? y?.titleRussain
                                                                                                    : SL_lang === 'german' ? y?.titleGerman
                                                                                                        : y?.titleEnglish}</span>
                                                                        </Link>
                                                                    </Menu.Item>
                                                                )
                                                            })}
                                                        </SubMenu>
                                                    )
                                                }
                                                return (
                                                    <Menu.Item key={v.route}
                                                        className='new-header-sub-menu'
                                                    >
                                                        <Link
                                                            to={v?.route}
                                                        >
                                                            <span className='my-menu-txtt'
                                                                // style={{ color: 'white' }}
                                                            >
                                                                {SL_lang === 'netherlands' ? v?.titleNl
                                                                    : SL_lang === 'italy' ? v?.titleItaly
                                                                        : SL_lang === 'spain' ? v?.titleSpain
                                                                            : SL_lang === 'egypt' ? v?.titleEgypt
                                                                                : SL_lang === 'russain' ? v?.titleRussain
                                                                                    : SL_lang === 'german' ? v?.titleGerman
                                                                                        : v?.titleEnglish}
                                                            </span>
                                                        </Link>
                                                    </Menu.Item>
                                                )
                                            })}
                                        </>
                                    </Menu>
                                </span>
                                <span className='new-menu-for-mobile'>
                                    <MenuModal user={user} {...props} />
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default NewHeader