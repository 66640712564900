import React from "react";
import { useHistory } from "react-router-dom";
import { IoIosArrowBack } from "react-icons/io"
import { currencySign } from "../../utils/constants";
import { convertRoundNumber } from "../../utils/helpers";

const CompetitionResult = (props) => {
    const history = useHistory()
    const { user, selectedLanguage } = props
    let competition = history?.location?.state

    console.log('competition', competition)
    return (

        <div className='new-main-component-screen-div'>
            <div >
                <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
                    <span style={{ marginTop: '12px', cursor: 'pointer' }}>
                        <IoIosArrowBack
                            style={{ marginRight: '5px', color: 'black' }}
                            size={28}
                            onClick={() => history?.goBack()} />
                    </span>
                    <p className='new-screen-title'>

                        {selectedLanguage?.text_CompetitionResult}
                    </p>
                </div>
                <div style={{ margin: '30px' }}>
                    <h3 className="main-title-result" >{selectedLanguage?.text_Competition} : {competition?.competitionName || '----'}</h3>
                    <h3 className="main-title-result" >{selectedLanguage?.text_TotalPrie}: {currencySign} {convertRoundNumber(competition?.totalPrize)}</h3>
                    <h3 className="main-title-result" >{selectedLanguage?.text_PromotedUsers}:{competition?.result?.promotedUsers?.length}</h3>
                    {/*  */}
                    <div style={{ margin: '10px', marginLeft: '0px' }}>
                        {competition?.result?.promotedUsers?.length ?
                            competition?.result?.promotedUsers?.map((v, i) => {
                                return (
                                    <div className="ppp-box">
                                        <h3 className="main-title-result" style={{ fontSize: '16px' }}>{i + 1}:{v?.userId?.name}</h3>
                                        <h3 className="main-title-result" style={{ fontSize: '16px' }}>{selectedLanguage?.text_prize}:{convertRoundNumber(v?.prize)}</h3>
                                    </div>
                                )
                            }) :
                            <div className="ppp-box">
                                <h3 className="main-title-result" style={{ fontSize: '16px' }}>{selectedLanguage?.text_NoPromotedUser}</h3>
                            </div>
                        }
                    </div>

                    {/*  */}

                    <h3 className="main-title-result">{selectedLanguage?.text_DegradedUsers}:{competition?.result?.degradedUsers?.length}</h3>
                    <div style={{ margin: '10px', marginLeft: '0px' }}>
                        {competition?.result?.degradedUsers?.length ?
                            competition?.result?.degradedUsers?.map((v, i) => {
                                return (
                                    <div className="ppp-box">
                                        <h3 className="main-title-result" style={{ fontSize: '16px' }}>{i + 1}:{v?.userId?.name}</h3>
                                        <h3 className="main-title-result" style={{ fontSize: '16px' }}>{selectedLanguage?.text_prize}:{convertRoundNumber(v?.prize)}</h3>
                                    </div>
                                )
                            }) :
                            <div className="ppp-box">
                                <h3 className="main-title-result" style={{ fontSize: '16px' }}>{selectedLanguage?.text_NoDegradedUsers}</h3>
                            </div>
                        }
                    </div>


                    {/*  */}
                    <h3 className="main-title-result">{selectedLanguage?.text_PrizeOneUsers}:{competition?.result?.PrizeOne?.length}</h3>
                    <div style={{ margin: '10px', marginLeft: '0px' }}>
                        {competition?.result?.PrizeOne?.length ?
                            competition?.result?.PrizeOne?.map((v, i) => {
                                return (
                                    <div className="ppp-box">
                                        <h3 className="main-title-result" style={{ fontSize: '16px' }}>{i + 1}:{v?.userId?.name}</h3>
                                        <h3 className="main-title-result" style={{ fontSize: '16px' }}>{selectedLanguage?.text_prize}:{convertRoundNumber(v?.prize)}</h3>
                                    </div>
                                )
                            }) :
                            <div className="ppp-box">
                                <h3 className="main-title-result" style={{ fontSize: '16px' }}>{selectedLanguage?.text_NoPrizeOneUsers}</h3>
                            </div>
                        }
                    </div>


                    {/*  */}

                    <h3 className="main-title-result">{selectedLanguage?.text_PrizeTwoUsers}:{competition?.result?.PrizeTwo?.length}</h3>
                    <div style={{ margin: '10px', marginLeft: '0px' }}>
                        {competition?.result?.PrizeTwo?.length ?
                            competition?.result?.PrizeTwo?.map((v, i) => {
                                return (
                                    <div className="ppp-box">
                                        <h3 className="main-title-result" style={{ fontSize: '16px' }}>{i + 1}:{v?.userId?.name}</h3>
                                        <h3 className="main-title-result" style={{ fontSize: '16px' }}>{selectedLanguage?.text_prize}:{convertRoundNumber(v?.prize)}</h3>
                                    </div>
                                )
                            }) :
                            <div className="ppp-box">
                                <h3 className="main-title-result" style={{ fontSize: '16px' }}>{selectedLanguage?.text_NoPrizeTwoUsers}</h3>
                            </div>
                        }
                    </div>

                    {/*  */}
                    <h3 className="main-title-result">{selectedLanguage?.text_PrizeThreeUsers}:{competition?.result?.PrizeThree?.length}</h3>
                    <div style={{ margin: '10px', marginLeft: '0px' }}>
                        {competition?.result?.PrizeThree?.length ?
                            competition?.result?.PrizeThree?.map((v, i) => {
                                return (
                                    <div className="ppp-box">
                                        <h3 className="main-title-result" style={{ fontSize: '16px' }}>{i + 1}:{v?.userId?.name}</h3>
                                        <h3 className="main-title-result" style={{ fontSize: '16px' }}>{selectedLanguage?.text_prize}:{convertRoundNumber(v?.prize)}</h3>
                                    </div>
                                )
                            }) :
                            <div className="ppp-box">
                                <h3 className="main-title-result" style={{ fontSize: '16px' }}>{selectedLanguage?.text_NoPrizeThreeUsers}</h3>
                            </div>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}


export default CompetitionResult