import React, { useEffect, useState } from 'react'
import NoData from '../../../assets/noDataImae.png'
import { Button, Popconfirm, Table } from 'antd'
import { POST } from '../../../utils/apis'
import { successMessage, errorMessage, getCompetitionMatches, assignMessage } from '../../../utils/helpers'
import axios from 'axios'
const SentMatches = (props) => {
    const { selectedLanguage, user, matches, language, userActions } = props
    const [deleteLoading, setDeleteLoading] = useState({})

    const deleteMatchRequest = (data) => {
        setDeleteLoading({ [data?._id]: true })
        let obj = {
            _id: data?._id,
            userId: user?._id,
            code: data?.code,
        }
        axios.post(POST?.DELETE_COMPETITION_MATCH_REQUEST, obj)
            .then((res) => {
                const { data } = res
                setDeleteLoading({})
                if (data?.success) {
                    successMessage(assignMessage(data?.message, language?.language))
                    getCompetitionMatches(user?._id, userActions)
                }
                else {
                    errorMessage(assignMessage(data?.message, language?.language))
                }
            })
            .catch((e) => {
                setDeleteLoading({})
                errorMessage(assignMessage(e?.message, language?.language))
            })
    }

    const columns = [
        {
            title: selectedLanguage?.label_CompetitionName,
            render: (e) => {
                return (
                    <p>{e?.competition?.competitionName}</p>
                )
            }
        },
        {
            title: selectedLanguage?.text__Game,
            render: (e) => {
                return (
                    <p>{e?.gameId?.name}</p>
                )
            }
        },
        {
            title: selectedLanguage?.text__Matchtype,
            render: (e) => {
                return (
                    <p>{e?.matchType}</p>
                )
            }
        },
        {
            title: selectedLanguage?.text__Matchdate,
            render: (e) => {
                return (
                    <p>{e?.matchDate}</p>
                )
            }
        },
        {
            title: selectedLanguage?.text__Matchtime,
            render: (e) => {
                return (
                    <p>{e?.matchTime}</p>
                )
            }
        },
        {
            title: selectedLanguage?.text__ReceiverNme,
            render: (e) => {
                return (
                    <p>{e?.receiverId?.name}</p>
                )
            }
        },
        {
            title: selectedLanguage?.text__Status,
            render: (e) => {
                return (
                    <>
                        {
                            e?.isAccepted ?
                                <p className='accept-status'>{selectedLanguage?.sts_Accepted}</p>
                                : e?.isRejected ?
                                    <p className='reject-status'>{selectedLanguage?.sts_rejected}</p>
                                    :
                                    <p className='pending-stats'>{selectedLanguage?.sts_pending}</p>
                        }
                    </>
                )
            }
        },
        {
            title: selectedLanguage?.title_Action,
            render: (e) => {
                return (
                    <>
                        {
                            <Popconfirm
                                disabled={e?.isAccepted || e?.isRejected}
                                title={`${selectedLanguage?.text_AreyouwanttodeleteMatchrequestthatyousendto} ${e?.receiverId?.name}?`}
                                okText={selectedLanguage?.textDelete}
                                cancelText={selectedLanguage?.textCancel}
                                okButtonProps={{
                                    type: 'primary',
                                    className: 'form-button'
                                }}
                                cancelButtonProps={{ style: { borderRadius: 4 } }}
                                onConfirm={() => {
                                    deleteMatchRequest(e)
                                }}
                            >
                                <Button
                                    disabled={e?.isAccepted || e?.isRejected}
                                    type='primary'
                                    loading={deleteLoading[e?._id]}
                                    danger
                                >{selectedLanguage?.buttonText_Delete}</Button>
                            </Popconfirm>}</>
                )
            }
        },
    ]
    return (
        <>
            <div style={{ marginTop: '20px', borderBottom: `1px dashed black` }}>
                <div className="custom-table-container">
                    <Table
                        locale={{ emptyText: selectedLanguage?.txt_NoDataFound }}
                        className='new-layout-table'
                        dataSource={matches}
                        columns={columns}
                        pagination={false}
                        scroll={{ x: 1000 }}
                    />
                </div>
            </div>
        </>
    )
}

export default SentMatches