import { Modal } from 'antd';
import React, { useState } from 'react';
import { allPaths, drawerRoutesAdmin, drawerRoutesUser } from '../../utils/constants'
import { TfiMenu } from 'react-icons/tfi'
import { MdOutlineClose } from 'react-icons/md'
import { Link } from 'react-router-dom';

const MenuModal = (props) => {
    const { user, language, selectedLanguage } = props
    let SL_lang = user?.userType === 'player' ? user?.languageSetting : language?.language
    const currentPage = `${window?.location?.pathname}`
    const [menu, setMenu] = useState(false)

    console.log('====v', currentPage)

    const handleClose = () => {
        setMenu(false)
    }

    return (
        <>
            <div className='new-header-men-icon' onClick={() => setMenu(true)}>
                <TfiMenu size={20} />
            </div>
            <Modal
                className='my-modal-for-menu'
                open={menu}
                onCancel={() => setMenu(false)}
                footer={null}
            >
                <div className='cloxe-div-end'>
                    <p className='upper-header-txt-logo-menu'>
                        WGPPLAZA.COM
                    </p>
                    <div className='cloxe-div' onClick={() => setMenu(false)}>
                        <MdOutlineClose size={35} />
                    </div>
                </div>

                <div className='my-modal-menu-div new-layout-scroll-class'>
                    {(user?._id && user?.userType === 'player' ? drawerRoutesUser : user?._id ? drawerRoutesAdmin : [])?.map((v, i) => {
                        if (v?.isSubMenu && v?.children?.length) {
                            return (
                                <div key={v?.route}>
                                    <span style={{ color: 'white' }} className={false ? 'modal-menu-itm-active' : 'modal-menu-itm'} >
                                        {SL_lang === 'netherlands' ? v?.titleNl
                                            : SL_lang === 'italy' ? v?.titleItaly
                                                : SL_lang === 'spain' ? v?.titleSpain
                                                    : SL_lang === 'egypt' ? v?.titleEgypt
                                                        : SL_lang === 'russain' ? v?.titleRussain
                                                            : SL_lang === 'german' ? v?.titleGerman
                                                                : v?.titleEnglish}
                                    </span>
                                    <br />
                                    <br />
                                    {v?.children?.map((y, j) => {
                                        return (
                                            <p key={y?.route} style={{ marginLeft: '40px' }} >
                                                <Link to={y?.route} >
                                                    <span
                                                        onClick={handleClose}
                                                        className={y?.route === currentPage ? 'modal-menu-itm-child-active' : 'modal-menu-itm-child'}
                                                        style={{ color: 'white' }}
                                                    >
                                                        ◉{' '}
                                                        {SL_lang === 'netherlands' ? y?.titleNl
                                                            : SL_lang === 'italy' ? y?.titleItaly
                                                                : SL_lang === 'spain' ? y?.titleSpain
                                                                    : SL_lang === 'egypt' ? y?.titleEgypt
                                                                        : SL_lang === 'russain' ? y?.titleRussain
                                                                            : SL_lang === 'german' ? y?.titleGerman
                                                                                : y?.titleEnglish}
                                                    </span>
                                                </Link>
                                            </p>
                                        )
                                    })}
                                </div>

                            )
                        }
                        return (
                            <p key={v.route} >
                                <Link to={v?.route} >
                                    <span
                                        onClick={handleClose}
                                        className={v?.route == currentPage ? 'modal-menu-itm-active' : 'modal-menu-itm'}
                                        style={{ color: 'white' }} >
                                        {SL_lang === 'netherlands' ? v?.titleNl
                                            : SL_lang === 'italy' ? v?.titleItaly
                                                : SL_lang === 'spain' ? v?.titleSpain
                                                    : SL_lang === 'egypt' ? v?.titleEgypt
                                                        : SL_lang === 'russain' ? v?.titleRussain
                                                            : SL_lang === 'german' ? v?.titleGerman
                                                                : v?.titleEnglish}
                                    </span>
                                </Link>
                            </p>
                        )
                    })}
                </div>
            </Modal>
        </>
    )
}

export default MenuModal