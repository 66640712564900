import React, { useState } from "react";
import { currencySign, pointSubscription, pointsType } from "../../../utils/constants";
import { Modal, Select } from "antd";
import { assignMessage, errorMessage, getGeneralData, getUserData, infoMessage, successMessage } from "../../../utils/helpers";
import axios from "axios";
import { POST } from "../../../utils/apis";
import { LoadingOutlined } from '@ant-design/icons';

const PointSubscriptionModal = (props) => {
    const { selectedLanguage, openModal, handleClose, selectedGame, user, language, getUser, userActions, generalActions } = props
    const { Option } = Select
    const [pointType, setPointType] = useState('points')
    const [money, setMoney] = useState(10)
    const [loading, setLoading] = useState(false)

    const handleChangeType = (e) => {
        setPointType(e)
    }

    const handleChangeMoney = (e) => {
        setMoney(e)
    }


    const getSubscription = () => {
        let obj = {
            userId: user?._id,
            gameId: selectedGame?.gameId?._id,
            pointType: pointType,
            money: money,
            point: money === 10 ? 100 : money === 20 ? 300 : 500
        }
        setLoading(true)
        axios.post(POST?.USER_POINT_SUBSRIPTION, obj)
            .then(async (res) => {
                const { data } = res
                setLoading(false)
                if (data?.success) {
                    await getUser()
                    await getGeneralData(generalActions)
                    await getUserData(user?._id, userActions)
                    return successMessage(assignMessage(data?.message, language?.language))
                }
                errorMessage(assignMessage(data?.message, language?.language))
            })
            .catch((e) => {
                setLoading(false)
                errorMessage(assignMessage(e?.message, language?.language))
            })
    }

    return (
        <Modal
            open={openModal}
            className='pop-info-modal'
            footer={null}>
            <div style={{ background: '#3a3a3a', borderRadius: 12 }}>
                <p className='p-question'>{selectedLanguage?.txt_Game_Points_Subscription}</p>
                <div style={{ borderBottom: '1px solid #ec671b' }} />
                <div style={{ padding: 4 }}>
                    <p style={{ color: 'white' }} >{`${selectedLanguage?.txt_Your_Selected_Game_For_Subscription} ${selectedGame?.nickName}`}</p>
                    <br />
                    <p className='p-admin-title-text' >{selectedLanguage?.txt_Select_Subscription_For}</p>
                    <Select
                        onChange={handleChangeType}
                        value={pointType}
                        style={{ width: '100%' }}
                        notFoundContent={selectedLanguage?.txt_NoDataFound}
                        className='form-input-new-design-big-slam'
                        placeholder={selectedLanguage?.txt_Select_Subscription_For}>
                        {pointsType?.map((v, i) => {
                            return (
                                <Option value={v?.value}>{v?.name}</Option>
                            )
                        })}
                    </Select>
                    <br />
                    <br />
                    <p className='p-admin-title-text' >{selectedLanguage?.txt_Select_Subscription}</p>
                    <Select
                        onChange={handleChangeMoney}
                        value={money}
                        style={{ width: '100%' }}
                        notFoundContent={selectedLanguage?.txt_NoDataFound}
                        className='form-input-new-design-big-slam'
                        placeholder={selectedLanguage?.txt_Select_Subscription}>
                        {pointSubscription?.map((v, i) => {
                            return (
                                <Option value={v?.money}>{`${currencySign} ${v?.money} - ${v?.point} ${pointType === 'points' ?
                                    selectedLanguage?.txt_Points : selectedLanguage?.txt_Pro_Points}`}</Option>
                            )
                        })}
                    </Select>
                    <br />
                    <div style={{ display: 'flex', marginTop: 10 }}>
                        <button style={{ borderBottomLeftRadius: 8 }} className='cancel-btn' onClick={handleClose} >{selectedLanguage?.text_Cancel}</button>
                        <button
                            style={{ borderBottomRightRadius: 8, cursor: loading ? 'not-allowed' : 'pointer' }}
                            onClick={loading ? () => { console.log('') } : getSubscription}
                            className='confirm-btn'
                        >
                            {loading && <LoadingOutlined />}{selectedLanguage?.btn_Subscribe}
                        </button>
                    </div>
                </div>
            </div>
        </Modal>
    )
}

export default PointSubscriptionModal