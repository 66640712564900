import React, { useEffect, useState } from "react";
import { Button, Card, Modal, Select, Tooltip } from "antd";
import { GET, POST } from "../../../utils/apis";
import { errorMessage, successNotification, convertRoundNumber, assignMessage } from "../../../utils/helpers";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import allPaths from '../../../Config/paths'
import { useHistory } from "react-router-dom";
import { currencySign } from "../../../utils/constants";
import PointSubscriptionModal from "./PointSubscriptionModal";

const { Option } = Select

const SubscriptionScreen = (props) => {
    const { language } = props
    const dispatch = useDispatch()
    const gameSettings = useSelector(state => state?.userReducer?.gameSettings)
    const history = useHistory()
    const { user, selectedLanguage, getUser, location } = props
    const [loading, setLoading] = useState(false)
    const [money, setMoney] = useState(5)
    const [game, setGame] = useState(gameSettings?.[0]?._id)
    const [selectedGame, setSelectedGame] = useState(gameSettings?.[0])
    const [openModal, setOpenModal] = useState(false)
    const userId = new URLSearchParams(location.search).get('userId') || null
    let subscriptionMoney = [5, 10, 15, 20, 25, 50, 100]


    const handleChangeMoney = (value) => {
        setMoney(value)
    };
    const handleChangeGame = (value) => {
        setGame(value)
        const filteredArray = gameSettings.filter(item => item._id === value);
        setSelectedGame(filteredArray?.[0])
    }
    useEffect(() => {
        if (userId) {
            setLoading(true)
            axios.get(`${GET.PAYMENT_STATUS}/${userId}`)
                .then((res) => {
                    const { data } = res
                    getUser()
                    setLoading(false)
                    history?.replace(allPaths?.PLAYER_SUBSCRIPTION)
                    if (data.success) {
                        return successNotification(assignMessage(data?.message, language?.language))
                    }
                    errorMessage(assignMessage(data?.message, language?.language))
                })
                .catch((e) => {
                    setLoading(false)
                    errorMessage(selectedLanguage?.backendMsg_OopsSomethingWentWrong)
                })
        }
    }, [])


    const easyLoad = (a) => {
        setLoading(true)
        let obj = {
            userId: user?._id,
            amount: a,
            subscriptionName: 'Wallet Recharge subscription'
        }

        axios.post(POST.WALLET_RECHARGE, obj)
            .then((res) => {
                const { data } = res
                if (data.success) {
                    window.location.href = data?.redirectUrl
                }
                else {
                    setLoading(false)
                    errorMessage(assignMessage(data?.message, language?.language))
                }
            })
            .catch((e) => {
                setLoading(false)
                errorMessage(selectedLanguage?.backendMsg_OopsSomethingWentWrong)
            })
    }
    return (
        <div className='new-main-component-screen-div'>
            <p className='new-screen-title' >
                {selectedLanguage?.title_Subscription}
            </p>
            <div className='sidebar-scroll_class'
                style={{
                    overflow: 'auto',
                    display: 'flex',
                    flexDirection: 'row',
                    padding: '4px',
                    marginTop: '10px'
                }}
            >
                <div
                    style={{
                        width: '220px',
                        maxWidth: '220px',
                        minWidth: '220px',
                        marginRight: '10px',
                        boxShadow: '0px 0px 9px darkgrey',
                        borderRadius: '8px',
                    }}
                >
                    <Card style={{ borderRadius: '6px', }}
                        bordered={false}>
                        <div className='subscription-title-new'>
                            {selectedLanguage?.text_EasyloadCard}
                        </div>

                        <br />
                        <div className='subscription-title-new'
                            style={{ height: '60px', marginTop: '10px', fontSize: '13px' }}
                        >
                            {selectedLanguage?.text_LoadYourWalletwith} {currencySign} {convertRoundNumber(money)}
                        </div>
                        <Select
                            onChange={handleChangeMoney}
                            style={{ width: '100%' }}
                            defaultValue={money}
                            notFoundContent={selectedLanguage?.txt_NoDataFound}
                            className='form-input-new-style'
                            placeholder={selectedLanguage?.placeHolder_SelectGamefortournament}>
                            {subscriptionMoney?.length && subscriptionMoney?.map((v, i) => {
                                return (
                                    <Option value={v}>{`${currencySign} ${convertRoundNumber(v)}`}</Option>
                                )
                            })}
                        </Select>
                        <Button
                            type="primary"
                            style={{
                                padding: '0px 20px 0px 20px',
                                color: 'white',
                                border: 'none',
                                height: '40px',
                                marginTop: '10px',
                                borderRadius: 6
                            }}
                            block
                            htmlType='submit'
                            onClick={() => { easyLoad(money) }}
                            loading={loading}
                        >
                            {selectedLanguage?.text_PurchaceNow}
                        </Button>
                    </Card>
                </div>

                {/*  */}


                <div
                    style={{
                        width: '220px',
                        maxWidth: '220px',
                        minWidth: '220px',
                        marginRight: '10px',
                        boxShadow: '0px 0px 9px darkgrey',
                        borderRadius: '8px',
                    }}
                >
                    <Card style={{ borderRadius: '6px', }}
                        bordered={false}>
                        <div className='subscription-title-new'>
                            {selectedLanguage?.txt_BuyPoints}
                        </div>

                        <br />
                        <div className='subscription-title-new'
                            style={{ height: '60px', marginTop: '10px', fontSize: '13px' }}
                        >
                            {selectedLanguage?.txt_Buy_More_Points_Of_Games}
                        </div>
                        <Select
                            onChange={handleChangeGame}
                            value={game}
                            style={{ width: '100%' }}
                            notFoundContent={selectedLanguage?.txt_NoDataFound}
                            className='form-input-new-style'
                            placeholder={selectedLanguage?.placeHolder_SelectGamefortournament}>
                            {gameSettings?.length && gameSettings?.map((v, i) => {
                                return (
                                    <Option value={v?._id}>{v?.nickName}</Option>
                                )
                            })}
                        </Select>
                        <Button
                            type="primary"
                            style={{
                                padding: '0px 20px 0px 20px',
                                color: 'white',
                                border: 'none',
                                height: '40px',
                                marginTop: '10px',
                                borderRadius: 6
                            }}
                            block
                            htmlType='submit'
                            onClick={() => { setOpenModal(true) }}
                            loading={loading}
                        >
                            {selectedLanguage?.text_PurchaceNow}
                        </Button>
                    </Card>
                </div>
            </div>
            <PointSubscriptionModal
                {...props}
                openModal={openModal}
                selectedGame={selectedGame}
                handleClose={() => { setOpenModal(false) }}
            />
        </div >

    )
}

export default SubscriptionScreen