import React, { useEffect, useState } from 'react'
import { Button, Popconfirm, Table, Tooltip } from 'antd'
import axios from 'axios'
import { useSelector } from 'react-redux'
import { GET, POST } from '../../../utils/apis'
import { getUserData, successNotification, errorMessage, convertNumber, convertRoundNumber } from '../../../utils/helpers'
import moment from 'moment'
import NoData from '../../../assets/noDataImae.png'
import { AiOutlineArrowUp, AiOutlineArrowDown } from "react-icons/ai"
import { currencySign } from '../../../utils/constants'

const WalletTransactions = (props) => {
    const { user, userActions, selectedLanguage } = props
    const MyTransaction = useSelector(state => state.userReducer.walletTransaction)
    useEffect(() => {
        getUserData(user?._id, userActions)
    }, [])

    let columns = [
        {
            title: selectedLanguage?.text_Name_tr,
            key: 'name',
            render: (e) => <span>{e?.name}</span>
        },
        {
            title: selectedLanguage?.text_Date_tr,
            key: 'created',
            render: (e) => <span>{moment(e?.created).utcOffset(0, true).format('DD-MM-YYYY')}</span>
        },
        {
            title: selectedLanguage?.text_Amount_tr,
            key: 'amount',
            render: (e) => <span>{currencySign} {convertRoundNumber(e?.amount)} </span>
        },
        {
            title: selectedLanguage?.text_Fee_tr,
            key: 'fee',
            render: (e) => <span> {e?.serviceFee ? `${currencySign} ${e?.serviceFee}` : 0} </span>
        },
        {
            title: selectedLanguage?.text_Oldamount_tr,
            key: 'old-amount',
            render: (e) => <span> {currencySign} {convertRoundNumber(e?.oldAmount)}</span>
        },
        {
            title: selectedLanguage?.text_newAmount_tr,
            key: 'new-amount',
            render: (e) => <span>
                {currencySign} {e?.type ? convertRoundNumber(e?.oldAmount + e?.amount) : convertRoundNumber(e?.oldAmount - e?.amount)}
            </span>
        }
    ]
    
    return (
        <div className='new-main-component-screen-div'>
            <h1 className='new-screen-title'>
                {selectedLanguage?.text_Wallet_Transactions}
            </h1>
            {/*  */}
            <div style={{ marginTop: '20px' }}>
                <div className="custom-table-container">
                    <Table
                        locale={{ emptyText: selectedLanguage?.txt_NoDataFound }}
                        className='new-layout-table'
                        dataSource={MyTransaction}
                        columns={columns}
                        pagination={false}
                        scroll={{ x: 800 }}
                    />
                </div >
            </div >
        </div >
    )
}

export default WalletTransactions