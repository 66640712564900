import { Modal } from 'antd'
import _ from 'lodash'
import React, { useEffect, useState } from 'react'
import HTML2Canvas from 'html2canvas'
import jsPDF from 'jspdf'
import { convertNumber } from '../../../utils/helpers'
import { currencySign } from '../../../utils/constants'


const TournamentGraph = (props) => {
    const { viewTournament, onCancel, selectedLanguage, isEnglish } = props
    const [groupedData, setGroupedData] = useState({})
    const [runnerUp, setRunnerUp] = useState({})
    let totalTournamentPoints = viewTournament?.totalTournamentPoints
    let money = viewTournament?.participationMoney
    let fee = viewTournament?.participationFee
    let totalMoney = viewTournament?.tournamentParticipate?.length * money
    let totalFee = viewTournament?.tournamentParticipate?.length * fee
    let DollerPoint1 = (totalTournamentPoints * (Number(viewTournament?.prizeOne) / 100));
    let DollerPoint2 = (totalTournamentPoints * (Number(viewTournament?.prizeTwo) / 100));
    let DollerPoint3 = (totalTournamentPoints * (Number(viewTournament?.prizeThree) / 100));
    let DollerPoint4 = (totalTournamentPoints * (Number(viewTournament?.prizeFour) / 100));
    let DollerMoney1 = (totalMoney * (Number(viewTournament?.prizeOne) / 100));
    let DollerMoney2 = (totalMoney * (Number(viewTournament?.prizeTwo) / 100));
    let DollerMoney3 = (totalMoney * (Number(viewTournament?.prizeThree) / 100));
    let DollerMoney4 = (totalMoney * (Number(viewTournament?.prizeFour) / 100));
    let Point1 = (totalFee * (Number(viewTournament?.prizeOne) / 100));
    let Point2 = (totalFee * (Number(viewTournament?.prizeTwo) / 100));
    let Point3 = (totalFee * (Number(viewTournament?.prizeThree) / 100));
    let Point4 = (totalFee * (Number(viewTournament?.prizeFour) / 100));



    useEffect(() => {
        setGroupedData(_.groupBy(viewTournament?.tournamentHistory, 'round'))
        setRunnerUp(viewTournament?.tournamentHistory?.find((v) => v?.runnerUp))
    }, [])
    console.log('viewTournament--=->', viewTournament)
    console.log('groupedData==>', groupedData)



    const getMatch = (round, matchNumber, player) => {
        let match = groupedData?.[round]?.find((v) => v?.matchNumber === matchNumber)
        return match?.matchId?.dummy ? (match?.[player]?.userId?.name || 'Guest') : match?._id ? match?.[player]?.userId?.name : ' '
    }
    const getWinner = () => {
        let player1 = groupedData?.[viewTournament?.totalRounds]?.[0]?.player1
        let player2 = groupedData?.[viewTournament?.totalRounds]?.[0]?.player2
        let winner = groupedData?.[viewTournament?.totalRounds]?.[0]?.winner
        console.log(player1?._id === winner ? player1?.userId?.name : player2?._id === winner ? player2?.userId?.name : ' ', 'gggh=====')

        return player1?._id === winner ? player1?.userId?.name : player2?._id === winner ? player2?.userId?.name : ' '
    }
    const getRunnerUp = () => {
        let player1 = runnerUp?.player1
        let player2 = runnerUp?.player2
        let winner = runnerUp?.winner
        return player1?._id === winner ? player1?.userId?.name : player2?._id === winner ? player2?.userId?.name : ' '
    }

    const downloadTournament = async () => {
        let id1 = 'tournament_structure'
        const canvas = document.getElementById(id1)

        await HTML2Canvas(canvas, { allowTaint: true, useCORS: true })
            .then((data) => {
                const pngUrl = data.toDataURL()
                let downloadLink = document.createElement('a')
                downloadLink.href = pngUrl
                var doc = new jsPDF('p', 'mm')

                var padding = 5 // Specify the padding in millimeters
                var fixedWidth = 200 // Specify the fixed width in pixels

                var image = new Image()
                image.src = pngUrl
                image.onload = function () {
                    var aspectRatio = image.width / image.height
                    var imageHeight = fixedWidth / aspectRatio

                    var availableHeight = doc.internal.pageSize.getHeight() - (2 * padding)

                    if (imageHeight > availableHeight) {
                        imageHeight = availableHeight
                        fixedWidth = imageHeight * aspectRatio
                    }

                    doc.addImage(pngUrl, 'PNG', 5, 5, 200, imageHeight)
                    doc.save(`${viewTournament?.tournamentName || 'Tournament'}.pdf`)
                }
            })
            .catch((e) => {
                console.log('e', e)
            })
    }

    return (
        <>
            <div style={{ position: 'absolute', top: -5000 }}>
                <div class='playoff-table' id='tournament_structure'>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <h3 style={{ width: '200px', marginLeft: 15 }}>{viewTournament?.tournamentDate}</h3>
                        <h1>
                            {viewTournament?.tournamentName}
                        </h1>
                    </div>
                    <div style={{ width: '100%', justifyContent: 'end', display: 'flex', position: 'relative', zIndex: 2 }}>
                        {viewTournament?.isStake ?
                            <div style={{ width: '300px', }}>
                                <h3 style={{ margin: 0 }}>{selectedLanguage?.text_DlrPointsPrizes}</h3>
                                <div style={{ marginLeft: 20 }}>
                                    <p style={{ margin: 0 }}>{selectedLanguage?.text_winner} 1 {selectedLanguage?.text_proPoints}:{convertNumber(DollerPoint1)}</p>
                                    <p style={{ margin: 0 }}>{selectedLanguage?.text_winner} 2 {selectedLanguage?.text_proPoints}:{convertNumber(DollerPoint2)}</p>
                                    <p style={{ margin: 0 }}>{selectedLanguage?.text_winner} 3 {selectedLanguage?.text_proPoints}:{convertNumber(DollerPoint3)}</p>
                                    <p style={{ margin: 0 }}>{selectedLanguage?.text_winner} 4 {selectedLanguage?.text_proPoints}:{convertNumber(DollerPoint4)}</p>
                                </div>
                                <h3 style={{ margin: 0 }}>{selectedLanguage?.text_MoneyPrizes}</h3>
                                <div style={{ marginLeft: 20 }}>
                                    <p style={{ margin: 0 }}>{selectedLanguage?.text_winner} 1{selectedLanguage?.text_money}:{currencySign} {convertNumber(DollerMoney1)}</p>
                                    <p style={{ margin: 0 }}>{selectedLanguage?.text_winner} 2{selectedLanguage?.text_money}:{currencySign} {convertNumber(DollerMoney2)}</p>
                                    <p style={{ margin: 0 }}>{selectedLanguage?.text_winner} 3{selectedLanguage?.text_money}:{currencySign} {convertNumber(DollerMoney3)}</p>
                                    <p style={{ margin: 0 }}>{selectedLanguage?.text_winner} 4{selectedLanguage?.text_money}:{currencySign} {convertNumber(DollerMoney4)}</p>
                                </div>
                            </div>
                            :
                            <div style={{ width: '300px' }}>
                                <h3 style={{ margin: 0 }}>{selectedLanguage?.text_PointPrizes}</h3>
                                <div style={{ marginLeft: 20 }}>
                                    <p style={{ margin: 0 }}>{selectedLanguage?.text_winner} 1{selectedLanguage?.text_points}:{convertNumber(Point1)}</p>
                                    <p style={{ margin: 0 }}>{selectedLanguage?.text_winner} 2{selectedLanguage?.text_points}:{convertNumber(Point2)}</p>
                                    <p style={{ margin: 0 }}>{selectedLanguage?.text_winner} 3{selectedLanguage?.text_points}:{convertNumber(Point3)}</p>
                                    <p style={{ margin: 0 }}>{selectedLanguage?.text_winner} 4{selectedLanguage?.text_points}:{convertNumber(Point4)}</p>
                                </div>
                            </div>
                        }
                    </div>
                    <div class='playoff-table-content'>
                        {
                            ((viewTournament?.totalRounds && [...Array(viewTournament?.totalRounds - 1).keys()]) || [])?.map((v, index) => {
                                return (
                                    <div class='playoff-table-tour'>
                                        <h3>{(index + 1) === (viewTournament.totalRounds - 1) ? `1/2 final` : `Round ${index + 1}`}</h3>
                                        {
                                            [...Array(Math.pow(2, viewTournament?.totalRounds - (index + 2))).keys()]?.map((x, i) => {
                                                return (
                                                    <div class='playoff-table-group' style={{ height: viewTournament?.maximumPlayer === 4 ? '340px' : '100%' }}>
                                                        <div class='playoff-table-pair playoff-table-pair-style'>
                                                            <div class='playoff-table-left-player'>{getMatch(index + 1, (i * 2) + 1, 'player1')}</div>
                                                            <div class='playoff-table-right-player'>{getMatch(index + 1, (i * 2) + 1, 'player2')}</div>
                                                        </div>
                                                        <div class='playoff-table-pair playoff-table-pair-style'>
                                                            <div class='playoff-table-left-player'>{getMatch(index + 1, (i + 1) * 2, 'player1')}</div>
                                                            <div class='playoff-table-right-player'>{getMatch(index + 1, (i + 1) * 2, 'player2')}</div>
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                )
                            })
                        }

                        {
                            runnerUp?._id ? <div class='playoff-table-tour'>
                                <h3>{selectedLanguage?.txt_FinalRound}</h3>
                                <div class='playoff-table-group-winner' style={{ marginBottom: -10 }}>
                                    <div class='playoff-table-pair playoff-table-pair-style'>
                                        <div class='playoff-table-left-player'>{groupedData?.[viewTournament?.totalRounds]?.[0]?.matchId?._id ? groupedData?.[viewTournament?.totalRounds]?.[0]?.player1?.userId?.name : ' '}</div>
                                        <div class='playoff-table-right-player'>{groupedData?.[viewTournament?.totalRounds]?.[0]?.matchId?._id ? groupedData?.[viewTournament?.totalRounds]?.[0]?.player2?.userId?.name : ' '}</div>
                                    </div>
                                </div>
                                <div style={{ marginBottom: 55 }}>
                                    <center> <h3>3rd / 4th</h3></center>

                                    <div>
                                        <div class='playoff-table-pair playoff-table-pair-style'>
                                            <div class='playoff-table-left-player'>{runnerUp?.player1?.userId?.name}</div>
                                            <div class='playoff-table-right-player'>{runnerUp?.player2?.userId?.name}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                                :
                                <div class='playoff-table-tour'>
                                    <h3>{selectedLanguage?.txt_FinalRound}</h3>

                                    <div class='playoff-table-group-winner'>
                                        <div class='playoff-table-pair playoff-table-pair-style'>
                                            <div class='playoff-table-left-player'>{groupedData?.[viewTournament?.totalRounds]?.[0]?._id ? (groupedData?.[viewTournament?.totalRounds]?.[0]?.player1?.userId?.name || 'Guest') : ' '}</div>
                                            <div class='playoff-table-right-player'>{groupedData?.[viewTournament?.totalRounds]?.[0]?._id ? (groupedData?.[viewTournament?.totalRounds]?.[0]?.player2?.userId?.name || 'Guest') : ' '}</div>
                                        </div>
                                    </div>

                                </div>
                        }

                        {
                            runnerUp?._id ?
                                <div class='playoff-table-tour'>
                                    <h3>{selectedLanguage?.text_winner}</h3>
                                    <div class='playoff-table-group-winner'>
                                        <div class='playoff-table-pair-winner playoff-table-pair-style' style={{ top: 10 }}>
                                            <div class='playoff-table-left-player-winner'>{getWinner()}</div>
                                        </div>
                                    </div>
                                    <div style={{ marginBottom: 105 }}>
                                        <center> <h3>3rd</h3></center>
                                        <div class='playoff-table-group-winner' /* style={{ top: -60 }} */>
                                            <div class='playoff-table-pair-winner playoff-table-pair-style' >
                                                <div class='playoff-table-left-player-winner' >{getRunnerUp()}</div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                                :
                                <div class='playoff-table-tour'>
                                    <h3>{selectedLanguage?.text_winner}</h3>

                                    <div class='playoff-table-group-winner'>
                                        <div class='playoff-table-pair-winner playoff-table-pair-style'>
                                            <div class='playoff-table-left-player-winner'>{/* {groupedData?.[viewTournament?.totalRounds]?.[0]?._id ? (groupedData?.[viewTournament?.totalRounds]?.[0]?.player1?.userId?.name || 'Guest') : ' '} */}
                                                {
                                                    groupedData?.[viewTournament?.totalRounds]?.[0]?.matchId?.isCompleted ?
                                                        getWinner()
                                                        /*  (groupedData?.[viewTournament?.totalRounds]?.[0]?._id ? (groupedData?.[viewTournament?.totalRounds]?.[0]?.player1?.userId?.name || 'Guest')  : ' ')*/
                                                        : ''
                                                }
                                            </div>
                                        </div>
                                    </div>

                                </div>
                        }
                    </div>
                </div>
            </div>

            <Modal
                open={viewTournament?._id}
                onOk={downloadTournament}
                onCancel={onCancel}
                okText={selectedLanguage?.text_Download}
                cancelText={selectedLanguage?.text_Cancel}
                className='modal_width'
            >
                <div class='playoff-table' >
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <h3 style={{ width: '200px', marginLeft: 15 }}>{viewTournament?.tournamentDate}</h3>
                        <h1>
                            {viewTournament?.tournamentName}
                        </h1>
                    </div>
                    <div style={{ width: '100%', justifyContent: 'end', display: 'flex', position: 'relative', zIndex: 2 }}>
                        {viewTournament?.isStake ?
                            <div style={{ width: '300px', }}>
                                <h3 style={{ margin: 0 }}>{selectedLanguage?.text_DlrPointsPrizes}</h3>
                                <div style={{ marginLeft: 20 }}>
                                    <p style={{ margin: 0 }}>{selectedLanguage?.text_winner} 1 {selectedLanguage?.text_proPoints}: {convertNumber(DollerPoint1)}</p>
                                    <p style={{ margin: 0 }}>{selectedLanguage?.text_winner} 2 {selectedLanguage?.text_proPoints}: {convertNumber(DollerPoint2)}</p>
                                    <p style={{ margin: 0 }}>{selectedLanguage?.text_winner} 3 {selectedLanguage?.text_proPoints}: {convertNumber(DollerPoint3)}</p>
                                    <p style={{ margin: 0 }}>{selectedLanguage?.text_winner} 4 {selectedLanguage?.text_proPoints}: {convertNumber(DollerPoint4)}</p>
                                </div>
                                <h3 style={{ margin: 0 }}>{selectedLanguage?.text_MoneyPrizes}</h3>
                                <div style={{ marginLeft: 20 }}>
                                    <p style={{ margin: 0 }}>{selectedLanguage?.text_winner} 1 {selectedLanguage?.text_money}:{currencySign} {convertNumber(DollerMoney1)}</p>
                                    <p style={{ margin: 0 }}>{selectedLanguage?.text_winner} 2 {selectedLanguage?.text_money}:{currencySign} {convertNumber(DollerMoney2)}</p>
                                    <p style={{ margin: 0 }}>{selectedLanguage?.text_winner} 3 {selectedLanguage?.text_money}:{currencySign} {convertNumber(DollerMoney3)}</p>
                                    <p style={{ margin: 0 }}>{selectedLanguage?.text_winner} 4 {selectedLanguage?.text_money}:{currencySign} {convertNumber(DollerMoney4)}</p>
                                </div>
                            </div>
                            :
                            <div style={{ width: '300px' }}>
                                <h3 style={{ margin: 0 }}>{selectedLanguage?.text_PointPrizes}</h3>
                                <div style={{ marginLeft: 20 }}>
                                    <p style={{ margin: 0 }}>{selectedLanguage?.text_winner} 1 {selectedLanguage?.text_points}: {convertNumber(Point1)}</p>
                                    <p style={{ margin: 0 }}>{selectedLanguage?.text_winner} 2 {selectedLanguage?.text_points}: {convertNumber(Point2)}</p>
                                    <p style={{ margin: 0 }}>{selectedLanguage?.text_winner} 3 {selectedLanguage?.text_points}: {convertNumber(Point3)}</p>
                                    <p style={{ margin: 0 }}>{selectedLanguage?.text_winner} 4 {selectedLanguage?.text_points}: {convertNumber(Point4)}</p>
                                </div>
                            </div>
                        }
                    </div>
                    <div class='playoff-table-content'>
                        {
                            ((viewTournament?.totalRounds && [...Array(viewTournament?.totalRounds - 1).keys()]) || [])?.map((v, index) => {
                                return (
                                    <div class='playoff-table-tour'>
                                        <h3>{(index + 1) === (viewTournament.totalRounds - 1) ? `1/2 final` : `Round ${index + 1}`}</h3>
                                        {
                                            (viewTournament?.totalRounds && [...Array(Math.pow(2, viewTournament?.totalRounds - (index + 2))).keys()] || [])?.map((x, i) => {
                                                return (
                                                    <div class='playoff-table-group' style={{ height: viewTournament?.maximumPlayer === 4 ? '280px' : '100%' }}>
                                                        <div class='playoff-table-pair playoff-table-pair-style'>
                                                            <div class='playoff-table-left-player'>{getMatch(index + 1, (i * 2) + 1, 'player1')}</div>
                                                            <div class='playoff-table-right-player'>{getMatch(index + 1, (i * 2) + 1, 'player2')}</div>
                                                        </div>
                                                        <div class='playoff-table-pair playoff-table-pair-style'>
                                                            <div class='playoff-table-left-player'>{getMatch(index + 1, (i + 1) * 2, 'player1')}</div>
                                                            <div class='playoff-table-right-player'>{getMatch(index + 1, (i + 1) * 2, 'player2')}</div>
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                )
                            })
                        }

                        {
                            runnerUp?._id ?
                                <div class='playoff-table-tour'>
                                    <h3>{selectedLanguage?.txt_FinalRound}</h3>
                                    <div class='playoff-table-group-winner' style={{ marginBottom: -10 }}>
                                        <div class='playoff-table-pair playoff-table-pair-style'>
                                            <div class='playoff-table-left-player'>{groupedData?.[viewTournament?.totalRounds]?.[0]?.matchId?._id ? groupedData?.[viewTournament?.totalRounds]?.[0]?.player1?.userId?.name : ' '}</div>
                                            <div class='playoff-table-right-player'>{groupedData?.[viewTournament?.totalRounds]?.[0]?.matchId?._id ? groupedData?.[viewTournament?.totalRounds]?.[0]?.player2?.userId?.name : ' '}</div>
                                        </div>
                                    </div>
                                    <div style={{ marginBottom: 25 }}>
                                        <center> <h3>3rd / 4th</h3></center>
                                        <div>
                                            <div class='playoff-table-pair playoff-table-pair-style'>
                                                <div class='playoff-table-left-player'>{runnerUp?.player1?.userId?.name}</div>
                                                <div class='playoff-table-right-player'>{runnerUp?.player2?.userId?.name}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                :
                                <div class='playoff-table-tour'>
                                    <h3>{selectedLanguage?.txt_FinalRound}</h3>
                                    <div class='playoff-table-group-winner'>
                                        <div class='playoff-table-pair playoff-table-pair-style'>
                                            <div class='playoff-table-left-player'>{groupedData?.[viewTournament?.totalRounds]?.[0]?._id ? (groupedData?.[viewTournament?.totalRounds]?.[0]?.player1?.userId?.name || 'Guest') : ' '}</div>
                                            <div class='playoff-table-right-player'>{groupedData?.[viewTournament?.totalRounds]?.[0]?._id ? (groupedData?.[viewTournament?.totalRounds]?.[0]?.player2?.userId?.name || 'Guest') : ' '}</div>
                                        </div>
                                    </div>
                                </div>
                        }

                        {
                            runnerUp?._id ?
                                <div class='playoff-table-tour'>
                                    <h3>{selectedLanguage?.text_winner}</h3>

                                    <div class='playoff-table-group-winner'>
                                        <div class='playoff-table-pair-winner playoff-table-pair-style' style={{ top: 20 }}>
                                            <div class='playoff-table-left-player-winner'>{getWinner()}</div>
                                        </div>
                                    </div>
                                    <div style={{ marginBottom: 75 }}>
                                        <center> <h3>3rd</h3></center>

                                        <div class='playoff-table-group-winner' /* style={{ top: -60 }} */>
                                            <div class='playoff-table-pair-winner playoff-table-pair-style' >
                                                <div class='playoff-table-left-player-winner' >{getRunnerUp()}</div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                                :
                                <div class='playoff-table-tour'>
                                    <h3>{selectedLanguage?.text_winner}</h3>

                                    <div class='playoff-table-group-winner'>
                                        <div class='playoff-table-pair-winner playoff-table-pair-style'>
                                            <div class='playoff-table-left-player-winner'>
                                                {/* {
                                                    groupedData?.[viewTournament?.totalRounds]?.[0]?.matchId?.isCompleted ?
                                                        (groupedData?.[viewTournament?.totalRounds]?.[0]?._id ? (groupedData?.[viewTournament?.totalRounds]?.[0]?.player1?.userId?.name || 'Guest') : ' ')
                                                        : ''
                                                } */}
                                                {
                                                    groupedData?.[viewTournament?.totalRounds]?.[0]?.matchId?.isCompleted ?
                                                        getWinner()
                                                        /*  (groupedData?.[viewTournament?.totalRounds]?.[0]?._id ? (groupedData?.[viewTournament?.totalRounds]?.[0]?.player1?.userId?.name || 'Guest')  : ' ')*/
                                                        : ''
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                        }
                    </div>
                </div>
            </Modal>
        </>
    )
}


export default TournamentGraph