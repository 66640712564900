import React, { useState, useEffect } from 'react'
import { Menu, Layout, Tooltip } from 'antd'
import { Link } from 'react-router-dom'
import { setActiveMenu, } from '../../utils/helpers'
import { useDispatch } from 'react-redux'
import { drawerRoutesAdmin, drawerRoutesUser } from '../../utils/constants'
import sideLogo from "../../assets/SideWGPLogo.jpg"
import sideLogoText from "../../assets/WGP_text.png"
import { SetMenuNone } from '../../Redux/actions/menuNoneAction'
import { IoIosArrowBack } from "react-icons/io"
const { SubMenu } = Menu
const { Sider } = Layout

const SideMenu = (props) => {
    const dispatch = useDispatch()
    const { location, MenuNone, user, selectedLanguage, language, isEnglish } = props
    const [key, setKey] = useState(1)
    useEffect(() => {
        dispatch(SetMenuNone(false))
    }, [])
    useEffect(() => {
        window.addEventListener('resize', setWindowWidth)
        setWindowWidth()
    }, [])

    const setWindowWidth = () => {
        window.innerWidth > 700 ? dispatch(SetMenuNone(false)) : dispatch(SetMenuNone(true))
    }

    const handleClick = (e) => {
        setKey(parseInt(e?.key))
    }

    return (
        <div className='home-main'
            style={{
                background: 'white',
            }}
        >
            <div className='flex-row'>
                <div
                    style={{
                        height: '100vh',
                        background: '#ffffff',
                        paddingRight: '4px',
                        position: 'absolute',
                        zIndex: 10,
                        width: 253,
                        boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2)',
                        display: MenuNone && 'none'

                    }}
                >
                    <Layout className='sidebar-scroll_class' style={{
                        position: 'fixed',
                        zIndex: 9999,
                        height: `100vh`,
                        overflowY: 'scroll',
                        paddingBottom: '20px',
                        backgroundColor: 'white',
                        width: '250px'
                    }}>

                        <Sider
                            collapsible
                            className='new-side-menu'
                            width={245}
                        >
                            <div className='desktop-head'
                                style={{
                                    height: '120px',
                                    width: '100%',
                                    background: 'white',
                                    textAlign: 'center',
                                    justifyContent: 'center'
                                }}>
                                <img src={sideLogo} style={{ height: '120px' }} />
                            </div>
                            <div
                                className='mobile-head'
                            >
                                <div
                                    style={{
                                        height: '120px',
                                        display: 'flex',
                                        alignItems: 'center',
                                        width: '100%',
                                        background: '#ec671b',
                                        textAlign: 'center',
                                    }}>
                                    <div style={{
                                        width: '98%',
                                        height: '34px',
                                        display: 'flex',
                                        justifyContent: 'flex-start'
                                    }}>
                                        <IoIosArrowBack
                                            onClick={window.innerWidth < 700 ? () => { dispatch(SetMenuNone(true)) } : null}
                                            size={28} />
                                        <img src={sideLogoText} style={{ height: '25px', marginTop: '4px' }} />
                                    </div>
                                </div>
                            </div>

                            <Menu
                                onClick={handleClick}
                                defaultOpenKeys={[`${(location?.pathname)}`]}
                                defaultSelectedKeys={[`${(location?.pathname)}`]}
                                mode='inline'
                                theme='light'
                                className='my-menu-sh-new'
                                style={{ border: 'none' }}
                            >
                                {(user?.userType === 'player' ? drawerRoutesUser : drawerRoutesAdmin).map((v, i) => {
                                    if (v?.isSubMenu && v?.children?.length) {
                                        return <SubMenu
                                            key={v?.route} icon={v?.icon} title={!isEnglish ? v.titleNl : v.title}
                                            style={{ fontSize: 18, }}>
                                            {v?.children?.map((y, j) => {
                                                return (
                                                    <Menu.Item
                                                        key={y?.route} icon={y.icon}>
                                                        <Link
                                                            to={y?.route}
                                                            onClick={window.innerWidth < 700 ? () => { dispatch(SetMenuNone(true)) } : null}
                                                            className='side-list'
                                                        >
                                                            {!isEnglish ? y.titleNl : y.title}
                                                        </Link>
                                                    </Menu.Item>
                                                )
                                            })}

                                        </SubMenu>
                                    }
                                    return (
                                        <Menu.Item key={v.route} icon={v?.icon}>
                                            <Link
                                                to={v?.route}
                                                onClick={window.innerWidth < 700 ? () => { dispatch(SetMenuNone(true)) } : null}
                                                className='side-list'
                                            >
                                                {!isEnglish ? v.titleNl : v.title}
                                            </Link>
                                        </Menu.Item>
                                    )
                                })}
                            </Menu>
                        </Sider>
                    </Layout>
                </div>
            </div >
        </div >
    )
}

export default SideMenu