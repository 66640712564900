import { Button, Select, Table } from 'antd'
import React, { useEffect, useState } from 'react'
import { IoIosArrowBack } from "react-icons/io"
import { useSelector } from 'react-redux'
import { getGeneralData, getUserData } from '../../../utils/helpers'
import NoData from '../../../assets/noDataImae.png'
import { AiOutlineArrowUp, AiOutlineArrowDown } from "react-icons/ai"
import { TbEqual } from "react-icons/tb"
import AllPlayerCompetitionRank from './AllPlayerCompetitionRank'


const PlayerCompetitionRank = (props) => {
    const { Option } = Select
    const { selectedLanguage, isEnglish, user, generalActions, userActions } = props
    const games = useSelector(state => state.generalReducer.games)
    const allCompetitions = useSelector(state => state.generalReducer.allCompetitions)
    const competitionRanks = useSelector(state => state.userReducer.competitionRanks)
    const [allPlayer, setAllPlayer] = useState(false)
    const [selectedCompetition, setSelectedCompetition] = useState(allCompetitions?.[0]?._id || null)

    useEffect(() => {
        getGeneralData(generalActions)
        getUserData(user?._id, userActions)
    }, [])


    const columns = [
        {
            title: selectedLanguage?.text_Name_tr,
            render: (e) => {
                return (
                    <p>
                        {e?.competition?.competitionName}
                    </p>
                )
            }
        },
        {
            title: selectedLanguage?.label_Levels,
            render: (e) => {
                return (
                    <p>
                        {e?.competition?.level}
                    </p>
                )
            }
        },
        {
            title: selectedLanguage?.tableText_Game,
            render: (e) => {
                return (
                    <p>
                        {e?.gameId?.name}
                    </p>
                )
            }
        },
        {
            title: selectedLanguage?.tableText_Ranking,
            render: (e) => {
                return (
                    <p>
                        {e?.rank}
                    </p>
                )
            }
        },
        {
            title: `${selectedLanguage?.tableText_Points}-($)`,
            render: (e) => {
                return (
                    <p>
                        {e?.point}
                    </p>
                )
            }
        },
    ]

    return (
        <div className='new-main-component-screen-div'>
            <div style={{ marginTop: '10px' }}>
                {allPlayer ?
                    <div style={{
                        display: 'flex',
                        justifyContent: 'flex-start',
                        alignItems: 'center'
                    }}>
                        <IoIosArrowBack
                            onClick={() => { setAllPlayer(false) }}
                            style={{ marginRight: '5px', color: 'black', marginTop: '8px' }}
                            size={28} />
                        <h1 className='new-screen-title'>
                            {selectedLanguage?.text_AllPlayerProRankingAndPointsDollerpoints}
                        </h1>
                    </div> :
                    <h1 className='new-screen-title'>
                        {selectedLanguage?.text_MyProRankingAndPointDollerPoints}
                    </h1>
                }
            </div>
            <span style={{ width: '98%', margin: '0px auto', marginTop: '20px', }} className='button-div-flex-end'>
                {allPlayer ?
                    <Select
                        showArrow
                        allowClear={false}
                        notFoundContent={selectedLanguage?.txt_NoDataFound}
                        defaultValue={selectedCompetition}
                        onChange={(e) => setSelectedCompetition(e)}
                        style={{
                            height: '35px',
                            minWidth: 130
                        }}
                        placeholder={selectedLanguage?.label_SelectGame}
                        optionFilterProp='children'
                        filterOption={(input, option) =>
                            option?.value?.toString()?.indexOf(input.toLowerCase()) >= 0
                        }
                    >
                        {allCompetitions?.length && allCompetitions?.map((v, i) => <Option value={v?._id} key={i}>{v?.competitionName} | {v?.gameId?.name}</Option>)}
                    </Select>
                    :
                    <Button
                        type='primary'
                        onClick={() => setAllPlayer(true)}>
                        {selectedLanguage?.btnAllplayerProRanks}
                    </Button>}
            </span>
            <div style={{ marginTop: '25px' }}>
                {allPlayer ?
                    <AllPlayerCompetitionRank {...props} selectedCompetition={selectedCompetition} />
                    :
                    <div className="custom-table-container">
                        <Table
                            locale={{ emptyText: selectedLanguage?.txt_NoDataFound }}
                            className='new-layout-table'
                            dataSource={competitionRanks}
                            columns={columns}
                            pagination={false}
                            scroll={{ x: 1000 }}
                        />
                    </div>
                }
            </div>
        </div>
    )
}

export default PlayerCompetitionRank