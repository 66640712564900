import { ADMIN_ALL_PLAYER } from '../types'

const AddAllPlayers = (adminAllPlayer) => {
    return {
        type: ADMIN_ALL_PLAYER,
        adminAllPlayer
    }
}
export {
    AddAllPlayers,
}