import React from "react";
import { useHistory } from "react-router-dom";
import { IoIosArrowBack } from "react-icons/io"
import { Tabs } from 'antd'
import ParticipationChart from "./TournamentChart";
import ParticipationTable from "./TournamentParticipater";


const TournamentDetail = (props) => {
    const { TabPane } = Tabs
    const history = useHistory()
    const { user, selectedLanguage } = props
    let Tournament = history?.location?.state
    return (
        <div className='new-main-component-screen-div'>
            <div >
                <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
                    <span style={{ marginTop: '12px', cursor: 'pointer' }}>
                        <IoIosArrowBack
                            style={{ marginRight: '5px', color: 'black' }}
                            size={28}
                            onClick={() => history?.goBack()} />
                    </span>
                    <p className='new-screen-title'>
                        {Tournament?.tournamentName || '----'}
                    </p>
                </div>
                <div style={{ marginTop: '20px' }}>
                    <Tabs className='new-new-my-tabs' defaultActiveKey='1' centered>
                        <TabPane tab={<p className='my-menu-new-tab-p'>{selectedLanguage?.text_ParticipaterTable}</p>} key='1'>
                            <ParticipationTable Tournament={Tournament} {...props} />
                        </TabPane>
                        <TabPane tab={<p className='my-menu-new-tab-p'>{selectedLanguage?.text_ParticipaterGraph}</p>} key='2'>
                            <ParticipationChart Tournament={Tournament} {...props} />
                        </TabPane>
                    </Tabs>
                </div>
            </div>
        </div >
    )
}

export default TournamentDetail