import React, { useEffect, useState } from "react";
import { Button, Form, Input, Select, Switch, Upload } from "antd";
import { UploadOutlined } from '@ant-design/icons'
import { BiMoneyWithdraw, } from 'react-icons/bi'
import { GiRank1 } from 'react-icons/gi'
import { assignMessage, errorMessage, getGeneralData, successMessage, warningMessage } from "../../../utils/helpers";
import { useSelector } from "react-redux";
import axios from "axios";
import { ADMIN } from "../../../utils/apis";
import { currencySign } from "../../../utils/constants";


const AddAdminCoupons = (props) => {
    const { Option } = Select
    const { selectedLanguage, generalActions, user, language } = props
    const allCompetitions = useSelector(state => state.generalReducer.allCompetitions)
    const [form] = Form.useForm()
    const [loading, setLoading] = useState(false)
    const [fileList, setFileList] = useState([])
    const [money, setMoney] = useState(false)
    const [competition, setcompetition] = useState(false)
    const tournaments = useSelector(state => state.generalReducer)
    const adminTournament = tournaments?.adminTournament?.filter((v) => v?.ownerAdminId && v?.promoterId?._id) || []
    console.log(allCompetitions, "=allCompetitions")
    console.log(tournaments, "==tournaments")
    console.log(adminTournament, "==adminTournament ")
    useEffect(() => {
        getGeneralData(generalActions)
    }, [])

    function onChangeCheckBox(e) {
        setMoney(e/*?.target.checked*/);
    }
    function onChangeCheckBoxCompetition(e) {
        setcompetition(e/*?.target.checked*/);
    }
    const normFile = (e) => {
        setFileList([])

        if (e?.fileList?.length && e?.file?.type !== 'image/png' && e?.file?.type !== 'image/jpeg') {
            errorMessage(selectedLanguage?.PleaseUploadImageonly)
            return []
        }

        if (Array.isArray(e)) {
            return e
        }

        e?.fileList?.length ? setFileList([...[e?.file]]) : setFileList([])
        return e && [e.file]
    }

    console.log(fileList[0], "=fileList")

    const onFinish = (values) => {
        values.adminId = user?._id
        values.file = fileList
        if (!values._id && !values?.file?.length) {
            return warningMessage(selectedLanguage?.text_PleaseUploadImage)
        }
        console.log(Number(values?.quantity), '==values')

        if (Number(values?.quantity) < 1) {
            return warningMessage(selectedLanguage?.txt_Quantity_must_be_more_then_1)
        }

        setLoading(true)

        let formData = new FormData()

        formData.append('file', values?.file[0])
        formData.append('description', values?.couponDescription)
        formData.append('quantity', Number(values?.quantity))
        formData.append('point', Number(values?.point || 0) || 0)
        formData.append('money', Number(values?.money || 0) || 0)
        formData.append('adminId', values?.adminId)
        if (values?.tournament?.length) {
            for (let v of values?.tournament) {
                formData.append('tournament[]', v)
            }
        }
        if (values?.competition?.length) {
            for (let v of values?.competition) {
                formData.append('competition[]', v)
            }
        }

        axios?.post(ADMIN?.CREATE_TOURNAMENT_COUPOUN, formData)
            .then(async (res) => {
                const { data } = res
                if (data?.success) {
                    successMessage(assignMessage(data?.message, language?.language))
                    setLoading(false)
                    await form.resetFields()
                    await setFileList([])
                }
                else {
                    setLoading(false)
                    errorMessage(assignMessage(data?.message, language?.language))
                }

            }).catch((e) => {
                setLoading(false)
                errorMessage(assignMessage(e?.message, language?.language))
            })
    }
    return (
        <Form
            name='form'
            form={form}
            onFinish={onFinish}
            layout={'vertical'}
            requiredMark={false}
        >
            <div className='form-two-input-flex'>
                <div className='form-two-input-flex-inner-input'>
                    <Form.Item
                        name='couponDescription'
                        label={<p className='p-admin-title-text'>{selectedLanguage?.text_Description}</p>}
                        rules={[
                            {
                                required: true,
                                message: selectedLanguage?.error_PleaseInputDescription
                            }
                        ]}
                    >
                        <Input
                            maxLength={40}
                            className='form-input-new-design-big-slam'
                            placeholder={selectedLanguage?.placeHolder_InputYourDescriptionHere}
                        />
                    </Form.Item>
                </div>
                <div className='form-two-input-flex-inner-input'>
                    <Form.Item
                        name='quantity'
                        label={<p className='p-admin-title-text'>{selectedLanguage?.title_Quantity}</p>}
                        rules={[
                            {
                                required: true,
                                message: selectedLanguage?.error_PleaseInputYourQuantity
                            },
                            ({ getFieldValue }) => ({
                                validator(rule, value) {
                                    if (value < 1) {
                                        return Promise.reject(selectedLanguage?.txt_Value_must_more_then + ' 0')
                                    }
                                    else if (value > 500) {
                                        return Promise.reject(selectedLanguage?.txt_Value_must_Less_then + ' 501')
                                    }
                                    return Promise.resolve()
                                }
                            })
                        ]}
                    >
                        <div>
                            <Input
                                type='number'
                                className='form-input-new-design-big-slam'
                                placeholder={selectedLanguage?.placeholderInputYourQuantityHere}
                                min={0}
                                max={500}
                                step={1}
                                precision={0.5}
                            />
                        </div>
                    </Form.Item>
                </div>
            </div>

            <div className='form-two-input-flex'>
                <div className='form-two-input-flex-inner-input'>
                    <Form.Item
                        label={<p className='p-admin-title-text'>{selectedLanguage?.text_TournamentCompetition}</p>}
                        name='pointORmoney'
                        onChange={onChangeCheckBoxCompetition}
                        initialValue={competition}
                        valuePropName='checked'>
                        <div
                            className='form-input-new-design-big-slam'
                            style={{ display: 'flex', alignItems: 'center' }}
                        >
                            <p className='color-bg' style={{ margin: '0px 10px' }}>{selectedLanguage?.text_TournamentC}</p>
                            <Switch
                                onChange={onChangeCheckBoxCompetition}
                                checked={competition}
                                checkedChildren={
                                    <BiMoneyWithdraw size={19} style={{ height: 22, marginRight: 4 }} />
                                }
                                unCheckedChildren={
                                    <GiRank1 size={19} style={{ height: 22, marginLeft: 4 }} />
                                }
                            />
                            <p className='color-bg' style={{ margin: '0px 10px' }}>{selectedLanguage?.text_TCompetition}</p>
                        </div>
                    </Form.Item>
                </div>

                {competition &&
                    <div className='form-two-input-flex-inner-input'>
                        <Form.Item
                            name='money'
                            label={<p className='p-admin-title-text'>{selectedLanguage?.text_money}</p>}
                            rules={[
                                {
                                    required: true,
                                    message: selectedLanguage?.error_moneyisrequired
                                },
                                ({ getFieldValue }) => ({
                                    validator(rule, value) {
                                        if (value < 1) {
                                            return Promise.reject(selectedLanguage?.txt_Value_must_more_then + ' 0')
                                        }
                                        return Promise.resolve()
                                    }
                                })
                            ]}
                        >
                            <Input
                                prefix={<p style={{ color: 'white', margin: '0px' }}>{currencySign}</p>}
                                placeholder={selectedLanguage?.placeholder_enterMoneyHere}
                                className='form-input-new-design-big-slam'
                                precision={2}
                                type='number'
                                step={0.01}
                                min={0}
                            />
                        </Form.Item>
                    </div>
                }
            </div>


            {!competition && <div className='form-two-input-flex'>
                <div className='form-two-input-flex-inner-input'>
                    <Form.Item
                        label={<p className='p-admin-title-text'>{selectedLanguage?.label_PointORMoney}</p>}
                        name='pointORmoney'
                        onChange={onChangeCheckBox}
                        initialValue={money}
                        valuePropName='checked'>
                        <div
                            className='form-input-new-design-big-slam'
                            style={{ display: 'flex', alignItems: 'center' }}
                        >
                            <p className='color-bg' style={{ margin: '0px 10px' }}>{selectedLanguage?.text_points}</p>
                            <Switch onChange={onChangeCheckBox} checked={money}
                                checkedChildren={
                                    <BiMoneyWithdraw size={19} style={{ height: 22, marginRight: 4 }} />
                                }
                                unCheckedChildren={
                                    <GiRank1 size={19} style={{ height: 22, marginLeft: 4 }} />
                                }
                            />
                            <p className='color-bg' style={{ margin: '0px 10px' }}>{selectedLanguage?.text_money}</p>
                        </div>
                    </Form.Item>
                </div>
                {money ?
                    <div className='form-two-input-flex-inner-input'>
                        <Form.Item
                            name='money'
                            label={<p className='p-admin-title-text'>{selectedLanguage?.text_money}</p>}
                            rules={[
                                {
                                    required: true,
                                    message: selectedLanguage?.error_moneyisrequired
                                },
                                ({ getFieldValue }) => ({
                                    validator(rule, value) {
                                        if (value < 1) {
                                            return Promise.reject(selectedLanguage?.txt_Value_must_more_then + ' 0')
                                        }
                                        return Promise.resolve()
                                    }
                                })
                            ]}
                        >
                            <Input
                                prefix={<p style={{ color: 'white', margin: '0px' }}>{currencySign}</p>}
                                placeholder={selectedLanguage?.placeholder_enterMoneyHere}
                                className='form-input-new-design-big-slam'
                                precision={2}
                                type='number'
                                step={0.01}
                                min={0}

                            />
                        </Form.Item>
                    </div>
                    :
                    <div className='form-two-input-flex-inner-input'>
                        <Form.Item
                            name='point'
                            label={<p className='p-admin-title-text'>{selectedLanguage?.text_points}</p>}
                            rules={[
                                {
                                    required: true,
                                    message: selectedLanguage?.error_pointsIsRequired
                                },
                                ({ getFieldValue }) => ({
                                    validator(rule, value) {
                                        if (value < 1) {
                                            return Promise.reject(selectedLanguage?.txt_Value_must_more_then + ' 0')
                                        }
                                        return Promise.resolve()
                                    }
                                })
                            ]}
                        >
                            <div>
                                <Input
                                    className='form-input-new-design-big-slam'
                                    placeholder={selectedLanguage?.placeholder_enterPointhere}
                                    type='number'
                                    step={0.01}
                                    min={0}
                                    precision={2}
                                />
                            </div>
                        </Form.Item>
                    </div>
                }
            </div>}

            <div className='form-input-single-input'>
                {competition ?
                    // <div className='form-two-input-flex-inner-input'>
                    <Form.Item
                        name='competition'
                        label={<p className='p-admin-title-text'>{selectedLanguage?.text_Select_Competition}</p>}
                        hasFeedback
                        rules={[
                            {
                                required: true,
                                message: selectedLanguage?.text_Competitionisrequired
                            }
                        ]}
                    >
                        <Select
                            mode='multiple'
                            className='form-input-new-style-select-minhi'
                            showSearch={true}
                            notFoundContent={selectedLanguage?.txt_NoDataFound}
                            optionFilterProp="children"
                            placeholder={selectedLanguage?.text_SelectCompetitionHere}
                        >
                            {allCompetitions?.map((v, i) => {
                                return (
                                    <Option value={v?._id}>
                                        {`${v?.competitionName} | ${currencySign} ${v?.participationMoney}`}
                                    </Option>
                                )
                            })}
                        </Select>
                    </Form.Item>
                    // </div> 
                    :
                    // <div className='form-two-input-flex-inner-input'>
                    <Form.Item
                        name='tournament'
                        label={<p className='p-admin-title-text'>{selectedLanguage?.title_SelectTournament}</p>}
                        hasFeedback
                        rules={[
                            {
                                required: true,
                                message: selectedLanguage?.error_tournamentisrequired
                            }
                        ]}
                    >
                        <Select
                            mode='multiple'
                            className='form-input-new-style-select-minhi'
                            showSearch={true}
                            optionFilterProp="children"
                            notFoundContent={selectedLanguage?.txt_NoDataFound}
                            placeholder={selectedLanguage?.placeHolder_SelectTournament}
                        >
                            {adminTournament?.map((v, i) => {
                                return (
                                    <Option value={v?._id}>
                                        {`${v?.tournamentName}`}
                                    </Option>
                                )
                            })}
                        </Select>
                    </Form.Item>
                    // </div>
                }
            </div>

            <div className='form-input-single-input'>
                <Form.Item
                    name='file'
                    label={<p className='p-admin-title-text'>{selectedLanguage?.text_IMG}</p>}
                >
                    <Upload
                        className='ant-upload-new-big-slaim'
                        name='file'
                        multiple={false}
                        beforeUpload={() => false}
                        accept='image/png, image/jpeg'
                        onChange={normFile}
                        fileList={fileList}
                    >
                        <Button
                            style={{ marginTop: '16px' }}
                            icon={<UploadOutlined />}>{selectedLanguage?.text_ClickToUpload}</Button>
                    </Upload>
                </Form.Item>
            </div>


            <Form.Item>
                <Button
                    type="primary"
                    htmlType='submit'
                    loading={loading}
                >
                    {selectedLanguage?.btn_CreateCoupons}
                </Button>
            </Form.Item>
        </Form >
    )
}
export default AddAdminCoupons