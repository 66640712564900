import React, { useState } from 'react'
import { Button, Form, Input, Select, } from 'antd'
import { assignMessage, errorMessage, getGeneralData, successMessage } from '../../../utils/helpers'
import axios from 'axios'
import { ADMIN } from '../../../utils/apis'


const AdminAddGame = (props) => {
    const { goBack } = props
    const { Option } = Select
    const { selectedLanguage, language, edit, isHide, generalActions } = props
    const [form] = Form.useForm()
    const [loading, setLoading] = useState(false)
    let gameTypes = [
        { name: selectedLanguage?.text_OneByOneMatchGame, value: '1-1 Match Game' },
        { name: selectedLanguage?.text_TeamMatchGame, value: 'Team Match Game' },
    ]
    const onFinish = (values) => {
        setLoading(true)
        values._id = edit?._id
        values.name = values.name.trim()
        values.description = values.description.trim()
        values.playingTime = values.playingTime.trim()
        axios.post(edit?._id ? ADMIN.EDIT_GAME : ADMIN.ADD_NEW_GAME, values)
            .then((res) => {
                const { data } = res
                setLoading(false)
                if (data.success) {
                    getGeneralData(generalActions)
                    successMessage(assignMessage(data?.message, language?.language))
                    form.resetFields()
                    edit?._id && (goBack())
                }
                else {
                    errorMessage(assignMessage(data?.message, language?.language))
                }
            })
            .catch((e) => {
                setLoading(false)
                errorMessage(assignMessage(e?.message, language?.language))
            })
    }


    return (
        <div className='new-main-component-screen-div' style={{ background: 'black' }}>
            {isHide ? null :
                <h1 className='new-screen-title orang-color' >
                    {selectedLanguage?.title_AddNewGame}
                </h1>
            }
            <div style={{ marginTop: '20px' }}>
                <Form
                    name='form'
                    form={form}
                    onFinish={onFinish}
                    layout={'vertical'}
                >

                    <div className='form-two-input-flex'>
                        <div className='form-two-input-flex-inner-input'>
                            <Form.Item
                                name='name'
                                initialValue={edit?.name}
                                hasFeedback
                                label={<p className='p-admin-title-text'>{selectedLanguage?.title_Name}</p>}
                                rules={[
                                    {
                                        required: true,
                                        message: selectedLanguage?.error_PleaseInputYourName
                                    }
                                ]}
                            >
                                <Input
                                    className='form-input-new-design-big-slam'
                                    placeholder={selectedLanguage?.placeHolder_InputYourNameHere}
                                />
                            </Form.Item>
                        </div>

                        <div className='form-two-input-flex-inner-input'>
                            <Form.Item
                                name='gameType'
                                hasFeedback
                                label={<p className='p-admin-title-text'>{selectedLanguage?.label_selectGameType}</p>}
                                initialValue={edit?.gameType}
                                rules={[
                                    {
                                        required: true,
                                        message: selectedLanguage?.error_selectGameType
                                    }
                                ]}
                            >
                                <Select
                                    className='form-input-new-design-big-slam'
                                    notFoundContent={selectedLanguage?.txt_NoDataFound}
                                    placeholder={selectedLanguage?.placeHolder_selectGameType}>
                                    {gameTypes?.length ?
                                        gameTypes?.map((v, i) => {
                                            return (
                                                <Option value={v?.value}>{`${v?.name}`}</Option>
                                            )
                                        })
                                        : null}
                                </Select>
                            </Form.Item>
                        </div>
                    </div>

                    <div className='form-two-input-flex'>
                        <div className='form-two-input-flex-inner-input'>
                            <Form.Item
                                name='description'
                                initialValue={edit?.description}
                                hasFeedback
                                label={<p className='p-admin-title-text'>{selectedLanguage?.title_Description}</p>}
                                rules={[
                                    {
                                        required: true,
                                        message: selectedLanguage?.error_Description
                                    }
                                ]}
                            >
                                <Input
                                    className='form-input-new-design-big-slam'
                                    placeholder={selectedLanguage?.placeHolder_Description}
                                />
                            </Form.Item>
                        </div>

                        <div className='form-two-input-flex-inner-input'>
                            <Form.Item
                                name='playingTime'
                                initialValue={edit?.playingTime}
                                hasFeedback
                                label={<p className='p-admin-title-text'>{selectedLanguage?.title_Playingtimeavg}</p>}
                                rules={[
                                    {
                                        required: true,
                                        message: selectedLanguage?.error_Playingtimeavg
                                    },
                                    ({ getFieldValue }) => ({
                                        validator(rule, value) {
                                            if (value < 1) {
                                                return Promise.reject(selectedLanguage?.txt_Value_must_more_then + ' 0')
                                            }
                                            else if (value > 120) {
                                                return Promise.reject(selectedLanguage?.txt_Value_must_Less_then + ' 121')
                                            }
                                            return Promise.resolve()
                                        }
                                    })

                                ]}
                            >
                                <Input
                                    min={0}
                                    max={120}
                                    type='number'
                                    pattern='[0-9]*'
                                    className='form-input-new-design-big-slam'
                                    placeholder={`${selectedLanguage?.placeHolder_Playingtimeavg}   (1 - 120)`}
                                />
                            </Form.Item>
                        </div>
                    </div>

                    <div className='form-two-input-flex'>
                        <div className='form-two-input-flex-inner-input newInpuHeightFull'>
                            <Form.Item
                                name='startingPoint'
                                initialValue={edit?.startingPoint}
                                hasFeedback
                                label={<p className='p-admin-title-text'>{selectedLanguage?.title_StartingPoint}</p>}
                                rules={[
                                    {
                                        required: true,
                                        message: selectedLanguage?.error_StartingPoint
                                    },
                                    ({ getFieldValue }) => ({
                                        validator(rule, value) {
                                            if (value < 1) {
                                                return Promise.reject(selectedLanguage?.txt_Value_must_more_then + ' 0')
                                            }
                                            else if (value > 500) {
                                                return Promise.reject(selectedLanguage?.txt_Value_must_Less_then + ' 501')
                                            }
                                            return Promise.resolve()
                                        }
                                    })
                                ]}
                            >
                                <Input
                                    min={0}
                                    max={500}
                                    type='number'
                                    pattern='[0-9]*'
                                    style={{ width: '100%' }}
                                    className='form-input-new-design-big-slam'
                                    placeholder={`${selectedLanguage?.placeHolder_StartingPoint}  (1 - 500)`}
                                />
                            </Form.Item>
                        </div>
                    </div>

                    <div style={{ display: 'flex', justifyContent: 'flex-end', }}>
                        <Form.Item>
                            <Button
                                type='primary'
                                htmlType='submit'
                                loading={loading}
                            >
                                {edit?._id ? selectedLanguage?.buttonText_updateGame : selectedLanguage?.buttonText_addGame}
                            </Button>
                        </Form.Item>
                    </div>
                </Form>
            </div>
        </div>
    )
}

export default AdminAddGame