const HOME = '/' 
const LOGIN = '/login'
const SIGNUP = '/register'
const VISITER_HOME = '/home'
const FORGOT_PASSOWRD = '/forgot-password'
const VERIFY_EMAIL = `/verifyemail`
const UPDATE_PASSWORD = `/update-password`
const PROMOTING_FORM = '/promoting-form'
const FAQ = '/faq'
const SIDE_MENU_FAQ = '/wgp/faq'
const ADMIN = '/admin'
const PLAYER = '/player'
const TOURNAMENT_PARTICIPATION = '/tournament/participation'
const COMPETITION_RESULT = '/competition/result'
const ADMIN_HOME = `${ADMIN}`
const ADMIN_LOGIN = `${ADMIN}/login`
const ADMIN_PROFILE = `${ADMIN}/Profile`
const ADMIN_CREATE_GAME = `${ADMIN}/create-game`
const ADMIN_GAME_SUGGESTION = `${ADMIN}/game-suggestion`
const ADMIN_CREATE_TOURNAMENT = `${ADMIN}/create-tournament`
const ADMIN_TOURNAMENT_SELECT = `${ADMIN}/tournament-select`
const ADMIN_GAMES = `${ADMIN}/games`
const ADMIN_CLAIM_ARGUES = `${ADMIN}/claims-argues`
const ADMIN_PROMOTER_SETTINGS = `${ADMIN}/promoter`
const ADMIN_POINT_SETTINGS = `${ADMIN}/point-setting`
const ADMIN_PLAYER_DETAIL = `${ADMIN}/player-detail`
const ADMIN_COUPONS = `${ADMIN}/coupons`
const ADMIN_PLAYER_INVOICES = `${ADMIN}/players-invoices`
const ADMIN_CASHBACK = `${ADMIN}/admin-cashback`
const ADMIN_BTW_INVOICES = `${ADMIN}/btw-invoices`
const ADMIN_ADD_FAQ = `${ADMIN}/add-faq`
const ADMIN__FAQ = `${ADMIN}/faq`
const ADMIN_CREATE_COMPETITION = `${ADMIN}/create-competition`
const ADMIN_COMPETITION_SELECT = `${ADMIN}/competition-select`
const ADMIN_COMPETITION_SUB_INVOICES = `${ADMIN}/competition-sub-invoices`

const PLAYER_PROFILE = `${PLAYER}/Profile`
const PLAYER_GAME_SETTING = `${PLAYER}/game-setting`
const PLAYER_CONTACT = `${PLAYER}/contact`
const PLAYER_CREATE_TOURNAMENT = `${PLAYER}/create-tournament`
const PLAYER_CREATE_SUGGESTION = `${PLAYER}/create-suggestion`
const PLAYER_SELECT_TOURNAMENT = `${PLAYER}/select-tournament`
const PLAYER_RANKING_POINT = `${PLAYER}/ranking-point`
const PLAYER_CREATE_MATCH_REQ = `${PLAYER}/create-match-request`
const PLAYER_MATCH_REQUESTS = `${PLAYER}/match-requests`
const PLAYER_MATCH_RESULT = `${PLAYER}/match-result`
const PLAYER_CLAIM = `${PLAYER}/claim`
const PLAYER_INVITE_FRIEND = `${PLAYER}/invite-friend`
const PLAYER_TOURNAMENT_MATCHES = `${PLAYER}/tournament-matches`
const PLAYER__PRO_RANKING_POINT = `${PLAYER}/pro-ranking-point`
const PLAYER_SUBSCRIPTION = `${PLAYER}/subscription`
const PLAYER_INVOIVE = `${PLAYER}/invoice`
const PLAYER_CASHBACK = `${PLAYER}/cashback`
const PLAYER_WALLET_TRANSACTION = `${PLAYER}/wallet/transactions`
const PLAYER_CREATE_COMPETITION_MATCH = `${PLAYER}/create-competition-match-request`
const COMPETITION_SELECT = `${PLAYER}/competition-select`
const COMPETITION_MATCHES = `${PLAYER}/competition-matches`
const COMPETITION_MATCHES_RESULTS = `${PLAYER}/competition-matches-result`
const COMPETITION_RANKING_POINT = `${PLAYER}/competition-ranking-point`
const COMPETITION_SUB_INVOICE = `${PLAYER}/competition-sub-invoice`

export default {
    HOME,
    LOGIN,
    SIGNUP,
    ADMIN_LOGIN,
    FORGOT_PASSOWRD,
    FAQ,
    VERIFY_EMAIL,
    UPDATE_PASSWORD,
    ADMIN_PROFILE,
    ADMIN_CREATE_GAME,
    ADMIN_CREATE_TOURNAMENT,
    ADMIN_TOURNAMENT_SELECT,
    ADMIN_GAMES,
    ADMIN_CLAIM_ARGUES,
    ADMIN_HOME,
    PLAYER_PROFILE,
    PLAYER_GAME_SETTING,
    PLAYER_CONTACT,
    PLAYER_CREATE_TOURNAMENT,
    SIDE_MENU_FAQ,
    ADMIN_GAME_SUGGESTION,
    PLAYER_CREATE_SUGGESTION,
    PLAYER_SELECT_TOURNAMENT,
    PLAYER_RANKING_POINT,
    PLAYER_CREATE_MATCH_REQ,
    PLAYER_MATCH_REQUESTS,
    ADMIN_PROMOTER_SETTINGS,
    PROMOTING_FORM,
    PLAYER_MATCH_RESULT,
    PLAYER_CLAIM,
    PLAYER_INVITE_FRIEND,
    ADMIN_POINT_SETTINGS,
    ADMIN_PLAYER_DETAIL,
    ADMIN_COUPONS,
    PLAYER_TOURNAMENT_MATCHES,
    PLAYER__PRO_RANKING_POINT,
    PLAYER_SUBSCRIPTION,
    PLAYER_INVOIVE,
    ADMIN_PLAYER_INVOICES,
    PLAYER_CASHBACK,
    ADMIN_CASHBACK,
    ADMIN_BTW_INVOICES,
    ADMIN_ADD_FAQ,
    ADMIN__FAQ,
    TOURNAMENT_PARTICIPATION, 
    PLAYER_WALLET_TRANSACTION,
    ADMIN_CREATE_COMPETITION,
    PLAYER_CREATE_COMPETITION_MATCH,
    COMPETITION_SELECT,
    COMPETITION_MATCHES,
    COMPETITION_MATCHES_RESULTS,
    COMPETITION_RANKING_POINT,
    ADMIN_COMPETITION_SELECT,
    COMPETITION_SUB_INVOICE,
    ADMIN_COMPETITION_SUB_INVOICES,
    COMPETITION_RESULT,
    VISITER_HOME
}