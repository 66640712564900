import React, { useEffect, useState } from "react";
import NoData from '../../../assets/noDataImae.png'
import axios from 'axios'
import { GET } from "../../../utils/apis";
import { createCompetitionInvoice, getGeneralData } from "../../../utils/helpers";
import moment from "moment";
import { Select, Table } from "antd";
import { useSelector } from 'react-redux'
const { Option } = Select

const AdminCompetitionSubInvoices = (props) => {
    const { selectedLanguage, user, generalActions } = props
    const allCompetitions = useSelector(state => state.generalReducer.allCompetitions)
    const [selectedCompetition, setSelectedCompetition] = useState(allCompetitions?.length ? allCompetitions?.[0]?._id : null)
    const [selectedCompetitionDetail, setSelectedCompetitionDetail] = useState(allCompetitions?.[0] || null)

    useEffect(() => {
        getGeneralData(generalActions)
    }, [])

    useEffect(() => {
        setSelectedCompetitionDetail(allCompetitions?.filter((v) => v?._id === selectedCompetition)?.[0])
    }, [selectedCompetition])
    let columns = [
        {
            title: 'User name',
            key: 'name',
            render: (e) => <p style={{ paddingTop: 15, marginTop: -5, }}>{e?.userId?.name}</p>
        },
        {
            title: selectedLanguage?.text_SubscriptionDate,
            key: 'date',
            render: (e) => <p style={{ paddingTop: 15, marginTop: -5, }}> {moment(e?.created).format('MM-DD-YYYY')} </p>
        },
        {
            title: 'Coupon used',
            key: 'user',
            render: (e) => <p style={{ paddingTop: 15, marginTop: -5, }}>{e?.isCouponUser ? 'true' : 'false'}</p>
        },
        {
            title: selectedLanguage?.text_DownloadInvoice,
            key: 'action',
            render: (e) => <a onClick={() => { createCompetitionInvoice(e) }} > {selectedLanguage?.text_Download} </a>
        }
    ]
    return (
        <div className='new-main-component-screen-div'>
            <h1 className='new-screen-title' >
                {selectedLanguage?.txt_CompetitionSubscriptionInvoices}
            </h1>
            <br />
            <Select
                showSearch
                showArrow
                style={{ width: '200px' }}
                allowClear={false}
                defaultValue={selectedCompetition}
                onChange={(e) => setSelectedCompetition(e)}
                notFoundContent={selectedLanguage?.txt_NoDataFound}
                className='form-input'
                placeholder={selectedLanguage?.txt_Selectcompetition}
                optionFilterProp='children'
                filterOption={(input, option) =>
                    option?.value?.toString()?.indexOf(input.toLowerCase()) >= 0
                }
            >
                {allCompetitions?.map((v, i) => <Option value={v?._id} key={i}>{`${(v?.competitionName)}`}</Option>)}
            </Select>


            <div className="custom-table-container">
                <Table
                    locale={{ emptyText: selectedLanguage?.txt_NoDataFound }}
                    className='new-layout-table'
                    dataSource={selectedCompetitionDetail?.competitionSubscribers || []}
                    columns={columns}
                    pagination={false}
                    scroll={{ x: 700 }}
                />
            </div>
        </div>
    )
}

export default AdminCompetitionSubInvoices