import React, { useEffect, useState } from 'react'
import { Button, Form, Table } from 'antd'
import { IoIosArrowBack } from "react-icons/io"
import AddGameSuggestion from './AddGameSuggestion'
import axios from 'axios'
import { GET } from '../../../utils/apis'
import { getUserData } from '../../../utils/helpers'
import { useSelector } from 'react-redux'
import NoData from '../../../assets/noDataImae.png'

const PlayerGameSuggestion = (props) => {
    const { selectedLanguage, isEnglish, user, userActions } = props
    const gameSuggestions = useSelector(state => state?.userReducer?.gameSuggestions)
    const [newSuggestion, setNewSuggestion] = useState(false)
    useEffect(() => {
        getUserData(user?._id, userActions)

    }, [])

    const goBack = () => {
        setNewSuggestion(false)
    }

    const columns = [
        {
            title: selectedLanguage?.title_ID,
            dataIndex: '_id',
            key: 'id',
            render: (e) => {
                let Number = e.substr(e.length - 5)
                return (
                    <p className='p_button_style'>{Number}</p>
                )
            }
        },
        {
            title: selectedLanguage?.title_OwnerName,
            dataIndex: 'userId',
            key: 'userId',
            render: (e) => {
                return (
                    <p style={{ width: '160px' }}>{e?.name || '---'}</p>
                )
            }
        },
        {
            title: selectedLanguage?.title_Name,
            dataIndex: 'name',
            key: 'name',
            render: (e) => {
                return (
                    <p style={{ width: '100px' }}>{e || '----'}</p>
                )
            }
        },
        {
            title: selectedLanguage?.text_gameType,
            dataIndex: 'gameType',
            key: 'gameType',
            render: (e) => {
                return (
                    <p style={{ width: '120px' }}>{e || '----'}</p>
                )
            }
        },
        {
            title: selectedLanguage?.title_Description,
            dataIndex: 'description',
            key: 'description',
            render: (e) => {
                return (
                    <p style={{ width: '120px' }}>{e || '----'}</p>
                )
            }
        },
        {
            title: selectedLanguage?.title_PlayingTimeAvgmin,
            dataIndex: 'playingTime',
            key: 'playingTime',
            render: (e) => {
                return (
                    <p>{e}{' '}{selectedLanguage?.Text_Min}</p>
                )
            }
        },
        {
            title: selectedLanguage?.txt_Status,
            render: (e) => {
                return (
                    <p style={{ width: '160px' }} >{
                        e?.verify ?
                            selectedLanguage?.text_ApprevedByAdmin :
                            e?.isDeleted ?
                                selectedLanguage?.text_DeletedFromAdmin :
                                selectedLanguage?.text_Pending
                    }</p>
                )
            }
        },
    ]

    return (
        <div>
            {newSuggestion ?
                <div className='new-main-component-screen-div' style={{ background: 'black' }}>
                    <div style={{ display: 'flex', justifyContent: 'flex-start', marginTop: '10px' }}>
                        <span
                            onClick={() => {
                                goBack()
                            }}
                            style={{ marginTop: '12px', cursor: 'pointer', }}>
                            <IoIosArrowBack size={28} color='#ec671b' />
                        </span>
                        <h1 className='new-screen-title  orang-color' >
                            {selectedLanguage?.title_AddGameSuggestion}
                        </h1>
                    </div>
                    <AddGameSuggestion {...props} />
                </div>
                :
                <div className='new-main-component-screen-div'>
                    <h1 className='new-screen-title' >
                        {selectedLanguage?.text_GameSuggestionCreatedbyMe}
                    </h1>
                    <div style={{ marginTop: '20px' }}>
                        <div style={{ display: 'flex', justifyContent: 'flex-end', }}>
                            <Button
                                type='primary'
                                onClick={() => { setNewSuggestion(true) }}
                            >
                                {selectedLanguage?.btn_AddNewGameSuggestion}
                            </Button>
                        </div>

                        <div className="custom-table-container">
                            <Table
                                locale={{ emptyText: selectedLanguage?.txt_NoDataFound }}
                                className='new-layout-table'
                                dataSource={gameSuggestions || []}
                                columns={columns}
                                pagination={false}
                                scroll={{ x: 800 }}
                            />
                        </div>
                    </div>
                </div>
            }
        </div>
    )
}

export default PlayerGameSuggestion