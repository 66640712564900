import React, { useState } from 'react'
import allPaths from '../../../Config/paths'
import { useHistory } from 'react-router-dom'

const PlayerHome = (props) => {
    const { selectedLanguage, isEnglish, } = props
    const history = useHistory()
    return (
        <div>
            <h1 className='new-screen-title'>{selectedLanguage?.txt_Home}</h1>
            <div className='new-screen-text-box'>
                <p className='new-home-txts-1st'>{selectedLanguage?.text_ChallengeYourFriendsAndImproveYourRanking}</p>
                <p className='new-home-txts-1st'>{selectedLanguage?.text_EarnPointsOrRealMoney}</p>
            </div>

            <div className='three-boxes-card-div'>
                <div className='ex-tion-card'>
                    <p className='div-tt'>{selectedLanguage?.text_CreateTournament}</p>
                    <p>{selectedLanguage?.txt_loremispum_full}
                    </p>
                    <button
                        onClick={() => history?.push(allPaths?.PLAYER_CREATE_TOURNAMENT)}
                        className='ex-tion-card-button' >
                        {selectedLanguage?.text_CreateTournament} ➝
                    </button>
                </div>
                <div className='ex-tion-card'>
                    <p className='div-tt'>{selectedLanguage?.text_CreateMatchRequest} </p>
                    <p>{selectedLanguage?.txt_loremispum_full}
                    </p>
                    <button
                        onClick={() => history?.push(allPaths?.PLAYER_CREATE_MATCH_REQ)}
                        className='ex-tion-card-button' >
                        {selectedLanguage?.text_CreateMatchRequest} ➝
                    </button>
                </div>
                <div className='ex-tion-card'>
                    <p className='div-tt'>{selectedLanguage?.text_UploadWallet} </p>
                    <p>{selectedLanguage?.txt_loremispum_full}
                    </p>
                    <button
                        onClick={() => history?.push(allPaths?.PLAYER_SUBSCRIPTION)}
                        className='ex-tion-card-button' >
                        {selectedLanguage?.text_UploadWallet} ➝
                    </button>
                </div>
            </div>
        </div>
        )
}

export default PlayerHome