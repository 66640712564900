import React, { useEffect, useState } from "react";
import NoData from '../../../assets/noDataImae.png'
import axios from 'axios'
import { GET } from "../../../utils/apis";
import { createCompetitionInvoice } from "../../../utils/helpers";
import moment from "moment";
import { Table } from "antd";

const CompetitionSubInvoives = (props) => {
    const { selectedLanguage, user } = props
    const [data, setData] = useState([])


    console.log(data, "=data")

    const getAllData = () => {
        axios.get(`${GET.GET_PLAYER_COMPETITION_SUBSCRIPTION}/${user._id}`)
            .then((res) => {
                const { data } = res
                if (data.success) {
                    setData(data.data)
                }
                else {
                    setData([])
                }
            })
            .catch((e) => {
                setData([])
            })
    }



    useEffect(() => {
        getAllData()
    }, [])


    let columns = [
        {
            title: selectedLanguage?.text__Competitionname,
            key: 'subscriptionName',
            // sorter: true,
            render: (e) => <p>{e?.competitionId?.competitionName}</p>
        },
        {
            title: selectedLanguage?.text_SubscriptionDate,
            key: 'created',
            // sorter: true,
            render: (e) => <p>{moment(e?.created).format('MM-DD-YYYY')}</p>
        },
        {
            title: selectedLanguage?.text__Couponused,
            render: (e) => <p>{e?.isCouponUser ? 'true' : 'false'}</p>
        },
        {
            title: selectedLanguage?.text_DownloadInvoice,
            key: 'invoice',
            render: (e) => <a onClick={() => { createCompetitionInvoice(e) }}> {selectedLanguage?.text_Download} </a>
        }
    ]
    return (
        <div className='new-main-component-screen-div'>
            <p className='new-screen-title' >
                {selectedLanguage?.txt_Competition_Subscription_Invoices}
            </p>

            <div className="custom-table-container">
                <Table
                    locale={{ emptyText: selectedLanguage?.txt_NoDataFound }}
                    className='new-layout-table'
                    dataSource={data || []}
                    columns={columns}
                    pagination={false}
                    scroll={{ x: 700 }}
                />
            </div>
        </div>
    )
}

export default CompetitionSubInvoives