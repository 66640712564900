import { Button, Image, Input, Popconfirm, Table } from 'antd'
import React, { useEffect, useState } from 'react'
import { IoIosArrowBack } from "react-icons/io"
import AddPromoter from './AddPromoter'
import { ADMIN } from '../../../utils/apis'
import { assignMessage, convertTitle, errorMessage, getGeneralData, successMessage } from '../../../utils/helpers'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import axios from 'axios'
import { useSelector } from 'react-redux'

const Promoter = (props) => {
    const { selectedLanguage, language, user, generalActions } = props
    const [game, setGame] = useState(false)
    const [loading, setLoading] = useState(false)
    const [deleteLoading, setDeleteLoading] = useState({})
    const [allGames, setAllGames] = useState([])
    const [edit, setEdit] = useState({})
    const promoter = useSelector(state => state.generalReducer)


    useEffect(() => {
        getGeneralData(generalActions)
    }, [])

    const getAllGames = () => {
        setLoading(true)
        console.log('run')
        axios.get(ADMIN.GET_ALL_PROMOTER)
            .then((res) => {
                const { data } = res
                setLoading(false)
                if (data?.success) {
                    console.log('')
                    setAllGames(data?.data || [])
                    getGeneralData(generalActions)
                }
                else {
                    setAllGames([])
                }
            })
            .catch((e) => {
                setLoading(false)
                errorMessage(assignMessage(e?.message, language?.language))
            })
    }


    const deleteGame = (id) => {
        setDeleteLoading({ [id]: true })
        let obj = { _id: id, adminId: user?._id }
        axios.post(ADMIN.DELETE_PROMOTER, obj)
            .then((res) => {
                const { data } = res
                setDeleteLoading({})
                if (data?.success) {
                    successMessage(assignMessage(data?.message, language?.language))
                    setAllGames([])
                    getAllGames()
                }
                else {
                    errorMessage(assignMessage(data?.message, language?.language))
                }
            })
            .catch((e) => {
                setDeleteLoading({})
                errorMessage(assignMessage(e?.message, language?.language))
            })
    }


    useEffect(() => {
        getAllGames()
    }, [deleteLoading])


    const columns = [
        {
            title: selectedLanguage?.title_AdFile,
            dataIndex: 'adFile',
            key: 'adFile',
            align: 'center',
            render: (e) => <Image src={e?.url} style={{ marginRight: 10, width: 86, height: 60 }} />
        },
        {
            title: selectedLanguage?.title_TournamentFile,
            dataIndex: 'tournamentFile',
            key: 'tournamentFile',
            align: 'center',
            render: (e) => <Image src={e?.url} style={{ marginRight: 10, width: 86, height: 60 }} />
        },
        {
            title: selectedLanguage?.title_Name,
            dataIndex: 'name',
            key: 'name',
            render: (e) => e || '----'
        },
        {
            title: selectedLanguage?.label_Email,
            dataIndex: 'email',
            key: 'email',
            render: (e) => e || '----'
        },
        {
            title: '',
            render: (e) => {
                return (
                    <span style={{ display: 'flex', }}>
                        <Button
                            size='small'
                            onClick={() => {
                                setEdit(e)
                                setGame(true)
                            }}
                            type='primary'
                        >{selectedLanguage?.buttonText_Edit}
                        </Button>
                        &nbsp;
                        &nbsp;
                        <Popconfirm
                            title={`${selectedLanguage?.textDelete} ${convertTitle(`${e?.name} `)} ${selectedLanguage?.textGame} ?`}
                            okText={selectedLanguage?.textDelete}
                            cancelText={selectedLanguage?.textCancel}
                            okButtonProps={{
                                type: 'primary',
                                className: 'form-button'
                            }}
                            cancelButtonProps={{ style: { borderRadius: 4 } }}
                            onConfirm={() => {
                                deleteGame(e?._id)
                            }}
                        >
                            <Button
                                loading={deleteLoading[e?._id]}
                                type='primary'
                            >{selectedLanguage?.buttonText_Delete}</Button>
                        </Popconfirm>
                    </span>
                )
            }
        },
    ]

    const goBack = () => {
        setGame(false)
        getAllGames()
        setEdit({})
    }


    return (
        <>
            {!game ?
                <div className='new-main-component-screen-div'>
                    <h1 className='new-screen-title'>
                        {selectedLanguage?.title_Promoters}
                    </h1>
                    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <CopyToClipboard text={'https://wgp-plaza-2bogwjma7a-ez.a.run.app/promoting-form'}
                            onCopy={() => successMessage(selectedLanguage?.SuccessfullyCopiedtoClipboard)}>
                            <Button
                                type='primary'
                            >{selectedLanguage?.txt_copyPromotingformUrl}</Button>
                        </CopyToClipboard>
                        &nbsp;
                        &nbsp;
                        <Button
                            type='primary'
                            onClick={() => {
                                setGame(true)
                            }}
                        >
                            {selectedLanguage?.text_AddPromotion}
                        </Button>
                    </div>
                    <div
                        className="custom-table-container"
                    >
                        <Table
                            locale={{ emptyText: selectedLanguage?.txt_NoDataFound }}
                            className='new-layout-table'
                            dataSource={allGames || []}
                            columns={columns}
                            pagination={false}
                            loading={loading}
                            scroll={{ x: 1000 }}
                        />
                    </div>
                </div >
                :
                <div className='new-main-component-screen-div' style={{ background: 'black' }}>
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                        <span
                            onClick={() => {
                                goBack()
                            }}
                            style={{ marginTop: '12px', cursor: 'pointer' }}>
                            <IoIosArrowBack size={28} className='orang-color' />
                        </span>
                        <h1 className='new-screen-title orang-color' >
                            {selectedLanguage?.title_Promoters}
                        </h1>
                    </div>
                    <AddPromoter onCancel={() => (setGame(false), getAllGames())} isHide={true} edit={edit} {...props} />
                </div>
            }
        </>

    )
}

export default Promoter