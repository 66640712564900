import { Table } from "antd";
import moment from "moment";
import React from "react";
import NoData from '../../assets/noDataImae.png'
const ParticipationTable = (props) => {
    const { Tournament, selectedLanguage } = props
    let participater = Tournament?.tournamentParticipate
    let sortParticipater = participater?.sort((a, b) => Tournament?.isStake ? b?.playerPoint?.proPoint - a?.playerPoint?.proPoint : b?.playerPoint?.point - a?.playerPoint?.point);

    // console.log(Tournament?.isStake, sortParticipater)

    const columns = [
        {
            title: selectedLanguage?.txt_playerName,
            dataIndex: 'userId',
            render: (e) => {
                return (
                    <p style={{ width: '100px' }}>{e?.name || '----'}</p>
                )
            }
        },
        {
            title: Tournament?.isStake ? selectedLanguage?.txt_PlayerProPoint : selectedLanguage?.txt_Playerpoint,
            dataIndex: 'playerPoint',
            render: (e) => {
                return (
                    <p style={{ width: '100px' }}>{Tournament?.isStake ? e?.proPoint || 0 : e?.point || 0}</p>
                )
            }
        },
        {
            title: Tournament?.isStake ? selectedLanguage?.txt_MoneyCollected : selectedLanguage?.txt_PointCollected,
            render: (e) => {
                return (
                    <p style={{ width: '100px' }}>{Tournament?.isStake ? e?.moneyCollected : e?.pointCollected}</p>
                )
            }
        },
        {
            title: selectedLanguage?.txt_Participationdate,
            render: (e) => {
                return (
                    <p style={{ width: '100px' }}>{moment(e?.created).format('DD-MM-YYYY')}</p>
                )
            }
        },

    ]

    return (
        <div style={{ marginTop: '20px' }}>
            <div className="custom-table-container">
                <Table
                    locale={{ emptyText: selectedLanguage?.txt_NoDataFound }}
                    className='new-layout-table'
                    dataSource={sortParticipater}
                    columns={columns}
                    pagination={false}
                    scroll={{ x: 800 }}
                />
            </div>
        </div>
    )
}
export default ParticipationTable