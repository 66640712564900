import React, { useEffect, useState } from 'react'
import { IoIosArrowUp, IoIosArrowDown } from "react-icons/io"
import { useHistory } from 'react-router-dom'
import { FaqsParticipation, FaqsGoal, FaqsTournaments, HowWork } from "../../../utils/faqConstant"
import EnglishLanguage from '../../../Languages/EnglishLanguage.json'
import NlLanguage from '../../../Languages/NetherlandsLanguage.json'
import { useSelector } from 'react-redux'
import axios from 'axios'
import { ADMIN } from '../../../utils/apis'
import { assignMessage, errorMessage, getFaqHelper, successMessage } from '../../../utils/helpers'
import { AiFillDelete } from 'react-icons/ai'
import { FaEdit } from 'react-icons/fa'
import { Button, Popconfirm, Spin } from 'antd'
import AddFaq from './AddFaq'
const AdminFaq = (props) => {
    const { faqSettingAction, user, selectedLanguage, language } = props
    const faqSettingData = useSelector(state => state?.faqSettingReducer?.faqSetting)
    const Sl_lan = language?.language
    console.log(Sl_lan, '=Sl_lan')
    const [editFaq, setEditFaq] = useState({})
    const [loading, setLoading] = useState({})
    const [reading, setReading] = useState('')
    const [readingGoals, setReadingGoals] = useState('')
    const [readingTournaments, setReadingTournaments] = useState('')

    const deleteFaq = (id) => {
        setLoading({ [id]: true })
        axios.post(ADMIN?.DELETE_FAQ_QUESTION, { id })
            .then((res) => {
                const { data } = res
                if (data?.success) {
                    successMessage(assignMessage(data?.message, language?.language))
                    getFaqHelper(faqSettingAction)
                    setLoading({})
                }
                else {
                    errorMessage(assignMessage(data?.message, language?.language))
                    setLoading({})
                }
            })
            .catch((e) => {
                setLoading({})
                errorMessage(assignMessage(e?.message, language?.language))
            })
    }

    useEffect(() => {
        getFaqHelper(faqSettingAction)
    }, [])


    return (

        <>
            {!editFaq?._id ?
                <div className='new-main-component-screen-div'>
                    <div style={{ paddingTop: '20px' }}>
                        <div>
                            <div className='height-35'>
                                <h1 className='new-screen-title'>
                                    {selectedLanguage?.title_HowItWorks}
                                </h1>
                            </div>
                        </div>

                        <div className='divFaqDiv' style={{ marginTop: '35px' }}>
                            {faqSettingData?.howIsWorkArray?.map((v, i) => {
                                return (
                                    <>
                                        <div
                                            key={1 + i}
                                            style={{
                                                padding: '20px',
                                                width: '100%',
                                                marginTop: '20px',
                                                boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2)',
                                                borderRadius: '8px',
                                                paddingTop: '30px',
                                                background: '#d7d7d761'
                                            }}>
                                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                                                <p style={{
                                                    fontWeight: '600',
                                                    fontSize: 16,
                                                    width: '90%',
                                                    margin: '0px',
                                                    padding: '0px',
                                                    lineBreak: 'anywhere',
                                                    color: 'black'
                                                }}>
                                                    {Sl_lan === 'netherlands' ? v?.titleDutch
                                                        : Sl_lan === 'italy' ? v?.titleItaly
                                                            : Sl_lan === 'spain' ? v?.titleSpain
                                                                : Sl_lan === 'egypt' ? v?.titleEgypt
                                                                    : Sl_lan === 'russain' ? v?.titleRussain
                                                                        : Sl_lan === 'german' ? v?.titleGerman
                                                                            : v?.titleEnglish}
                                                </p>

                                                <FaEdit size={20} color='#EC671B' onClick={() => setEditFaq(v)} />
                                                <Popconfirm
                                                    title={selectedLanguage?.faq_AreYouSureYouDeletethisfaq}
                                                    okText={selectedLanguage?.textDelete}
                                                    cancelText={selectedLanguage?.textCancel}
                                                    okButtonProps={{
                                                        type: 'primary',
                                                        className: 'form-button'
                                                    }}
                                                    cancelButtonProps={{ style: { borderRadius: 4 } }}
                                                    onConfirm={() => {
                                                        deleteFaq(v?._id)
                                                    }}
                                                >
                                                    {loading[v?._id] ? <Spin /> : <AiFillDelete size={20} color='#EC671B' />}
                                                </Popconfirm>
                                            </div>
                                            <div>
                                                <br />
                                                <pre style={{
                                                    width: '100%',
                                                    margin: '0px',
                                                    padding: '0px',
                                                    lineBreak: 'anywhere',
                                                    color: 'black'
                                                }}>
                                                    {Sl_lan === 'netherlands' ? v?.DiscriptionDutch
                                                        : Sl_lan === 'italy' ? v?.DiscriptionItaly
                                                            : Sl_lan === 'spain' ? v?.DiscriptionSpain
                                                                : Sl_lan === 'egypt' ? v?.DiscriptionEgypt
                                                                    : Sl_lan === 'russain' ? v?.DiscriptionRussain
                                                                        : Sl_lan === 'german' ? v?.DiscriptionGerman
                                                                            : v?.DiscriptionEnglish}
                                                </pre>
                                            </div>
                                        </div>
                                    </>
                                )
                            })
                            }
                        </div>

                        <div className='div-flex-back-name' style={{ marginTop: '35px' }}>
                            <div className='height-35'>
                                <h1 className='new-screen-title'>
                                    {selectedLanguage?.faq_FAQS}
                                </h1>
                            </div>
                        </div>
                        {faqSettingData?.FaqArrayGrourp?.Participation?.length &&
                            <div>
                                <div className='height-35' style={{ width: '100%', marginTop: '35px' }} >
                                    <p className='screen-heading' style={{
                                        textAlign: 'center',
                                        color: 'black'
                                    }}>{selectedLanguage?.title_Participation}</p>
                                </div>
                                <div className='divFaqDiv' style={{ marginTop: '35px' }}>
                                    {faqSettingData?.FaqArrayGrourp?.Participation?.map((v, i) => {
                                        return (
                                            <>
                                                <div
                                                    key={1 + i}
                                                    style={{
                                                        padding: '20px',
                                                        width: '100%',
                                                        marginTop: '20px',
                                                        boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2)',
                                                        borderRadius: '8px',
                                                        paddingTop: '30px',
                                                        background: '#d7d7d761'
                                                    }}>
                                                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                                                        <p style={{ width: '90%', margin: '0px', padding: '0px', lineBreak: 'anywhere', color: 'black' }}>
                                                            {Sl_lan === 'netherlands' ? v?.titleDutch
                                                                : Sl_lan === 'italy' ? v?.titleItaly
                                                                    : Sl_lan === 'spain' ? v?.titleSpain
                                                                        : Sl_lan === 'egypt' ? v?.titleEgypt
                                                                            : Sl_lan === 'russain' ? v?.titleRussain
                                                                                : Sl_lan === 'german' ? v?.titleGerman
                                                                                    : v?.titleEnglish}
                                                        </p>
                                                        <FaEdit size={20} color='#EC671B' onClick={() => setEditFaq(v)} />
                                                        <Popconfirm
                                                            title={selectedLanguage?.faq_AreYouSureYouDeletethisfaq}
                                                            okText={selectedLanguage?.textDelete}
                                                            cancelText={selectedLanguage?.textCancel}
                                                            okButtonProps={{
                                                                type: 'primary',
                                                                className: 'form-button'
                                                            }}
                                                            cancelButtonProps={{ style: { borderRadius: 4 } }}
                                                            onConfirm={() => {
                                                                deleteFaq(v?._id)
                                                            }}
                                                        >
                                                            {loading[v?._id] ? <Spin /> : <AiFillDelete size={20} color='#EC671B' />}
                                                        </Popconfirm>
                                                        {reading !== v?.titleEnglish ?
                                                            <IoIosArrowDown color='#ec671b' style={{ cursor: 'pointer' }} size={25} onClick={() => {
                                                                setReading(v?.titleEnglish)
                                                            }} /> :
                                                            <IoIosArrowUp color='#ec671b' style={{ cursor: 'pointer' }} size={25} onClick={() => {
                                                                setReading('')
                                                            }} />}
                                                    </div>
                                                    {reading === v?.titleEnglish ?
                                                        <div>
                                                            <br />
                                                            <pre style={{ width: '100%', margin: '0px', padding: '0px', lineBreak: 'anywhere', color: 'black' }}>

                                                                {Sl_lan === 'netherlands' ? v?.DiscriptionDutch
                                                                    : Sl_lan === 'italy' ? v?.DiscriptionItaly
                                                                        : Sl_lan === 'spain' ? v?.DiscriptionSpain
                                                                            : Sl_lan === 'egypt' ? v?.DiscriptionEgypt
                                                                                : Sl_lan === 'russain' ? v?.DiscriptionRussain
                                                                                    : Sl_lan === 'german' ? v?.DiscriptionGerman
                                                                                        : v?.DiscriptionEnglish}
                                                            </pre>
                                                        </div> : null}
                                                </div>
                                            </>
                                        )
                                    })
                                    }
                                </div>
                            </div>
                        }

                        {faqSettingData?.FaqArrayGrourp?.Goal?.length &&
                            <div>
                                <div className='height-35' style={{ width: '100%', marginTop: '35px' }} >
                                    <p className='screen-heading' style={{ textAlign: 'center', color: 'black' }}>{selectedLanguage?.title_Goal}</p>
                                </div>
                                <div className='divFaqDiv' style={{ marginTop: '35px' }}>
                                    {faqSettingData?.FaqArrayGrourp?.Goal?.map((v, i) => {
                                        return (
                                            <>
                                                <div
                                                    key={1 + i}
                                                    style={{
                                                        padding: '20px',
                                                        width: '100%',
                                                        marginTop: '20px',
                                                        boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2)',
                                                        borderRadius: '8px',
                                                        paddingTop: '30px',
                                                        background: '#d7d7d761'
                                                    }}>
                                                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                                                        <p style={{ width: '90%', margin: '0px', padding: '0px', lineBreak: 'anywhere', color: 'black' }}>
                                                            {Sl_lan === 'netherlands' ? v?.titleDutch
                                                                : Sl_lan === 'italy' ? v?.titleItaly
                                                                    : Sl_lan === 'spain' ? v?.titleSpain
                                                                        : Sl_lan === 'egypt' ? v?.titleEgypt
                                                                            : Sl_lan === 'russain' ? v?.titleRussain
                                                                                : Sl_lan === 'german' ? v?.titleGerman
                                                                                    : v?.titleEnglish}
                                                        </p>
                                                        <FaEdit size={20} color='#EC671B' onClick={() => setEditFaq(v)} />
                                                        <Popconfirm
                                                            title={selectedLanguage?.faq_AreYouSureYouDeletethisfaq}
                                                            okText={selectedLanguage?.textDelete}
                                                            cancelText={selectedLanguage?.textCancel}
                                                            okButtonProps={{
                                                                type: 'primary',
                                                                className: 'form-button'
                                                            }}
                                                            cancelButtonProps={{ style: { borderRadius: 4 } }}
                                                            onConfirm={() => {
                                                                deleteFaq(v?._id)
                                                            }}
                                                        >
                                                            {loading[v?._id] ? <Spin /> : <AiFillDelete size={20} color='#EC671B' />}
                                                        </Popconfirm>
                                                        {readingGoals !== v?.titleEnglish ?
                                                            <IoIosArrowDown color='#ec671b' style={{ cursor: 'pointer' }} size={25} onClick={() => {
                                                                setReadingGoals(v?.titleEnglish)
                                                            }} /> :
                                                            <IoIosArrowUp color='#ec671b' style={{ cursor: 'pointer' }} size={25} onClick={() => {
                                                                setReadingGoals('')
                                                            }} />}
                                                    </div>
                                                    {readingGoals === v?.titleEnglish ?
                                                        <div>
                                                            <br />
                                                            <pre style={{ width: '100%', margin: '0px', padding: '0px', lineBreak: 'anywhere', color: 'black' }}>
                                                                {Sl_lan === 'netherlands' ? v?.DiscriptionDutch
                                                                    : Sl_lan === 'italy' ? v?.DiscriptionItaly
                                                                        : Sl_lan === 'spain' ? v?.DiscriptionSpain
                                                                            : Sl_lan === 'egypt' ? v?.DiscriptionEgypt
                                                                                : Sl_lan === 'russain' ? v?.DiscriptionRussain
                                                                                    : Sl_lan === 'german' ? v?.DiscriptionGerman
                                                                                        : v?.DiscriptionEnglish}
                                                            </pre>
                                                        </div> : null}
                                                </div>
                                            </>
                                        )
                                    })
                                    }
                                </div>
                            </div>
                        }

                        {faqSettingData?.FaqArrayGrourp?.Tournaments?.length &&
                            <div>
                                <div className='height-35' style={{ width: '100%', marginTop: '35px' }} >
                                    <p className='screen-heading' style={{ textAlign: 'center', color: 'black' }}>{selectedLanguage?.title_Tournaments}</p>
                                </div>
                                <div className='divFaqDiv' style={{ marginTop: '35px' }}>
                                    {faqSettingData?.FaqArrayGrourp?.Tournaments?.map((v, i) => {
                                        return (
                                            <>
                                                <div
                                                    key={1 + i}
                                                    style={{
                                                        padding: '20px',
                                                        width: '100%',
                                                        marginTop: '20px',
                                                        boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2)',
                                                        borderRadius: '8px',
                                                        paddingTop: '30px',
                                                        background: '#d7d7d761'
                                                    }}>
                                                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                                                        <p style={{ width: '90%', margin: '0px', padding: '0px', lineBreak: 'anywhere', color: 'black' }}>
                                                            {Sl_lan === 'netherlands' ? v?.titleDutch
                                                                : Sl_lan === 'italy' ? v?.titleItaly
                                                                    : Sl_lan === 'spain' ? v?.titleSpain
                                                                        : Sl_lan === 'egypt' ? v?.titleEgypt
                                                                            : Sl_lan === 'russain' ? v?.titleRussain
                                                                                : Sl_lan === 'german' ? v?.titleGerman
                                                                                    : v?.titleEnglish}
                                                        </p>
                                                        <FaEdit size={20} color='#EC671B' onClick={() => setEditFaq(v)} />
                                                        <Popconfirm
                                                            title={selectedLanguage?.faq_AreYouSureYouDeletethisfaq}
                                                            okText={selectedLanguage?.textDelete}
                                                            cancelText={selectedLanguage?.textCancel}
                                                            okButtonProps={{
                                                                type: 'primary',
                                                                className: 'form-button'
                                                            }}
                                                            cancelButtonProps={{ style: { borderRadius: 4 } }}
                                                            onConfirm={() => {
                                                                deleteFaq(v?._id)
                                                            }}
                                                        >
                                                            {loading[v?._id] ? <Spin /> : <AiFillDelete size={20} color='#EC671B' />}
                                                        </Popconfirm>
                                                        {readingTournaments !== v?.titleEnglish ?
                                                            <IoIosArrowDown color='#ec671b' style={{ cursor: 'pointer' }} size={25} onClick={() => {
                                                                setReadingTournaments(v?.titleEnglish)
                                                            }} /> :
                                                            <IoIosArrowUp color='#ec671b' style={{ cursor: 'pointer' }} size={25} onClick={() => {
                                                                setReadingTournaments('')
                                                            }} />}
                                                    </div>
                                                    {readingTournaments === v?.titleEnglish ?
                                                        <div>
                                                            <br />
                                                            <pre style={{ width: '100%', margin: '0px', padding: '0px', lineBreak: 'anywhere', color: 'black' }}>
                                                                {Sl_lan === 'netherlands' ? v?.DiscriptionDutch
                                                                    : Sl_lan === 'italy' ? v?.DiscriptionItaly
                                                                        : Sl_lan === 'spain' ? v?.DiscriptionSpain
                                                                            : Sl_lan === 'egypt' ? v?.DiscriptionEgypt
                                                                                : Sl_lan === 'russain' ? v?.DiscriptionRussain
                                                                                    : Sl_lan === 'german' ? v?.DiscriptionGerman
                                                                                        : v?.DiscriptionEnglish}
                                                            </pre>
                                                        </div> : null}
                                                </div>
                                            </>
                                        )
                                    })
                                    }
                                </div>
                            </div>
                        }
                    </div >
                </div >
                :
                <>
                    <AddFaq
                        {...props}
                        editData={editFaq}
                        handleOK={() => setEditFaq({})}
                        getFun={() => { getFaqHelper(faqSettingAction) }}
                    />
                </>
            }
        </>
    )
}

export default AdminFaq