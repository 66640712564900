import { CloseCircleOutlined } from '@ant-design/icons'
import { Button, Input, Modal, Typography, InputNumber } from 'antd'
import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { POST } from '../../../utils/apis'
import { assignMessage, convertRoundNumber, errorMessage, getPointSetting, getUserData, successMessage } from '../../../utils/helpers'
import { useSelector } from 'react-redux'
import { currencySign } from '../../../utils/constants'
const { Title } = Typography

const AddCashback = (props) => {
    const { user, userActions, reRender, getCashBackForm, selectedLanguage, language, getUser, pointSettingAction } = props
    const AdminServiceFee = useSelector(state => state?.pointSettingReducer)
    const cashBackServiceFee = AdminServiceFee?.cashbackServiceFee
    let isUserIsValidForCashback = user?.walletBalance > cashBackServiceFee ? true : false
    const [visible, setVisible] = useState(false)
    const [confirmLoading, setConfirmLoading] = useState(false)
    const [loading, setLoading] = useState(false)
    const [image, setImage] = useState({})
    const [amount, setAmount] = useState('')
    const [isModalVisible, setIsModalVisible] = useState(false)
    const wallet = user?.walletBalance

    useEffect(() => {
        getUserData(user?._id, userActions)
        getUser()
        getPointSetting(pointSettingAction)
    }, [])

    const showModal = () => {
        setVisible(true)
    }

    const handleOk = () => {
        setConfirmLoading(true)
        let obj = {
            playerId: user?._id,
            amount: Number(amount),
            serviceCharge: cashBackServiceFee
        }

        setLoading(true)
        if (Number(amount) < 0) {
            setLoading(false)
            errorMessage(selectedLanguage?.txt_the_amount_not_be_a_negitive)
            return true
        }
        if (Number(amount) > wallet) {
            setLoading(false)
            errorMessage(selectedLanguage?.backendMsg_InsufficientBalanceinYourwallet)
            return true
        } else if ((Number(amount) + cashBackServiceFee) > wallet) {
            setLoading(false)
            errorMessage(`${selectedLanguage?.text_Themaximumamountyoucanwithdrawis} ${currencySign} ${(wallet - cashBackServiceFee)?.toFixed(2)}`)
            return true
        } else {
            setLoading(true)
            axios.post(POST.CASH_BACK_REQUEST, obj)
                .then((res) => {
                    const { data } = res
                    setLoading(false)
                    if (data.success) {
                        getCashBackForm()
                        successMessage(assignMessage(data?.message, language?.language))
                        setVisible(false)
                    }
                    else {
                        errorMessage(assignMessage(data?.message, language?.language))
                    }
                })
                .catch((e) => {
                    setVisible(false)
                    setLoading(false)
                    errorMessage(assignMessage(e?.message, language?.language))
                })
        }
        showModal1()
    }

    const handleCancel = () => {
        setVisible(false)
        showModal1()
    }

    const showModal1 = () => {
        setIsModalVisible(true)
    }

    const handleCancel1 = () => {
        setImage({})
    }

    return (
        <div>
            <Button type='primary' onClick={showModal} >
                {selectedLanguage?.text_CashbackRequest}
            </Button>

            <Modal
                open={visible}
                onOk={handleOk}
                confirmLoading={confirmLoading}
                onCancel={handleCancel}
                closable={false}
                className='pop-info-modal'
                footer={null}
            >
                <p className='p-question'>{selectedLanguage?.title_CashBackFrom}</p>
                {isUserIsValidForCashback ?
                    <div style={{ display: 'flex', flexDirection: 'column' }} className='form-div-in-modal'>
                        <h4 style={{ color: 'white' }}>{selectedLanguage?.text_EnteryourCashBackAmount}</h4>
                        <Input
                            precision={2}
                            min={0}
                            type='number'
                            pattern='[0-9]*'
                            className='form-input-new-design-big-slam input12345'
                            style={{ width: '100%', height: '45px' }}
                            autoFocus
                            required
                            value={amount}
                            onChange={(e) => setAmount(e?.target?.value)}
                            placeholder='e.g: 145'
                            step={1}
                            maxLength={11}
                            prefix={<p style={{ color: 'white', margin: '0px' }}>{currencySign}</p>}
                        />
                        <p style={{ color: 'white' }}>{selectedLanguage?.text_Themaximumamountyoucanwithdrawis} {currencySign} {convertRoundNumber(wallet - cashBackServiceFee)}, {selectedLanguage?.text_As} {currencySign} {convertRoundNumber(cashBackServiceFee)} {selectedLanguage?.text_isServiceCharges}</p>
                        <span style={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <Button key='back' onClick={handleCancel} >
                                {selectedLanguage?.btn_Cancel}
                            </Button>
                            &nbsp;
                            &nbsp;
                            <Button key='ok' type='primary' onClick={handleOk} loading={loading} > {selectedLanguage?.btn_ok} </Button>
                        </span>
                    </div>
                    :
                    <div style={{ padding: '15px' }}>
                        <p className='clc-insufficient-balance'>{selectedLanguage?.txt_Youhaveinsufficientbalanceinyourwallet}</p>
                        <p className='clc-balance-info'>{selectedLanguage?.txt_Walletamountmustmorethen1}</p>
                        <p className='clc-balance-info'>{selectedLanguage?.txt_sericceFeeFromAdmin}</p>
                    </div>
                }
            </Modal>
        </div>
    )
}

export default AddCashback