import { Button, Popconfirm, Table } from 'antd'
import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { POST } from '../../../utils/apis'
import { assignMessage, convertTitle, errorMessage, getUserData, successMessage } from '../../../utils/helpers'
import AddContact from './AddContact'


const PlayerContact = (props) => {
    const { selectedLanguage, language, user, userActions } = props
    const playerContacts = useSelector(state => state?.userReducer?.playerContacts)
    const [visible, setVisible] = useState(false)
    const [deleteLoading, setDeleteLoading] = useState({})

    useEffect(() => {
        getUserData(user?._id, userActions)
    }, [])

    const deleteConatact = (id) => {
        setDeleteLoading({ [id]: true })
        let obj = { _id: id }
        axios.post(POST.DELETE_CONTACT, obj)
            .then((res) => {
                const { data } = res
                setDeleteLoading({})
                if (data?.success) {
                    successMessage(assignMessage(data?.message, language?.language))
                    getUserData(user?._id, userActions)
                }
                else {
                    errorMessage(assignMessage(data?.message, language?.language))
                }
            })
            .catch((e) => {
                setDeleteLoading({})
                errorMessage(assignMessage(e?.message, language?.language))
            })
    }

    const handleOk = () => {
        getUserData(user?._id, userActions)
        setVisible(false)
    }

    const columns = [
        {
            title: selectedLanguage?.label_Email,
            dataIndex: 'contactId',
            key: 'email',
            render: (e) => {
                return (
                    <p>{e?.email}</p>
                )
            }
        },
        {
            title: selectedLanguage?.title_Name,
            dataIndex: 'contactId',
            key: 'name',
            render: (e) => {
                return (
                    <p>{e?.name}</p>
                )
            }
        },
        {
            title: selectedLanguage?.label_Country,
            dataIndex: 'contactId',
            key: 'country',
            render: (e) => {
                return (
                    <p>{e?.country}</p>
                )
            }
            
        },
        {
            title: selectedLanguage?.title_Action,
            key: 'action',
            render: (e) => {
                return (
                    <Popconfirm
                        title={`${selectedLanguage?.textDelete} ${convertTitle(`${e?.contactId?.name} `)} ${selectedLanguage?.text_confirm_Contact} ?`}
                        okText={selectedLanguage?.textDelete}
                        cancelText={selectedLanguage?.textCancel}
                        okButtonProps={{
                            type: 'primary',
                            className: 'form-button'
                        }}
                        cancelButtonProps={{ style: { borderRadius: 4 } }}
                        onConfirm={() => {
                            deleteConatact(e?._id)
                        }}
                    >
                        <Button
                            type='primary'
                            loading={deleteLoading[e?._id]}
                        // className='addproduct-text'
                        //  style={{ width: '80px' }}
                        >
                            {selectedLanguage?.buttonText_Delete}
                        </Button>
                    </Popconfirm>
                )
            }
        }
    ]

    return (
        <div className='new-main-component-screen-div'>
            <h1 className='new-screen-title' >
                {selectedLanguage?.title_Contacts}
            </h1>
            <span style={{ marginTop: '20px' }} className='button-div-flex-end'>
                <Button
                    type='primary'
                    onClick={() => setVisible(true)}
                >
                    {selectedLanguage?.title_AddnewContact}
                </Button>
            </span>
            <div className="custom-table-container">
                <Table
                    locale={{ emptyText: selectedLanguage?.txt_NoDataFound }}
                    className='new-layout-table'
                    dataSource={
                        playerContacts?.sort((a, b) => {
                            if (a?.contactId?.email === undefined || a?.contactId?.email === null) {
                                return 1
                            }
                            if (b?.contactId?.email === undefined || b?.contactId?.email === null) {
                                return -1
                            }
                            return a?.contactId?.email.localeCompare(b?.contactId?.email);
                        })
                    }
                    columns={columns}
                    pagination={false}
                    scroll={{ x: 400 }}
                />
            </div>

            {
                visible &&
                <AddContact playerContact={playerContacts} visible={visible} handleOk={handleOk} {...props} />

            }
        </div>
    )
}

export default PlayerContact