import { Table } from 'antd'
import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { GET } from '../../../utils/apis'
import { adjustDataForProRank, assignMessage, convertNumber, errorMessage } from '../../../utils/helpers'
import NoData from '../../../assets/noDataImae.png'
import { AiOutlineArrowUp, AiOutlineArrowDown } from "react-icons/ai"
import { TbEqual } from "react-icons/tb"

const AllProRankingPiont = (props) => {
    const { selectedLanguage, language, selectedGame, user } = props
    const [loading, setLoading] = useState(false)
    const [gameRanks, setGameRanks] = useState([])

    const getGameRanks = () => {
        setLoading(true)
        axios.get(`${GET?.GET_GAME_RANKS}/${selectedGame}`,)
            .then((res) => {
                const { data } = res
                setLoading(false)
                if (data?.success) {
                    setGameRanks(adjustDataForProRank(data?.data) || [])
                }
                else {
                    setGameRanks([])
                }
            })
            .catch((e) => {
                setLoading(false)
                errorMessage(assignMessage(e?.message, language?.language))
            })
    }

    useEffect(() => {
        getGameRanks()
    }, [selectedGame])




    const columns = [
        {
            title: selectedLanguage?.title_PlayerName,
            key: 'name',
            render: (e) => {
                return (
                    <p>
                        {e?.userId?._id === user?._id ? e?.userId?.name + (selectedLanguage?.text_me) : e?.userId?.name}
                    </p>
                )
            }
        },
        {
            title: selectedLanguage?.tableText_Ranking,
            key: 'ranking',
            render: (e) => {
                return (
                    <p>
                        {e?.rank}
                    </p>
                )
            }
        },

        {
            title: `${selectedLanguage?.tableText_Points}-($)`,
            key: 'proPoint',
            render: (e) => {
                return (
                        <p> {convertNumber(e?.proPoint)} </p>
                )
            }
        },
        {
            title: selectedLanguage?.tableText_WeeklyResult,
            key: 'result',
            render: (e) => {
                return (
                    <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
                        {!e?.weaklyProPointReport?.length || e?.weaklyProPointReport?.[0]?.difference === 0 ?
                            <TbEqual color='black' size={22} style={{
                                cursor: 'pointer',
                                background: 'white',
                                margin: '10px',
                                height: '30px',
                                width: '30px',
                                borderRadius: '20px',
                                padding: '4px'
                            }} />
                            :
                            e?.proPointUp ?
                                <AiOutlineArrowUp color='green' size={22} style={{
                                    cursor: 'pointer',
                                    background: 'white',
                                    margin: '10px',
                                    height: '30px',
                                    width: '30px',
                                    borderRadius: '20px',
                                    padding: '4px'
                                }} /> :
                                <AiOutlineArrowDown color='red' size={22} style={{
                                    cursor: 'pointer',
                                    background: 'white',
                                    margin: '10px',
                                    height: '30px',
                                    width: '30px',
                                    borderRadius: '20px',
                                    padding: '4px'
                                }} />}
                        <p style={{ margin: '16px' }}>
                            {e?.weaklyProPointReport?.[0]?.difference || 0}
                        </p>
                    </div>
                )
            }
        },
    ]

    return (
        <div className="custom-table-container">
            <Table
                locale={{ emptyText: selectedLanguage?.txt_NoDataFound }}
                className='new-layout-table'
                dataSource={gameRanks}
                columns={columns}
                pagination={false}
                loading={loading}
                scroll={{ x: 1000 }}
            />
        </div>
    )
}

export default AllProRankingPiont

