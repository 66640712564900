import { CASHBACK_SERVICE_FEE, COMPETITION_SUB_SERVICE_FEE, DOLLER_MATCH_REQ_SEND_FEE, DOLLER_TOURNAMENT_ORGANIZER, DOLLER_TOURNAMENT_SUB_FEE } from '../types'

const reducer = (state = {}, action) => {
    switch (action.type) {

        case DOLLER_MATCH_REQ_SEND_FEE: {
            return { ...state, dollerMatchReqSendFee: action.dollerMatchReqSendFee }
        }
        case DOLLER_TOURNAMENT_SUB_FEE: {
            return { ...state, dollerTournamentSubscriptionFee: action.dollerTournamentSubscriptionFee }
        }
        case DOLLER_TOURNAMENT_ORGANIZER: {
            return { ...state, dollerTournamentOrganizerFee: action.dollerTournamentOrganizerFee }
        }
        case CASHBACK_SERVICE_FEE: {
            return { ...state, cashbackServiceFee: action.cashbackServiceFee }
        }
        case COMPETITION_SUB_SERVICE_FEE: {
            return { ...state, competitionSubServiceFee: action.competitionSubServiceFee }
        }
        default: {
            return state
        }
    }
}

export default reducer