import { Button, DatePicker, Form, Select, TimePicker } from 'antd'
import React, { useEffect, useState } from 'react'
import { dateFormat } from '../../../utils/constants'
import moment from 'moment'
import { assignMessage, disabledDate, errorMessage, getGeneralData, getUserData, successMessage } from '../../../utils/helpers'
import { useSelector } from 'react-redux'
import axios from 'axios'
import { POST } from '../../../utils/apis'

const { Option } = Select
const CreateCompetitionMatchRequest = (props) => {
    const { selectedLanguage, user, language, userActions, } = props
    const subscribedCompetitions = useSelector(state => state.userReducer.subscribedCompetitions)
    const [form] = Form.useForm()
    const format = 'HH:mm'
    const [loading, setLoading] = useState(false)
    const [selectedCompetition, setSelectedCompetition] = useState(null)
    const [date, setDate] = useState(null)
    const [time, setTime] = useState(null)
    useEffect(() => {
        getUserData(user?._id, userActions)
    }, [])

    const onChangeDate = (date) => {
        setDate(moment(date).format(dateFormat))
    }

    const onChangeTime = (date) => {
        setTime(moment(date).format(format))
    }

    const onFinish = (fieldsValue) => {

        const values = {
            ...fieldsValue,
            matchDate: fieldsValue['matchDate'].format('YYYY-MM-DD'),
            matchTime: fieldsValue['matchTime'].format('hh:mm'),
            userId: user?._id
        }

        setLoading(true)
        axios.post(POST.CREATE_COMPETITION_MATCH, values)
            .then((res) => {
                const { data } = res
                setLoading(false)
                if (data?.success) {
                    successMessage(assignMessage(data?.message, language?.language))
                }
                else {
                    errorMessage(assignMessage(data?.message, language?.language))
                }
            }).catch((e) => {
                setLoading(false)
                console.log(e)
                errorMessage(assignMessage(e?.message, language?.language))
            })
    }
    const competitionChange = (e) => {
        let competitionSelected = subscribedCompetitions?.find((v) => v?._id === e)
        setSelectedCompetition(competitionSelected)

        form.setFieldsValue({
            matchDate: null
        })
    }

    const disabledDate = (current) => {

        let competitionDate1 = moment(selectedCompetition?.competitionDuration?.date1, 'YYYY-MM-DD')
        let competitionDate2 = moment(selectedCompetition?.competitionDuration?.date2, 'YYYY-MM-DD').add(1, 'days')
        if (!selectedCompetition) {
            return current && current.isBefore(moment(), 'day')
        } else {
            return current && current.isBefore(moment(), 'day') || current.isBefore(competitionDate1, 'day') || !current.isBefore(competitionDate2, 'day')
        }
    }

    return (
        <div className='new-main-component-screen-div' style={{ background: 'black' }}>
            <h1 className='new-screen-title orang-color' >
                {selectedLanguage?.text_Create_matchRequest}
            </h1>
            <div style={{ marginTop: '20px' }}>
                <Form
                    name='form'
                    form={form}
                    onFinish={onFinish}
                    layout={'vertical'}
                >
                    <div className='form-two-input-flex'>
                        <div className='form-two-input-flex-inner-input'>
                            <Form.Item
                                name='competitionId'
                                hasFeedback
                                label={<p className='p-admin-title-text'>{selectedLanguage?.text_Selectcompetitionname}</p>}
                                rules={[
                                    {
                                        required: true,
                                        message: selectedLanguage?.text_Pleaseselectcompetitionname
                                    }
                                ]}
                            >
                                <Select
                                    onChange={competitionChange}
                                    notFoundContent={selectedLanguage?.txt_NoDataFound}
                                    className='form-input-new-design-big-slam'
                                    placeholder={selectedLanguage?.placeHolder_SelectGameforCompetition}>
                                    {subscribedCompetitions?.map((v, i) => {
                                        return (
                                            <Option value={v?._id}>{`${v?.competitionName} ${v?.gameId?.name ? `| ${v?.gameId?.name}` : ''}`}</Option>
                                        )
                                    })}
                                </Select>
                            </Form.Item>
                        </div>
                    </div>

                    <div className='form-two-input-flex'>
                        <div className='form-two-input-flex-inner-input'>
                            <Form.Item
                                name='matchDate'
                                hasFeedback
                                label={<p className='p-admin-title-text'>{selectedLanguage?.label_Gamedate}</p>}
                                rules={[
                                    {
                                        required: true,
                                        message: selectedLanguage?.error_pleaseselectgamedate
                                    }
                                ]}
                            >
                                <DatePicker
                                    disabled={!selectedCompetition}
                                    onChange={onChangeDate}
                                    disabledDate={disabledDate}
                                    className='form-input-new-design-big-slam'
                                    placeholder={selectedLanguage?.placeHolder_selectgamedate}
                                    style={{ width: '100%' }}
                                    allowClear={false}
                                    format={dateFormat}
                                />
                            </Form.Item>
                        </div>
                    </div>

                    <div className='form-two-input-flex'>
                        <div className='form-two-input-flex-inner-input'>
                            <Form.Item
                                name='matchTime'
                                hasFeedback
                                label={<p className='p-admin-title-text'>{selectedLanguage?.label_gametime}</p>}
                                rules={[
                                    {
                                        required: true,
                                        message: selectedLanguage?.error_pleaseselectgametime
                                    }
                                ]}
                            >
                                <TimePicker
                                    disabled={!selectedCompetition}
                                    onChange={onChangeTime}
                                    minuteStep={15}
                                    style={{ width: '100%', }}
                                    className='form-input-new-design-big-slam'
                                    placeholder={selectedLanguage?.plaecholder_selectgametime}
                                    format={format} />
                            </Form.Item>
                        </div>
                    </div>

                    <Form.Item>
                        <Button
                            type='primary'
                            htmlType='submit'
                            style={{ marginLeft: 5 }}
                            loading={loading}
                        >
                            {selectedLanguage?.btn_SendMatchRequest_send}
                        </Button>
                    </Form.Item>

                </Form>
            </div>
        </div>
    )
}

export default CreateCompetitionMatchRequest