import { CASHBACK_SERVICE_FEE, COMPETITION_SUB_SERVICE_FEE, DOLLER_MATCH_REQ_SEND_FEE, DOLLER_TOURNAMENT_ORGANIZER, DOLLER_TOURNAMENT_SUB_FEE } from '../types'

const AddMatchReqSendFee = (dollerMatchReqSendFee) => {
    return {
        type: DOLLER_MATCH_REQ_SEND_FEE,
        dollerMatchReqSendFee
    }
}
const AddSubscriptionFeeDollerTournamnet = (dollerTournamentSubscriptionFee) => {
    return {
        type: DOLLER_TOURNAMENT_SUB_FEE,
        dollerTournamentSubscriptionFee
    }
}
const AddDollerTournamnetOrganizer = (dollerTournamentOrganizerFee) => {
    return {
        type: DOLLER_TOURNAMENT_ORGANIZER,
        dollerTournamentOrganizerFee
    }
}
const AddCashbackServiceFee = (cashbackServiceFee) => {
    return {
        type: CASHBACK_SERVICE_FEE,
        cashbackServiceFee
    }
}
const AddCompetitionSubServiceFee = (competitionSubServiceFee) => {
    return {
        type: COMPETITION_SUB_SERVICE_FEE,
        competitionSubServiceFee
    }
}


export {
    AddMatchReqSendFee,
    AddSubscriptionFeeDollerTournamnet,
    AddDollerTournamnetOrganizer,
    AddCashbackServiceFee,
    AddCompetitionSubServiceFee
}