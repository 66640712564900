import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Redirect, useHistory, useLocation, Route } from 'react-router-dom' 
import { addAllGames, addAllPromoter, adminTournament, allCompetitions } from '../../Redux/actions/generalActions'
import { addAllGameSettings, addAllGameSuggestions, addAllPlayer, addAllPlayerContacts, addAllUserTournaments, addUserRanks, addUserProRanks, addAllTournaments, addAllTournamentMatches, addAllSentMatches, addAllReceiveMatches, addAllMatchResults, addWalletTransaction, addCompetitionRanks, addAllSentCompetitionMatches, addAllReceiveCompetitionMatches, addAllCompetitionMatches, addSubscribedCompetitions } from '../../Redux/actions/userAction'
import axios from 'axios'
import { GET } from '../../utils/apis'
import { loginUser, removeUser } from '../../Redux/actions/authActions'
import { AddCashbackServiceFee, AddCompetitionSubServiceFee, AddDollerTournamnetOrganizer, AddMatchReqSendFee, AddSubscriptionFeeDollerTournamnet } from '../../Redux/actions/pointSettingAction'
import { allPaths } from '../../utils/constants'
import { assignLanguage, get1_1_MatchData, getAllTournaments, getCompetitionMatches, getFaqHelper, getPointSetting, getTournamentMatchData, getUserData } from '../../utils/helpers'
import { AddFaqSetting } from '../../Redux/actions/faqSettingActions'
import NewHeader from '../NewHeader/Header'
import NewFooter from '../NewFooter/NewFooter'
import TextAnimationsComponent from '../TextAnimations'


const NewMenuLayout = ({ component: Component, ...rest }) => {
    const history = useHistory()
    const location = useLocation();
    const CurrnetPath = location?.pathname
    const isAdminScreen = CurrnetPath?.includes('admin')
    const dispatch = useDispatch()
    const pointsSettingData = useSelector(state => state?.pointSettingReducer)
    const user = useSelector(state => state.authReducer.user)
    const MenuNone = useSelector(state => state.menuNoneReducer.menuNone)
    const language = useSelector(state => state?.languageReducer)
    const selectedLanguage = assignLanguage(language?.language)


    const selectedLanguageUser = user?.userType === 'player' && assignLanguage(user?.languageSetting)

    const isEnglish = language?.language === 'english'


    useEffect(() => {
        CurrnetPath !== '/' && CurrnetPath !== '/tournament/participation' && CurrnetPath !== '/competition/result' && isAdminScreen && user?.userType === "player" && dispatch(removeUser())
        CurrnetPath !== '/' && CurrnetPath !== '/tournament/participation' && CurrnetPath !== '/competition/result' && !isAdminScreen && user?.userType !== "player" && dispatch(removeUser())
    }, [CurrnetPath])


    useEffect(() => {
        user?._id && getUserData(user?._id, userActions)
        user?._id && getAllTournaments(user?._id, userActions)
        user?._id && getTournamentMatchData(user?._id, userActions)
        user?._id && get1_1_MatchData(user?._id, userActions)
        user?._id && getPointSetting(pointSettingAction)
        user?._id && getFaqHelper(faqSettingAction)
        user?._id && getCompetitionMatches(user?._id, userActions)
    }, [])

    const faqSettingAction = {
        AddFaqSetting: (e) => dispatch(AddFaqSetting(e)),
    }

    const generalActions = {
        addAllGames: (e) => dispatch(addAllGames(e)),
        addAllPromoter: (e) => dispatch(addAllPromoter(e)),
        adminTournament: (e) => dispatch(adminTournament(e)),
        allCompetitions: (e) => dispatch(allCompetitions(e)),
    }

    const userActions = {
        addAllGameSettings: (e) => dispatch(addAllGameSettings(e)),
        addAllPlayerContacts: (e) => dispatch(addAllPlayerContacts(e)),
        addAllPlayer: (e) => dispatch(addAllPlayer(e)),
        addAllGameSuggestions: (e) => dispatch(addAllGameSuggestions(e)),
        addAllUserTournaments: (e) => dispatch(addAllUserTournaments(e)),
        addUserRanks: (e) => dispatch(addUserRanks(e)),
        addAllTournaments: (e) => dispatch(addAllTournaments(e)),
        addAllTournamentMatches: (e) => dispatch(addAllTournamentMatches(e)),
        addAllSentMatches: (e) => dispatch(addAllSentMatches(e)),
        addAllReceiveMatches: (e) => dispatch(addAllReceiveMatches(e)),
        addAllMatchResults: (e) => dispatch(addAllMatchResults(e)),
        addWalletTransaction: (e) => dispatch(addWalletTransaction(e)),
        addUserProRanks: (e) => dispatch(addUserProRanks(e)),
        addCompetitionRanks: (e) => dispatch(addCompetitionRanks(e)),
        addAllSentCompetitionMatches: (e) => dispatch(addAllSentCompetitionMatches(e)),
        addAllReceiveCompetitionMatches: (e) => dispatch(addAllReceiveCompetitionMatches(e)),
        addAllCompetitionMatches: (e) => dispatch(addAllCompetitionMatches(e)),
        addSubscribedCompetitions: (e) => dispatch(addSubscribedCompetitions(e))
    }

    const pointSettingAction = {
        AddMatchReqSendFee: (e) => dispatch(AddMatchReqSendFee(e)),
        AddSubscriptionFeeDollerTournamnet: (e) => dispatch(AddSubscriptionFeeDollerTournamnet(e)),
        AddDollerTournamnetOrganizer: (e) => dispatch(AddDollerTournamnetOrganizer(e)),
        AddCashbackServiceFee: (e) => dispatch(AddCashbackServiceFee(e)),
        AddCompetitionSubServiceFee: (e) => dispatch(AddCompetitionSubServiceFee(e))

    }

    if (!user) {
        return (
            <Redirect to={allPaths?.VISITER_HOME} />
        )
    }

    return (
        <Route {...rest} render={props => <AddMenu
            {...props}
            user={user}
            MenuNone={MenuNone}
            language={user?.userType === 'player' ? { language: user?.languageSetting } : language}
            isEnglish={isEnglish}
            component={Component}
            userActions={userActions}
            generalActions={generalActions}
            selectedLanguage={user?.userType === 'player' ? selectedLanguageUser : selectedLanguage}
            faqSettingAction={faqSettingAction}
            pointsSettingData={pointsSettingData}
            pointSettingAction={pointSettingAction}
        />}
        />
    )
}

const AddMenu = ({ component: Component, ...props }) => {
    const { user, history, location } = props
    const dispatch = useDispatch()

    const language = useSelector(state => state?.languageReducer)
    const selectedLanguage = assignLanguage(language?.language)

    const selectedLanguageUser = user?.userType === 'player' && assignLanguage(user?.languageSetting)



    if (!user) {
        return (
            <Redirect to={allPaths?.VISITER_HOME} />
        )
    }

    const getUser = () => {
        axios.get(`${GET?.USER_BY_ID}/${user._id}`)
            .then((res) => {
                const { data } = res
                if (data.success) {
                    dispatch(loginUser(data.user))
                }
                else {
                    history?.replace(allPaths?.VISITER_HOME)
                    dispatch(removeUser())
                }
            })
            .catch((e) => {
                dispatch(removeUser())
            })
    }



    return (
        <div className='new-main-layout-div'>
            <div className='main-2nd-div-new-layout sidebar-scroll_class'>
                <NewHeader user={user} selectedLanguage={user?.userType === 'player' ? selectedLanguageUser : selectedLanguage}/>
                <TextAnimationsComponent selectedLanguage={user?.userType === 'player' ? selectedLanguageUser : selectedLanguage} />
                <div >
                    <Component {...props} getUser={getUser} user={user} history={history} />
                </div>
                <NewFooter selectedLanguage={user?.userType === 'player' ? selectedLanguageUser : selectedLanguage} />
            </div>
        </div>
    )
}

export default NewMenuLayout 