import React, { useEffect, useState } from 'react';
import imgForDeck from '../../assets/WGP-white.png'
import imgForMobile from '../../assets/new-logowhite.png'
import { FaFacebookF, FaHome, FaInstagram, FaQuestion, FaTwitter } from 'react-icons/fa';
import { Menu } from 'antd';
import ReactFlagsSelect from 'react-flags-select';
import { IoIosArrowDown } from 'react-icons/io';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { setLanguage } from '../../Redux/actions/languageAction';
import { allPaths, drawerRoutesUser } from '../../utils/constants';
import { TfiMenu } from 'react-icons/tfi';
import LoginAlert from './LoginAlert';


const NewVisiterHeader = (props) => {
    const { selectedLanguage } = props
    const { SubMenu } = Menu
    const dispatch = useDispatch()
    const history = useHistory()
    const [selected, setSelected] = useState('GB')
    const [modal, setModal] = useState(false);
    const language = useSelector(state => state?.languageReducer)
    const openModalForLogin = () => {
        setModal(true)
    }
    let hideTabs = [allPaths?.HOME, allPaths?.SIDE_MENU_FAQ]
    let SL_lang = language?.language

    useEffect(() => {
        language?.language === 'netherlands' ?
            setSelected('NL') :
            language?.language === 'italy' ?
                setSelected('IT') :
                language?.language === 'german' ?
                    setSelected('DE') :
                    language?.language === 'spain' ?
                        setSelected('ES') :
                        language?.language === 'egypt' ?
                            setSelected('EG') :
                            language?.language === 'russain' ?
                                setSelected('RU') :
                                setSelected('GB')
    })


    const onchangeLanguage = (v) => {
        setSelected(v)
        if (v === 'NL') {
            dispatch(setLanguage('netherlands'))
        }
        else if (v === 'IT') {
            dispatch(setLanguage('italy'))
        }
        else if (v === 'DE') {
            dispatch(setLanguage('german'))
        }
        else if (v === 'ES') {
            dispatch(setLanguage('spain'))
        }
        else if (v === 'EG') {
            dispatch(setLanguage('egypt'))
        }
        else if (v === 'RU') {
            dispatch(setLanguage('russain'))
        }
        else {
            dispatch(setLanguage('english'))
        }
    }

    const currentPage = window?.location?.pathname

    return (
        <div>
            <div className="new-small-upper-header">
                <div className="new-small-upper-header-inner">
                    <span className="name">
                        {'WGPPLAZA.COM'}
                    </span>
                    <span className="last-div">
                        <p className='uppr-icon-main'>
                            <span className='upper-header-icon'><FaFacebookF size={18} /></span>
                            <span className='upper-header-icon'><FaTwitter size={18} /></span>
                            <span className='upper-header-icon'><FaInstagram size={18} /></span>
                        </p>
                        <span className='round-lang-upper-header-icon'>
                            <ReactFlagsSelect
                                className='round-countries-select-update'
                                fullWidth={false}
                                showOptionLabel={false}
                                showSelectedLabel={false}
                                countries={['GB', 'NL', 'IT', 'ES', 'EG', 'RU', 'DE']}
                                selected={selected}
                                onSelect={(v) => onchangeLanguage(v)}
                            />
                        </span>
                        <p className='uppr-icon-main' style={{ display: 'inline' }}>
                            <span className='upper-header-icon'
                                onClick={() => history?.push(currentPage === allPaths?.VISITER_HOME ? allPaths?.FAQ : allPaths?.VISITER_HOME)}
                            >
                                {currentPage === allPaths?.VISITER_HOME ? <FaQuestion size={18} /> : <FaHome size={18} />}
                            </span>
                        </p>

                    </span>
                </div>
            </div>

            <div className="new-design-header">
                <div className="new-designer-inner">
                    <div className='new-main-header-inner-bottom' style={{ width: '100%' }}>
                        <div className='new-lower-nav'>
                            <div>
                                <img src={imgForDeck} className='new-header-img-desk' />
                                <img src={imgForMobile} className='new-header-img-mobile' />
                            </div>
                            <div className='lower-nav-left'>
                                <span className='new-menu-for-web' style={{ display: 'flex', width: '100%' }}>
                                    <Menu
                                        defaultSelectedKeys={[`${window?.location?.pathname}`]}
                                        mode='horizontal'
                                        className='me-new-top-menu'
                                    >
                                        <>
                                            {(drawerRoutesUser)?.map((v, i) => {
                                                if (hideTabs?.includes?.(v.route)) {
                                                    return null
                                                }
                                                return (
                                                    <SubMenu
                                                        key={v.route}
                                                        className="new-header-sub-menu"
                                                        title={
                                                            <span className="my-menu-txtt"
                                                                onClick={openModalForLogin}
                                                                style={{ color: 'white' }} >
                                                                {SL_lang === 'netherlands' ? v?.titleNl
                                                                    : SL_lang === 'italy' ? v?.titleItaly
                                                                        : SL_lang === 'spain' ? v?.titleSpain
                                                                            : SL_lang === 'egypt' ? v?.titleEgypt
                                                                                : SL_lang === 'russain' ? v?.titleRussain
                                                                                    : SL_lang === 'german' ? v?.titleGerman
                                                                                        : v?.titleEnglish}
                                                                {v?.isSubMenu && v?.children?.length &&
                                                                    <span style={{ paddingLeft: '5px', paddingTop: '3px' }}>
                                                                        {<IoIosArrowDown color={'white'} />}
                                                                    </span>}
                                                            </span>
                                                        }>
                                                    </SubMenu>
                                                );
                                            })}
                                        </>
                                    </Menu>
                                </span>
                                <span className="new-menu-for-mobile" onClick={openModalForLogin} >
                                    <div className="new-header-men-icon">
                                        <TfiMenu size={20} />
                                    </div>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <LoginAlert history={history} selectedLanguage={selectedLanguage} open={modal} onCancel={() => setModal(false)} {...props} />

            <div className='div-for-text-transition' style={{ height: 'auto', marginTop: '100px' }}>
                <h1 className="text-transition">

                    <div className='div-flex-colum'>
                        <span className='smaill-1st-heaader-text'>{selectedLanguage?.txt_MeettheRookee}</span>
                        <div className='margin-mins-20'>
                            <span className='smaill-2nd-orange-heaader-text' > {'Jhon'} </span> <span className='smaill-2nd-white-heaader-text'>{'Smith'} </span>
                        </div>
                        <span className='smaill-3rd-white-heaader-text'>{selectedLanguage?.txt_Most_Scroing_Player_2017}</span>
                    </div>
                </h1>
            </div>
            <div className='new-div-button'>
                <button className='home-snup-btn' onClick={() => history?.push(allPaths?.SIGNUP)}>{selectedLanguage?.txt_Register} ➝</button>
                <button className='home-login-btn' onClick={() => history?.push(allPaths?.LOGIN)}>{selectedLanguage?.txt_Login} ➝</button>
            </div>
            <br />
            <br />
            <br />
        </div>
    )
}

export default NewVisiterHeader