import React, { useState } from 'react'
import { Button, Modal, Spin } from 'antd'
import MyPdfView from './pdfView'

const ViewCard = (props) => {
    const { card, showData, onCancel, selectedLanguage } = props
    const [numPages, setNumPages] = useState(null)

    const onDocumentLoadSuccess = ({ numPages }) => {
        console.log(numPages, "numberpage")
        setNumPages(numPages)
    }


    return (
        <Modal
            open={showData}
            onCancel={onCancel}
            className='pop-info-modal'
            footer={null}
        >
            <MyPdfView pdfUrl={card?.fileUrl} />
        </Modal>
    )
}

export default ViewCard