import React, { useEffect, useState } from 'react'
import { UploadOutlined } from '@ant-design/icons'
import { Button, Form, Image, Modal, Radio, Upload } from 'antd'
import { assignMessage, errorMessage, successMessage } from '../../../utils/helpers'
import axios from 'axios'
import { ADMIN } from '../../../utils/apis'
import { currencySign } from '../../../utils/constants'

const ClaimDetailModal = (props) => {
    const { selectedLanguage, language, user, data, visible, handleOk, onCancel } = props
    const [loading, setLoading] = useState(false)
    const [allGamesSetting, setAllGamesSetting] = useState([])
    const [form] = Form.useForm()
    const senderNickName = allGamesSetting?.length && allGamesSetting.find((item) => item.playerId === data?.senderId?._id);
    const reciverNickName = allGamesSetting?.length && allGamesSetting.find((item) => item.playerId === data?.receiverId?._id);

    const getGameNames = () => {
        axios.get(`${ADMIN?.GET_GAME_DATA}/${data?.gameId?._id}`)
            .then((res) => {
                const { data } = res
                if (data?.success) {
                    setAllGamesSetting(data?.data || [])
                }
                else {
                    console.log('esle is runnig')
                }
            }).catch(() => {
                console.log('main erorr')
            })
    }
    console.log('data', data)

    const onFinish = (values) => {
        setLoading(true)
        let { winner } = values

        let obj
        if (winner === 'draw') {
            obj = {
                adminId: user?._id,
                _id: data?._id,
                winner: null,
                looser: null,
                gameId: data?.gameId?._id,
                points: data?.points,
                proPoints: data?.proPoints,
                money: data?.money,
                isStake: data?.isStake,
                isDraw: true,
                unClaimer: (data?.receiverId?._id !== winner) ? data?.receiverId?._id : data?.senderId?._id,
                drawData: data?.draw,
                draw: [data?.receiverId?._id, data?.senderId?._id],
                competition: data?.competition,
                receiverId: data?.receiverId?._id,
                senderId: data?.senderId?._id,
            }
        } else {
            obj = {
                adminId: user?._id,
                _id: data?._id,
                winner,
                looser: (data?.receiverId?._id !== winner) ? data?.receiverId?._id : data?.senderId?._id,
                gameId: data?.gameId?._id,
                points: data?.points,
                proPoints: data?.proPoints,
                money: data?.money,
                isStake: data?.isStake,
                isDraw: false,
                claimer: null,
                competition: data?.competition
            }
        }
        console.log(obj)

        axios.post(ADMIN?.RESOLVE_ARGUES, obj)
            .then((res) => {
                const { data } = res
                if (data?.success) {
                    setLoading(false)
                    successMessage(assignMessage(data?.message, language?.language))
                    onCancel()
                }
                else {
                    errorMessage(assignMessage(data?.message, language?.language))
                }
            })
            .catch((e) => {
                errorMessage(assignMessage(e?.message, language?.language))
                setLoading(false)
            })
    }

    const onChange = (val) => {
        console.log(val?.target?.value)
    }

    useEffect(() => {
        getGameNames()
    }, [])

    return (
        <Modal
            open={visible}
            onOk={handleOk}
            onCancel={handleOk}
            footer={null}>
            <div style={{ marginTop: '10px' }}>
                <span style={{ textAlign: 'center' }}>
                    <p className='admin-home-title' style={{ fontSize: '18px' }}>{
                        selectedLanguage?.text_DetailsOfMatchClaim
                    }</p>
                </span>
            </div>
            <div className='border-line' />
            <div style={{ marginBottom: '15px' }}>
                <p className='admin-home-title' style={{ fontSize: '18px', marginBottm: '15px' }}>{selectedLanguage?.text_ClaimerDetailAndProof}</p>
                <p className='p-none-margin'><p className='p-none-margin-bolder'>{selectedLanguage?.text_NickName}</p>: {data?.gameId?.name}</p>
                {!data?.winnerProof?.url ? <span style={{ textAlign: 'center' }}>
                    <p className='admin-home-title' style={{ fontSize: '18px' }}>
                        {selectedLanguage?.text_Draw}
                    </p>
                </span>
                    :
                    <>
                        <p className='p-none-margin-bolder'>{selectedLanguage?.text_Proof}</p>
                        <div style={{ textAlign: 'center', marginTop: '15px' }}>
                            <Image src={data?.winnerProof?.url} style={{ width: '100%', maxWidth: '280px' }} />
                        </div>
                    </>
                }
            </div>
            <div>
                <p className='admin-home-title' style={{ fontSize: '18px', marginBottm: '15px' }}>{selectedLanguage?.text_MatchDetail}</p>
                <p className='p-none-margin'> <p className='p-none-margin-bolder'>{selectedLanguage?.text_Match} </p>: {data?.gameId?.name}</p>
                <p className='p-none-margin'> <p className='p-none-margin-bolder'>{selectedLanguage?.text_MatchDateTime} </p>: {data?.matchDate} | {data?.matchTime}</p>
                <p className='p-none-margin'> <p className='p-none-margin-bolder'>{selectedLanguage?.text_MessagefromSender} </p>: {data?.message}</p>
                <p className='p-none-margin'> <p className='p-none-margin-bolder'>{data?.isStake ? selectedLanguage?.text_money : selectedLanguage?.text_Points} </p>: {data?.isStake ? `${currencySign} ${data?.money}` : data?.points}</p>
            </div>
            <div>
                <p className='admin-home-title' style={{ fontSize: '18px', marginBottm: '15px' }}>{selectedLanguage?.text_SenderDetail}</p>
                <p className='p-none-margin'> <p className='p-none-margin-bolder'>{selectedLanguage?.txt_Name} </p>: {data?.senderId?.name} </p>
                <p className='p-none-margin'> <p className='p-none-margin-bolder'>{selectedLanguage?.label_NickName} </p>: {senderNickName?.nickName || '----'} </p>
                <p className='p-none-margin'> <p className='p-none-margin-bolder'>{selectedLanguage?.txt_Email} </p>: {data?.senderId?.email}</p>
            </div>
            <div>
                <p className='admin-home-title' style={{ fontSize: '18px', marginBottm: '15px' }}>{selectedLanguage?.text_OponentDetail}</p>
                <p className='p-none-margin'> <p className='p-none-margin-bolder'>{selectedLanguage?.txt_Name} </p>: {data?.receiverId?.name} </p>
                <p className='p-none-margin'> <p className='p-none-margin-bolder'>{selectedLanguage?.label_NickName} </p>: {reciverNickName?.nickName || '----'} </p>
                <p className='p-none-margin'> <p className='p-none-margin-bolder'>{selectedLanguage?.txt_Email} </p>: {data?.receiverId?.email}</p>
            </div>
            <div className='border-line' />
            <p className='admin-home-title' style={{ fontSize: '18px', marginBottm: '15px' }}>{selectedLanguage?.text_decidedToLossOrWon}</p>
            <div style={{ marginTop: '20px' }}>
                <span style={{ textAlign: 'center' }}>
                    <p className='admin-home-title' style={{ fontSize: '18px' }}>{
                        selectedLanguage?.text_SelectWinner
                    }</p>
                </span>
                <Form
                    name='form'
                    form={form}
                    onFinish={onFinish}
                    layout={'vertical'}
                >
                    <div className='form-two-input-flex-inner-input' style={{ width: '100%' }}>
                        <Form.Item
                            name='winner'
                            // label={'Select Winner'}
                            hasFeedback
                            rules={[
                                {
                                    required: true,
                                    message: selectedLanguage?.textPleaseSelectWinnerOrDecidedToDrawIsMatch
                                }
                            ]}
                        >
                            <Radio.Group
                                onChange={onChange}
                                className='form-input-new-style'
                                style={{
                                    width: '100%',
                                    padding: '10px',
                                    display: 'flex',
                                    alignItems: 'center'
                                }}
                            >
                                <Radio value={data?.receiverId?._id}><p className='p-text-black'>{data?.receiverId?.name}</p></Radio>
                                <Radio value={data?.senderId?._id}><p className='p-text-black'>{data?.senderId?.name}</p></Radio>
                                <Radio value={'draw'}><p className='p-text-black'>{selectedLanguage?.text_DrawMatch}</p></Radio>
                            </Radio.Group>
                        </Form.Item>
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <Form.Item >
                            <Button
                                type='primary'
                                htmlType='submit'
                                loading={loading}
                            >
                                {selectedLanguage?.textbtn_ResolveArgue}
                            </Button>
                        </Form.Item>
                    </div>
                </Form>
            </div>

        </Modal>
    )
}

export default ClaimDetailModal