import { Image, Table } from 'antd'
import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { convertRoundNumber, errorMessage, getGeneralData } from "../../../utils/helpers"
import { GrContactInfo } from 'react-icons/gr'
import { allPaths, currencySign } from '../../../utils/constants'
import { useHistory } from 'react-router-dom'

const AdminCompetitionSelect = (props) => {
    const { selectedLanguage, user, generalActions, } = props
    const allCompetitions = useSelector(state => state.generalReducer.allCompetitions)
    const history = useHistory()

    useEffect(() => {
        getGeneralData(generalActions)
    }, [])

    const columns = [
        {
            title: selectedLanguage?.text_Image,
            render: (e) => {
                return (
                    <Image src={e?.imageObj?.url} style={{ marginRight: 10, width: 86, height: 50 }} />
                )
            }
        },
        {
            title: selectedLanguage?.text_Name,
            render: (e) => {
                return (
                    <p>
                        {e?.competitionName}
                    </p>
                )
            }
        },
        {
            title: selectedLanguage?.text_StartDate,
            render: (e) => {
                return (
                    <p>
                        {e?.competitionDuration?.date1}
                    </p>
                )
            }
        },
        {
            title: selectedLanguage?.text_EndDate,
            render: (e) => {
                return (
                    <p>
                        {e?.competitionDuration?.date2}
                    </p>
                )
            }
        },
        {
            title: selectedLanguage?.text_Level,
            render: (e) => {
                return (
                    <p>
                        {e?.level}
                    </p>
                )
            }
        },
        {
            title: selectedLanguage?.label_Maximumplayer,
            render: (e) => {
                return (
                    <p>
                        {e?.maximumPlayer}
                    </p>
                )
            }
        },
        {
            title: selectedLanguage?.text_Registered,
            render: (e) => {
                return (
                    <p>
                        {e?.competitionSubscribers?.length ? e?.competitionSubscribers?.length : 0}
                    </p>
                )
            }
        },
        {
            title: selectedLanguage?.title_Fee,
            render: (e) => {
                return (
                    <p>
                        {currencySign} {convertRoundNumber(e?.participationMoney)}
                    </p>
                )
            }
        },
        {
            title: selectedLanguage?.text_com_Result,
            render: (e) => {
                return (
                    <GrContactInfo
                        color='white'
                        size={30}
                        style={{
                            background: '#ec671b',
                            borderRadius: '100px',
                            padding: '4px',
                            cursor: 'pointer'
                        }}
                        onClick={() => {
                            e?.isCompleted ?
                                history?.push({ pathname: allPaths?.COMPETITION_RESULT, state: e })
                                :
                                errorMessage(selectedLanguage?.text_competitionisNotCompeletedyet)
                        }} />)
            }
        },
    ]

    return (
        <div className='new-main-component-screen-div'>
            <h1 className='new-screen-title'>
                {selectedLanguage?.text_CompetitionSelect}
            </h1>

            <div className="custom-table-container">
                <Table
                    locale={{ emptyText: selectedLanguage?.txt_NoDataFound }}
                    className='new-layout-table'
                    dataSource={allCompetitions}
                    columns={columns}
                    pagination={false}
                    scroll={{ x: 1000 }}
                />
            </div>
        </div >
    )
}

export default AdminCompetitionSelect