import { GAMES_SETTINGS, GAME_SUGGESTIONS, PLAYERS, PLAYER_CONTACTS, USER_RANKS, USER_TOURNAMENTS, USER_PRO_RANKS, TOURNAMENTS, TOURNAMENTS_MATCHES, SENT_MATCHES, RECEIVE_MATCHES, MATCH_RESULTS, WALLET_TRANSACTIONS, COMPETITIONS_RANKS, RECEIVE_COMPETITION_MATCHES, SENT_COMPETITION_MATCHES, COMPETITION_MATCH_RESULTS, SUBSCRIBED_COMPETITION } from '../types'

const reducer = (state = {}, action) => {
    switch (action.type) {

        case GAMES_SETTINGS: {
            return { ...state, gameSettings: action.gameSettings }
        }
        case PLAYER_CONTACTS: {
            return { ...state, playerContacts: action.playerContacts }
        }
        case PLAYERS: {
            return { ...state, players: action.players }
        }
        case GAME_SUGGESTIONS: {
            return { ...state, gameSuggestions: action.gameSuggestions }
        }
        case USER_TOURNAMENTS: {
            return { ...state, userTournaments: action.userTournaments }
        }
        case USER_RANKS: {
            return { ...state, userRanks: action.userRanks }
        }
        case USER_PRO_RANKS: {
            return { ...state, userProRanks: action.userProRanks }
        }
        case TOURNAMENTS: {
            return { ...state, tournaments: action.tournaments }
        }
        case TOURNAMENTS_MATCHES: {
            return { ...state, tournamentMatches: action.tournamentMatches }
        }
        case SENT_MATCHES: {
            return { ...state, sentMatches: action.sentMatches }
        }
        case RECEIVE_MATCHES: {
            return { ...state, receiveMatches: action.receiveMatches }
        }
        case MATCH_RESULTS: {
            return { ...state, matchResults: action.matchResults }
        }
        case WALLET_TRANSACTIONS: {
            return { ...state, walletTransaction: action.walletTransaction }
        }
        case COMPETITIONS_RANKS: {
            return { ...state, competitionRanks: action.competitionRanks }
        }
        case SENT_COMPETITION_MATCHES: {
            return { ...state, sentCompetitionMatches: action.sentCompetitionMatches }
        }
        case RECEIVE_COMPETITION_MATCHES: {
            return { ...state, receiveCompetitionMatches: action.receiveCompetitionMatches }
        }
        case COMPETITION_MATCH_RESULTS: {
            return { ...state, competitionMatches: action.competitionMatches }
        }
        case SUBSCRIBED_COMPETITION: {
            return { ...state, subscribedCompetitions: action.subscribedCompetitions }
        }
        default: {
            return state
        }
    }
}

export default reducer