import React, { useRef, useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { Form, Input, Button, Select } from 'antd'
import { requiredMessage, inputPlace, errorMessage, successNotification, assignMessage } from '../../utils/helpers'
import { allPaths, provincieLanguage } from '../../utils/constants'
import AllCountries from '../../utils/allCountries'
import { LoginHeader } from '../../Components'
import axios from 'axios'
import { AUTH } from '../../utils/apis'
import EnglishLanguage from '../../Languages/EnglishLanguage.json'
import NlLanguage from '../../Languages/NetherlandsLanguage.json'
import countryCodes from '../../utils/countryCodes'
import ReactFlagsSelect from 'react-flags-select'
import WGP_logo_Text from '../../assets/WGP-white.png'

const { Option } = Select

const Signup = (props) => {
    const { language, selectedLanguage } = props
    const history = useHistory()
    const formRef = useRef(null)
    const isEnglish = language?.language === 'english'
    const [selected, setSelected] = useState('GB')
    const [userLanguage, setUserLanguage] = useState('english')
    const [country, setCountry] = useState('Netherlands')
    const [countryCode, setCountryCode] = useState('+31')
    const [loading, setLoading] = useState(false)



    const onchangeLanguage = (v) => {
        setSelected(v)
        if (v === 'NL') {
            setUserLanguage('netherlands')
        }
        else if (v === 'IT') {
            setUserLanguage('italy')
        }
        else if (v === 'DE') {
            setUserLanguage('german')
        }
        else if (v === 'ES') {
            setUserLanguage('spain')
        }
        else if (v === 'EG') {
            setUserLanguage('egypt')
        }
        else if (v === 'RU') {
            setUserLanguage('russain')
        }
        else {
            setUserLanguage('english')
        }
    }
    console.log(userLanguage, "=userLanguage")

    const updateCountry = (e) => {
        let selectedCode = countryCodes.filter((v) => v?.code === e)[0].dial_code

        setCountryCode(selectedCode)

        formRef.current.setFieldsValue({ prefix: selectedCode })
    }

    const prefixSelector = () => {
        return (
            <Form.Item name='prefix' noStyle>
                <Select
                    showSearch
                    disabled
                    style={{
                        minWidth: 80,
                        maxWidth: window.innerWidth > 500 ? 'auto' : 120,
                    }}
                    defaultValue={countryCode}
                    notFoundContent={selectedLanguage?.txt_NoDataFound}
                    value={countryCode}
                    filterOption={(input, option) =>
                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                >
                    {countryCodes?.map((v, i) => (
                        <Option
                            value={v?.dial_code}
                            key={i}
                        >{`${v?.name} ${v?.dial_code}`}</Option>
                    ))}
                </Select>
            </Form.Item>
        )
    }


    const onFinish = (value) => {
        value.userType = 'player'
        value.languageSetting = userLanguage
        setLoading(true)
        axios.post(AUTH.SIGNUP, value)
            .then((res) => {
                const { data } = res
                setLoading(false)
                if (data.success) {
                    successNotification(selectedLanguage?.message_SuccessfullySignUpWeHaveSentYouVerificationLinkToYourEmailPpleaseVerifyYourEemailAndLogin)
                    return setTimeout(() => {
                        history.push('/login')
                    }, 300)
                }
                else {
                    errorMessage(assignMessage(data.message, language?.language))
                }
            })
            .catch((e) => {
                setLoading(false)
                errorMessage(assignMessage(e.message, language?.language))
            })
    }


    return (
        <div >
            <LoginHeader />
            <div className='auth-screen-main-div-new sidebar-scroll_class'>
                <center>
                    <img src={WGP_logo_Text} className='img-for-login-page' />
                </center>
                <p className='heading head-center' style={{ color: 'white' }}>{selectedLanguage?.title_Register}</p>
                <Form
                    name='form'
                    onFinish={onFinish}
                    layout={'vertical'}
                    requiredMark={false}
                    ref={formRef}
                >
                    <div className='form-two-input-flex'>
                        <div className='form-two-input-flex-inner-input'>
                            <Form.Item
                                name='name'
                                label={<p className='p-admin-title-text'>{selectedLanguage?.label_FullName}</p>}
                                rules={[
                                    {
                                        required: true,
                                        message: selectedLanguage?.error_PleaseEnterYourFullName
                                    }
                                ]}
                            >
                                <Input
                                    className='form-input-new-design-big-slam'
                                    placeholder={selectedLanguage?.placeHolder_PleaseEnterYourFullNameHere}
                                />
                            </Form.Item>
                        </div>
                        <div className='form-two-input-flex-inner-input'>
                            <Form.Item
                                name='email'
                                label={<p className='p-admin-title-text'>{selectedLanguage?.label_Email}</p>}
                                rules={[
                                    {
                                        required: true,
                                        message: selectedLanguage?.error_PleaseEnterYourEmail
                                    },
                                    {
                                        type: 'email',
                                        message: selectedLanguage?.error_EmailIsNotValid,
                                    },
                                ]}
                            >
                                <Input
                                    className='form-input-new-design-big-slam'
                                    placeholder={selectedLanguage?.placeHolder_PleaseEnterYourEmail}
                                />
                            </Form.Item>
                        </div>
                    </div>

                    <div className='form-two-input-flex'>
                        <div className='form-two-input-flex-inner-input'>
                            <Form.Item
                                name='country'
                                label={<p className='p-admin-title-text'>{selectedLanguage?.label_Country}</p>}
                                hasFeedback
                                initialValue={country}
                                rules={[
                                    {
                                        required: true,
                                        message: selectedLanguage?.error_PleaseSelectYourCountry
                                    }
                                ]}
                            >
                                <Select
                                    showSearch
                                    showArrow
                                    allowClear={false}
                                    notFoundContent={selectedLanguage?.txt_NoDataFound}
                                    className='form-input-new-design-big-slam input-none'
                                    placeholder={selectedLanguage?.placeHolder_SelectYourCountry}
                                    optionFilterProp='children'
                                    onChange={updateCountry}
                                    filterOption={(input, option) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                >
                                    {countryCodes?.map((v, i) => <Option value={v?.code} key={i}>{isEnglish ? v?.name : v?.nameNL}</Option>)}
                                </Select>
                            </Form.Item>
                        </div>
                        <div className='form-two-input-flex-inner-input'>
                            <Form.Item
                                name='city'
                                label={<p className='p-admin-title-text'>{selectedLanguage?.label_City}</p>}
                                hasFeedback
                                rules={[
                                    {
                                        required: true,
                                        message: selectedLanguage?.error_PleaseInputYourCity
                                    }
                                ]}
                            >
                                <Input
                                    className='form-input-new-design-big-slam'
                                    placeholder={selectedLanguage?.placeHolder_InputYourCityHere}
                                />
                            </Form.Item>
                        </div >
                    </div >

                    <div className='form-two-input-flex'>
                        <div className='form-two-input-flex-inner-input'>
                            <Form.Item
                                name='password'
                                label={<p className='p-admin-title-text'>{selectedLanguage?.label_Password}</p>}
                                rules={[
                                    {
                                        required: true,
                                        message: selectedLanguage?.error_PleaseEnterYourPassword
                                    },
                                    {
                                        min: 8,
                                        message: selectedLanguage?.error_PasswordMustBeAtLeastEightCharacters
                                    }
                                ]}
                            >
                                <Input.Password
                                    className='form-input-new-design-big-slam'
                                    placeholder={selectedLanguage?.PlaceHolder_PleaseEnterYourPasswordHere}
                                />
                            </Form.Item>
                        </div>
                        <div className='form-two-input-flex-inner-input'>
                            <Form.Item
                                label={<p className='p-admin-title-text'>{selectedLanguage?.label_ConfirmPassword}</p>}
                                name='confirm'
                                hasFeedback
                                rules={[
                                    {
                                        required: true,
                                        message: selectedLanguage?.error_PleaseEnterYourConfirmPassword,
                                    },
                                    ({ getFieldValue }) => ({
                                        validator(rule, value) {
                                            if (!value || getFieldValue('password') === value) {
                                                return Promise.resolve()
                                            }

                                            return Promise.reject(selectedLanguage?.error_TheTwoPasswordsThatYouEnteredDoNotMatch)
                                        },
                                    })
                                ]}
                            >
                                <Input.Password
                                    placeholder={selectedLanguage?.placeHolder_InputYourConfirmPassword}
                                    className='form-input-new-design-big-slam'
                                />
                            </Form.Item>
                        </div>
                    </div>

                    <div style={{ marginBottom: '20px' }}>
                        <p style={{ marginLeft: '5px', marginBottom: '7px', }} className='p-admin-title-text'> {selectedLanguage?.tedxt_LanguageSetting}</p>
                        <div className='form-input-new-design-big-slam-cus' >
                            <span style={{ marginLeft: '20px' }}>
                                <ReactFlagsSelect
                                    className='countries-select-grey'
                                    fullWidth={false}
                                    showOptionLabel={false}
                                    showSelectedLabel={false}
                                    countries={['GB', 'NL', 'IT', 'ES', 'EG', 'RU', 'DE']}
                                    selected={selected}
                                    onSelect={(v) => onchangeLanguage(v)}
                                />
                            </span>
                        </div>
                    </div>

                    <br />
                    <Form.Item>
                        <Button
                            className='login-btn-height'
                            type='primary'
                            block
                            htmlType='submit'
                            loading={loading}
                        >
                            {selectedLanguage?.ButtonText_Submit}
                        </Button>
                        <div style={{ textAlign: 'center', marginTop: '20px' }}>
                            <p style={{ color: 'white' }}>{selectedLanguage?.minTitle_AlreadyHaveAnAccount}
                                <Link className='linkStyle' to={allPaths?.LOGIN}>{'  '}{selectedLanguage?.minTitle_Login}</Link>
                                <br />
                                <Link className='linkStyle' to={allPaths?.FORGOT_PASSOWRD}>{selectedLanguage?.minTitle_ForgotPassword}</Link>
                            </p>
                        </div>
                    </Form.Item>
                </Form>
            </div>
        </div>
    )
}

export default Signup