import React from 'react';

function PDFViewer({ blobUrl }) {
  return (
    <div>
      <embed src={blobUrl} type="application/pdf" width="100%" height="500px" />
    </div>
  );
}

function MyPdfView(props) {
  const {pdfUrl,selectedLanguage}=props
  const [blobUrl, setBlobUrl] = React.useState(null);

  React.useEffect(() => {
    // Simulating the fetching of the PDF file as a Blob
    fetch(pdfUrl)
      .then((response) => response.blob())
      .then((blob) => {
        const url = URL.createObjectURL(blob);
        setBlobUrl(url);
      });

    // Clean up the Blob URL when the component is unmounted
    return () => {
      if (blobUrl) {
        URL.revokeObjectURL(blobUrl);
      }
    };
  }, []);

  return (
    <div style={{width:'100%'}}>
      {blobUrl ? (
        <PDFViewer blobUrl={blobUrl} />
      ) : (
        <div>{selectedLanguage?.text_LoadingPDF}</div>
      )}
    </div>
  );
}

export default MyPdfView;
