const AllBackendMessages = {
    ApisMessage_incorrect_username_password: {
        messageEnglish: 'Oops, incorrect username or password!',
        messageSpain: '¡Ups, nombre de usuario o contraseña incorrectos!',
        messageGerman: 'Hoppla, falscher Benutzername oder falsches Passwort!',
        messageItaly: 'Ops, nome utente o password errati!',
        messageRussain: 'Ой, неправильное имя пользователя или пароль!',
        messageNetherlands: 'Oeps, onjuiste gebruikersnaam of wachtwoord!',
        messageEgypt: 'أوبس، اسم المستخدم أو كلمة المرور غير صحيحة!',
    },
    ApisMessage_Oops_something_went_wrong: {
        messageEnglish: 'Oops something went wrong',
        messageSpain: 'Vaya, algo ha ido mal',
        messageGerman: 'Hoppla, etwas ist schiefgelaufen',
        messageItaly: 'Ops, qualcosa è andato storto',
        messageRussain: 'Упс, что-то пошло не так',
        messageNetherlands: 'Oeps, er is iets misgegaan',
        messageEgypt: 'عذرًا، حدث خطأ ما',
    },
    ApisMessage_admin_not_found: {
        messageEnglish: 'Admin not found!',
        messageSpain: '¡Administrador no encontrado!',
        messageGerman: 'Administrator nicht gefunden!',
        messageItaly: 'Amministratore non trovato!',
        messageRussain: 'Администратор не найден!',
        messageNetherlands: 'Beheerder niet gevonden!',
        messageEgypt: 'المشرف غير موجود!',
    },
    ApisMessage_data_not_found: {
        messageEnglish: 'Data not Found!',
        messageSpain: '¡Datos no encontrados!',
        messageGerman: 'Daten nicht gefunden!',
        messageItaly: 'Dati non trovati!',
        messageRussain: 'Данные не найдены!',
        messageNetherlands: 'Gegevens niet gevonden!',
        messageEgypt: 'البيانات غير موجودة!',
    },
    ApisMessage_Params_id_is_missing: {
        messageEnglish: 'Params id is missing!',
        messageSpain: '¡Falta el id de parámetro!',
        messageGerman: 'Parameter-ID fehlt!',
        messageItaly: "Manca l'id dei parametri!",
        messageRussain: 'Отсутствует id параметра!',
        messageNetherlands: 'Params id ontbreekt!',
        messageEgypt: 'مفتاح الهوية مفقود!',
    },
    ApisMessage_user_not_found: {
        messageEnglish: 'user not found!',
        messageSpain: '¡Usuario no encontrado!',
        messageGerman: 'Benutzer nicht gefunden!',
        messageItaly: 'Utente non trovato!',
        messageRussain: 'Пользователь не найден!',
        messageNetherlands: 'Gebruiker niet gevonden!',
        messageEgypt: 'المستخدم غير موجود!',
    },
    ApisMessage_Please_provide_All_Required_Fields: {
        messageEnglish: 'Please provide all required fields!',
        messageSpain: '¡Por favor, proporcione todos los campos requeridos!',
        messageGerman: 'Bitte geben Sie alle erforderlichen Felder an!',
        messageItaly: 'Si prega di fornire tutti i campi richiesti!',
        messageRussain: 'Пожалуйста, заполните все обязательные поля!',
        messageNetherlands: 'Vul alle verplichte velden in!',
        messageEgypt: 'يرجى تقديم جميع الحقول المطلوبة!',
    },
    ApisMessage_Admin_dont_have_access: {
        messageEnglish: 'Admin does not have access!',
        messageSpain: '¡El administrador no tiene acceso!',
        messageGerman: 'Administrator hat keinen Zugriff!',
        messageItaly: "L'amministratore non ha accesso!",
        messageRussain: 'У администратора нет доступа!',
        messageNetherlands: 'Beheerder heeft geen toegang!',
        messageEgypt: 'المشرف لا يملك وصول!',
    },
    ApisMessage_Please_upload_image_only: {
        messageEnglish: 'Please upload image only!',
        messageSpain: '¡Por favor, suba solo imágenes!',
        messageGerman: 'Bitte laden Sie nur Bilder hoch!',
        messageItaly: 'Carica solo immagini, per favore!',
        messageRussain: 'Пожалуйста, загрузите только изображение!',
        messageNetherlands: 'Upload alstublieft alleen een afbeelding!',
        messageEgypt: 'من فضلك قم برفع الصور فقط!',
    },
    ApisMessage_Successfully_created: {
        messageEnglish: 'Successfully created!',
        messageSpain: '¡Creado con éxito!',
        messageGerman: 'Erfolgreich erstellt!',
        messageItaly: 'Creato con successo!',
        messageRussain: 'Успешно создано!',
        messageNetherlands: 'Succesvol aangemaakt!',
        messageEgypt: 'تم إنشاء بنجاح!',
    },
    ApisMessage_Successfully_deleted: {
        messageEnglish: 'Successfully deleted!',
        messageSpain: '¡Eliminado con éxito!',
        messageGerman: 'Erfolgreich gelöscht!',
        messageItaly: 'Eliminato con successo!',
        messageRussain: 'Успешно удалено!',
        messageNetherlands: 'Succesvol verwijderd!',
        messageEgypt: 'تم الحذف بنجاح!',
    },
    ApisMessage_Successfully_updated: {
        messageEnglish: 'Successfully updated!',
        messageSpain: '¡Actualizado con éxito!',
        messageGerman: 'Erfolgreich aktualisiert!',
        messageItaly: 'Aggiornato con successo!',
        messageRussain: 'Успешно обновлено!',
        messageNetherlands: 'Succesvol bijgewerkt!',
        messageEgypt: 'تم التحديث بنجاح!',
    },
    ApisMessage_Tournament_Successfully_created: {
        messageEnglish: 'Tournament successfully created!',
        messageSpain: 'Torneo creado con éxito!',
        messageGerman: 'Turnier erfolgreich erstellt!',
        messageItaly: 'Torneo creato con successo!',
        messageRussain: 'Турнир успешно создан!',
        messageNetherlands: 'Toernooi succesvol aangemaakt!',
        messageEgypt: 'تم إنشاء البطولة بنجاح!',
    },
    ApisMessage_This_email_already_exists: {
        messageEnglish: 'This email already exists!',
        messageSpain: '¡Este correo electrónico ya existe!',
        messageGerman: 'Diese E-Mail existiert bereits!',
        messageItaly: 'Questa email esiste già!',
        messageRussain: 'Этот электронный адрес уже существует!',
        messageNetherlands: 'Dit e-mailadres bestaat al!',
        messageEgypt: 'هذا البريد الإلكتروني موجود بالفعل!',
    },
    ApisMessage_Setting_not_found: {
        messageEnglish: 'Setting not found!',
        messageSpain: '¡Configuración no encontrada!',
        messageGerman: 'Einstellung nicht gefunden!',
        messageItaly: 'Impostazione non trovata!',
        messageRussain: 'Настройка не найдена!',
        messageNetherlands: 'Instelling niet gevonden!',
        messageEgypt: 'الإعدادات غير موجودة!',
    },
    ApisMessage_Insufficient_Balance_in_Player_Wallet: {
        messageEnglish: 'Insufficient balance in player wallet!',
        messageSpain: 'Saldo insuficiente en la cartera del jugador!',
        messageGerman: 'Unzureichendes Guthaben im Spielerkonto!',
        messageItaly: 'Saldo insufficiente nel portafoglio del giocatore!',
        messageRussain: 'Недостаточно средств в кошельке игрока!',
        messageNetherlands: 'Onvoldoende saldo in spelersportemonnee!',
        messageEgypt: 'الرصيد في محفظة اللاعب غير كافٍ!',
    },
    ApisMessage_Successfully_added_cashback_service_fee: {
        messageEnglish: 'Successfully added cashback service fee',
        messageSpain: 'Cuota de servicio de reembolso agregada con éxito',
        messageGerman: 'Cashback-Servicegebühr erfolgreich hinzugefügt',
        messageItaly: 'Tariffa di servizio cashback aggiunta con successo',
        messageRussain: 'Успешно добавлена комиссия за сервис кэшбэка',
        messageNetherlands: 'Cashback servicekosten succesvol toegevoegd',
        messageEgypt: 'تم إضافة رسوم خدمة الاسترداد بنجاح',
    },
    ApisMessage_A_competition_already_exists_with_this_name: {
        messageEnglish: 'A competition already exists with this name',
        messageSpain: 'Ya existe una competición con este nombre',
        messageGerman: 'Es gibt bereits einen Wettbewerb mit diesem Namen',
        messageItaly: 'Esiste già una competizione con questo nome',
        messageRussain: 'Соревнование с таким именем уже существует',
        messageNetherlands: 'Er bestaat al een competitie met deze naam',
        messageEgypt: 'يوجد بالفعل مسابقة بهذا الاسم',
    },
    ApisMessage_Successfully_Confirmed: {
        messageEnglish: 'Successfully Confirmed',
        messageSpain: 'Confirmado con éxito',
        messageGerman: 'Erfolgreich bestätigt',
        messageItaly: 'Confermato con successo',
        messageRussain: 'Успешно подтверждено',
        messageNetherlands: 'Succesvol bevestigd',
        messageEgypt: 'تم التأكيد بنجاح',
    },
    ApisMessage_No_CashBack_Found_With_This_Id: {
        messageEnglish: 'No CashBack Found With This Id',
        messageSpain: 'No se encontró CashBack con esta identificación',
        messageGerman: 'Kein Cashback mit dieser ID gefunden',
        messageItaly: 'Nessun cashback trovato con questo ID',
        messageRussain: 'Кэшбэк с таким идентификатором не найден',
        messageNetherlands: 'Geen cashback gevonden met deze ID',
        messageEgypt: 'لم يتم العثور على استرداد نقدي بهذا المعرف',
    },
    ApisMessage_Successfully_added_doller_Match_Request_Fee: {
        messageEnglish: 'Successfully added $ Match Request Fee',
        messageSpain: 'Cuota de solicitud de partido de $ agregada con éxito',
        messageGerman: 'Erfolgreich $ Spielanfragegebühr hinzugefügt',
        messageItaly: 'Commissione di richiesta partita $ aggiunta con successo',
        messageRussain: 'Успешно добавлена плата за запрос на матч $',
        messageNetherlands: 'Succesvol $ wedstrijdverzoekskosten toegevoegd',
        messageEgypt: 'تم إضافة رسوم طلب المباراة $ بنجاح',
    },
    ApisMessage_Successfully_added_subscription_doller_tournament: {
        messageEnglish: 'Successfully added subscription $ tournament',
        messageSpain: 'Torneo de suscripción de $ agregado con éxito',
        messageGerman: '$ Abonnement-Turnier erfolgreich hinzugefügt',
        messageItaly: 'Torneo di abbonamento $ aggiunto con successo',
        messageRussain: 'Успешно добавлен турнир подписки за $',
        messageNetherlands: 'Abonnement $ toernooi succesvol toegevoegd',
        messageEgypt: 'تم إضافة بطولة الاشتراك $ بنجاح',
    },
    ApisMessage_Successfully_added_Tournament_organizer_pay_cost: {
        messageEnglish: 'Successfully added Tournament organizer pay cost',
        messageSpain: 'Costo de pago del organizador del torneo añadido con éxito',
        messageGerman: 'Turnierorganisatorzahlung erfolgreich hinzugefügt',
        messageItaly: 'Costo di pagamento dell’organizzatore del torneo aggiunto con successo',
        messageRussain: 'Успешно добавлена плата за организацию турнира',
        messageNetherlands: 'Kosten voor betaling toernooiorganisator succesvol toegevoegd',
        messageEgypt: 'تم إضافة تكلفة دفع منظم البطولة بنجاح',
    },
    ApisMessage_Successfully_added_withdrawal_points: {
        messageEnglish: 'Successfully added withdrawal points',
        messageSpain: 'Puntos de retiro agregados con éxito',
        messageGerman: 'Auszahlungspunkte erfolgreich hinzugefügt',
        messageItaly: 'Punti di prelievo aggiunti con successo',
        messageRussain: 'Успешно добавлены очки на вывод средств',
        messageNetherlands: 'Opnamepunten succesvol toegevoegd',
        messageEgypt: 'تم إضافة نقاط السحب بنجاح',
    },
    ApisMessage_Successfully_added_referral_points: {
        messageEnglish: 'Successfully added referral points',
        messageSpain: 'Puntos de referencia agregados con éxito',
        messageGerman: 'Empfehlungspunkte erfolgreich hinzugefügt',
        messageItaly: 'Punti di riferimento aggiunti con successo',
        messageRussain: 'Успешно добавлены реферальные очки',
        messageNetherlands: 'Verwijspunten succesvol toegevoegd',
        messageEgypt: 'تم إضافة نقاط الإحالة بنجاح',
    },
    ApisMessage_Promoter_Created_Successfully: {
        messageEnglish: 'Promoter Created Successfully',
        messageSpain: 'Promotor creado con éxito',
        messageGerman: 'Promoter erfolgreich erstellt',
        messageItaly: 'Promotore creato con successo',
        messageRussain: 'Промоутер успешно создан',
        messageNetherlands: 'Promotor succesvol aangemaakt',
        messageEgypt: 'تم إنشاء المروج بنجاح',
    },
    ApisMessage_Game_Created_Successfully: {
        messageEnglish: 'Game Created Successfully',
        messageSpain: 'Juego creado con éxito',
        messageGerman: 'Spiel erfolgreich erstellt',
        messageItaly: 'Gioco creato con successo',
        messageRussain: 'Игра успешно создана',
        messageNetherlands: 'Spel succesvol gecreëerd',
        messageEgypt: 'تم إنشاء اللعبة بنجاح',
    },
    ApisMessage_Admin_Created_Successfully: {
        messageEnglish: 'Admin Created Successfully',
        messageSpain: 'Administrador creado con éxito',
        messageGerman: 'Admin erfolgreich erstellt',
        messageItaly: 'Amministratore creato con successo',
        messageRussain: 'Администратор успешно создан',
        messageNetherlands: 'Beheerder succesvol aangemaakt',
        messageEgypt: 'تم إنشاء المسؤول بنجاح',
    },
    ApisMessage_Message_Provide_All_Required_Fields: {
        messageEnglish: 'Message Provide All Required Fields',
        messageSpain: 'Mensaje proporcione todos los campos requeridos',
        messageGerman: 'Bitte alle erforderlichen Felder ausfüllen',
        messageItaly: 'Messaggio: fornire tutti i campi richiesti',
        messageRussain: 'Сообщение: заполните все необходимые поля',
        messageNetherlands: 'Bericht: Vul alle verplichte velden in',
        messageEgypt: 'الرسالة: يرجى توفير جميع الحقول المطلوبة',
    },
    ApisMessage_Email_already_in_use: {
        messageEnglish: 'Email already in use!',
        messageSpain: 'Correo electrónico ya en uso!',
        messageGerman: 'E-Mail bereits in Gebrauch!',
        messageItaly: 'Email già in uso!',
        messageRussain: 'Электронная почта уже используется!',
        messageNetherlands: 'E-mailadres is al in gebruik!',
        messageEgypt: 'البريد الإلكتروني مستخدم بالفعل!',
    },
    ApisMessage_Oops_incorrect_email: {
        messageEnglish: 'Incorrect Email or password!',
        messageSpain: 'Correo electrónico o contraseña incorrectos!',
        messageGerman: 'Falsche E-Mail oder Passwort!',
        messageItaly: 'Email o password incorretti!',
        messageRussain: 'Неверный адрес электронной почты или пароль!',
        messageNetherlands: 'Onjuist e-mailadres of wachtwoord!',
        messageEgypt: 'البريد الإلكتروني أو كلمة المرور غير صحيحة!',
    },
    ApisMessage_Oops_no_email_found: {
        messageEnglish: 'Oops, email not found!',
        messageSpain: 'Vaya, correo electrónico no encontrado!',
        messageGerman: 'Ups, E-Mail nicht gefunden!',
        messageItaly: 'Ops, email non trovata!',
        messageRussain: 'Упс, электронная почта не найдена!',
        messageNetherlands: 'Oeps, email niet gevonden!',
        messageEgypt: 'عذرًا، لم يتم العثور على البريد الإلكتروني!',
    },
    ApisMessage_We_have_sent_you_a_verification_code: {
        messageEnglish: 'We have sent you a verification code!',
        messageSpain: '¡Te hemos enviado un código de verificación!',
        messageGerman: 'Wir haben Ihnen einen Verifizierungscode geschickt!',
        messageItaly: 'Ti abbiamo inviato un codice di verifica!',
        messageRussain: 'Мы отправили вам код подтверждения!',
        messageNetherlands: 'We hebben je een verificatiecode gestuurd!',
        messageEgypt: 'قد أرسلنا لك رمز التحقق!',
    },
    ApisMessage_Game_suggestion_created_successfully: {
        messageEnglish: 'Game suggestion created successfully',
        messageSpain: 'Sugerencia de juego creada con éxito',
        messageGerman: 'Spielevorschlag erfolgreich erstellt',
        messageItaly: 'Suggerimento di gioco creato con successo',
        messageRussain: 'Предложение игры успешно создано',
        messageNetherlands: 'Spel suggestie succesvol aangemaakt',
        messageEgypt: 'تم إنشاء اقتراح اللعبة بنجاح',
    },
    ApisMessage_Tournament_Created_Successfully: {
        messageEnglish: `Tournament Created Successfully`,
        messageSpain: `Torneo creado con éxito`,
        messageGerman: `Turnier erfolgreich erstellt`,
        messageItaly: `Torneo creato con successo`,
        messageRussain: `Турнир успешно создан`,
        messageNetherlands: `Toernooi succesvol aangemaakt`,
        messageEgypt: `تم إنشاء البطولة بنجاح`,
    },
    ApisMessage_Game_successfully_added_to_setting: {
        messageEnglish: `Game successfully added to setting`,
        messageSpain: `Juego añadido con éxito a la configuración`,
        messageGerman: `Spiel erfolgreich zur Einstellung hinzugefügt`,
        messageItaly: `Gioco aggiunto con successo alle impostazioni`,
        messageRussain: `Игра успешно добавлена в настройки`,
        messageNetherlands: `Spel succesvol toegevoegd aan instellingen`,
        messageEgypt: `تم إضافة اللعبة إلى الإعدادات بنجاح`,
    },
    ApisMessage_Contact_successfully_Added: {
        messageEnglish: `Contact successfully Added`,
        messageSpain: `Contacto añadido con éxito`,
        messageGerman: `Kontakt erfolgreich hinzugefügt`,
        messageItaly: `Contatto aggiunto con successo`,
        messageRussain: `Контакт успешно добавлен`,
        messageNetherlands: `Contact succesvol toegevoegd`,
        messageEgypt: `تم إضافة الاتصال بنجاح`,
    },
    ApisMessage_Invalid_Coupon_Code: {
        messageEnglish: 'Invalid Coupon Code',
        messageSpain: 'Código de cupón no válido',
        messageGerman: 'Ungültiger Gutscheincode',
        messageItaly: 'Codice coupon non valido',
        messageRussain: 'Недействительный код купона',
        messageNetherlands: 'Ongeldige couponcode',
        messageEgypt: 'رمز الكوبون غير صالح',
    },
    ApisMessage_This_Coupon_has_been_Claimed_already: {
        messageEnglish: 'This Coupon has been Claimed already',
        messageSpain: 'Este cupón ya ha sido canjeado',
        messageGerman: 'Dieser Gutschein wurde bereits eingelöst',
        messageItaly: 'Questo coupon è già stato utilizzato',
        messageRussain: 'Этот купон уже был использован',
        messageNetherlands: 'Deze coupon is al geclaimd',
        messageEgypt: 'تم استيفاء هذا الكوبون بالفعل',
    },
    ApisMessage_This_Coupon_is_not_Valid_for_this_Tournament: {
        messageEnglish: 'This Coupon is not Valid for this tournament',
        messageSpain: 'Este cupón no es válido para este torneo',
        messageGerman: 'Dieser Gutschein ist nicht für dieses Turnier gültig',
        messageItaly: 'Questo coupon non è valido per questo torneo',
        messageRussain: 'Этот купон не действителен для этого турнира',
        messageNetherlands: 'Deze coupon is niet geldig voor dit toernooi',
        messageEgypt: 'هذا الكوبون غير صالح لهذه البطولة',
    },
    ApisMessage_Your_ranking_is_not_worthy_of_subscribing_to_the_tournament: {
        messageEnglish: `Your ranking is not worthy of subscribing to the tournament`,
        messageSpain: `Tu clasificación no justifica la inscripción al torneo`,
        messageGerman: `Ihr Ranking rechtfertigt nicht die Anmeldung zum Turnier`,
        messageItaly: `Il tuo ranking non merita di iscriversi al torneo`,
        messageRussain: `Ваш рейтинг не позволяет подписаться на турнир`,
        messageNetherlands: `Je rang is niet waardig om je in te schrijven voor het toernooi`,
        messageEgypt: `تصنيفك لا يؤهلك للاشتراك في البطولة`,
    },
    ApisMessage_You_dont_have_enough_points_to_join_this_tournament: {
        messageEnglish: `You don't have enough points to join this tournament`,
        messageSpain: `No tienes suficientes puntos para unirte a este torneo`,
        messageGerman: `Sie haben nicht genügend Punkte, um an diesem Turnier teilzunehmen`,
        messageItaly: `Non hai abbastanza punti per partecipare a questo torneo`,
        messageRussain: `У вас недостаточно очков для участия в этом турнире`,
        messageNetherlands: `Je hebt niet genoeg punten om aan dit toernooi deel te nemen`,
        messageEgypt: `ليس لديك نقاط كافية للانضمام إلى هذه البطولة`,
    },
    ApisMessage_successfully_Subscribed: {
        messageEnglish: 'Successfully Subscribed',
        messageSpain: 'Suscripción exitosa',
        messageGerman: 'Erfolgreich abonniert',
        messageItaly: 'Iscrizione avvenuta con successo',
        messageRussain: 'Успешная подписка',
        messageNetherlands: 'Succesvol geabonneerd',
        messageEgypt: 'تم الاشتراك بنجاح',
    },
    ApisMessage_Successfully_unsubscribed_and_money_will_be_refunded: {
        messageEnglish: 'Successfully unsubscribed and money will be refunded',
        messageSpain: 'Baja realizada con éxito y se reembolsará el dinero',
        messageGerman: 'Erfolgreich abgemeldet, das Geld wird zurückerstattet',
        messageItaly: 'Annullamento avvenuto con successo e il denaro sarà rimborsato',
        messageRussain: 'Успешная отписка и деньги будут возвращены',
        messageNetherlands: 'Succesvol uitgeschreven en geld zal worden terugbetaald',
        messageEgypt: 'تم الإلغاء بنجاح وسيتم استرداد الأموال',
    },
    ApisMessage_successfully_Subscribed_and_money_will_be_deducted: {
        messageEnglish: 'Successfully subscribed and money will be deducted',
        messageSpain: 'Suscripción realizada con éxito y se descontará el dinero',
        messageGerman: 'Erfolgreich abonniert, das Geld wird abgebucht',
        messageItaly: 'Iscrizione avvenuta con successo e il denaro sarà detratto',
        messageRussain: 'Успешно подписано и средства будут списаны',
        messageNetherlands: 'Succesvol ingeschreven en geld zal worden afgeschreven',
        messageEgypt: 'تم الاشتراك بنجاح وسيتم خصم المال',
    },
    ApisMessage_Successfully_unsubscribed_and_point_will_be_refunded: {
        messageEnglish: 'Successfully unsubscribed and point will be refunded',
        messageSpain: 'Dado de baja con éxito y se reembolsará el punto',
        messageGerman: 'Erfolgreich abgemeldet und Punkt wird erstattet',
        messageItaly: 'Cancellazione avvenuta con successo e il punto verrà rimborsato',
        messageRussain: 'Успешная отписка и балл будет возвращён',
        messageNetherlands: 'Succesvol uitgeschreven en punt wordt terugbetaald',
        messageEgypt: 'تم إلغاء الاشتراك بنجاح وسيتم رد النقطة',
    },
    ApisMessage_Low_Pro_Points_on_your_pro_ranking: {
        messageEnglish: 'Low Pro Points on your pro ranking',
        messageSpain: 'Pocos Puntos Pro en tu clasificación profesional',
        messageGerman: 'Niedrige Pro-Punkte in Ihrem Profi-Ranking',
        messageItaly: 'Pochi punti Pro nel tuo ranking professionale',
        messageRussain: 'Мало профессиональных баллов в вашем рейтинге',
        messageNetherlands: 'Weinig Pro Punten op je pro ranking',
        messageEgypt: 'نقاط البرو المنخفضة في تصنيفك الاحترافي',
    },
    ApisMessage_Low_Points_on_your_ranking: {
        messageEnglish: 'Low Points on your ranking',
        messageSpain: 'Pocos puntos en tu clasificación',
        messageGerman: 'Wenig Punkte in Ihrem Ranking',
        messageItaly: 'Pochi punti nel tuo ranking',
        messageRussain: 'Мало очков в вашем рейтинге',
        messageNetherlands: 'Weinig punten op je ranking',
        messageEgypt: 'نقاط منخفضة في تصنيفك',
    },
    ApisMessage_Match_request_delivered_money_deducted_from_your_wallet: {
        messageEnglish: 'Match request delivered money deducted from your wallet',
        messageSpain: 'Solicitud de partido realizada, dinero deducido de tu billetera',
        messageGerman: 'Spielanfrage zugestellt, Geld von Ihrer Brieftasche abgebucht',
        messageItaly: 'Richiesta di partita consegnata, denaro detratto dal tuo portafoglio',
        messageRussain: 'Запрос на матч доставлен, деньги списаны с вашего кошелька',
        messageNetherlands: 'Wedstrijdverzoek geleverd, geld afgeschreven van je portemonnee',
        messageEgypt: 'طلب المباراة تم تسليمه، النقود خصمت من محفظتك',
    },
    ApisMessage_Match_request_delivered_point_deducted_from_your_points: {
        messageEnglish: 'Match request delivered point deducted from your points',
        messageSpain: 'Solicitud de partido realizada, punto deducido de tus puntos',
        messageGerman: 'Spielanfrage geliefert, Punkt von Ihren Punkten abgezogen',
        messageItaly: 'Richiesta di partita consegnata, punto detratto dai tuoi punti',
        messageRussain: 'Запрос на матч выполнен, баллы списаны с ваших очков',
        messageNetherlands: 'Wedstrijdverzoek geleverd, punt afgetrokken van je punten',
        messageEgypt: 'تم تسليم طلب المباراة، وتم خصم النقطة من نقاطك',
    },
    ApisMessage_Successfully_delete_match_request_and_money_is_refunded: {
        messageEnglish: 'Successfully delete match request and money is refunded',
        messageSpain: 'Solicitud de partido eliminada con éxito y dinero reembolsado',
        messageGerman: 'Matchanfrage erfolgreich gelöscht und Geld erstattet',
        messageItaly: 'Richiesta di partita cancellata con successo e denaro rimborsato',
        messageRussain: 'Запрос на матч успешно удален и деньги возвращены',
        messageNetherlands: 'Wedstrijdverzoek succesvol verwijderd en geld wordt terugbetaald',
        messageEgypt: 'تم حذف طلب المباراة بنجاح وتم استرداد الأموال',
    },
    ApisMessage_Successfully_delete_match_request_and_point_is_refunded: {
        messageEnglish: 'Successfully delete match request and point is refunded',
        messageSpain: 'Solicitud de partido eliminada con éxito y punto reembolsado',
        messageGerman: 'Spielanfrage erfolgreich gelöscht und Punkt erstattet',
        messageItaly: 'Richiesta di partita cancellata con successo e punto rimborsato',
        messageRussain: 'Запрос на матч успешно удален, баллы возвращены',
        messageNetherlands: 'Wedstrijdverzoek succesvol verwijderd en punt wordt terugbetaald',
        messageEgypt: 'تم حذف طلب المباراة بنجاح وتم استرداد النقطة',
    },
    ApisMessage_You_do_not_have_enough_doller_points_to_accept_this_request: {
        messageEnglish: 'You do not have enough dollar points to accept this request',
        messageSpain: 'No tienes suficientes puntos de dólar para aceptar esta solicitud',
        messageGerman: 'Sie haben nicht genügend Dollar-Punkte, um diese Anfrage zu akzeptieren',
        messageItaly: 'Non hai abbastanza punti in dollari per accettare questa richiesta',
        messageRussain: 'У вас недостаточно долларовых баллов для принятия этого запроса',
        messageNetherlands: 'Je hebt niet genoeg dollar punten om dit verzoek te accepteren',
        messageEgypt: 'ليس لديك نقاط دولار كافية لقبول هذا الطلب',
    },
    ApisMessage_Successfully_accepted_match_request_and_deducted_money_from_wallet: {
        messageEnglish: 'Successfully accepted match request and deducted money from wallet',
        messageSpain: 'Solicitud de partido aceptada con éxito y dinero deducido de la cartera',
        messageGerman: 'Matchanfrage erfolgreich angenommen und Geld vom Wallet abgezogen',
        messageItaly: 'Richiesta di partita accettata con successo e denaro detratto dal portafoglio',
        messageRussain: 'Запрос на матч успешно принят и деньги списаны с кошелька',
        messageNetherlands: 'Wedstrijdverzoek succesvol geaccepteerd en geld afgetrokken van portemonnee',
        messageEgypt: 'تم قبول طلب المباراة بنجاح وخُصمت الأموال من المحفظة',
    },
    ApisMessage_Successfully_accepted_match_request_and_deducted_points: {
        messageEnglish: 'Successfully accepted match request and deducted points',
        messageSpain: 'Solicitud de partido aceptada con éxito y puntos deducidos',
        messageGerman: 'Spielanfrage erfolgreich angenommen und Punkte abgezogen',
        messageItaly: 'Richiesta di partita accettata con successo e punti detratti',
        messageRussain: 'Запрос на матч успешно принят и очки списаны',
        messageNetherlands: 'Wedstrijdverzoek succesvol geaccepteerd en punten afgetrokken',
        messageEgypt: 'تم قبول طلب المباراة بنجاح وتم خصم النقاط',
    },
    ApisMessage_You_do_not_have_enough_points_to_accept_this_request: {
        messageEnglish: 'You do not have enough points to accept this request',
        messageSpain: 'No tienes suficientes puntos para aceptar esta solicitud',
        messageGerman: 'Sie haben nicht genügend Punkte, um diese Anfrage zu akzeptieren',
        messageItaly: 'Non hai abbastanza punti per accettare questa richiesta',
        messageRussain: 'У вас недостаточно очков для принятия этого запроса',
        messageNetherlands: 'Je hebt niet genoeg punten om dit verzoek te accepteren',
        messageEgypt: 'ليس لديك نقاط كافية لقبول هذا الطلب',
    },
    ApisMessage_Match_Lost_and_pelenty_money_refund_to_your_wallet: {
        messageEnglish: 'Match Lost and penalty money refunded to your wallet',
        messageSpain: 'Partido perdido y dinero de la penalización reembolsado a tu cartera',
        messageGerman: 'Spiel verloren und Strafgeld wird an Ihr Wallet erstattet',
        messageItaly: 'Partita persa e denaro della penale rimborsato al tuo portafoglio',
        messageRussain: 'Матч проигран, и штрафные деньги возвращены в ваш кошелек',
        messageNetherlands: 'Wedstrijd verloren en boetegeld teruggestort in je portemonnee',
        messageEgypt: 'المباراة خسرت وتم استرداد المال الجزائي إلى محفظتك',
    },
    ApisMessage_Match_Lost_and_pelenty_point_refund_to_your_points: {
        messageEnglish: 'Match Lost and penalty points refunded to your points',
        messageSpain: 'Partido perdido y puntos de la penalización reembolsados a tus puntos',
        messageGerman: 'Spiel verloren und Strafpunkte werden auf Ihre Punkte erstattet',
        messageItaly: 'Partita persa e punti penalità rimborsati ai tuoi punti',
        messageRussain: 'Матч проигран, и штрафные очки возвращены на ваш счёт',
        messageNetherlands: 'Wedstrijd verloren en strafpunten teruggestort op je punten',
        messageEgypt: 'المباراة خسرت وتم استرداد نقاط الجزاء إلى نقاطك',
    },
    ApisMessage_This_user_is_already_registered: {
        messageEnglish: 'This user is already registered',
        messageSpain: 'Este usuario ya está registrado',
        messageGerman: 'Dieser Benutzer ist bereits registriert',
        messageItaly: 'Questo utente è già registrato',
        messageRussain: 'Этот пользователь уже зарегистрирован',
        messageNetherlands: 'Deze gebruiker is al geregistreerd',
        messageEgypt: 'هذا المستخدم مُسجل بالفعل',
    },
    ApisMessage_File_sending_error: {
        messageEnglish: 'File sending error',
        messageSpain: 'Error al enviar archivo',
        messageGerman: 'Fehler beim Senden der Datei',
        messageItaly: 'Errore nell\'invio del file',
        messageRussain: 'Ошибка отправки файла',
        messageNetherlands: 'Fout bij het verzenden van het bestand',
        messageEgypt: 'خطأ في إرسال الملف',
    },
    ApisMessage_This_user_is_already_invited: {
        messageEnglish: 'This user is already invited',
        messageSpain: 'Este usuario ya está invitado',
        messageGerman: 'Dieser Benutzer ist bereits eingeladen',
        messageItaly: 'Questo utente è già stato invitato',
        messageRussain: 'Этот пользователь уже приглашен',
        messageNetherlands: 'Deze gebruiker is al uitgenodigd',
        messageEgypt: 'تم دعوة هذا المستخدم بالفعل',
    },
    ApisMessage_successfully_invite_your_friend_get_Email_soon: {
        messageEnglish: 'Successfully invited your friend, they will receive an Email soon',
        messageSpain: 'Invitación a tu amigo realizada con éxito, recibirá un Email pronto',
        messageGerman: 'Erfolgreich deinen Freund eingeladen, er wird bald eine E-Mail erhalten',
        messageItaly: 'Il tuo amico è stato invitato con successo, riceverà presto un\'Email',
        messageRussain: 'Приглашение вашего друга отправлено, скоро он получит Email',
        messageNetherlands: 'Je hebt je vriend succesvol uitgenodigd, ze ontvangen binnenkort een e-mail',
        messageEgypt: 'لقد دعوت صديقك بنجاح وسيتلقى بريدًا إلكترونيًا قريبًا',
    },
    ApisMessage_Match_Tied: {
        messageEnglish: 'Match Tied',
        messageSpain: 'Partido Empatado',
        messageGerman: 'Spiel Unentschieden',
        messageItaly: 'Partita Pareggiata',
        messageRussain: 'Матч сыгран вничью',
        messageNetherlands: 'Wedstrijd Gelijkspeel',
        messageEgypt: 'المباراة متعادلة',
    },
    ApisMessage_participation_is_full: {
        messageEnglish: 'Participation is full',
        messageSpain: 'La participación está completa',
        messageGerman: 'Die Teilnahme ist voll',
        messageItaly: 'La partecipazione è completa',
        messageRussain: 'Все места заняты',
        messageNetherlands: 'Deelname is vol',
        messageEgypt: 'المشاركة مكتملة',
    },
    ApisMessage_Match_Lost: {
        messageEnglish: 'Match Lost',
        messageSpain: 'Partido Perdido',
        messageGerman: 'Spiel Verloren',
        messageItaly: 'Partita Persa',
        messageRussain: 'Матч проигран',
        messageNetherlands: 'Wedstrijd Verloren',
        messageEgypt: 'المباراة خسرت',
    },
    ApisMessage_You_have_a_Pending_Request: {
        messageEnglish: 'You have a pending request',
        messageSpain: 'Tienes una solicitud pendiente',
        messageGerman: 'Du hast eine ausstehende Anfrage',
        messageItaly: 'Hai una richiesta in sospeso',
        messageRussain: 'У вас есть нерассмотренный запрос',
        messageNetherlands: 'Je hebt een hangend verzoek',
        messageEgypt: 'لديك طلب معلق',
    },
    ApisMessage_IBAN_Number_Not_Found: {
        messageEnglish: 'IBAN number not found',
        messageSpain: 'Número IBAN no encontrado',
        messageGerman: 'IBAN-Nummer nicht gefunden',
        messageItaly: 'Numero IBAN non trovato',
        messageRussain: 'Номер IBAN не найден',
        messageNetherlands: 'IBAN-nummer niet gevonden',
        messageEgypt: 'رقم الآيبان غير موجود',
    },
    ApisMessage_You_cant_subscribe_to_this_level_as_youre_not_promoted_from_the_previous_level: {
        messageEnglish: 'You cannot subscribe to this level as you are not promoted from the previous level',
        messageSpain: 'No puedes suscribirte a este nivel ya que no has sido promovido del nivel anterior',
        messageGerman: 'Du kannst dich nicht für diese Stufe einschreiben, da du von der vorherigen Stufe nicht befördert wurdest',
        messageItaly: 'Non puoi iscriverti a questo livello in quanto non sei stato promosso dal livello precedente',
        messageRussain: 'Вы не можете подписаться на этот уровень, так как не были повышены с предыдущего уровня',
        messageNetherlands: 'Je kunt je niet inschrijven voor dit niveau omdat je niet bent bevorderd van het vorige niveau',
        messageEgypt: 'لا يمكنك الاشتراك في هذا المستوى لأنك لم تُرق من المستوى السابق',
    },
    ApisMessage_compitetion_subscription_is_full: {
        messageEnglish: 'Competition subscription is full',
        messageSpain: 'La subscripción a la competición está completa',
        messageGerman: 'Die Anmeldung zum Wettbewerb ist voll',
        messageItaly: 'L\'iscrizione alla competizione è completa',
        messageRussain: 'Регистрация на соревнование полностью завершена',
        messageNetherlands: 'Inschrijving voor de competitie is vol',
        messageEgypt: 'اكتمال الاشتراك في المسابقة',
    },
    ApisMessage_This_Coupon_discount_is_more_then_your_fee: {
        messageEnglish: 'This coupon discount is more than your fee',
        messageSpain: 'Este descuento de cupón es mayor que tu tarifa',
        messageGerman: 'Dieser Gutscheinrabatt ist höher als deine Gebühr',
        messageItaly: 'Questo sconto con coupon è maggiore della tua tariffa',
        messageRussain: 'Скидка по этому купону больше вашей платы',
        messageNetherlands: 'Deze couponkorting is meer dan je tarief',
        messageEgypt: 'خصم هذه القسيمة أكثر من رسومك',
    },
    ApisMessage_You_cant_play_more_then_ten_matches_in_the_competition: {
        messageEnglish: 'You cannot play more than 10 matches in the competition',
        messageSpain: 'No puedes jugar más de 10 partidos en la competición',
        messageGerman: 'Du kannst nicht mehr als 10 Spiele im Wettbewerb spielen',
        messageItaly: 'Non puoi giocare più di 10 partite nella competizione',
        messageRussain: 'Вы не можете играть более 10 матчей в соревновании',
        messageNetherlands: 'Je kunt niet meer dan 10 wedstrijden spelen in de competitie',
        messageEgypt: 'لا يمكنك لعب أكثر من 10 مباريات في المسابقة',
    },
    ApisMessage_Successfully_rejected_match_request: {
        messageEnglish: 'Match request successfully rejected',
        messageSpain: 'Solicitud de partido rechazada con éxito',
        messageGerman: 'Spielanfrage erfolgreich abgelehnt',
        messageItaly: 'Richiesta di partita rifiutata con successo',
        messageRussain: 'Запрос на матч успешно отклонен',
        messageNetherlands: 'Wedstrijdverzoek succesvol afgewezen',
        messageEgypt: 'تم رفض طلب المباراة بنجاح',
    },
    ApisMessage_Successfully_accepted_match_request: {
        messageEnglish: "Successfully accepted match request",
        messageSpain: "Solicitud de partido aceptada con éxito",
        messageGerman: "Spielanfrage erfolgreich angenommen",
        messageItaly: "Richiesta di partita accettata con successo",
        messageRussain: "Заявка на матч успешно принята",
        messageNetherlands: "Wedstrijdverzoek succesvol geaccepteerd",
        messageEgypt: "تم قبول طلب المباراة بنجاح",
    },
    ApisMessage_Match_Lost_and_pelenty_points_refund_to_your_points: {
        messageEnglish: "Match Lost and penalty points refunded to your points",
        messageSpain: "Partido perdido y puntos de penalización reembolsados a tus puntos",
        messageGerman: "Spiel verloren und Strafpunkte wurden zu deinen Punkten erstattet",
        messageItaly: "Partita persa e punti di penalità rimborsati ai tuoi punti",
        messageRussain: "Матч проигран и штрафные очки возвращены на ваш счет",
        messageNetherlands: "Wedstrijd verloren en strafpunten teruggestort op je punten",
        messageEgypt: "خسرت المباراة وتم رد نقاط الجزاء إلى نقاطك",
    },
    ApisMessage_Either_your_link_has_expired_or_you_have_provided_incorrect_link: {
        messageEnglish: "Sorry, unable to verify your email. Either your link has expired or you have provided an incorrect link",
        messageSpain: "Lo sentimos, no se puede verificar tu correo electrónico. O bien tu enlace ha expirado o has proporcionado un enlace incorrecto",
        messageGerman: "Entschuldigung, Ihre E-Mail kann nicht verifiziert werden. Entweder ist Ihr Link abgelaufen oder Sie haben einen falschen Link angegeben",
        messageItaly: "Spiacenti, impossibile verificare la tua email. O il tuo link è scaduto o hai fornito un link errato",
        messageRussain: "Извините, не удалось верифицировать вашу электронную почту. Либо ваша ссылка истекла, либо вы предоставили неверную ссылку",
        messageNetherlands: "Sorry, het is niet mogelijk om je e-mail te verifiëren. Je link is verlopen of je hebt een onjuiste link opgegeven",
        messageEgypt: "عذرًا، لا يمكن التحقق من بريدك الإلكتروني. إما أن الرابط قد انتهت صلاحيته أو أنك قدمت رابطًا غير صحيح",
    },
    ApisMessage_Email_Already_Verified: {
        messageEnglish: "Email already verified",
        messageSpain: "Correo electrónico ya verificado",
        messageGerman: "E-Mail bereits verifiziert",
        messageItaly: "Email già verificata",
        messageRussain: "Электронная почта уже подтверждена",
        messageNetherlands: "E-mail al geverifieerd",
        messageEgypt: "البريد الإلكتروني مُحقق بالفعل",
    },
    ApisMessage_Your_link_has_either_expired_or_become_invalid_Request_another_link: {
        messageEnglish: "Sorry, unable to verify your email. Your link has either expired or become invalid. Please request another link",
        messageSpain: "Lo sentimos, no se puede verificar tu correo electrónico. Tu enlace ha expirado o se ha vuelto inválido. Por favor, solicita otro enlace",
        messageGerman: "Entschuldigung, Ihre E-Mail kann nicht verifiziert werden. Entweder ist Ihr Link abgelaufen oder ungültig geworden. Bitte fordern Sie einen neuen Link an",
        messageItaly: "Spiacente, impossibile verificare la tua e-mail. Il tuo link è scaduto o diventato invalido. Si prega di richiedere un altro link",
        messageRussain: "Извините, не удалось верифицировать вашу электронную почту. Ваша ссылка истекла или стала недействительной. Пожалуйста, запросите другую ссылку",
        messageNetherlands: "Sorry, het is niet mogelijk om je e-mail te verifiëren. Je link is verlopen of ongeldig geworden. Vraag een nieuwe link aan",
        messageEgypt: "عذرًا، لا يمكن التحقق من بريدك الإلكتروني. إما أن رابطك قد انتهت صلاحيته أو أصبح غير صالح. يرجى طلب رابط آخر",
    },
    ApisMessage_Successfully_verified_your_Account_you_can_Login_Now: {
        messageEnglish: "Successfully verified your account. You can log in now",
        messageSpain: "Cuenta verificada con éxito. Ahora puedes iniciar sesión",
        messageGerman: "Konto erfolgreich verifiziert. Sie können sich jetzt anmelden",
        messageItaly: "Account verificato con successo. Ora puoi accedere",
        messageRussain: "Учетная запись успешно подтверждена. Вы можете войти сейчас",
        messageNetherlands: "Account succesvol geverifieerd. Je kunt nu inloggen",
        messageEgypt: "تم التحقق بنجاح من حسابك. يمكنك تسجيل الدخول الآن",
    },
    ApisMessage_Sorry_your_verification_link_has_expired_We_have_sent_another_verification_link_to_your_email: {
        messageEnglish: "Sorry, your verification link has expired. We have sent another verification link to your email. Please check your inbox",
        messageSpain: "Lo sentimos, tu enlace de verificación ha expirado. Hemos enviado otro enlace de verificación a tu correo. Por favor, revisa tu buzón",
        messageGerman: "Entschuldigung, Ihr Verifizierungslink ist abgelaufen. Wir haben einen neuen Verifizierungslink an Ihre E-Mail gesendet. Bitte überprüfen Sie Ihren Posteingang",
        messageItaly: "Spiacenti, il tuo link di verifica è scaduto. Abbiamo inviato un altro link di verifica alla tua e-mail. Si prega di controllare la posta in arrivo",
        messageRussain: "Извините, ваша ссылка для подтверждения истекла. Мы отправили другую ссылку для подтверждения на вашу электронную почту. Пожалуйста, проверьте ваш почтовый ящик",
        messageNetherlands: "Sorry, je verificatielink is verlopen. We hebben een nieuwe verificatielink naar je e-mail gestuurd. Controleer je inbox",
        messageEgypt: "عذرًا، انتهت صلاحية رابط التحقق الخاص بك. لقد أرسلنا رابط تحقق آخر إلى بريدك الإلكتروني. يرجى التحقق من البريد الوارد",
    },
    ApisMessage_Unable_to_verify_your_request_Either_your_link_has_expired_or_you_have_provided_incorrect_link: {
        messageEnglish: "Unable to verify your request. Either your link has expired or you have provided an incorrect link",
        messageSpain: "No se puede verificar su solicitud. O bien su enlace ha caducado o ha proporcionado un enlace incorrecto",
        messageGerman: "Ihre Anfrage kann nicht überprüft werden. Entweder ist Ihr Link abgelaufen oder Sie haben einen falschen Link angegeben",
        messageItaly: "Impossibile verificare la tua richiesta. O il tuo link è scaduto o hai fornito un link errato",
        messageRussain: "Не удается проверить ваш запрос. Либо ваша ссылка истекла, либо вы указали неверную ссылку",
        messageNetherlands: "Het is niet mogelijk om je aanvraag te verifiëren. Je link is verlopen of je hebt een onjuiste link opgegeven",
        messageEgypt: "غير قادر على التحقق من طلبك. إما أن الرابط قد انتهت صلاحيته أو أنك قدمت رابطًا غير صحيح",
    },
    ApisMessage_Sorry_Unable_to_verify_your_request_Your_link_has_either_expired_or_become_invalid: {
        messageEnglish: "Sorry, unable to verify your request. Your link has either expired or become invalid",
        messageSpain: "Lo sentimos, no se puede verificar tu solicitud. Tu enlace ha expirado o se ha vuelto inválido",
        messageGerman: "Entschuldigung, Ihre Anfrage kann nicht verifiziert werden. Ihr Link ist entweder abgelaufen oder ungültig geworden",
        messageItaly: "Spiacente, non è possibile verificare la tua richiesta. Il tuo link è scaduto o non è più valido",
        messageRussain: "Извините, мы не можем проверить ваш запрос. Ваша ссылка либо истекла, либо стала недействительной",
        messageNetherlands: "Sorry, het is niet mogelijk om je aanvraag te verifiëren. Je link is verlopen of ongeldig geworden",
        messageEgypt: "عذرًا، لا يمكن التحقق من طلبك. إما أن رابطك قد انتهت صلاحيته أو أصبح غير صالح",
    },
    ApisMessage_Sorry_your_forgot_password_link_has_expired: {
        messageEnglish: "Sorry, your forgot password link has expired",
        messageSpain: "Lo sentimos, tu enlace para restablecer la contraseña ha caducado",
        messageGerman: "Entschuldigung, Ihr Link zum Zurücksetzen des Passworts ist abgelaufen",
        messageItaly: "Spiacente, il link per reimpostare la password è scaduto",
        messageRussain: "Извините, срок действия вашей ссылки для сброса пароля истек",
        messageNetherlands: "Sorry, je link voor het opnieuw instellen van je wachtwoord is verlopen",
        messageEgypt: "عذرًا، انتهت صلاحية رابط استعادة كلمة المرور الخاص بك",
    },
    ApisMessage_Ranks_not_found: {
        messageEnglish: "Ranks not found",
        messageSpain: "Rangos no encontrados",
        messageGerman: "Ränge nicht gefunden",
        messageItaly: "Gradi non trovati",
        messageRussain: "Звания не найдены",
        messageNetherlands: "Rangen niet gevonden",
        messageEgypt: "الرتب غير موجودة",
    },
    ApisMessage_Request_Not_Found: {
        messageEnglish: "Request not found",
        messageSpain: "Solicitud no encontrada",
        messageGerman: "Anfrage nicht gefunden",
        messageItaly: "Richiesta non trovata",
        messageRussain: "Запрос не найден",
        messageNetherlands: "Verzoek niet gevonden",
        messageEgypt: "الطلب غير موجود",
    },
    ApisMessage_Payment_Unsuccessful: {
        messageEnglish: "Payment Unsuccessful",
        messageSpain: "Pago no exitoso",
        messageGerman: "Zahlung nicht erfolgreich",
        messageItaly: "Pagamento non riuscito",
        messageRussain: "Платеж не прошел",
        messageNetherlands: "Betaling mislukt",
        messageEgypt: "الدفع فشل",
    },
    ApisMessage_Payment_Paid_Successfully: {
        messageEnglish: "Payment Paid Successfully",
        messageSpain: "Pago realizado con éxito",
        messageGerman: "Zahlung erfolgreich geleistet",
        messageItaly: "Pagamento effettuato con successo",
        messageRussain: "Платеж успешно совершен",
        messageNetherlands: "Betaling succesvol voldaan",
        messageEgypt: "تم الدفع بنجاح",
    },
    ApisMessage_No_Invited_User_Found: {
        messageEnglish: "No invited user found",
        messageSpain: "No se encontró ningún usuario invitado",
        messageGerman: "Kein eingeladener Benutzer gefunden",
        messageItaly: "Nessun utente invitato trovato",
        messageRussain: "Приглашенный пользователь не найден",
        messageNetherlands: "Geen uitgenodigde gebruiker gevonden",
        messageEgypt: "لم يتم العثور على مستخدم مدعو",
    },
    ApisMessage_Invalid_User: {
        messageEnglish: "Invalid User",
        messageSpain: "Usuario no válido",
        messageGerman: "Ungültiger Benutzer",
        messageItaly: "Utente non valido",
        messageRussain: "Неверный пользователь",
        messageNetherlands: "Ongeldige gebruiker",
        messageEgypt: "مستخدم غير صالح",
    },
    ApisMessage_This_Game_Added_In_Player_GamesSetting: {
        messageEnglish: "This Game Added In Player GamesSetting",
        messageSpain: "Este juego agregado en la configuración de juegos de jugadores",
        messageGerman: "Dieses Spiel wurde im Player GamesSetting hinzugefügt",
        messageItaly: "Questo gioco è stato aggiunto nell'impostazione dei giochi del giocatore",
        messageRussain: "Эта игрiа добавлена ​​в настройки игр игрока",
        messageNetherlands: "Эта игра добавлена ​​в настройки игр игрока",
        messageEgypt: "تمت إضافة هذه اللعبة إلى إعدادات ألعاب اللاعبين",
    },
    ApisMessage_Your_Proof_is_Submitted: {
        messageEnglish: "Your Proof is Submitted",
        messageSpain: "Su prueba ha sido enviada",
        messageGerman: "Ihr Nachweis ist eingereicht",
        messageItaly: "La tua prova è stata inviata",
        messageRussain: "Ваше доказательство отправлено",
        messageNetherlands: "Uw bewijs is ingediend",
        messageEgypt: "تم تقديم الدليل الخاص بك",
    },
    ApisMessage_competition_have_no_subscribers_waiting_for_player_subscribe_tournament: {
        messageEnglish: 'competition have no subscribers waiting for player subscribe tournament',
        messageSpain: 'la competencia no tiene suscriptores esperando que el jugador se suscriba al torneo',
        messageGerman: 'Beim Wettbewerb gibt es keine Abonnenten, die darauf warten, dass ein Spieler das Turnier abonniert',
        messageItaly: "la competizione non ha abbonati in attesa dell'iscrizione del giocatore al torneo",
        messageRussain: "у соревнования нет подписчиков, ожидание турнира по подписке игрока",
        messageNetherlands: "competitie heeft geen abonnees die wachten op een spelersinschrijvingstoernooi",
        messageEgypt: 'المنافسة ليس لديها مشتركين في انتظار اشتراك اللاعب في البطولة'
    },
    ApisMessage_The_Coupon_Discount_much_more_from_tournament_fee: {
        messageEnglish: 'The Coupon Discount much more from tournament fee',
        messageSpain: 'El cupón de descuento mucho más de la tarifa del torneo.',
        messageGerman: 'Der Coupon-Rabatt viel mehr von der Turniergebühr',
        messageItaly: 'Lo sconto coupon vale molto di più della quota del torneo',
        messageRussain: 'Скидка по купону намного больше от турнирного взноса.',
        messageNetherlands: 'De couponkorting veel meer op de toernooikosten',
        messageEgypt: 'قسيمة خصم أكثر بكثير من رسوم البطولة'
    },
    ApisMessage_Player_Game_Setting_Not_Found: {
        messageEnglish: 'PlayerGame Setting Not Found',
        messageSpain: 'Configuración de PlayerGame no encontrada',
        messageGerman: 'PlayerGame-Einstellung nicht gefunden',
        messageItaly: 'Impostazione PlayerGame non trovata',
        messageRussain: 'Настройка PlayerGame не найдена',
        messageNetherlands: 'PlayerGame-instelling niet gevonden',
        messageEgypt: 'لم يتم العثور على إعدادات لعبة اللاعب',
    },
    ApisMessage_You_Have_Dont_Enough_Wallet_Amount: {
        messageEnglish: 'You Have Dont Enough Wallet Amount',
        messageSpain: 'No tienes suficiente cantidad en tu billetera',
        messageGerman: 'Ihr Guthaben reicht nicht aus',
        messageItaly: 'Non hai abbastanza importo nel portafoglio',
        messageRussain: 'У вас недостаточно средств на кошельке',
        messageNetherlands: 'U heeft niet genoeg portemonneebedrag',
        messageEgypt: 'ليس لديك مبلغ كافٍ في المحفظة',
    },
    ApisMessage_Successfully_Subscribed_Points: {
        messageEnglish: 'You Successfully Subscribed Points Subscription',
        messageSpain: 'Usted se suscribió exitosamente a la suscripción de puntos',
        messageGerman: 'Sie haben das Punkteabonnement erfolgreich abonniert',
        messageItaly: "Hai sottoscritto con successo l'abbonamento ai punti",
        messageRussain: 'Вы успешно оформили подписку на баллы',
        messageNetherlands: 'U heeft met succes een puntenabonnement afgesloten',
        messageEgypt: 'لقد قمت بالاشتراك بنجاح في اشتراك النقاط',
    },
}


export default AllBackendMessages