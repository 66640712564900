import React, { useEffect } from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import { AddGameSuggestion, AdminAddGame, AdminClaim, AdminCoupons, AdminGame, AdminHome, AdminLogin, AdminProfile, AdminSelectTournaments, CreateMatchRequest, CreatePlayerTournament, CreateTournament, ForgotPassword, GameSuggestion, Home, InviteFriend, Login, MatchRequestScreen, MatchResult, PlayerClaim, PlayerContact, PlayerDetail, PlayerGameSetting, PlayerGameSuggestion, PlayerProRankingPiont, PlayerProfile, PlayerRankingPiont, PlayerTournamentSelect, PointSetting, Promoter, PromotingForm, Signup, TournamentMatchResults, UpdatePassword, VerifyEmail, SubscriptionScreen, PlayerInvoices, AdminAllInvoices, PlayerCashback, AdminCashback, BtwInvoice, AddFaq, AdminFaq, MainHome, WalletTransactions, CreateCompetitionMatchRequest, CompetitionSelect, CreateCompetition, CompetetionMatches, AdminCompetitionSelect, CompetitionMatchesResult, PlayerCompetitionRank, CompetitionSubInvoives, AdminCompetitionSubInvoices, VisiterHome, VisiterFaq } from '../Screens'
import { CompetitionResult, Faq, MenuLayout, SideMenuFaq, TournamentDetail } from '../Components'
import allPaths from './paths'
import { Result, Button } from 'antd'
import { assignLanguage, getFaqHelper } from '../utils/helpers'
import { AddFaqSetting } from '../Redux/actions/faqSettingActions'
import { useDispatch, useSelector } from 'react-redux' 
import { NewMenuLayout } from '../NewComponents'


const Page404 = (props) => {
    const { history } = props
    return (
        <Result
            status='404'
            title='404'
            subTitle='Sorry, the page you visited does not exist.'
            extra={<Button
                type='primary'
                className='form-button'
                onClick={() => history.push('/')}
            >Back to home</Button>}
        />
    )
}


const Routes = () => {
    const dispatch = useDispatch()
    const language = useSelector(state => state?.languageReducer)
    const selectedLanguage = assignLanguage(language?.language)

    const faqSettingAction = {
        AddFaqSetting: (e) => dispatch(AddFaqSetting(e)),
    }

    useEffect(() => {
        getFaqHelper(faqSettingAction)
    }, [])



    return (
        <Router>
            <Switch>
                <Route path={allPaths?.LOGIN} exact component={(p) => <Login language={language} selectedLanguage={selectedLanguage} />} />
                <Route path={allPaths?.SIGNUP} exact component={(p) => <Signup language={language} selectedLanguage={selectedLanguage} />} />
                <Route path={allPaths?.FORGOT_PASSOWRD} exact component={(p) => <ForgotPassword language={language} selectedLanguage={selectedLanguage} />} />
                <Route path={allPaths?.ADMIN_LOGIN} exact component={(p) => <AdminLogin language={language} selectedLanguage={selectedLanguage} />} />
                <Route path={allPaths?.FAQ} exact component={(p) => <VisiterFaq language={language} selectedLanguage={selectedLanguage} />} />
                <Route path={`${allPaths?.VERIFY_EMAIL}/:token`} exact component={(p) => <VerifyEmail language={language} selectedLanguage={selectedLanguage} />} />
                <Route path={`${allPaths?.UPDATE_PASSWORD}/:token`} exact component={(p) => <UpdatePassword language={language} selectedLanguage={selectedLanguage} />} />
                <Route path={`${allPaths?.PROMOTING_FORM}`} exact component={(p) => <PromotingForm language={language} selectedLanguage={selectedLanguage} />} />
                <Route path={allPaths?.VISITER_HOME} exact component={(p) => <VisiterHome language={language} selectedLanguage={selectedLanguage} />} />

                {/* New Layout For Player  */}
                <NewMenuLayout exact path={allPaths?.HOME} component={Home} />
                <NewMenuLayout path={allPaths?.PLAYER_CREATE_MATCH_REQ} exact component={CreateMatchRequest} />
                <NewMenuLayout path={allPaths?.PLAYER_CREATE_TOURNAMENT} exact component={CreatePlayerTournament} />
                <NewMenuLayout path={allPaths?.PLAYER_CREATE_COMPETITION_MATCH} exact component={CreateCompetitionMatchRequest} />
                <NewMenuLayout path={allPaths?.PLAYER_MATCH_REQUESTS} exact component={MatchRequestScreen} />
                <NewMenuLayout path={allPaths?.PLAYER_MATCH_RESULT} exact component={MatchResult} />
                <NewMenuLayout path={allPaths?.PLAYER_TOURNAMENT_MATCHES} exact component={TournamentMatchResults} />
                <NewMenuLayout path={allPaths?.PLAYER_SELECT_TOURNAMENT} exact component={PlayerTournamentSelect} />
                <NewMenuLayout path={allPaths?.COMPETITION_SELECT} exact component={CompetitionSelect} />
                <NewMenuLayout path={allPaths?.TOURNAMENT_PARTICIPATION} exact component={TournamentDetail} />
                <NewMenuLayout path={allPaths?.COMPETITION_RESULT} exact component={CompetitionResult} />
                <NewMenuLayout path={allPaths?.COMPETITION_MATCHES} exact component={CompetetionMatches} />
                <NewMenuLayout path={allPaths?.COMPETITION_MATCHES_RESULTS} exact component={CompetitionMatchesResult} />
                <NewMenuLayout path={allPaths?.PLAYER_RANKING_POINT} exact component={PlayerRankingPiont} />
                <NewMenuLayout path={allPaths?.PLAYER__PRO_RANKING_POINT} exact component={PlayerProRankingPiont} />
                <NewMenuLayout path={allPaths?.COMPETITION_RANKING_POINT} exact component={PlayerCompetitionRank} />
                <NewMenuLayout path={allPaths?.PLAYER_CLAIM} exact component={PlayerClaim} />
                <NewMenuLayout path={allPaths?.PLAYER_PROFILE} exact component={PlayerProfile} />
                <NewMenuLayout path={allPaths?.PLAYER_CONTACT} exact component={PlayerContact} />
                <NewMenuLayout path={allPaths?.PLAYER_GAME_SETTING} exact component={PlayerGameSetting} />
                <NewMenuLayout path={allPaths?.PLAYER_CREATE_SUGGESTION} exact component={PlayerGameSuggestion} />
                <NewMenuLayout path={allPaths?.PLAYER_INVITE_FRIEND} exact component={InviteFriend} />
                <NewMenuLayout path={allPaths?.SIDE_MENU_FAQ} exact component={SideMenuFaq} />
                <NewMenuLayout path={allPaths?.PLAYER_SUBSCRIPTION} exact component={SubscriptionScreen} />
                <NewMenuLayout path={allPaths?.PLAYER_INVOIVE} exact component={PlayerInvoices} />
                <NewMenuLayout path={allPaths?.COMPETITION_SUB_INVOICE} exact component={CompetitionSubInvoives} />
                <NewMenuLayout path={allPaths?.PLAYER_CASHBACK} exact component={PlayerCashback} />
                <NewMenuLayout path={allPaths?.PLAYER_WALLET_TRANSACTION} exact component={WalletTransactions} />

                {/* New Layout For  Admin Routes */}

                <NewMenuLayout path={allPaths?.ADMIN_PROFILE} exact component={AdminProfile} />
                <NewMenuLayout path={allPaths?.ADMIN_CREATE_GAME} exact component={AdminAddGame} />
                <NewMenuLayout path={allPaths?.ADMIN_GAME_SUGGESTION} exact component={GameSuggestion} />
                <NewMenuLayout path={allPaths?.ADMIN_CREATE_TOURNAMENT} exact component={CreateTournament} />
                <NewMenuLayout path={allPaths?.ADMIN_PROMOTER_SETTINGS} exact component={Promoter} />
                <NewMenuLayout path={allPaths?.ADMIN_POINT_SETTINGS} exact component={PointSetting} />
                <NewMenuLayout path={allPaths?.ADMIN_ADD_FAQ} exact component={AddFaq} />
                <NewMenuLayout path={allPaths?.ADMIN_CREATE_COMPETITION} exact component={CreateCompetition} />
                <NewMenuLayout path={allPaths?.ADMIN_COMPETITION_SELECT} exact component={AdminCompetitionSelect} />
                <NewMenuLayout path={allPaths?.ADMIN_TOURNAMENT_SELECT} exact component={AdminSelectTournaments} />
                <NewMenuLayout path={allPaths?.ADMIN_GAMES} exact component={AdminGame} />
                <NewMenuLayout path={allPaths?.ADMIN_COUPONS} exact component={AdminCoupons} />
                <NewMenuLayout path={allPaths?.ADMIN__FAQ} exact component={AdminFaq} />
                <NewMenuLayout path={allPaths?.ADMIN_PLAYER_DETAIL} exact component={PlayerDetail} />
                <NewMenuLayout path={allPaths?.ADMIN_CLAIM_ARGUES} exact component={AdminClaim} />
                <NewMenuLayout path={allPaths?.ADMIN_PLAYER_INVOICES} exact component={AdminAllInvoices} />
                <NewMenuLayout path={allPaths?.ADMIN_BTW_INVOICES} exact component={BtwInvoice} />
                <NewMenuLayout path={allPaths?.ADMIN_COMPETITION_SUB_INVOICES} exact component={AdminCompetitionSubInvoices} />
                <NewMenuLayout path={allPaths?.ADMIN_CASHBACK} exact component={AdminCashback} />

                <Route path='/:page404' exact component={Page404} />
                <Route path='player/:page404' exact component={Page404} />
                <Route path='admin/:page404' exact component={Page404} />
            </Switch >
        </Router >
    )
}

export {
    Routes,
    Page404
}