import { Button, Modal, Popconfirm, Table } from 'antd';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { POST } from '../../../utils/apis';
import { BTW_TEX, assignMessage, convertNumber, convertRoundNumber, errorMessage, getPointSetting, successMessage } from '../../../utils/helpers';
import { currencySign } from '../../../utils/constants';

const RecivedMatchRequest = (props) => {
    const { selectedLanguage, language, getUser, pointSettingAction, receiveMatches } = props
    const AdminServiceFee = useSelector(state => state?.pointSettingReducer)
    const matchServiceFee = AdminServiceFee?.dollerMatchReqSendFee
    const matchServiceBTW = convertRoundNumber((matchServiceFee * BTW_TEX))
    const matchServiceFeeWithBTW = convertRoundNumber(matchServiceFee + (matchServiceFee * BTW_TEX))
    const [currentAccept, setCurrentAccept] = useState({})
    const [acceptloading, setAcceptLoading] = useState({})
    const [rejectloading, setRejectLoading] = useState({})
    const [confirmModal, setConfirmModal] = useState(false)

    const columns = [
        {
            title: selectedLanguage?.title_Game,
            dataIndex: 'gameId',
            render: (e) => {
                return (
                    <p className='table_p' style={{ width: '110px' }}>{e?.name || '-----'}</p>
                )
            }
        },
        {
            title: selectedLanguage?.title_GameType,
            dataIndex: 'matchType',
            render: (e) => {
                return (
                    <p className='table_p' style={{ width: '110px' }}>{e || '-----'}</p>
                )
            }
        },
        {
            title: selectedLanguage?.title_DateTime,
            render: (e) => {
                return (
                    <p className='table_p' style={{ width: '120px' }}>{e?.matchDate || '-----'} | {e?.matchTime || '-----'}</p>
                )
            }
        },
        {
            title: selectedLanguage?.title_Creatorofmatchrequest,
            dataIndex: 'senderId',
            render: (e) => {
                return (
                    <p className='table_p' style={{ width: '120px' }}>{e?.name}</p>
                )
            }

        },
        {
            title: selectedLanguage?.text_dollerpoints,
            render: (e) => {
                return (
                    <p className='table_p'>{convertNumber(e?.proPoints)}</p>
                )
            }
        },
        {
            title: `${selectedLanguage?.title_Points}/${selectedLanguage?.text_money}`,
            render: (e) => {
                return (
                    <p className='table_p'>{e?.isStake ? `${currencySign} ${convertNumber(e?.money)}` : convertNumber(e?.points) || '----'}</p>
                )
            }
        },
        {
            title: `${selectedLanguage?.title_Oponentpoints}/${selectedLanguage?.text_money}`,
            render: (e) => {
                return (
                    <p className='table_p'>
                        {e?.isStake ? `${currencySign} ${convertNumber(e?.senderWalletBalance)}` : convertNumber(e.senderPoints) || '----'}
                    </p>
                )
            }
        },
        {
            title: `${selectedLanguage?.title_Mypoints}/${selectedLanguage?.text_money}`,
            render: (e) => {
                return (
                    <p className='table_p'>
                        {e?.isStake ? `${currencySign} ${convertNumber(e?.receiverWalletBalance)}` : convertNumber(e?.receiverPoints) || '----'}
                    </p>
                )
            }
        },
        {
            title: selectedLanguage?.title_Message,
            dataIndex: 'message',
            render: (e) => {
                return (
                    <p className='table_p' style={{ width: '120px' }}> {e || '----'}</p>
                )
            }
        },
        {
            title: selectedLanguage?.txt_Status,
            render: (e) => {
                console.log(e, "==e")
                return (
                    <>
                        {e?.isAccepted ?
                            <p className='accept-status' style={{ width: '188px' }}>{selectedLanguage?.textyouAcceptedTheRequest}</p>
                            :
                            <p className='pending-stats' style={{ width: '158px' }}>{selectedLanguage?.text_NewRequestArrived}</p>
                        }
                    </>
                )
            }
        },
        {
            title: selectedLanguage?.title_Action,
            render: (e) => {
                return (
                    <>
                        {
                            e?.isAccepted ?
                                <div style={{ display: 'flex' }} >
                                    <Button type='primary' disabled={true} style={{ opacity: 0.2 }}
                                    // className='accept_m_btn'
                                    >{selectedLanguage?.btn_Accept}</Button>
                                </div > :
                                <div style={{ display: 'flex' }}>
                                    <Button
                                        type='primary'
                                        // className='accept_m_btn'
                                        loading={acceptloading[e?._id]}
                                        onClick={() => {
                                            setConfirmModal(true)
                                            setCurrentAccept(e)
                                        }}
                                    >{selectedLanguage?.btn_Accept}</Button>
                                    <Popconfirm
                                        title={`${selectedLanguage?.text_AreYouWantToRejectMatchRequestThatRecivedFrom} ${e?.senderId?.name}?`}
                                        okText={selectedLanguage?.textDelete}
                                        cancelText={selectedLanguage?.textCancel}
                                        okButtonProps={{
                                            type: 'primary',
                                            className: 'form-button'
                                        }}
                                        cancelButtonProps={{ style: { borderRadius: 4 } }}
                                        onConfirm={() => {
                                            RejectMatchRequest(e)
                                        }}
                                    >
                                        <Button
                                            type='primary'
                                            danger
                                            loading={rejectloading[e?._id]}
                                            style={{ marginLeft: '10px' }}
                                        >{selectedLanguage?.btn_reject}</Button>
                                    </Popconfirm>
                                </div>}
                    </>
                )
            }
        }
    ]

    const AcceptMatchRequest = (data) => {
        console.log(data)
        setAcceptLoading({ [data?._id]: true })

        let obj = {
            _id: data?._id,
            userId: data?.receiverId,
            gameId: data?.gameId?._id,
            code: data?.code,
            shuffle: data?.shuffle,
            pointdeduct: data?.points * 2,
            proPointdeduct: data?.proPoints * 2,
            moneydeduct: data?.money * 2,
            isStake: data?.isStake
        }

        axios.post(POST?.ACCEPT_MATCH_REQUEST, obj)
            .then((res) => {
                const { data } = res
                setAcceptLoading({})
                if (data?.success) {
                    successMessage(assignMessage(data?.message, language?.language))
                }
                else {
                    errorMessage(assignMessage(data?.message, language?.language))
                }
            })
            .catch((e) => {
                setAcceptLoading({})
                errorMessage(assignMessage(e?.message, language?.language))
            })

    }

    const RejectMatchRequest = (data) => {
        setRejectLoading({ [data?._id]: true })
        let obj = {
            _id: data?._id,
            userId: data?.senderId?._id,
            gameId: data?.gameId?._id,
            pointRefund: data?.points * 2,
            proPointRefund: data?.proPoints * 2,
            moneyRefund: data?.money * 2,
            isStake: data?.isStake,
            shuffle: data?.shuffle,
            code: data?.code,
        }

        axios.post(POST?.REJECT_MATCH_REQUEST, obj)
            .then((res) => {
                const { data } = res
                setRejectLoading({})
                if (data?.success) {
                    successMessage(assignMessage(data?.message, language?.language))
                    getUser()
                }
                else {
                    errorMessage(assignMessage(data?.message, language?.language))
                }
            })
            .catch((e) => {
                setRejectLoading({})
                errorMessage(assignMessage(e?.message, language?.language))
            })
    }

    const handleOk = () => {
        AcceptMatchRequest(currentAccept)
        setConfirmModal(false)
    }
    const handleCancel = () => {
        setCurrentAccept({})
        setConfirmModal(false)
    }

    useEffect(() => {
        getPointSetting(pointSettingAction)
    }, [])

    return (
        <div >
            <h1 className='new-screen-title'>
                {selectedLanguage?.text_RecivedMatchRequest}
            </h1>

            <div style={{ marginTop: '20px' }}>
                <div className="custom-table-container">
                    <Table
                        locale={{ emptyText: selectedLanguage?.txt_NoDataFound }}
                        className='new-layout-table'
                        dataSource={receiveMatches}
                        columns={columns}
                        pagination={false}
                        scroll={{ x: 400 }}
                    />
                </div>
            </div>

            {/* Modal Confirm */}
            <Modal
                open={confirmModal}
                footer={null}
                className='pop-info-modal'
                style={{ top: '10px', }}
            >
                <div>
                    <p className='p-question'>{selectedLanguage?.text_AreYouSureYouWantToAcceptMacthRequest}</p>
                    {currentAccept?.isStake ?
                        <p className='p-opt'>{selectedLanguage?.text_Acceptingmatchrequestmoneywillbedeductfromyourwallet}</p>
                        :
                        <p className='p-opt'>{selectedLanguage?.text_byAcceptingMatchRequestPointsbeFeductFromYourAccount}</p>
                    }
                    <p className='p-opt'>
                        {!currentAccept?.isStake ? selectedLanguage?.text_point : selectedLanguage?.text_money}
                        :
                        {currentAccept?.isStake ? `${currencySign} ${convertRoundNumber(currentAccept?.money)}` : (currentAccept?.points)}
                    </p>
                    <p className='p-opt'>
                        {!currentAccept?.isStake ? selectedLanguage?.text_penaltyPoint : selectedLanguage?.text_penaltymoney}
                        :
                        {currentAccept?.isStake ? `${currencySign} ${convertRoundNumber(currentAccept?.money)}` : (currentAccept?.points)}
                    </p>
                    {currentAccept?.isStake &&
                        <p className='p-opt'>
                            {selectedLanguage?.text_AdminserviceFee}
                            :
                            {currencySign} {convertRoundNumber(matchServiceFee || 0)}
                        </p>}
                    {currentAccept?.isStake &&
                        <p className='p-opt'>
                            {selectedLanguage?.text_tmntyoneBTW}
                            :
                            {currencySign} {convertRoundNumber(matchServiceBTW || 0)}
                        </p>}
                    {currentAccept?.isStake &&
                        <p className='p-opt'>
                            {selectedLanguage?.text_AdminserviceFeeBTW}
                            :
                            {currencySign} {convertRoundNumber(matchServiceFeeWithBTW || 0)}
                        </p>}
                    <p className='p-opt'>
                        {!currentAccept?.isStake ? selectedLanguage?.text_TotalPoint : selectedLanguage?.text_TotalinclBTW}
                        :
                        {!currentAccept?.isStake ? currentAccept?.points * 2 : `${currencySign} ${convertRoundNumber((currentAccept?.money * 2) + (+matchServiceFeeWithBTW))}`}
                    </p>
                </div>
                <div style={{ display: 'flex', marginTop: 10 }}>
                    <button className='cancel-btn' onClick={handleCancel}>{selectedLanguage?.text_Cancel}</button>
                    <button className='confirm-btn' onClick={handleOk}>{selectedLanguage?.btn_Accept}</button>
                </div>

            </Modal>
            {/*  Modal Confirm*/}
        </div>
    )
}

export default RecivedMatchRequest