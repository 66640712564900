import { Button, Checkbox, DatePicker, Form, Input, InputNumber, Modal, Select, Switch, TimePicker } from 'antd'
import React, { useEffect, useState } from 'react'
import { currencySign, dateFormat } from '../../../utils/constants'
import { GET, POST } from '../../../utils/apis'
import axios from 'axios'
import moment from 'moment'
import { BTW_TEX, assignMessage, convertRoundNumber, disabledDate, errorMessage, getPointSetting, getUserData, infoMessage, removePlayerById, sortPlayersForMatchRequest, successMessage } from '../../../utils/helpers'
import { useSelector } from 'react-redux'
import { BiMoneyWithdraw, } from 'react-icons/bi'
import { GiRank1 } from 'react-icons/gi'

const CreateMatchRequest = (props) => {
    const { Option } = Select
    const { selectedLanguage, user, language, userActions, getUser, pointSettingAction } = props
    const AdminServiceFee = useSelector(state => state?.pointSettingReducer)
    const matchServiceFee = AdminServiceFee?.dollerMatchReqSendFee
    const matchServiceBTW = convertRoundNumber((matchServiceFee * BTW_TEX))
    const matchServiceFeeWithBTW = convertRoundNumber(matchServiceFee + (matchServiceFee * BTW_TEX))
    const gameSettings = useSelector(state => state?.userReducer?.gameSettings)
    const playerContacts = useSelector(state => state?.userReducer?.playerContacts)
    const players = useSelector(state => state?.userReducer?.players)
    const [form] = Form.useForm()
    const format = 'HH:mm'
    const [type, setType] = useState('1-1 Match Game')
    const [allValues, setAllValues] = useState({})
    const [loading, setLoading] = useState(false)
    const [confirmModal, setConfirmModal] = useState(false)
    const [shuffle, setShuffle] = useState(false)
    const [selectedGamePoint, setSelectedGamePoint] = useState(null)
    const [selectedGameProPoint, setSelectedGameProPoint] = useState(null)
    const [oponentPoint, setOponentPoint] = useState(null)
    const [selectedGameSetting, setSelectedGameSetting] = useState(null)
    const [selectedGameId, setSelectedGameId] = useState(null)
    const [sameSettingIds, setSameSettingIds] = useState(null)
    const [allPlayerRanks, setAllPlayerRanks] = useState([])
    let myPointHalf = Math.floor(selectedGamePoint / 2)
    let myProPointHalf = Math.floor(selectedGameProPoint / 2)
    let myWalletBalanceHalf = Math.floor(user?.walletBalance / 2)
    const [allOponent, setAllOpenent] = useState(sortPlayersForMatchRequest(players || [], playerContacts || []))
    const [stake, setStake] = useState(false)
    const [date, setDate] = useState(null)
    const [time, setTime] = useState(null)

    useEffect(() => {
        getPointSetting(pointSettingAction)
    }, [])
    useEffect(() => {
        getUserData(user?._id, userActions)
        const allOponents = sortPlayersForMatchRequest(players || [], playerContacts || [])
        setAllOpenent(allOponents)
    }, [])

    useEffect(() => {
        const playerIds = selectedGameSetting?.map(player => player?.playerId?._id);
        setSameSettingIds(playerIds)
    }, [selectedGameSetting])

    const onChangeDate = (date) => {
        setDate(moment(date).format(dateFormat))
    }
    const onChangeTime = (date) => {
        setTime(moment(date).format(format))
    }
    function onChangeStack(e) {
        setStake(e/* .target.checked */)
    }

    const handleChange = (value) => {
        getPlayerGameRank(value)
        getGameSetting(value)
        setSelectedGameId(value)
        getGameRanks(value)
    }

    const handleChangeOponent = (value) => {
        getOponentGameRank(value)
    }

    const getGameRanks = (id) => {
        axios.get(`${GET?.GET_GAME_RANKS}/${id}`,)
            .then((res) => {
                const { data } = res
                if (data?.success) {
                    setAllPlayerRanks(data?.data || [])
                }
                else {
                    setAllPlayerRanks([])
                }
            })
            .catch((e) => {
                errorMessage(assignMessage(e?.message, language?.language))
            })
    }

    const getPlayerGameRank = (id) => {
        axios.get(`${GET?.GET_PLAYER_GAME_RANK}/${id}/${user?._id}`)
            .then((res) => {
                const { data } = res
                if (data.success) {
                    setSelectedGamePoint(data?.data?.[0]?.point || 0)
                    setSelectedGameProPoint(data?.data?.[0]?.proPoint || 0)
                    if (data?.data?.[0]?.point === 0 || !data?.data?.[0]?.point) {
                        infoMessage(selectedLanguage?.msg_youhavenotenoughpointforthisgame)
                    }
                }
                else {
                    setSelectedGamePoint(0)
                    setSelectedGameProPoint(0)
                }
            })
            .catch((e) => {
                errorMessage(assignMessage(e?.message, language?.language))
            })
    }

    const getOponentGameRank = (id) => {
        axios.get(`${GET?.GET_PLAYER_GAME_RANK}/${selectedGameId}/${id}`)
            .then((res) => {
                const { data } = res
                if (data.success) {
                    setOponentPoint(data?.data?.[0]?.point || 0)
                }
                else {
                    setOponentPoint(0)
                }
            })
            .catch((e) => {
                errorMessage(assignMessage(e?.message, language?.language))
            })
    }

    const getGameSetting = (id) => {
        axios.get(`${GET?.GET_GAME_SETTING}/${id}`)
            .then((res) => {
                const { data } = res
                if (data.success) {
                    setSelectedGameSetting(removePlayerById(data?.data, user?._id) || [])
                }
                else {
                    setSelectedGameSetting([])
                }
            })
            .catch((e) => {
                errorMessage(assignMessage(e?.message, language?.language))
            })
    }

    const handleOk = () => {
        submitForm(allValues)
        setConfirmModal(false)
    }

    const handleCancel = () => {
        setConfirmModal(false)
    }

    const onFinish = (values) => {
        setAllValues(values)
        setConfirmModal(true)
    }

    const submitForm = (values) => {
        const receiverWalletamount = allOponent.find(obj => obj?._id === values?.receiverId)
        values.senderId = user?._id
        values.receiverId = values?.receiverId
        values.matchType = type
        values.matchDate = date
        values.matchTime = time
        values.points = values?.points
        values.proPoints = values?.proPoints ? values?.proPoints : 0
        values.senderPoints = selectedGamePoint
        values.receiverPoints = oponentPoint
        values.message = values?.message
        values.money = values?.money
        values.senderWalletBalance = user?.walletBalance
        values.receiverWalletBalance = receiverWalletamount?.walletBalance || 0
        values.isStake = stake

        axios.post(POST?.CREATE_MATCH_REQUEST, values)
            .then((res) => {
                const { data } = res
                setLoading(false)
                if (data.success) {
                    successMessage(assignMessage(data?.message, language?.language))
                    form.resetFields()
                    getUser()
                }
                else {
                    errorMessage(assignMessage(data?.message, language?.language))
                }
            })
            .catch((e) => {
                setLoading(false)
                errorMessage(assignMessage(e?.message, language?.language))
            })
    }
    const selectOpponents = allOponent?.length && allOponent?.map((v, i) => {
        let points = allPlayerRanks?.filter((x) => x?.userId?._id === v?._id)
        return {
            ...v,
            points: points?.[0]?.point
        }
    }).filter((v) => sameSettingIds?.includes(v?._id))

    return (
        <div className='new-main-component-screen-div' style={{ background: 'black' }}>
            <h1 className='new-screen-title orang-color' >
                {selectedLanguage?.titleCreateMatchRequest}
            </h1>
            <div style={{ marginTop: '20px' }}>
                <Form
                    name='form'
                    form={form}
                    onFinish={onFinish}
                    layout={'vertical'}
                >
                    <div className='form-two-input-flex'>
                        <div className='form-two-input-flex-inner-input'>
                            <p
                                className='p-admin-title-text'
                                style={{
                                    padding: '0px', margin: '0px', marginBottom: '4px', marginLeft: '4px'
                                }}
                            >{selectedLanguage?.text_SelectMatchType}</p>
                            <Select defaultValue={type}
                                placeholder={selectedLanguage?.text_SelectMatchType}
                                className='form-input-new-design-big-slam'
                                onChange={(e) => { setType(e) }}
                                style={{ width: '100%', marginBottom: '20px', marginTop: '5px' }}
                            >
                                <Option value={'1-1 Match Game'}>{selectedLanguage?.text_OneByOneMatchGame}</Option>
                                <Option value={'Team Match Game'}>{selectedLanguage?.text_TeamMatchGame}</Option>
                            </Select>
                        </div>
                        <div className='form-two-input-flex-inner-input'>
                            <Form.Item
                                name='gameId'
                                hasFeedback
                                label={<p className='p-admin-title-text'>{selectedLanguage?.label_SelectGame}</p>}
                                rules={[
                                    {
                                        required: true,
                                        message: selectedLanguage?.error_pleaseSelectGame
                                    }
                                ]}
                            >
                                <Select
                                    onChange={handleChange}
                                    notFoundContent={selectedLanguage?.txt_NoDataFound}
                                    className='form-input-new-design-big-slam'
                                    placeholder={selectedLanguage?.placeHolder_SelectGamefortournament}>
                                    {gameSettings?.length && gameSettings?.map((v, i) => {
                                        return (
                                            v?.gameId?.gameType === type &&
                                            <Option value={v?.gameId?._id}>{`${v?.gameId?.name} - ${v?.gameId?.gameType} - ${v?.gameId?.description} `}</Option>
                                        )
                                    })}
                                </Select>
                            </Form.Item>
                        </div>
                    </div>
                    <div className='form-two-input-flex'>
                        <div className='form-two-input-flex-inner-input '>
                            <Form.Item
                                name='shuffle'
                                onChange={(e) => setShuffle(e.target.checked)}
                                valuePropName='checked'>
                                <Checkbox
                                    className='form-input-new-design-big-slam'
                                    style={{ width: '100%', padding: '17px 14px' }}>
                                    {selectedLanguage?.text_Shuffle}
                                </Checkbox>
                            </Form.Item>
                        </div>
                        {shuffle &&
                            <div className='form-two-input-flex-inner-input '>
                                <Form.Item
                                    name='sameLevel'
                                    valuePropName='checked'>
                                    <Checkbox
                                        className='form-input-new-design-big-slam'
                                        style={{ width: '100%', padding: '17px 14px', backgroundColor: "white" }}
                                    >{selectedLanguage?.text_SameLevel}</Checkbox>
                                </Form.Item>
                            </div>
                        }
                    </div>
                    <div className='form-two-input-flex'>
                        <div className='form-two-input-flex-inner-input'>
                            <Form.Item
                                name='matchDate'
                                hasFeedback
                                label={<p className='p-admin-title-text'>{selectedLanguage?.label_Gamedate}</p>}
                                rules={[
                                    {
                                        required: true,
                                        message: selectedLanguage?.error_pleaseselectgamedate
                                    }
                                ]}
                            >
                                <DatePicker
                                    onChange={onChangeDate}
                                    disabledDate={disabledDate}
                                    className='form-input-new-design-big-slam'
                                    placeholder={selectedLanguage?.placeHolder_selectgamedate}
                                    style={{ width: '100%' }}
                                    allowClear={false}
                                    format={dateFormat}
                                />
                            </Form.Item>
                        </div>
                        <div className='form-two-input-flex-inner-input'>
                            <Form.Item
                                name='matchTime'
                                hasFeedback
                                label={<p className='p-admin-title-text'>{selectedLanguage?.label_gametime}</p>}
                                rules={[
                                    {
                                        required: true,
                                        message: selectedLanguage?.error_pleaseselectgametime
                                    }
                                ]}
                            >
                                <TimePicker
                                    onChange={onChangeTime}
                                    minuteStep={15}
                                    style={{ width: '100%' }}
                                    className='form-input-new-design-big-slam'
                                    placeholder={selectedLanguage?.plaecholder_selectgametime}
                                    format={format} />
                            </Form.Item>
                        </div>
                    </div>
                    <div className='form-two-input-flex'>
                        <div className='form-two-input-flex-inner-input '>
                            <Form.Item
                                label={<p className='p-admin-title-text'>{selectedLanguage?.text_Stackswitch}</p>}
                                name='isStake'
                                initialValue={stake}
                                onChange={onChangeStack}
                                valuePropName='checked'>
                                <div
                                    className='form-input-new-design-big-slam'
                                    style={{ display: 'flex', alignItems: 'center', background: 'white' }}
                                >
                                    <p style={{ margin: '0px 10px' }} className='color-bg'>{selectedLanguage?.title_Point}</p>
                                    <Switch
                                        onChange={onChangeStack}
                                        checked={stake}
                                        checkedChildren={<BiMoneyWithdraw size={19} style={{ height: 22, marginRight: 4 }} />}
                                        unCheckedChildren={<GiRank1 size={19} style={{ height: 22, marginLeft: 4 }} />} />
                                    <p style={{ margin: '0px 10px' }} className='color-bg'>{selectedLanguage?.text_money}</p>
                                </div>
                            </Form.Item>
                        </div>
                        {stake ?
                            <div className='form-two-input-flex-inner-input newInpuHeightFull'>
                                <Form.Item
                                    name='money'
                                    hasFeedback
                                    label={<p className='p-admin-title-text'>{selectedLanguage?.text_money}</p>}
                                    rules={[
                                        {
                                            required: true,
                                            message: selectedLanguage?.text_Pleaseentermoneyhere
                                        },
                                        ({ getFieldValue }) => ({
                                            validator(rule, value) {
                                                if (value < 1) {
                                                    return Promise.reject(selectedLanguage?.txt_Value_must_more_then + ' 0')
                                                }
                                                else if (value > myWalletBalanceHalf) {
                                                    return Promise.reject(selectedLanguage?.txt_Value_must_Less_then + ' ' + myWalletBalanceHalf)
                                                }
                                                return Promise.resolve()
                                            }
                                        })
                                    ]}
                                >
                                    <Input
                                        // Number
                                        disabled={!myWalletBalanceHalf}
                                        step={0.5}    // Set the step to 0.01 for two decimal places
                                        min={1}        // Set the minimum value
                                        precision={2}
                                        max={myWalletBalanceHalf}
                                        type='number'
                                        style={{ width: '100%' }}
                                        className='form-input-new-design-big-slam'
                                        placeholder={selectedLanguage?.place_enterMoneyHere}
                                    />
                                </Form.Item>
                                {user?.walletBalance ?
                                    <p style={{ marginTop: '-20px', fontSize: 12 }} className='p-admin-title-text' >
                                        {selectedLanguage?.text_minValueAndMaxis} {convertRoundNumber(myWalletBalanceHalf)}</p>
                                    : null
                                }
                            </div>
                            :
                            <div className='form-two-input-flex-inner-input newInpuHeightFull'>
                                <Form.Item
                                    name='points'
                                    hasFeedback
                                    label={<p className='p-admin-title-text'>{selectedLanguage?.label_point}</p>}
                                    rules={[
                                        {
                                            required: true,
                                            message: selectedLanguage?.error_enterpoint
                                        },
                                        ({ getFieldValue }) => ({
                                            validator(rule, value) {
                                                if (value < 1) {
                                                    return Promise.reject(selectedLanguage?.txt_Value_must_more_then + ' 0')
                                                }
                                                else if (value > myPointHalf) {
                                                    return Promise.reject(selectedLanguage?.txt_Value_must_Less_then + ' ' + myPointHalf)
                                                }
                                                return Promise.resolve()
                                            }
                                        })
                                    ]}
                                >
                                    <Input
                                        // {/* Number */}
                                        disabled={!myPointHalf}
                                        min={1}
                                        max={myPointHalf}
                                        type='number'
                                        style={{ width: '100%' }}
                                        className='form-input-new-design-big-slam'
                                        placeholder={selectedLanguage?.placeHolder_point}
                                    />
                                </Form.Item>
                                {myPointHalf ? <p className='p-admin-title-text' style={{ marginTop: '-20px', fontSize: 12 }}>{selectedLanguage?.text_minValueAndMaxis} {myPointHalf}</p> : null}
                            </div>}
                    </div>
                    {stake &&
                        <div className='form-input-single-input'>
                            <div className='form-two-input-flex-inner-input newInpuHeightFull'
                                style={{ width: '100%' }}
                            >
                                <Form.Item
                                    name='proPoints'
                                    hasFeedback
                                    label={<p className='p-admin-title-text'>{selectedLanguage?.text_dlrpoint}</p>}
                                    initialValue={1}
                                    rules={[
                                        {
                                            required: false,
                                            message: selectedLanguage?.error_enterpoint
                                        },
                                        ({ getFieldValue }) => ({
                                            validator(rule, value) {
                                                if (value < 1) {
                                                    return Promise.reject(selectedLanguage?.txt_Value_must_more_then + ' 0')
                                                }
                                                else if (value > myProPointHalf) {
                                                    return Promise.reject(selectedLanguage?.txt_Value_must_Less_then + ' ' + myProPointHalf)
                                                }
                                                return Promise.resolve()
                                            }
                                        })
                                    ]}

                                >
                                    <Input
                                        // Number
                                        disabled={!myProPointHalf}
                                        max={myProPointHalf}
                                        type='number'
                                        style={{ width: '100%' }}
                                        className='form-input-new-design-big-slam'
                                        placeholder={selectedLanguage?.text_enterDlrpoint}
                                    />
                                </Form.Item>
                                {myProPointHalf ? <p className='p-admin-title-text' style={{ marginTop: '-20px' }}>{selectedLanguage?.text_maxDlrPointsis} {myProPointHalf}</p> : null}

                            </div>
                        </div>}

                    {!shuffle
                        &&
                        <div className='form-input-single-input'>
                            <div className='form-two-input-flex-inner-input'
                                style={{ width: '100%' }}
                            >
                                <Form.Item
                                    name='receiverId'
                                    hasFeedback
                                    label={<p className='p-admin-title-text'>{selectedLanguage?.label_Selectoponent}</p>}
                                    rules={[
                                        {
                                            required: !shuffle,
                                            message: selectedLanguage?.error_pleaseselectanoponent
                                        }
                                    ]}
                                >
                                    <Select
                                        disabled={!myPointHalf || shuffle}
                                        onChange={handleChangeOponent}
                                        notFoundContent={selectedLanguage?.txt_NoDataFound}
                                        className='form-input-new-design-big-slam new-for-text-top-issue'
                                        showSearch={true}
                                        optionFilterProp="children"
                                        filterOption={(input, option) =>
                                            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                        }
                                        placeholder={selectedLanguage?.placeHolder_selectanoponent}
                                        options={
                                            (selectOpponents || [])?.map((v, i) => {
                                                return {
                                                    value: v?._id,
                                                    label: `${v?.email} - ${(v?.points || 0)}`
                                                }
                                            })
                                        } />
                                </Form.Item>
                            </div>
                        </div>
                    }

                    <div className='form-input-single-input'>
                        <Form.Item
                            name='message'
                            hasFeedback
                            label={<p className='p-admin-title-text'>{selectedLanguage?.label_Message}</p>}
                            rules={[
                                {
                                    required: true,
                                    message: selectedLanguage?.error_pleaseEnterAmessage
                                }
                            ]}
                        >
                            <Input
                                className='form-input-new-design-big-slam'
                                placeholder={selectedLanguage?.placeHolder_enterAmessage}
                            />
                        </Form.Item>
                    </div>
                    <Form.Item>

                        <Button
                            type='primary'
                            htmlType='submit'
                            loading={loading}
                        >{selectedLanguage?.btn_SendMatchRequest_send}
                        </Button>
                    </Form.Item>
                </Form>
            </div>
            < Modal
                open={confirmModal}
                footer={null}
                className='pop-info-modal'
                style={{ top: '10px', }}
            >
                <div>
                    <p className='p-question'>{selectedLanguage?.textAreYouSureYOuWantToCreatMacthRequest}</p>
                    {!stake ?
                        <p className='p-opt'>{selectedLanguage?.text_byCreatingMatchRequestPointsWillBeDeductFromYourAccount}</p>
                        : <p className='p-opt'>{selectedLanguage?.text_bycreatingamatchrequestmoneywillbedeductfromyourwallet}</p>}
                    <p className='p-opt'>
                        {!stake ? selectedLanguage?.text_point : selectedLanguage?.text_money}
                        :
                        {!stake ? allValues?.points : `${currencySign} ${convertRoundNumber(allValues?.money)}`}
                    </p>
                    <p className='p-opt'>
                        {!stake ? selectedLanguage?.text_penaltyPoint : selectedLanguage?.text_penaltymoney}
                        :
                        {!stake ? allValues?.points : `${currencySign} ${convertRoundNumber(allValues?.money)}`}
                    </p>
                    {stake && <p className='p-opt'>
                        {selectedLanguage?.text_AdminserviceFee}
                        :
                        {currencySign} {convertRoundNumber(matchServiceFee || 0)}
                    </p>}
                    {stake && <p className='p-opt'>
                        {selectedLanguage?.text_tmntyoneBTW}
                        :
                        {currencySign} {convertRoundNumber(matchServiceBTW)}
                    </p>}
                    {stake && <p className='p-opt'>
                        {selectedLanguage?.text_AdminserviceFeeBTW}
                        :
                        {currencySign} {convertRoundNumber(matchServiceFeeWithBTW)}
                    </p>}
                    <p className='p-opt'>
                        {!stake ? selectedLanguage?.text_TotalPoint : selectedLanguage?.text_TotalinclBTW}
                        :
                        {!stake ? allValues?.points * 2 : `${currencySign} ${convertRoundNumber((allValues?.money * 2) + (+matchServiceFeeWithBTW))}`}
                    </p>
                </div>
                <div style={{ display: 'flex', marginTop: 10 }}>
                    <button className='cancel-btn' onClick={handleCancel}>{selectedLanguage?.text_Cancel}</button>
                    <button className='confirm-btn' onClick={handleOk}>{selectedLanguage?.btn_SendMatchRequest_send}</button>
                </div>
            </Modal >
        </div >
    )
}

export default CreateMatchRequest