import { GAMES, PROMOTER, ADMIN_TOURNAMENT, ALL_COMPETITIONS } from '../types'

const reducer = (state = {}, action) => {
    switch (action.type) {

        case GAMES: {
            return { ...state, games: action.games }
        }
        case PROMOTER: {
            return { ...state, promoter: action.promoter }
        }
        case ADMIN_TOURNAMENT: {
            return { ...state, adminTournament: action.adminTournament }
        }
        case ALL_COMPETITIONS: {
            return { ...state, allCompetitions: action.allCompetitions }
        }
        default: {
            return state
        }
    }
}

export default reducer