import React, { useEffect, useState } from 'react'
import NoData from '../../../assets/noDataImae.png'
import { Button, Popconfirm, Spin, Table } from 'antd'
import { assignMessage, checkIfDateTimePassedForMatchResultBtns, errorMessage, getCompetitionMatches, successMessage } from '../../../utils/helpers'
import { useSelector } from 'react-redux'
import io from 'socket.io-client'
import axios from 'axios'
import { POST } from '../../../utils/apis'
import ClaimMatchModal from '../MatchRequest/ClaimMatchModal'
import DrawMatchProof from '../MatchRequest/DrawMatchProof'
import moment from 'moment'

const CompetitionMatchesResult = (props) => {
    const { selectedLanguage, userActions, user, isEnglish, language } = props
    const [deleteLoading, setDeleteLoading] = useState({})
    const competitionMatches = useSelector(state => state.userReducer.competitionMatches)
    const [data, setData] = useState(competitionMatches)

    const [spin, setSpin] = useState({})
    const [claimData, setClaimData] = useState({})
    const [drawData, setDrawData] = useState({})

    console.log('competitionMatches', competitionMatches)
    
    useEffect(() => {
        getCompetitionMatches(user?._id, userActions)
        getSocketInfo()
    }, [])

    useEffect(() => {
        const intervalId = setInterval(() => {
            const newData = competitionMatches.map(item => {
                if (item?.updateTime) {
                    let diffMinutes = moment.utc().diff(moment.utc(item?.updateTime), 'minutes');
                    return { ...item, diffMinutes: diffMinutes }
                } else {
                    return { ...item, diffMinutes: -1 }
                }
            })
            setData(newData)
        }, 5000)

        return () => clearInterval(intervalId)
    }, [])

    const handleOk = () => {
        setClaimData({})
        setDrawData({})
    }
    let origin = `https://wgpplaza.com`
    if (process.env.NODE_ENV === 'development') {
        origin = `http://localhost:4000`
    }

    const socket = io(origin, { reconnect: true });

    socket.on('connect', () => {
        console.log('connected')
    })

    const getSocketInfo = () => {
        socket.on('matchRequestResponse', (d) => {
            const { documentKey, operationType, updateDescription } = d
            getCompetitionMatches(user?._id, userActions)

        })
    }

    const userLostMatch = (e) => {
        
        let obj = {
            gameId: e?.gameId?._id,
            userId: user?._id,
            opponentId: e?.receiverId?._id !== user?._id ? e?.receiverId?._id : e?.senderId?._id,
            competition: e?.competition?._id,
            _id: e?._id
        }

        setSpin({ [e?._id]: true })

        axios.post(POST?.USER_COMPETITION_LOST_MATCH, obj)
            .then((res) => {
                const { data } = res
                setSpin({})
                if (data?.success) {
                    successMessage(assignMessage(data?.message, language?.language))
                    getCompetitionMatches(user?._id, userActions)
                    // getUser()
                }
                else {
                    errorMessage(assignMessage(data?.message, language?.language))
                }
            })
            .catch((e) => {
                setSpin({})
                errorMessage(assignMessage(e?.message, language?.language))
            })

    }

    const userConfirmDrawMatch = (e) => {
        console.log('e', e)
        let obj = {
            gameId: e?.gameId?._id,
            points: e?.points,
            proPoints: e?.proPoints,
            money: e?.money,
            isStake: e?.isStake,
            userId: user?._id,
            opponentId: e?.receiverId?._id !== user?._id ? e?.receiverId?._id : e?.senderId?._id,
            competition: e?.competition?._id,
            _id: e?._id
        }

        setSpin({ [e?._id]: true })

        axios.post(POST?.USER_CONFIRM_MATCH_DRAW, obj)
            .then((res) => {
                const { data } = res
                setSpin({})
                if (data?.success) {
                    successMessage(assignMessage(data?.message, language?.language))
                }
                else {
                    errorMessage(assignMessage(data?.message, language?.language))
                }
            })
            .catch((e) => {
                setSpin({})
                errorMessage(assignMessage(e?.message, language?.language))
            })
    }


    const columns = [
        {
            title: selectedLanguage?.text_Result_Competition,
            render: (e) => {
                return (
                    <p>
                        {e?.competition?.competitionName}
                    </p>
                )
            }
        },
        {
            title: selectedLanguage?.title_Game,
            render: (e) => {
                return (
                    <p>
                        {e?.gameId?.name}
                    </p>
                )
            }
        },
        {
            title: selectedLanguage?.title_DateTime,
            render: (e) => {
                return (
                    <p>
                        {e?.matchDate || '-----'} | {e?.matchTime || '-----'}
                    </p>
                )
            }
        },
        {
            title: selectedLanguage?.text_Sender,
            render: (e) => {
                return (
                    <p>
                        {e?.senderId?.name}{e?.senderId?._id === user?._id ? ` - ${selectedLanguage?.txt_me} ` : ''}
                    </p>
                )
            }
        },
        {
            title: selectedLanguage?.text_Oponent,
            render: (e) => {
                return (
                    <p>
                        {e?.receiverId?.name}{e?.receiverId?._id === user?._id ? ` - ${selectedLanguage?.txt_me} ` : ''}
                    </p>
                )
            }
        },
        {
            title: selectedLanguage?.txt_Status,
            render: (e) => {
                return (
                    <>
                        {e?.isAccepted ?
                            <p className='accept-status'>{selectedLanguage?.sts_Accepted}</p>
                            : e?.isRejected ?
                                <p className='reject-status'>{selectedLanguage?.sts_rejected}</p>
                                :
                                <p className='pending-stats'>{selectedLanguage?.sts_pending}</p>
                        }
                    </>
                )
            }
        },
        {
            title: selectedLanguage?.text_Result,
            render: (e) => {
                return (
                    <>
                        {((e?.winner && !e?.looser) || e?.isDraw) && (e?.diffMinutes >= 0 && e?.diffMinutes < Number(e?.gameId?.playingTime)) ?
                            <p className='pending-stats' style={{ width: '180px' }}>{selectedLanguage?.status_ResultInProcess}</p>
                            :
                            e?.winner === user?._id && e?.looser ? <p className='accept-status'>{selectedLanguage?.status_Won}</p> :
                                e?.looser === user?._id && e?.winner ? <p className='reject-status'>{selectedLanguage?.status_Loss}</p> :
                                    !e?.winner && !e?.looser && !e?.isDraw ?
                                        <p className='pending-stats' style={{ width: '140px' }}>{selectedLanguage?.status_MatchNotPlayed}</p> :
                                        <p className='pending-stats'>{selectedLanguage?.status_Argue}</p>
                        }
                    </>
                )
            }
        },
        {
            title: selectedLanguage?.title_Action,
            render: (e) => {
                let timeDiabledcondition = checkIfDateTimePassedForMatchResultBtns(e)
                return (<>
                    {spin?.[e?._id] ?
                        <Spin /> :
                        (!e?.looser && !e?.winner && !e?.isDraw) ?
                            <div style={{ display: 'flex' }}>
                                {!timeDiabledcondition ?
                                    <Button
                                        disabled={true}
                                        className={'i-won-button-dis'}
                                        style={{ marginLeft: '10px', opacity: 0.4 }}
                                    >
                                        {selectedLanguage?.btn_IWon}
                                    </Button> :
                                    <Button
                                        disabled={e?.looser || e?.winner}
                                        onClick={() => setClaimData(e)}
                                        className={e?.looser || e?.winner ? '' : 'i-won-button'}
                                        style={{ marginLeft: '10px' }}
                                    >
                                        {selectedLanguage?.btn_IWon}
                                    </Button>
                                }
                                {!timeDiabledcondition ?
                                    <Button
                                        style={{
                                            backgroundColor: 'yellow',
                                            marginLeft: 10,
                                            color: 'black',
                                            opacity: 0.4
                                        }}
                                        disabled={true}
                                    >
                                        {selectedLanguage?.btn_Draw}
                                    </Button> :
                                    <Button
                                        style={{ backgroundColor: 'yellow', marginLeft: 10, color: 'black' }}
                                        disabled={e?.looser || e?.winner}
                                        onClick={() => setDrawData(e)}
                                    >
                                        {selectedLanguage?.btn_Draw}
                                    </Button>}
                                <Popconfirm
                                    title={`${selectedLanguage?.areYouSureYouLostThe}"${e?.gameId?.name}" ${selectedLanguage?.text_game}?`}
                                    okText={selectedLanguage?.text_yes}
                                    cancelText={selectedLanguage?.text_No}
                                    okButtonProps={{
                                        type: 'primary',
                                        className: 'form-button'
                                    }}
                                    cancelButtonProps={{ style: { borderRadius: 4 } }}
                                    onConfirm={() => {
                                        userLostMatch(e)
                                    }}
                                >
                                    <Button
                                        type='primary'
                                        danger
                                        disabled={e?.looser || e?.winner}
                                        loading={spin?.[e?._id]}
                                        style={{ marginLeft: '10px' }}
                                    >{selectedLanguage?.btn_ILost}</Button>
                                </Popconfirm>

                            </div> :
                            (e?.diffMinutes >= 0 && e?.diffMinutes < Number(e?.gameId?.playingTime)) && e?.isDraw && !e?.draw?.includes(user?._id) ?
                                <div style={{ display: 'flex' }}>
                                    <Button
                                        disabled={e?.looser || e?.winner}
                                        onClick={() => setClaimData(e)}
                                        className={e?.looser || e?.winner ? '' : 'i-won-button'}
                                        style={{ marginLeft: '10px' }}
                                    >
                                        {selectedLanguage?.btn_IWon}
                                    </Button>
                                    <Button
                                        style={{ backgroundColor: 'yellow', marginLeft: 10, color: 'black' }}
                                        disabled={e?.looser || e?.winner}
                                        onClick={() => userConfirmDrawMatch(e)}
                                    >
                                        {selectedLanguage?.btn_Draw}
                                    </Button>
                                </div >
                                : !e?.looser && e?.winner !== user?._id && (e?.diffMinutes >= 0 && e?.diffMinutes < Number(e?.gameId?.playingTime)) && !e?.isDraw ?
                                    <Popconfirm
                                        title={`${selectedLanguage?.areYouSureYouLostThe}"${e?.gameId?.name}" ${selectedLanguage?.text_game}?`}
                                        okText={selectedLanguage?.text_yes}
                                        cancelText={selectedLanguage?.text_No}
                                        okButtonProps={{
                                            type: 'primary',
                                            className: 'form-button'
                                        }}
                                        cancelButtonProps={{ style: { borderRadius: 4 } }}
                                        onConfirm={() => {
                                            userLostMatch(e)
                                        }}
                                    >
                                        <Button
                                            type='primary'
                                            danger
                                            loading={spin?.[e?._id]}
                                            style={{ marginLeft: '10px', width: 180 }}
                                        >
                                            {selectedLanguage?.button_ILostDeleteClaim}
                                        </Button>
                                    </Popconfirm>
                                    :
                                    null}
                </>
                )
            }
        },
    ]
    return (
        <div className='new-main-component-screen-div'>
            <h1 className='new-screen-title'>
                {selectedLanguage?.text_Competitionmatchesresult}
            </h1>

            <div style={{ marginTop: '20px', borderBottom: `1px dashed black` }}>
                <div className="custom-table-container">
                    <Table
                        locale={{ emptyText: selectedLanguage?.txt_NoDataFound }}
                        className='new-layout-table'
                        dataSource={data}
                        columns={columns}
                        pagination={false}
                        scroll={{ x: 1000 }}
                    />
                </div>
            </div>

            {
                claimData?._id &&
                <ClaimMatchModal data={claimData} onCancel={() => setClaimData({})} visible={claimData._id ? true : false} handleOk={handleOk} {...props} />
            }
            {
                drawData?._id &&
                <DrawMatchProof data={drawData} onCancel={() => setDrawData({})} visible={drawData._id ? true : false} handleOk={handleOk} {...props} />
            }
        </div >
    )
}

export default CompetitionMatchesResult