import React, { useEffect, useState } from 'react'
import AdminHome from '../AdminScreen/Home/home'
import PlayerHome from '../PlayerScreen/Home/Home'
import { Redirect } from 'react-router-dom'
import { allPaths } from '../../utils/constants'

const Home = (props) => {
    const { language, user } = props

    useEffect(() => {
        if (!user?._id) {
            return (
                <Redirect to={allPaths?.HOME} />
            )
        }
    }, [])

    console.log(language,'==language')

    return (
        <div className='new-main-component-screen-div'>
            {user?.userType === "player" ?
                <PlayerHome {...props} /> :
                <AdminHome {...props} />}
        </div>
    )
}

export default Home