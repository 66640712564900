import { ADMIN_ALL_PLAYER } from '../types'

const reducer = (state = {}, action) => {
    switch (action.type) {
        case ADMIN_ALL_PLAYER: {
            return { ...state, adminAllPlayer: action?.adminAllPlayer }
        }
        default: {
            return state
        }
    }
}

export default reducer