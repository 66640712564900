import { Button, Select, Table } from 'antd'
import React, { useEffect, useState } from 'react'
import { IoIosArrowBack } from "react-icons/io"
import { useSelector } from 'react-redux'
import { convertNumber, getGeneralData, getUserData } from '../../../utils/helpers'
import AllProRankingPiont from './AllProRankingPoint'
import NoData from '../../../assets/noDataImae.png'
import { AiOutlineArrowUp, AiOutlineArrowDown } from "react-icons/ai"
import { TbEqual } from "react-icons/tb"


const PlayerProRankingPiont = (props) => {
    const { Option } = Select
    const { selectedLanguage, isEnglish, user, generalActions, userActions } = props
    const games = useSelector(state => state.generalReducer.games)
    const userProRanks = useSelector(state => state.userReducer.userProRanks)
    const [allPlayer, setAllPlayer] = useState(false)
    const [selectedGame, setSelectedGame] = useState(games?.[0]?._id || null)
    useEffect(() => {
        getGeneralData(generalActions)
        getUserData(user?._id, userActions)
    }, [])

    const columns = [
        {
            title: selectedLanguage?.tableText_Game,
            render: (e) => {
                return (
                    <p> {e?.gameId?.name} </p>
                )
            }
        },
        {
            title: selectedLanguage?.tableText_Ranking,
            render: (e) => {
                return (
                    <p> {e?.rank} </p>
                )
            }
        },
        {
            title: `${selectedLanguage?.tableText_Points}-($)`,
            render: (e) => {
                return (
                    <p> {convertNumber(e?.proPoint)} </p>
                )
            }
        },
        {
            title: selectedLanguage?.tableText_WeeklyResult,
            render: (e) => {
                return (
                    <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
                        {!e?.weaklyProPointReport?.length || e?.weaklyProPointReport?.[0]?.difference === 0 ?
                            <TbEqual color='black' size={22} style={{
                                cursor: 'pointer',
                                background: 'white',
                                margin: '10px',
                                height: '30px',
                                width: '30px',
                                borderRadius: '20px',
                                padding: '4px'
                            }} />
                            :
                            e?.proPointUp ?
                                <AiOutlineArrowUp color='green' size={22} style={{
                                    cursor: 'pointer',
                                    background: 'white',
                                    margin: '10px',
                                    height: '30px',
                                    width: '30px',
                                    borderRadius: '20px',
                                    padding: '4px'
                                }} /> :
                                <AiOutlineArrowDown color='red' size={22} style={{
                                    cursor: 'pointer',
                                    background: 'white',
                                    margin: '10px',
                                    height: '30px',
                                    width: '30px',
                                    borderRadius: '20px',
                                    padding: '4px'
                                }} />}
                        <p style={{ margin: '16px' }}>
                            {e?.weaklyProPointReport?.[0]?.difference || 0}
                        </p>
                    </div>

                )
            }
        },
    ]

    return (
        <div className='new-main-component-screen-div'>
            <div>
                <div style={{ marginTop: '10px' }}>
                    {allPlayer ?
                        <div style={{
                            display: 'flex',
                            justifyContent: 'flex-start',
                            alignItems: 'center'
                        }}>
                            <IoIosArrowBack
                                onClick={() => { setAllPlayer(false) }}
                                style={{ marginRight: '5px', color: 'black', marginTop: '9px' }}
                                size={28} />
                            <h1 className='new-screen-title'>
                                {selectedLanguage?.text_AllPlayerProRankingAndPointsDollerpoints}
                            </h1>
                        </div> :
                        <h1 className='new-screen-title'>
                            {selectedLanguage?.text_MyProRankingAndPointDollerPoints}
                        </h1>
                    }
                </div>
                <span style={{ width: '98%', margin: '0px auto', marginTop: '20px', }} className='button-div-flex-end'>
                    {allPlayer ?
                        <Select
                            showSearch
                            showArrow
                            allowClear={false}
                            notFoundContent={selectedLanguage?.txt_NoDataFound}
                            defaultValue={selectedGame}
                            onChange={(e) => setSelectedGame(e)}
                            style={{
                                height: '35px',
                                width: '130px'
                            }}
                            placeholder={selectedLanguage?.label_SelectGame}
                            optionFilterProp='children'
                            filterOption={(input, option) =>
                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                        >
                            {games?.length && games?.map((v, i) => <Option value={v?._id} key={i}>{v?.name}</Option>)}
                        </Select>
                        :
                        <Button
                            type='primary'
                            onClick={() => setAllPlayer(true)}>
                            {selectedLanguage?.btnAllplayerProRanks}
                        </Button>
                    }
                </span>
                <div style={{ marginTop: '25px' }}>
                    {allPlayer ?
                        <AllProRankingPiont {...props} selectedGame={selectedGame} />
                        :
                        <div className="custom-table-container">
                            <Table
                                locale={{ emptyText: selectedLanguage?.txt_NoDataFound }}
                                className='new-layout-table'
                                dataSource={userProRanks}
                                columns={columns}
                                pagination={false}
                                scroll={{ x: 1000 }}
                            />
                        </div>
                    }
                </div>
            </div>
        </div>
    )
}

export default PlayerProRankingPiont