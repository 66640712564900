import { Button, Popconfirm, Spin, Table } from 'antd';
import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { GET, POST } from '../../../utils/apis';
import { assignMessage, checkIfDateTimePassedForMatchResultBtns, errorMessage, getTournamentMatchData, successMessage } from '../../../utils/helpers'
import ClaimMatchModal from './ClaimMatchModal';
import io from 'socket.io-client'
import moment from 'moment';
import DrawMatchProof from './DrawMatchProof';
import { useSelector } from 'react-redux';
import NoData from '../../../assets/noDataImae.png'

const TournamentMatchResults = (props) => {
    const { selectedLanguage, user, language, userActions } = props
    const [loading, setLoading] = useState(false)
    const [data, setData] = useState([])
    const [claimData, setClaimData] = useState({})
    const [drawData, setDrawData] = useState({})
    const [spin, setSpin] = useState({})
    const tournamentMatches = useSelector(state => state.userReducer.tournamentMatches)
    console.log('tournamentMatches', tournamentMatches)
    useEffect(() => {
        getTournamentMatchData(user?._id, userActions)
        getSocketInfo()

        const intervalId = setInterval(() => {
            const newData = tournamentMatches.map(item => {
                let matchDateTime = item?.matchDate + ' ' + item?.matchTime
                let momentMatchDate = moment(matchDateTime, 'DD-MM-YYYY HH:mm').add((Number(item?.gameId?.playingTime) * 1.5), 'minutes')
                let currentDateTime = moment(moment().format('DD-MM-YYYY HH:mm'), 'DD-MM-YYYY HH:mm')
                let timePassed = currentDateTime.isAfter(momentMatchDate, 'minutes')

                return { ...item, timePassed }
            })
            setData(newData)
        }, 5000)

        return () => clearInterval(intervalId)
    }, [])
    let origin = `https://wgpplaza.com`
    if (process.env.NODE_ENV === 'development') {
        origin = `http://localhost:4000`
    }

    const socket = io(origin, { reconnect: true });

    socket.on('connect', () => {
        console.log('connected')
    })

    const getSocketInfo = () => {
        socket.on('tournamentHistoryResponse', (d) => {
            const { documentKey, operationType, updateDescription } = d
            getTournamentMatchData(user?._id, userActions)
        })
    }

    const userLostMatch = (e) => {
        console.log('e', e)
        let obj = {
            gameId: e?.gameId?._id,
            points: e?.points,
            userId: user?._id,
            opponentId: e?.receiverId?._id !== user?._id ? e?.receiverId?._id : e?.senderId?._id,
            tournamentId: e?.tournament,
            _id: e?._id
        }
        setSpin({ [e?._id]: true })
        // console.log(user?._id)

        axios.post(POST?.TOURNAMENT_LOST_MATCH, obj)
            .then((res) => {
                const { data } = res
                setSpin({})
                if (data?.success) {
                    successMessage(assignMessage(data?.message, language?.language))
                    getSocketInfo()
                }
                else {
                    errorMessage(assignMessage(data?.message, language?.language))
                }
            })
            .catch((e) => {
                setSpin({})
                errorMessage(assignMessage(e?.message, language?.language))
            })

    }

    const columns = [
        {
            title: selectedLanguage?.title_Game,
            dataIndex: 'gameId',
            render: (e) => {
                return (
                    <p className='table_p' style={{ width: '110px' }}>{e?.name || '-----'}</p>
                )
            }
        },
        {
            title: selectedLanguage?.title_DateTime,
            render: (e) => {
                return (
                    <p className='table_p' style={{ width: '140px' }}>{e?.matchDate || '-----'} | {e?.matchTime || '-----'}</p>
                )
            }
        },
        {
            title: selectedLanguage?.text_Sender,
            dataIndex: "senderId",
            render: (e) => {
                return (
                    <p className='table_p' style={{ width: '160px' }}>{e?.name}{e?._id === user?._id ? `${selectedLanguage?.text_me}` : ''}</p>
                )
            }
        },
        {
            title: selectedLanguage?.text_Sender,
            dataIndex: 'receiverId',
            render: (e) => {
                return (
                    <p className='table_p' style={{ width: '160px' }}>
                        {e?.name}{!e?._id ? 'Guest' : e?._id === user?._id ? `${selectedLanguage?.text_me}` : ''}
                    </p>
                )
            }
        },
        {
            title: selectedLanguage?.text_Status,
            render: (e) => {

                return (
                    <>
                        {e?.looser || e?.winner || e?.isDraw ?
                            <p className='accept-status' style={{ width: '120px' }}>{selectedLanguage?.status_completed}</p>
                            :
                            <p className='pending-stats' style={{ width: '120px' }}>{selectedLanguage?.status_playing}</p>
                        }
                    </>
                )
            }
        },
        {
            title: selectedLanguage?.text_Result,
            render: (e) => {
                return (
                    <>
                        {!e?.winner && !e?.looser && !e?.isDraw && !e?.timePassed ?
                            <p className='pending-stats' style={{ width: '180px' }}>{selectedLanguage?.status_ResultInProcess}</p>
                            :
                            e?.winner === user?._id && e?.isCompleted ? <p className='accept-status'>{selectedLanguage?.status_Won}</p> :
                                e?.looser === user?._id/*  && e?.winner  */ ? <p className='reject-status'>{selectedLanguage?.status_Loss}</p> :
                                    <p className='pending-stats'>{selectedLanguage?.status_Argue}</p>
                        }
                    </>
                )
            }
        },
        {
            title: selectedLanguage?.title_Action,
            render: (e) => {
                let timeDiabledcondition = checkIfDateTimePassedForMatchResultBtns(e)
                return (
                    <>
                        {spin?.[e?._id] ?
                            <Spin /> :
                            (!e?.looser && !e?.winner && !e?.timePassed) ?
                                <div style={{ display: 'flex' }}>
                                    <Button
                                        disabled={!timeDiabledcondition || e?.looser || e?.winner}
                                        onClick={() => setClaimData(e)}
                                        className={!timeDiabledcondition || e?.looser || e?.winner ? '' : 'i-won-button'}
                                        style={{ marginLeft: '10px' }}
                                    >
                                        {selectedLanguage?.btn_IWon}
                                    </Button>
                                    <Popconfirm
                                        title={`${selectedLanguage?.areYouSureYouLostThe}"${e?.gameId?.name}" ${selectedLanguage?.text_game}?`}
                                        okText={selectedLanguage?.text_yes}
                                        cancelText={selectedLanguage?.text_No}
                                        okButtonProps={{
                                            type: 'primary',
                                            className: 'form-button'
                                        }}
                                        cancelButtonProps={{ style: { borderRadius: 4 } }}
                                        onConfirm={() => {
                                            userLostMatch(e)
                                        }}
                                    >
                                        <Button
                                            type='primary'
                                            danger
                                            disabled={e?.looser || e?.winner}
                                            loading={spin?.[e?._id]}
                                            style={{ marginLeft: '10px' }}
                                        >{selectedLanguage?.btn_ILost}</Button>
                                    </Popconfirm>
                                </div>
                                :
                                (e?.diffMinutes >= 0 && !e?.timePassed) ?
                                    <Button
                                        disabled={e?.looser || e?.winner}
                                        onClick={() => setClaimData(e)}
                                        className={e?.looser || e?.winner ? '' : 'i-won-button'}
                                        style={{ marginLeft: '10px' }}
                                    >
                                        {selectedLanguage?.btn_IWon}
                                    </Button>

                                    : !e?.looser && e?.winner && e?.winner !== user?._id && !e?.timePassed ?
                                        <Popconfirm
                                            title={`${selectedLanguage?.areYouSureYouLostThe}"${e?.gameId?.name}" ${selectedLanguage?.text_game}?`}
                                            okText={selectedLanguage?.text_yes}
                                            cancelText={selectedLanguage?.text_No}
                                            okButtonProps={{
                                                type: 'primary',
                                                className: 'form-button'
                                            }}
                                            cancelButtonProps={{ style: { borderRadius: 4 } }}
                                            onConfirm={() => {
                                                userLostMatch(e)
                                            }}
                                        >
                                            <Button
                                                type='primary'
                                                danger
                                                loading={spin?.[e?._id]}
                                                style={{ marginLeft: '10px', width: 180 }}
                                            >
                                                {selectedLanguage?.button_ILostDeleteClaim}
                                            </Button>
                                        </Popconfirm>
                                        :
                                        null
                        }
                    </>
                )
            }
        }
    ]

    const handleOk = () => {
        setClaimData({})
        setDrawData({})
    }
    return (
        <div className='new-main-component-screen-div'>
            <h1 className='new-screen-title'>
                {selectedLanguage?.title_TournamentMatchResults}
            </h1>

            <div className="custom-table-container">
                <Table
                    locale={{ emptyText: selectedLanguage?.txt_NoDataFound }}
                    className='new-layout-table'
                    dataSource={(data?.length && data) || tournamentMatches || []}
                    columns={columns}
                    pagination={false}
                    loading={loading}
                    scroll={{ x: 400 }}
                />
            </div>

            {
                claimData?._id &&
                <ClaimMatchModal data={claimData} onCancel={() => setClaimData({})} visible={claimData._id ? true : false} handleOk={handleOk} {...props} />
            }

        </div >
    )
}

export default TournamentMatchResults