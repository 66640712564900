import React, { useEffect, useState } from "react";
import { Button, Form, Input, InputNumber, Modal } from "antd";
import { assignMessage, convertRoundNumber, errorMessage, successMessage } from '../../../utils/helpers'
import { ADMIN } from '../../../utils/apis'
import axios from "axios";
import { currencySign } from "../../../utils/constants";

const ServiceFeeDollerTournament = (props) => {
    const { user, language, selectedLanguage } = props
    const [visible, setVisible] = useState(false)
    const [form] = Form.useForm()
    const [loading, setLoading] = useState(false)
    const [dollertournamentSubFee, setDollertournamentSubFee] = useState(null)

    const getDollertournamentSubFee = () => {
        axios.get(ADMIN?.GET_SUBSCRIPTION_FEE_FOR_DOLLER_TOURNAMENT)
            .then((res) => {
                const { data } = res
                setDollertournamentSubFee(data?.data?.[0] || [])
            })
            .catch((e) => {
                errorMessage(assignMessage(e?.message, language?.language))
            })
    }


    const onFinish = (values) => {
        values.adminId = user?._id
        dollertournamentSubFee?.money && (values._id = dollertournamentSubFee?._id)
        console.log(values, "=values")
        axios.post(dollertournamentSubFee?._id ? ADMIN?.EDIT_SUBSCRIPTION_FEE_FOR_DOLLER_TOURNAMENT : ADMIN?.ADD_SUBSCRIPTION_FEE_FOR_DOLLER_TOURNAMENT, values)
            .then((res) => {
                const { data } = res
                if (data?.success) {
                    successMessage(assignMessage(data?.message, language?.language))
                    getDollertournamentSubFee()
                }
            })
            .catch((e) => {
                errorMessage(assignMessage(e?.message, language?.language))
            })
    }

    useEffect(() => {
        getDollertournamentSubFee()
    }, [])

    const handleOk = () => {
        setVisible(false)
    }


    return (
        <>
            <div className="reward-point-box">
                <p className="p-none-margin-bolder-p">{selectedLanguage?.setting_playerNeedToPayServiceFeeForSubscriptionOfTournamentThatYouSetHere}</p>
                <p className="p-none-margin-bolder-p">
                    {'player can pay'} ( {dollertournamentSubFee?.money ? `${currencySign} ${convertRoundNumber(dollertournamentSubFee?.money)}` : '---'} ) {selectedLanguage?.setting_subscriptionFeeForSubscribingTournamentAsServiceFee}</p>
                <div style={{ marginTop: '20px' }} className='button-div-flex-end'>
                    <Button
                        type="primary"
                        onClick={() => { setVisible(true) }} > {selectedLanguage?.setting_UpdateTournamentSubscriptionFee}</Button>
                </div>
            </div>
            {/* --------Modal----- */}
            <Modal
                open={visible}
                onOk={handleOk}
                onCancel={handleOk} className='pop-info-modal'
                footer={null}>

                <div style={{ marginTop: '10px' }}>
                    <p className='p-question'>{selectedLanguage?.setting_UpdateSubscriptionFeeOfTournamnet}</p>
                    <div style={{ display: 'flex', flexDirection: 'column' }} className='form-div-in-modal'>
                        <Form
                            name='form'
                            form={form}
                            onFinish={onFinish}
                            layout={'vertical'}
                        >
                            <div className='form-two-input-flex-inner-input newInpuHeightFull' style={{ width: '100%' }}>
                                <Form.Item
                                    name='money'
                                    initialValue={dollertournamentSubFee?.money}
                                    hasFeedback
                                    label={<p className='p-admin-title-text'>{selectedLanguage?.setting_SubscriptionFeeOfTournamnet}</p>}
                                    rules={[
                                        {
                                            required: true,
                                            message: selectedLanguage?.setting_pleaseEnterSubscriptionFeeOfTournamnet
                                        }
                                    ]}
                                >
                                    <Input
                                        // Number
                                        step={0.5}    // Set the step to 0.01 for two decimal places
                                        precision={2}
                                        type='number'
                                        style={{ width: '100%' }}
                                        className='form-input-new-design-big-slam'
                                        prefix={<p style={{ color: 'white', margin: '0px' }}>{currencySign}</p>}
                                        placeholder={selectedLanguage?.setting_EnterSubscriptionFeeOfTournamnetHere}
                                    />
                                </Form.Item>
                            </div>


                            <div style={{ display: 'flex', justifyContent: 'flex-end', height: '40px', paddingRight: '5px' }}>
                                <Form.Item>
                                    <Button
                                        type="primary"
                                        htmlType='submit'
                                        loading={loading}
                                    >
                                        {dollertournamentSubFee?.money ? selectedLanguage?.text_UpdatePointSetting : selectedLanguage?.text_AddPointSetting}
                                    </Button>
                                </Form.Item>
                            </div>
                        </Form>
                    </div>
                </div >
            </Modal >
        </>
    )
}



export default ServiceFeeDollerTournament