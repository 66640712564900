import { GAMES_SETTINGS, WALLET_TRANSACTIONS, GAME_SUGGESTIONS, MATCH_RESULTS, PLAYERS, PLAYER_CONTACTS, RECEIVE_MATCHES, SENT_MATCHES, TOURNAMENTS, TOURNAMENTS_MATCHES, USER_PRO_RANKS, USER_RANKS, USER_TOURNAMENTS, COMPETITIONS_RANKS, SENT_COMPETITION_MATCHES, RECEIVE_COMPETITION_MATCHES, COMPETITION_MATCH_RESULTS, SUBSCRIBED_COMPETITION } from '../types'

const addAllGameSettings = (gameSettings) => {
    return {
        type: GAMES_SETTINGS,
        gameSettings
    }
}

const addAllPlayerContacts = (playerContacts) => {
    return {
        type: PLAYER_CONTACTS,
        playerContacts
    }
}

const addAllPlayer = (players) => {
    return {
        type: PLAYERS,
        players
    }
}

const addAllGameSuggestions = (gameSuggestions) => {
    return {
        type: GAME_SUGGESTIONS,
        gameSuggestions
    }
}

const addAllUserTournaments = (userTournaments) => {
    return {
        type: USER_TOURNAMENTS,
        userTournaments
    }
}
const addUserRanks = (userRanks) => {
    return {
        type: USER_RANKS,
        userRanks
    }
}

const addUserProRanks = (userProRanks) => {
    return {
        type: USER_PRO_RANKS,
        userProRanks
    }
}


const addAllTournaments = (tournaments) => {
    return {
        type: TOURNAMENTS,
        tournaments
    }
}
const addAllTournamentMatches = (tournamentMatches) => {
    return {
        type: TOURNAMENTS_MATCHES,
        tournamentMatches
    }
}
const addAllSentMatches = (sentMatches) => {
    return {
        type: SENT_MATCHES,
        sentMatches
    }
}
const addAllReceiveMatches = (receiveMatches) => {
    return {
        type: RECEIVE_MATCHES,
        receiveMatches
    }
}
const addAllMatchResults = (matchResults) => {
    return {
        type: MATCH_RESULTS,
        matchResults
    }
}
const addWalletTransaction = (walletTransaction) => {
    return {
        type: WALLET_TRANSACTIONS,
        walletTransaction
    }
}
const addCompetitionRanks = (competitionRanks) => {
    return {
        type: COMPETITIONS_RANKS,
        competitionRanks
    }
}
const addAllSentCompetitionMatches = (sentCompetitionMatches) => {
    return {
        type: SENT_COMPETITION_MATCHES,
        sentCompetitionMatches
    }
}
const addAllReceiveCompetitionMatches = (receiveCompetitionMatches) => {
    return {
        type: RECEIVE_COMPETITION_MATCHES,
        receiveCompetitionMatches
    }
}
const addAllCompetitionMatches = (competitionMatches) => {
    return {
        type: COMPETITION_MATCH_RESULTS,
        competitionMatches
    }
}
const addSubscribedCompetitions = (subscribedCompetitions) => {
    return {
        type: SUBSCRIBED_COMPETITION,
        subscribedCompetitions
    }
}
export {
    addAllGameSettings,
    addAllPlayerContacts,
    addAllPlayer,
    addAllGameSuggestions,
    addAllUserTournaments,
    addUserRanks,
    addUserProRanks,
    addAllTournaments,
    addAllTournamentMatches,
    addAllSentMatches,
    addAllReceiveMatches,
    addAllMatchResults,
    addWalletTransaction,
    addCompetitionRanks,
    addAllSentCompetitionMatches,
    addAllReceiveCompetitionMatches,
    addAllCompetitionMatches,
    addSubscribedCompetitions
}