import { MENU_NONE } from '../types'

const reducer = (state = { menuNone: false }, action) => {
    switch (action.type) {
        case MENU_NONE: {
            return { ...state, menuNone: action?.menuNone }
        }
        default: {
            return state
        }
    }
}

export default reducer