import HomeOutlined from '@ant-design/icons/HomeOutlined'
import allPaths from '../Config/paths'
import { CgProfile } from 'react-icons/cg'
import { FiSettings } from 'react-icons/fi'
import { TbDeviceGamepad2 } from 'react-icons/tb'
import { RiCouponLine, RiGamepadLine } from 'react-icons/ri'
import { BsFillExclamationDiamondFill } from 'react-icons/bs'
import { GiGameConsole, GiRank2, GiBabyfootPlayers } from 'react-icons/gi'
import { FaQuestionCircle } from 'react-icons/fa'
import { MdAccountBalance } from 'react-icons/md'
import { TbFileInvoice, TbBrandCashapp } from 'react-icons/tb'
import { GrGraphQl } from 'react-icons/gr'


const bgColor = '#0adc00'
const deployedURL = 'https://wgpplaza.com'
const drawerRoutesAdmin = [
    {
        titleEnglish: 'Home',
        titleNl: 'Thuis',
        titleEgypt: 'الصفحة الرئيسية',
        titleGerman: 'Heim',
        titleItaly: 'Casa',
        titleRussain: 'Дом',
        titleSpain: 'Hogar',
        route: allPaths.HOME,
        icon: <HomeOutlined />
    },
    {
        titleEnglish: 'Profile',
        titleNl: 'Profiel',
        titleEgypt: 'الملف الشخصي',
        titleGerman: 'Profil',
        titleItaly: 'Profilo',
        titleRussain: 'Профиль',
        titleSpain: 'Perfil',
        route: allPaths.ADMIN_PROFILE,
        icon: <CgProfile />
    },
    {
        titleEnglish: 'Settings',
        titleNl: 'Instellingen',
        titleEgypt: 'إعدادات',
        titleGerman: 'Einstellungen',
        titleItaly: 'Impostazioni',
        titleRussain: 'Настройки',
        titleSpain: 'Configuraciones',
        isSubMenu: true,
        icon: <FiSettings />,
        key: 1,
        children: [
            {
                titleEnglish: 'Create Game',
                titleNl: 'Maak game',
                titleEgypt: 'إنشاء لعبة',
                titleGerman: 'Spiel erstellen',
                titleItaly: 'Crea gioco',
                titleRussain: 'Создать игру',
                titleSpain: 'Crear juego',
                route: allPaths?.ADMIN_CREATE_GAME,
            },
            {
                titleEnglish: 'Game Suggestion',
                titleNl: 'Spelsuggesties',
                titleEgypt: 'اقتراح لعبة',
                titleGerman: 'Spielsuggestion',
                titleItaly: 'Suggerimento gioco',
                titleRussain: 'Предложение по игре',
                titleSpain: 'Sugerencia de juego',
                route: allPaths?.ADMIN_GAME_SUGGESTION,
            },
            {
                titleEnglish: 'Create Tournament',
                titleNl: 'Maak Toernooi',
                titleEgypt: 'إنشاء بطولة',
                titleGerman: 'Turnier erstellen',
                titleItaly: 'Crea torneo',
                titleRussain: 'Создать турнир',
                titleSpain: 'Crear torneo',
                route: allPaths?.ADMIN_CREATE_TOURNAMENT,
            },
            {
                titleEnglish: 'Promoter',
                titleNl: 'Promoter',
                titleEgypt: 'المروج',
                titleGerman: 'Promoter',
                titleItaly: 'Promoter',
                titleRussain: 'Продвигатель',
                titleSpain: 'Promotor',
                route: allPaths?.ADMIN_PROMOTER_SETTINGS,
            },
            {
                titleEnglish: 'Points setting',
                titleNl: 'punt instelling',
                titleEgypt: 'إعداد النقاط',
                titleGerman: 'Punkte einstellen',
                titleItaly: 'Impostazione dei punti',
                titleRussain: 'Настройка баллов',
                titleSpain: 'Ajuste de puntos',
                route: allPaths?.ADMIN_POINT_SETTINGS,
            },
            {
                titleEnglish: 'Create FAQ',
                titleNl: 'Maak FAQ',
                titleEgypt: 'إنشاء الأسئلة الشائعة',
                titleGerman: 'Häufig gestellte Fragen erstellen',
                titleItaly: 'Crea FAQ',
                titleRussain: 'Создать ЧАВО',
                titleSpain: 'Crear preguntas frecuentes',
                route: allPaths?.ADMIN_ADD_FAQ,
            },
        ]
    },
    {
        titleEnglish: 'Competition',
        titleNl: 'Competitie',
        titleEgypt: 'التنافس',
        titleGerman: 'Wettbewerb',
        titleItaly: 'Concorrenza',
        titleRussain: 'Конкуренция',
        titleSpain: 'Competición',
        isSubMenu: true,
        icon: <TbDeviceGamepad2 />,
        key: 2,
        children: [
            {
                titleEnglish: 'Create Competition',
                titleNl: 'Maak Competitie',
                titleEgypt: 'إنشاء تنافس',
                titleGerman: 'Wettbewerb erstellen',
                titleItaly: 'Crea competizione',
                titleRussain: 'Создать конкурс',
                titleSpain: 'Crear competición',
                route: allPaths?.ADMIN_CREATE_COMPETITION,
            },
            {
                titleEnglish: 'Competition select',
                titleNl: 'Competitie selecteren',
                titleEgypt: 'اختيار التنافس',
                titleGerman: 'Wettbewerb auswählen',
                titleItaly: 'Seleziona competizione',
                titleRussain: 'Выбрать конкурс',
                titleSpain: 'Seleccionar competición',
                route: allPaths?.ADMIN_COMPETITION_SELECT,
            },
        ]
    },
    {
        titleEnglish: 'Tournaments',
        titleNl: 'Toernooien',
        titleEgypt: 'البطولات',
        titleGerman: 'Turniere',
        titleItaly: 'Tornei',
        titleRussain: 'Турниры',
        titleSpain: 'Torneos',
        isSubMenu: true,
        icon: <TbDeviceGamepad2 />,
        key: 3,
        children: [
            {
                titleEnglish: 'Tournament Select',
                titleNl: 'Selecteer toernooi',
                titleEgypt: 'تحديد البطولة',
                titleGerman: 'Turnier auswählen',
                titleItaly: 'Seleziona torneo',
                titleRussain: 'Выбрать турнир',
                titleSpain: 'Seleccionar torneo',
                route: allPaths?.ADMIN_TOURNAMENT_SELECT,
            },
        ]
    },
    {
        titleEnglish: 'Games',
        titleNl: 'Spellen',
        titleEgypt: 'ألعاب',
        titleGerman: 'Spiele',
        titleItaly: 'Giochi',
        titleRussain: 'Игры',
        titleSpain: 'Juegos',
        route: allPaths.ADMIN_GAMES,
        icon: <RiGamepadLine />
    },
    {
        titleEnglish: 'Coupons',
        titleNl: 'Coupons',
        titleEgypt: 'كوبونات',
        titleGerman: 'Coupons',
        titleItaly: 'Buoni',
        titleRussain: 'Купоны',
        titleSpain: 'Cupones',
        route: allPaths.ADMIN_COUPONS,
        icon: <RiCouponLine />
    },
    {
        titleEnglish: 'Players',
        titleNl: 'Spelers',
        titleEgypt: 'لاعبون',
        titleGerman: 'Spieler',
        titleItaly: 'Giocatori',
        titleRussain: 'Игроки',
        titleSpain: 'Jugadores',
        route: allPaths.ADMIN_PLAYER_DETAIL,
        icon: <GiBabyfootPlayers />
    },
    {
        titleEnglish: 'Claims & Argues',
        titleNl: 'Claims',
        titleEgypt: 'المطالبات',
        titleGerman: 'Beschwerden & Argumente',
        titleItaly: 'Reclami e contestazioni',
        titleRussain: 'Претензии и споры',
        titleSpain: 'Reclamaciones y Disputas',
        route: allPaths.ADMIN_CLAIM_ARGUES,
        icon: <BsFillExclamationDiamondFill />
    },
    {
        titleEnglish: 'Financial ',
        titleNl: 'Financieel ',
        titleEgypt: 'المالية ',
        titleGerman: 'Finanziell ',
        titleItaly: 'Finanziario ',
        titleRussain: 'Финансовый ',
        titleSpain: 'Financiero ',
        isSubMenu: true,
        icon: <TbFileInvoice />,
        key: 4,
        children: [
            {
                titleEnglish: 'PlayerInvoices',
                titleNl: 'Spelerfacturen',
                titleEgypt: 'فواتير اللاعبين',
                titleGerman: 'Spielerrechnungen',
                titleItaly: 'Fatture giocatori',
                titleRussain: 'Счета игроков',
                titleSpain: 'Facturas de jugadores',
                route: allPaths.ADMIN_PLAYER_INVOICES,
                // icon: <TbFileInvoice />
            },
            {
                titleEnglish: 'BTW',
                titleNl: 'BTW',
                titleEgypt: 'ضريبة القيمة المضافة',
                titleGerman: 'Mehrwertsteuer',
                titleItaly: 'IVA',
                titleRussain: 'НДС',
                titleSpain: 'IVA',
                route: allPaths.ADMIN_BTW_INVOICES,
                // icon: <TbFileInvoice />
            },
            {
                titleEnglish: 'Competition subscription invoices',
                titleNl: 'Facturen voor competitie-abonnementen',
                titleEgypt: 'فواتير اشتراكات البطولة',
                titleGerman: 'Rechnungen für Wettbewerbsabonnements',
                titleItaly: 'Fatture abbonamenti alle competizioni',
                titleRussain: 'Счета за подписку на конкурс',
                titleSpain: 'Facturas de suscripción a competiciones',
                route: allPaths.ADMIN_COMPETITION_SUB_INVOICES,
                // icon: <TbFileInvoice />
            },
            {
                titleEnglish: 'Cashback',
                titleNl: 'geld terug',
                titleEgypt: 'استرداد النقود',
                titleGerman: 'Cashback',
                titleItaly: 'rimborso',
                titleRussain: 'возврат наличных',
                titleSpain: 'devolución de dinero',
                route: allPaths.ADMIN_CASHBACK,
                // icon: <TbBrandCashapp />
            },
        ]
    },
    {
        titleEnglish: 'FAQ',
        titleNl: 'FAQ',
        titleEgypt: 'أسئلة وأجوبة',
        titleGerman: 'FAQ',
        titleItaly: 'Domande frequenti',
        titleRussain: 'Часто задаваемые вопросы',
        titleSpain: 'Preguntas frecuentes',
        route: allPaths.ADMIN__FAQ,
        icon: <FaQuestionCircle />
    }
]

const drawerRoutesUser = [
    {
        titleEnglish: 'Home',
        titleNl: 'Thuis',
        titleEgypt: 'الصفحة الرئيسية',
        titleGerman: 'Zuhause',
        titleItaly: 'Casa',
        titleRussain: 'Дом',
        titleSpain: 'Hogar',
        route: allPaths.HOME,
        icon: <HomeOutlined />
    },
    {
        titleEnglish: 'Matches',
        titleNl: 'Wedstrijden',
        titleEgypt: 'مباريات',
        titleGerman: 'Spiele',
        titleItaly: 'Partite',
        titleRussain: 'Матчи',
        titleSpain: 'Partidos',
        isSubMenu: true,
        icon: <GiGameConsole />,
        key: 1,
        children: [
            {
                titleEnglish: 'Create match request',
                titleNl: 'Maak wedstrijdverzoek',
                titleEgypt: 'إنشاء طلب مباراة',
                titleGerman: 'Erstelle eine Spielanfrage',
                titleItaly: 'Crea richiesta di partita',
                titleRussain: 'Создать запрос на матч',
                titleSpain: 'Crear solicitud de partido',
                route: allPaths.PLAYER_CREATE_MATCH_REQ,
            },
            {
                titleEnglish: 'Match request',
                titleNl: 'Wedstrijdverzoek',
                titleEgypt: 'طلب مباراة',
                titleGerman: 'Spielanfrage',
                titleItaly: 'Richiesta di partita',
                titleRussain: 'Запрос на матч',
                titleSpain: 'Solicitud de partido',
                route: allPaths.PLAYER_MATCH_REQUESTS,
            },
            {
                titleEnglish: 'Match result',
                titleNl: 'Wedstrijdresultaat',
                titleEgypt: 'نتيجة المباراة',
                titleGerman: 'Spielergebnis',
                titleItaly: 'Risultato della partita',
                titleRussain: 'Результат матча',
                titleSpain: 'Resultado del partido',
                route: allPaths.PLAYER_MATCH_RESULT,
            },
            {
                titleEnglish: 'Tournament matches',
                titleNl: 'Toernooi-wedstrijden',
                titleEgypt: 'مباريات البطولة',
                titleGerman: 'Turniermatches',
                titleItaly: 'Partite di torneo',
                titleRussain: 'Турнирные матчи',
                titleSpain: 'Partidos de torneo',
                route: allPaths.PLAYER_TOURNAMENT_MATCHES,
            },
        ]
    },
    {
        titleEnglish: 'Tournaments',
        titleNl: 'Toernooien',
        titleEgypt: 'البطولات',
        titleGerman: 'Turniere',
        titleItaly: 'Tornei',
        titleRussain: 'Турниры',
        titleSpain: 'Torneos',
        isSubMenu: true,
        icon: <TbDeviceGamepad2 />,
        key: 2,
        children: [
            {
                titleEnglish: 'Tournament Select',
                titleNl: 'Selecteer toernooi',
                titleEgypt: 'تحديد البطولة',
                titleGerman: 'Turnier auswählen',
                titleItaly: 'Seleziona torneo',
                titleRussain: 'Выбрать турнир',
                titleSpain: 'Seleccionar torneo',
                route: allPaths.PLAYER_SELECT_TOURNAMENT,
            },
            {
                titleEnglish: 'Create tournament',
                titleNl: 'Maak toernooi',
                titleEgypt: 'إنشاء بطولة',
                titleGerman: 'Turnier erstellen',
                titleItaly: 'Crea torneo',
                titleRussain: 'Создать турнир',
                titleSpain: 'Crear torneo',
                route: allPaths.PLAYER_CREATE_TOURNAMENT,
            },
        ]
    },
    {
        titleEnglish: 'Competition',
        titleNl: 'Competitie',
        titleEgypt: 'المنافسة',
        titleGerman: 'Wettbewerb',
        titleItaly: 'Concorrenza',
        titleRussain: 'Соревнование',
        titleSpain: 'Competición',
        isSubMenu: true,
        icon: <GrGraphQl />,
        key: 3,
        children: [
            {
                titleEnglish: 'Select competition',
                titleNl: 'Selecteer competitie',
                titleEgypt: 'تحديد المنافسة',
                titleGerman: 'Wettbewerb auswählen',
                titleItaly: 'Seleziona competizione',
                titleRussain: 'Выбрать соревнование',
                titleSpain: 'Seleccionar competición',
                route: allPaths.COMPETITION_SELECT,
            },
            {
                titleEnglish: 'Create match request',
                titleNl: 'Maak wedstrijdverzoek',
                titleEgypt: 'إنشاء طلب مباراة',
                titleGerman: 'Erstelle eine Spielanfrage',
                titleItaly: 'Crea richiesta di partita',
                titleRussain: 'Создать запрос на матч',
                titleSpain: 'Crear solicitud de partido',
                route: allPaths.PLAYER_CREATE_COMPETITION_MATCH,
            },
            {
                titleEnglish: 'Match requests',
                titleNl: 'Wedstrijdverzoeken',
                titleEgypt: 'طلبات المباريات',
                titleGerman: 'Spielanfragen',
                titleItaly: 'Richieste di partite',
                titleRussain: 'Запросы на матчи',
                titleSpain: 'Solicitudes de partido',
                route: allPaths.COMPETITION_MATCHES,
            },
            {
                titleEnglish: 'Match result',
                titleNl: 'Wedstrijdresultaat',
                titleEgypt: 'نتيجة المباراة',
                titleGerman: 'Spielergebnis',
                titleItaly: 'Risultato della partita',
                titleRussain: 'Результат матча',
                titleSpain: 'Resultado del partido',
                route: allPaths.COMPETITION_MATCHES_RESULTS,
            },
        ]
    },
    {
        titleEnglish: 'Points',
        titleNl: 'Punten',
        titleEgypt: 'النقاط',
        titleGerman: 'Punkte',
        titleItaly: 'Punti',
        titleRussain: 'Очки',
        titleSpain: 'Puntos',
        isSubMenu: true,
        icon: <GiRank2 />,
        key: 4,
        children: [
            {
                titleEnglish: 'Ranking/points',
                titleNl: 'Ranking/punten',
                titleEgypt: 'التصنيف / النقاط',
                titleGerman: 'Rangliste / Punkte',
                titleItaly: 'Classifica / Punti',
                titleRussain: 'Рейтинг / Очки',
                titleSpain: 'Clasificación / Puntos',
                route: allPaths.PLAYER_RANKING_POINT,
                // icon: <GiRank2 />
            },
            {
                titleEnglish: 'Pro ranking/points',
                titleNl: 'Pro ranking/punten',
                titleEgypt: 'التصنيف المهني / النقاط',
                titleGerman: 'Pro Rangliste / Punkte',
                titleItaly: 'Classifica Pro / Punti',
                titleRussain: 'Профессиональный рейтинг / Очки',
                titleSpain: 'Clasificación Pro / Puntos',
                route: allPaths.PLAYER__PRO_RANKING_POINT,
                // icon: <GiRank2 />
            },
            {
                titleEnglish: 'Competition ranking/points',
                titleNl: 'Competitie ranking/punten',
                titleEgypt: 'تصنيف المنافسة / النقاط',
                titleGerman: 'Wettbewerbsrangliste / Punkte',
                titleItaly: 'Classifica Competizione / Punti',
                titleRussain: 'Рейтинг соревнования / Очки',
                titleSpain: 'Clasificación de la competición / Puntos',
                route: allPaths.COMPETITION_RANKING_POINT,
                // icon: <GiRank2 />
            },
        ]
    },
    {
        titleEnglish: 'Claims',
        titleNl: 'Claims',
        titleEgypt: 'المطالبات',
        titleGerman: 'Ansprüche',
        titleItaly: 'Reclami',
        titleRussain: 'Претензии',
        titleSpain: 'Reclamaciones',
        route: allPaths.PLAYER_CLAIM,
        icon: <BsFillExclamationDiamondFill />
    },
    {
        titleEnglish: 'Settings',
        titleNl: 'Instellingen',
        titleEgypt: 'الإعدادات',
        titleGerman: 'Einstellungen',
        titleItaly: 'Impostazioni',
        titleRussain: 'Настройки',
        titleSpain: 'Configuraciones',
        isSubMenu: true,
        icon: <FiSettings />,
        key: 5,
        children: [
            {
                titleEnglish: 'Profile',
                titleNl: 'Profiel',
                titleEgypt: 'الملف الشخصي',
                titleGerman: 'Profil',
                titleItaly: 'Profilo',
                titleRussain: 'Профиль',
                titleSpain: 'Perfil',
                route: allPaths?.PLAYER_PROFILE,
                // icon: <CgProfile />
            },
            {
                titleEnglish: 'Contact',
                titleNl: 'Contact',
                titleEgypt: 'اتصل بنا',
                titleGerman: 'Kontakt',
                titleItaly: 'Contatto',
                titleRussain: 'Контакт',
                titleSpain: 'Contacto',
                route: allPaths.PLAYER_CONTACT,
                // icon: <RiContactsFill />
            },
            {
                titleEnglish: 'Games',
                titleNl: 'Spel',
                titleEgypt: 'ألعاب',
                titleGerman: 'Spiele',
                titleItaly: 'Giochi',
                titleRussain: 'Игры',
                titleSpain: 'Juegos',
                route: allPaths.PLAYER_GAME_SETTING,
                // icon: <RiGamepadLine />
            },
            {
                titleEnglish: 'Game suggestion',
                titleNl: 'Spelsuggestie',
                titleEgypt: 'اقتراح اللعبة',
                titleGerman: 'Spielvorschlag',
                titleItaly: 'Suggerimento di gioco',
                titleRussain: 'Предложение игры',
                titleSpain: 'Sugerencia de juego',
                route: allPaths?.PLAYER_CREATE_SUGGESTION,
            },
            {
                titleEnglish: 'Invite friend',
                titleNl: 'Vriend uitnodigen',
                titleEgypt: 'دعوة صديق',
                titleGerman: 'Freund einladen',
                titleItaly: 'Invita un amico',
                titleRussain: 'Пригласить друга',
                titleSpain: 'Invitar a un amigo',
                route: allPaths?.PLAYER_INVITE_FRIEND,
                // icon: <CgProfile />
            },
        ]
    },
    {
        titleEnglish: 'Financial ',
        titleNl: 'Financieel ',
        titleEgypt: 'المالية ',
        titleGerman: 'Finanziell ',
        titleItaly: 'Finanziario ',
        titleRussain: 'Финансовый ',
        titleSpain: 'Financiero ',
        isSubMenu: true,
        icon: <TbFileInvoice />,
        key: 6,
        children: [
            {
                titleEnglish: 'Subscription ',
                titleNl: 'Abonnement',
                titleEgypt: 'الاشتراك',
                titleGerman: 'Abonnement',
                titleItaly: 'Abbonamento',
                titleRussain: 'Подписка',
                titleSpain: 'Suscripción',
                route: allPaths.PLAYER_SUBSCRIPTION,
                // icon: <MdAccountBalance />
            },
            {
                titleEnglish: 'Wallet invoices',
                titleNl: 'Portemonnee facturen',
                titleEgypt: 'فواتير المحفظة',
                titleGerman: 'Geldbörsenrechnungen',
                titleItaly: 'Fatture del portafoglio',
                titleRussain: 'Счета кошелька',
                titleSpain: 'Facturas de billetera',
                route: allPaths.PLAYER_INVOIVE,
                // icon: <TbFileInvoice />
            },
            {
                titleEnglish: 'competition subscription',
                titleNl: 'Competitie abonnement',
                titleEgypt: 'اشتراك في المنافسة',
                titleGerman: 'Wettbewerbsabonnement',
                titleItaly: 'Abbonamento alla competizione',
                titleRussain: 'Подписка на соревнование',
                titleSpain: 'Suscripción a la competición',
                route: allPaths.COMPETITION_SUB_INVOICE,
                // icon: <TbFileInvoice />
            },
            {
                titleEnglish: 'Cashback',
                titleNl: 'Geld terug',
                titleEgypt: 'استرداد النقود',
                titleGerman: 'Cashback',
                titleItaly: 'Rimborso',
                titleRussain: 'Возврат наличных',
                titleSpain: 'Devolución de dinero',
                route: allPaths.PLAYER_CASHBACK,
                // icon: <TbBrandCashapp />
            },
            {
                titleEnglish: 'Wallet transactions',
                titleNl: 'Portemonneetransacties',
                titleEgypt: 'معاملات المحفظة',
                titleGerman: 'Brieftaschentransaktionen',
                titleItaly: 'Transazioni del portafoglio',
                titleRussain: 'Транзакции кошелька',
                titleSpain: 'Transacciones de billetera',
                route: allPaths.PLAYER_WALLET_TRANSACTION,
                // icon: <TbBrandCashapp />
            },
        ]
    },
    {
        titleEnglish: 'FAQ',
        titleNl: 'FAQ',
        titleEgypt: 'الأسئلة الشائعة',
        titleGerman: 'FAQ',
        titleItaly: 'Domande frequenti',
        titleRussain: 'Часто задаваемые вопросы',
        titleSpain: 'Preguntas frecuentes',
        route: allPaths.SIDE_MENU_FAQ,
        icon: <FaQuestionCircle />
    },
]

const provincieLanguage = [
    { name: 'Noord-Holland', nameNl: 'Noord-Holland' },
    { name: 'Zuid-Holland', nameNl: 'Zuid-Holland' },
    { name: 'Zeeland', nameNl: 'Zeeland' },
    { name: 'Noord-Brabant', nameNl: 'Noord-Brabant' },
    { name: 'Utrecht', nameNl: 'Utrecht' },
    { name: 'Flevoland', nameNl: 'Flevoland' },
    { name: 'Friesland', nameNl: 'Friesland' },
    { name: 'Groningen', nameNl: 'Groningen' },
    { name: 'Drenthe', nameNl: 'Drenthe' },
    { name: 'Overijssel', nameNl: 'Overijssel' },
    { name: 'Gelderland', nameNl: 'Gelderland' },
    { name: 'Limburg', nameNl: 'Limburg' }
]

const dateFormat = 'DD-MM-YYYY'

const currencySign = ' € '

const pointsType =[{ name: 'Points',value: 'points' }, { name: 'Pro Points', value: 'proPoints' }]
const pointSubscription = [{ money: 10, point: '100' }, { money: 20, point: '300' }, { money: 30, point: '500' }]
export {
    provincieLanguage,
    bgColor,
    drawerRoutesUser,
    drawerRoutesAdmin,
    allPaths,
    dateFormat,
    deployedURL,
    currencySign,
    pointSubscription,
    pointsType
}