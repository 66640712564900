import { Button, Table } from 'antd'
import axios from 'axios'
import React, { useEffect, useState } from 'react'
import io from 'socket.io-client'
import { GET } from '../../../utils/apis'
import { assignMessage, convertNumber, errorMessage } from '../../../utils/helpers'
import PlayerClaimDetail from './PlayerClaimDetail'
import NoData from '../../../assets/noDataImae.png'
import { deployedURL } from '../../../utils/constants'

const PlayerClaim = (props) => {
    const { selectedLanguage, language, user } = props
    const [loading, setLoading] = useState(false)
    const [data, setData] = useState([])
    const [resolveData, setResolveData] = useState([])
    const [showArgues, setShowArgues] = useState(false)
    const [solveArueData, setSolveArueData] = useState([])
    const [claimDetail, setClaimDetail] = useState({})

    useEffect(() => {
        getSocketInfo()
        setLoading(true)
        getData()
        getTournamentArgueData()
    }, [])

    let origin = 'https://wgpplaza.com'
    if (process.env.NODE_ENV === 'development') {
        origin = `http://localhost:4000`
    }

    console.log('origin', origin)
    const socket = io(origin, { reconnect: true });

    socket.on('connect', () => {
        console.log('connected')
    })

    const getSocketInfo = () => {
        socket.on('matchRequestResponse', (d) => {
            const { documentKey, operationType, updateDescription } = d
            getData()
        })
    }

    const getTournamentArgueData = () => {
        console.log('called')
        axios.get(`${GET?.GET_TOURNAMENT_CLAIMS_ARGUE}/${user?._id}`)
            .then((res) => {
                const { data } = res
                setLoading(false)
                if (data?.success) {
                    console.log('dataaaaa', data)
                    setResolveData(data?.data)
                }
                else {
                    setResolveData([])
                }
            })
            .catch((e) => {
                setLoading(false)
                errorMessage(assignMessage(e?.message, language?.language))
            })
    }

    const getData = () => {
        axios.get(`${GET?.GET_PLAYER_CLAIMS_ARGUES}/${user?._id}`)
            .then((res) => {
                const { data } = res
                setLoading(false)
                if (data?.success) {
                    setData(data?.data)
                }
                else {
                    setData([])
                }
            })
            .catch((e) => {
                setLoading(false)
                errorMessage(assignMessage(e?.message, language?.language))
            })
    }

    const columns = [
        {
            title: selectedLanguage?.title_Game,
            dataIndex: 'gameId',
            render: (e) => {
                return (
                    <p className='table_p' style={{ width: '110px' }}>{e?.name || '-----'}</p>
                )
            }
        },
        {
            title: selectedLanguage?.title_DateTime,
            render: (e) => {
                return (
                    <p className='table_p' style={{ width: '140px' }}>{e?.matchDate || '-----'} | {e?.matchTime || '-----'}</p>
                )
            }
        },
        {
            title: selectedLanguage?.text_Sender,
            dataIndex: "senderId",
            render: (e) => {
                return (
                    <p className='table_p' style={{ width: '160px' }}>{e?.name}{e?._id === user?._id ? ` -${selectedLanguage?.text_me}` : ''}</p>
                )
            }

        },
        {
            title: selectedLanguage?.text_Oponent,
            dataIndex: "receiverId",
            render: (e) => {
                return (
                    <p className='table_p' style={{ width: '160px' }}>{e?.name}{e?._id === user?._id ? ` -${selectedLanguage?.text_me}` : ''}</p>

                )
            }
        },
        {
            title: `${selectedLanguage?.text_Points}/${selectedLanguage?.text_money}`,
            render: (e) => {
                return (
                    <p className='table_p'> {e?.isStake ? `$ ${convertNumber(e?.money)}` : convertNumber(e?.points) || '----'}</p>
                )
            }
        },
        {
            title: selectedLanguage?.text_Status,
            render: (e) => {
                return (
                    <>
                        {e?.isAccepted ?
                            <p className='pending-stats'>{selectedLanguage?.text_Pending}</p>
                            : e?.isRejected ?
                                <p className='reject-status'>{selectedLanguage?.text_YouLost}</p>
                                :
                                <p className='pending-stats'>{selectedLanguage?.text_Pending}</p>
                        }
                    </>
                )
            }
        },
    ]

    const argueColumns = [
        {
            title: selectedLanguage?.title_Game,
            dataIndex: 'gameId',
            render: (e) => {
                return (
                    <p className='table_p' style={{ width: '110px' }}>{e?.name || '-----'}</p>
                )
            }
        },
        {
            title: selectedLanguage?.text_GameType,
            dataIndex: 'gameId',
            render: (e) => {
                return (
                    <p className='table_p' style={{ width: '110px' }}>{e?.name || '-----'}</p>
                )
            }
        },
        {
            title: selectedLanguage?.text_MatchDateTime,
            render: (e) => {
                return (
                    <p className='table_p' style={{ width: '150px' }}>{e?.matchDate || '----'}|{e?.matchTime}</p>
                )
            }
        },
        {
            title: selectedLanguage?.text_SenderOfMatchRequest,
            dataIndex: 'senderId',
            render: (e) => {
                return (
                    <p className='table_p' style={{ width: '150px' }}>{e?.name}</p>
                )
            }
        },
        {
            title: selectedLanguage?.text_ReceiverOfMatchRequest,
            dataIndex: 'receiverId',
            render: (e) => {
                return (
                    <p className='table_p' style={{ width: '150px' }}>{e?.name}</p>
                )
            }
        },
        {
            title: `${selectedLanguage?.text_PointsOfMatch}/${selectedLanguage?.text_money}`,
            align: 'center',
            render: (e) => {
                return (
                    <p className='table_p' style={{ width: '150px' }}>{e?.isStake ? `$ ${convertNumber(e?.money)}` : convertNumber(e?.points)}</p>
                )
            }
        },
        {
            title: selectedLanguage?.text_viewDetails,
            render: (e) => {
                return (
                    <Button
                        onClick={() => setClaimDetail(e)}
                        type='primary'
                        style={{ marginLeft: '10px' }}
                    >{selectedLanguage?.text_viewDetails}</Button>
                )
            }
        },
    ]


    const handleOk = () => {
        setClaimDetail({})
    }
    return (
        <div className='new-main-component-screen-div'>
            <h1 className='new-screen-title'>
                {selectedLanguage?.text_MyClaim}
            </h1>
            {
                claimDetail?._id &&
                <PlayerClaimDetail data={claimDetail} onCancel={() => setClaimDetail({})} visible={claimDetail._id ? true : false} handleOk={handleOk} {...props} />
            }
            <span style={{ marginTop: '20px' }} className='button-div-flex-end'>
                {showArgues ?
                    <Button
                        type='primary'
                        onClick={() => setShowArgues(false)}>
                        {selectedLanguage?.text_Myclaims}
                    </Button>
                    :
                    <Button
                        type='primary'
                        onClick={() => setShowArgues(true)}>
                        {selectedLanguage?.text_Resolveclaims}
                    </Button>}
            </span>

            <div>


                <div className="custom-table-container">
                    <Table
                        locale={{ emptyText: selectedLanguage?.txt_NoDataFound }}
                        className='new-layout-table'
                        dataSource={showArgues ? resolveData : data}
                        columns={showArgues ? argueColumns : columns}
                        pagination={false}
                        loading={loading}
                        scroll={{ x: 400 }}
                    />
                </div>
            </div>
        </div>
    )
}

export default PlayerClaim