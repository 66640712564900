import React, { useState } from 'react'
import { UploadOutlined } from '@ant-design/icons'
import { Button, Form, Modal, Upload } from 'antd'
import { assignMessage, errorMessage, successMessage, warningMessage } from '../../../utils/helpers'
import { POST } from '../../../utils/apis'
import axios from 'axios'
import { currencySign } from '../../../utils/constants'

const DrawMatchProof = (props) => {
    const { selectedLanguage, language, onCancel, user, data, visible, handleOk } = props
    const [fileList, setFileList] = useState([])
    const [loading, setLoading] = useState(false)
    const [form] = Form.useForm()
    console.log('data', data)

    const onFinish = (values) => {
        setLoading(true)

        values.file = fileList

        if (!values._id && !values?.file?.length) {
            return warningMessage(selectedLanguage?.PleaseUploadImage)
        }

        let formData = new FormData()
        if (values?.file?.length) {
            formData.append('file', values?.file[0])
        }
        formData.append('_id', data?._id)
        formData.append('userId', user?._id)
        formData.append('gameId', data?.gameId?._id)
        formData.append('opponentId', data?.receiverId?._id !== user?._id ? data?.receiverId?._id : data?.senderId?._id)

        axios.post(POST.USER_MATCH_DRAW, formData)
            .then((res) => {
                const { data } = res
                setLoading(false)
                if (data?.success) {
                    successMessage(assignMessage(data?.message, language?.language))
                    onCancel()
                }
                else {
                    errorMessage(assignMessage(data?.message, language?.language))
                }
            })
            .catch((e) => {
                setLoading(false)
                errorMessage(assignMessage(e?.message, language?.language))
                console.log('e', e)
            })

    }

    const normFile = (e) => {
        setFileList([])

        if (e?.fileList?.length && e?.file?.type !== 'image/png' && e?.file?.type !== 'image/jpeg') {
            errorMessage(selectedLanguage?.YouCanOnlyUploadImages)
            return []
        }

        if (Array.isArray(e)) {
            return e
        }

        e?.fileList?.length ? setFileList([...[e?.file]]) : setFileList([])
        return e && [e.file]
    }

    return (
        <Modal
            open={visible}
            onOk={handleOk}
            onCancel={handleOk}
            footer={null}>
            <div style={{ marginTop: '10px' }}>
                <span style={{ textAlign: 'center' }}>
                    <p className='admin-home-title' style={{ fontSize: '18px' }}>{
                        selectedLanguage?.text_AddProofofmatchthatyouDraw
                    }</p>
                </span>
            </div>
            <div className='border-line' />
            <div>
                <p className='admin-home-title' style={{ fontSize: '18px', marginBottm: '15px' }}>{selectedLanguage?.text_GameDetail}</p>
                <p className='p-none-margin'><p className='p-none-margin-bolder'>{selectedLanguage?.label_NickName}</p>: {data?.gameId?.name}</p>
                <p className='p-none-margin'> <p className='p-none-margin-bolder'>{selectedLanguage?.text_Description} </p>: {data?.gameId?.description}</p>
            </div>
            <div>
                <p className='admin-home-title' style={{ fontSize: '18px', marginBottm: '15px' }}>{selectedLanguage?.text_MatchDetail}</p>
                <p className='p-none-margin'> <p className='p-none-margin-bolder'>{selectedLanguage?.text_MatchDateTime} </p>: {data?.matchDate} | {data?.matchTime}</p>
                <p className='p-none-margin'> <p className='p-none-margin-bolder'>{selectedLanguage?.text_MessagefromSender} </p>: {data?.message}</p>
                <p className='p-none-margin'> <p className='p-none-margin-bolder'>{data?.isStake ? selectedLanguage?.text_money : selectedLanguage?.text_points} </p>: {data?.isStake ? `${currencySign} ${data?.money}` : data?.points}</p>
            </div>
            <div>
                <p className='admin-home-title' style={{ fontSize: '18px', marginBottm: '15px' }}>{selectedLanguage?.text_Requestsenderdetail}</p>
                <p className='p-none-margin'> <p className='p-none-margin-bolder'>{selectedLanguage?.title_Name} </p>: {data?.senderId?.name} </p>
                <p className='p-none-margin'> <p className='p-none-margin-bolder'>{selectedLanguage?.label_Email} </p>: {data?.senderId?.email}</p>
            </div>
            <div>
                <p className='admin-home-title' style={{ fontSize: '18px', marginBottm: '15px' }}>{selectedLanguage?.text_Resuestoponentdetail}</p>
                <p className='p-none-margin'> <p className='p-none-margin-bolder'>{selectedLanguage?.title_Name} </p>: {data?.receiverId?.name} </p>
                <p className='p-none-margin'> <p className='p-none-margin-bolder'>{selectedLanguage?.label_Email} </p>: {data?.receiverId?.email}</p>
            </div>
            <div className='border-line' />
            <div style={{ marginTop: '20px' }}>
                <span style={{ textAlign: 'center' }}>
                    <p className='admin-home-title' style={{ fontSize: '18px' }}>{
                        selectedLanguage?.text_AddProofofDraw
                    }</p>
                </span>
                <Form
                    name='form'
                    form={form}
                    onFinish={onFinish}
                    layout={'vertical'}
                >
                    <div className='form-two-input-flex-inner-input' style={{ width: '100%' }}>
                        <Form.Item
                            name='file'
                            label={selectedLanguage?.text_DrawProof}
                        >
                            <Upload
                                name='file'
                                multiple={false}
                                beforeUpload={() => false}
                                accept='image/*'
                                onChange={normFile}
                                fileList={fileList}
                            >
                                <Button icon={<UploadOutlined />}>{selectedLanguage?.tetx_clicktouploadproof}</Button>
                            </Upload>
                        </Form.Item>
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'flex-end', }}>
                        <Form.Item>
                            <Button
                                type='primary'
                                htmlType='submit'
                                loading={loading}
                            >
                                {selectedLanguage?.text_SendtoAdmin}
                            </Button>
                        </Form.Item>
                    </div>
                </Form>
            </div>
        </Modal>
    )
}

export default DrawMatchProof