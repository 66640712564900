import React, { useEffect, useState } from "react";
import axios from "axios";
import { useDispatch, useSelector } from 'react-redux'
import { ADMIN } from "../../../utils/apis";
import { AddAllPlayers } from "../../../Redux/actions/adminAllPlayerAction";
import ViewCard from "../../Cards/ViewCard";
import { convertRoundNumber, createInvoicePdf } from "../../../utils/helpers";
import moment from "moment";
import { Select, Table } from "antd";
import { currencySign } from "../../../utils/constants";
const { Option } = Select

const AdminAllInvoices = (props) => {
    const { user, selectedLanguage, isEnglish } = props
    const dispatch = useDispatch()
    const player = useSelector(state => state?.adminAllPlayerReducer?.adminAllPlayer)
    const [selectedUser, setSelectedUser] = useState(player?.[0]?._id || null)
    const [selectedUserDetail, setSelectedUserDetail] = useState(player?.[0] || null)
    const [showInvoice, setShowInvoice] = useState(false)
    const [invoice, setInvoice] = useState({})
    useEffect(() => {
        setSelectedUserDetail(player?.filter((v) => v?._id === selectedUser)[0])
    }, [selectedUser])


    console.log(selectedUserDetail, 'asasa')
    const viewInvoice = (fileUrl) => {
        setInvoice({
            designName: 'Invoice',
            fileUrl
        })

        setShowInvoice(true)
    }

    const onCancel = () => {
        setInvoice({})
        setShowInvoice(false)
    }


    const getallPlayer = () => {
        axios.get(`${ADMIN?.GET_ALL_PLAYERS}/${user?._id}`)
            .then((res) => {
                const { data } = res
                if (data?.success) {
                    dispatch(AddAllPlayers(data?.data || []))
                } else {
                    console.log('else')
                }
            })
            .catch((e) => {
                console.log('mainError')
            })
    }

    let columns = [
        {
            title: selectedLanguage?.text_SubscriptionName,
            dataIndex: 'subscriptionName',
            key: 'subscriptionName',
            // sorter: true,
        },
        {
            title: selectedLanguage?.text_TotalAmount,
            dataIndex: 'totalAmount',
            key: 'totalAmount',
            // sorter: true,
            render: (e) => <p style={{ paddingTop: 15, marginTop: -5 }}>{currencySign} {convertRoundNumber(e)}</p>
        },
        {
            title: selectedLanguage?.text_SubscriptionDate,
            dataIndex: 'created',
            key: 'created',
            render: (e) => <p>{moment(e).format('DD-MM-YYYY')}</p>
        },
        {
            title: selectedLanguage?.text_ViewInvoice,
            key: 'invoice',
            render: (e) => <a
                onClick={() => { createInvoicePdf(e, selectedUserDetail, 'view', viewInvoice) }}
            >{selectedLanguage?.text_View}</a>
        },
        {
            title: selectedLanguage?.text_DownloadInvoice,
            key: 'download',
            render: (e) => <a
                onClick={() => { createInvoicePdf(e, selectedUserDetail, 'download', viewInvoice) }}
            >{selectedLanguage?.text_Download}</a>
        },
    ]


    useEffect(() => {
        getallPlayer()
    }, [])

    return (
        <div className='new-main-component-screen-div'>
            <h1 className='new-screen-title' >
                {selectedLanguage?.text_PlayerInvoices}
            </h1>
            <br />
            <Select
                showSearch
                showArrow
                allowClear={false}
                defaultValue={selectedUser}
                onChange={(e) => setSelectedUser(e)}
                className='form-input'
                placeholder={selectedLanguage?.placeholder_SelectUser}
                notFoundContent={selectedLanguage?.txt_NoDataFound}
                optionFilterProp='children'
                filterOption={(input, option) =>
                    option?.value?.toString()?.indexOf(input.toLowerCase()) >= 0
                }
            >
                {player?.map((v, i) => <Option value={v?._id} key={i}>{`${(v?.name)} ${v?.email}`}</Option>)}
            </Select>

            <div className="custom-table-container">
                <Table
                    locale={{ emptyText: selectedLanguage?.txt_NoDataFound }}
                    className='new-layout-table'
                    dataSource={selectedUserDetail?.invoiceHistory || []}
                    columns={columns}
                    pagination={false}
                    scroll={{ x: 700 }}
                />
            </div>
            {showInvoice ? <ViewCard card={invoice} onCancel={onCancel} showData={showInvoice}{...props} /> : null}

        </div>
    )
}

export default AdminAllInvoices