import { Button, Form, Input, Modal, Select, Table } from 'antd'
import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { POST } from '../../../utils/apis'
import { assignMessage, errorMessage, successMessage } from '../../../utils/helpers'
const AddNewGameSetting = (props) => {
    const { Option } = Select
    const { selectedLanguage, language, allGames, AddGame, handleOk, edit, user, myGameSetting } = props
    const [form] = Form.useForm()
    const [loading, setLoading] = useState(false)
    const [disabledArray, setDisabledArray] = useState([])
    const [type, setType] = useState(edit?.gameId?.gameType || '1-1 Match Game')

    useEffect(() => {
        let arr = []
        myGameSetting.map((v, i) => {
            arr.push(v?.gameId?._id)
        })
        setDisabledArray(arr)
    }, [])

    const onTypeChange = (e) => {
        setType(e)
        form.resetFields()
    }

    const onFinish = (values) => {
        setLoading(true)
        values.playerId = user?._id
        values.nickName = values.nickName.trim()
        values._id = edit?._id && edit?._id

        axios.post(edit?._id ? POST.EDIT_PLAYER_GAME_SETTING : POST?.CREATE_PLAYER_GAME_SETTING, values)
            .then((res) => {
                const { data } = res
                setLoading(false)
                if (data.success) {
                    successMessage(assignMessage(data?.message, language?.language))
                    form.resetFields()
                    handleOk()
                }
                else {
                    errorMessage(assignMessage(data?.message, language?.language))
                }
            })
            .catch((e) => {
                setLoading(false)
                errorMessage(assignMessage(e?.message, language?.language))
            })
    }

    return (
        <Modal
            open={AddGame}
            onOk={handleOk}
            onCancel={handleOk}
            className='pop-info-modal'
            footer={null}>
            <div>
                <p className='p-question'>
                    {edit?._id ? selectedLanguage?.title_UpdateGameSetting : selectedLanguage?.title_AddNewGameSetting}
                </p>
                <div className='form-div-in-modal'>
                    <p className='admin-home-title'
                        style={{
                            fontSize: '14px',
                            fontWeight: '500',
                            marginTop: '5px',
                            color: 'rgb(106, 212, 155)',
                            padding: 5
                        }}>{selectedLanguage?.text_RequiredFieldsSettingsNeedsTobeDoneForEachGameSeperately}
                    </p>
                    <Form
                        name='form'
                        form={form}
                        onFinish={onFinish}
                        layout={'vertical'}
                    >
                        <p style={{ padding: '0px', margin: '0px', marginBottom: '6px', marginLeft: '4px' }} className='p-admin-title-text' > {selectedLanguage?.text_SelectGameType} </p>

                        <Select
                            defaultValue={type}
                            disabled={edit?._id}
                            className='form-input-new-design-big-slam'
                            placeholder={selectedLanguage?.text_SelectGameType}
                            style={{ width: '100%', marginBottom: '20px', marginTop: '5px' }}
                            onChange={onTypeChange}
                        >
                            <Option value={'1-1 Match Game'}>{selectedLanguage?.text_OneByOneMatchGame}</Option>
                            <Option value={'Team Match Game'}>{selectedLanguage?.text_TeamMatchGame}</Option>
                        </Select>

                        <Form.Item
                            name='gameId'
                            hasFeedback
                            label={<p className='p-admin-title-text' >{selectedLanguage?.label_SelectGame}</p>}
                            initialValue={edit?.gameId?._id}
                            rules={[
                                {
                                    required: true,
                                    message: selectedLanguage?.error_pleaseSelectGame
                                }
                            ]}
                        >
                            <Select
                                className='form-input-new-design-big-slam'
                                disabled={edit?._id}
                                notFoundContent={selectedLanguage?.txt_NoDataFound}
                                placeholder={selectedLanguage?.placeHolder_SelectGamefortournament}>
                                {allGames?.length ?
                                    allGames?.map((v, i) => {
                                        return (
                                            disabledArray.includes(v?._id) ?
                                                null :
                                                v?.gameType === type &&
                                                <Option value={v?._id}>{`${v?.name} - ${v?.gameType} - ${v?.description}`}</Option>
                                        )
                                    })
                                    : null}
                            </Select>
                        </Form.Item>

                        <Form.Item
                            name='nickName'
                            hasFeedback
                            initialValue={edit?.nickName}
                            label={<p className='p-admin-title-text' >{selectedLanguage?.label_NickName}</p>}
                            rules={[
                                {
                                    required: true,
                                    message: selectedLanguage?.error_PleaseEnterYourNickName
                                }
                            ]}
                        >
                            <Input
                                className='form-input-new-design-big-slam'
                                placeholder={selectedLanguage?.placeHolder_EnterYourNickNameHere}
                            />
                        </Form.Item>

                        <div style={{ display: 'flex', justifyContent: 'flex-end', }}>
                            <Form.Item>
                                <Button
                                    type='primary'
                                    htmlType='submit'
                                    loading={loading}
                                >
                                    {edit?._id ?
                                        selectedLanguage?.button_UpdateGameSetting
                                        :
                                        selectedLanguage?.button_AddNewGameSetting
                                    }
                                </Button>
                            </Form.Item>
                        </div>
                    </Form>
                </div>
            </div>
        </Modal>
    )
}

export default AddNewGameSetting