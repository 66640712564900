import React, { useEffect, useState } from 'react'
import { IoIosArrowUp, IoIosArrowDown } from "react-icons/io"
import { useHistory } from 'react-router-dom'
import { FaqsParticipation, FaqsGoal, FaqsTournaments, HowWork } from "../../utils/faqConstant"
import EnglishLanguage from '../../Languages/EnglishLanguage.json'
import NlLanguage from '../../Languages/NetherlandsLanguage.json'
import { useSelector } from 'react-redux'
import { getFaqHelper } from '../../utils/helpers'
const SideMenuFaq = (props) => {
    const { faqSettingAction, language, selectedLanguage } = props
    const Sl_lan = language?.language
    const faqSettingData = useSelector(state => state?.faqSettingReducer?.faqSetting)
    const isEnglish = language?.language === 'english'
    const [reading, setReading] = useState('')
    const [readingGoals, setReadingGoals] = useState('')
    const [readingTournaments, setReadingTournaments] = useState('')

    useEffect(() => {
        getFaqHelper(faqSettingAction)
    }, [])

    return (
        <div className='new-main-component-screen-div'>

            <div className='height-35'>
                <p className='new-screen-title'>{selectedLanguage?.title_HowItWorks}</p>
            </div>

            <div className='divFaqDiv' style={{ marginTop: '35px' }}>
                {faqSettingData?.howIsWorkArray?.map((v, i) => {
                    return (
                        <>
                            <div
                                key={1 + i}
                                style={{
                                    padding: '20px',
                                    width: '100%',
                                    marginTop: '20px',
                                    borderRadius: '8px',
                                    paddingTop: '30px',
                                    boxShadow: 'white 0px 2px 5px -4px',
                                    borderRadius: '8px',
                                    background: '#000000a3'
                                }}>
                                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                                    <p style={{ fontWeight: '600', fontSize: 16, width: '90%', margin: '0px', padding: '0px', lineBreak: 'anywhere', color: 'white' }}>
                                        {Sl_lan === 'netherlands' ? v?.titleDutch
                                            : Sl_lan === 'italy' ? v?.titleItaly
                                                : Sl_lan === 'spain' ? v?.titleSpain
                                                    : Sl_lan === 'egypt' ? v?.titleEgypt
                                                        : Sl_lan === 'russain' ? v?.titleRussain
                                                            : Sl_lan === 'german' ? v?.titleGerman
                                                                : v?.titleEnglish}
                                    </p>
                                </div>
                                <div>
                                    <br />
                                    <pre style={{ width: '100%', margin: '0px', padding: '0px', lineBreak: 'anywhere', color: 'white' }}>
                                        {Sl_lan === 'netherlands' ? v?.DiscriptionDutch
                                            : Sl_lan === 'italy' ? v?.DiscriptionItaly
                                                : Sl_lan === 'spain' ? v?.DiscriptionSpain
                                                    : Sl_lan === 'egypt' ? v?.DiscriptionEgypt
                                                        : Sl_lan === 'russain' ? v?.DiscriptionRussain
                                                            : Sl_lan === 'german' ? v?.DiscriptionGerman
                                                                : v?.DiscriptionEnglish}
                                    </pre>
                                </div>
                            </div>
                        </>
                    )
                })
                }
            </div>

            <div className='div-flex-back-name' style={{ marginTop: '35px' }}>
                <div className='height-35'>
                    <p className='new-screen-title' >{selectedLanguage?.faq_FAQS}</p>
                </div>
            </div>

            {faqSettingData?.FaqArrayGrourp?.Participation?.length &&

                <div>
                    <div className='height-35' style={{ width: '100%', marginTop: '35px' }} >
                        <p className='new-screen-title' style={{ textAlign: 'center', }}>{selectedLanguage?.title_Participation}</p>
                    </div>
                    <div className='divFaqDiv' style={{ marginTop: '35px' }}>
                        {faqSettingData?.FaqArrayGrourp?.Participation?.map((v, i) => {
                            return (
                                <>
                                    <div
                                        key={1 + i}
                                        style={{
                                            padding: '20px',
                                            width: '100%',
                                            marginTop: '20px',
                                            boxShadow: 'white 0px 2px 5px -4px',
                                            borderRadius: '8px',
                                            background: '#000000a3',
                                            paddingTop: '30px'
                                        }}>
                                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                                            <p style={{ width: '90%', margin: '0px', padding: '0px', lineBreak: 'anywhere', color: 'white' }}>
                                                {Sl_lan === 'netherlands' ? v?.titleDutch
                                                    : Sl_lan === 'italy' ? v?.titleItaly
                                                        : Sl_lan === 'spain' ? v?.titleSpain
                                                            : Sl_lan === 'egypt' ? v?.titleEgypt
                                                                : Sl_lan === 'russain' ? v?.titleRussain
                                                                    : Sl_lan === 'german' ? v?.titleGerman
                                                                        : v?.titleEnglish}
                                            </p>
                                            {reading !== v?.titleEnglish ?
                                                <IoIosArrowDown color='white' style={{ cursor: 'pointer' }} size={25} onClick={() => {
                                                    setReading(v?.titleEnglish)
                                                }} /> :
                                                <IoIosArrowUp color='white' style={{ cursor: 'pointer' }} size={25} onClick={() => {
                                                    setReading('')
                                                }} />}
                                        </div>
                                        {reading === v?.titleEnglish ?
                                            <div>
                                                <br />
                                                <pre style={{ width: '100%', margin: '0px', padding: '0px', lineBreak: 'anywhere', color: 'white' }}>
                                                    {Sl_lan === 'netherlands' ? v?.DiscriptionDutch
                                                        : Sl_lan === 'italy' ? v?.DiscriptionItaly
                                                            : Sl_lan === 'spain' ? v?.DiscriptionSpain
                                                                : Sl_lan === 'egypt' ? v?.DiscriptionEgypt
                                                                    : Sl_lan === 'russain' ? v?.DiscriptionRussain
                                                                        : Sl_lan === 'german' ? v?.DiscriptionGerman
                                                                            : v?.DiscriptionEnglish}
                                                </pre>
                                            </div> : null}
                                    </div>
                                </>
                            )
                        })
                        }
                    </div>
                </div>
            }

            {faqSettingData?.FaqArrayGrourp?.Goal?.length &&
                <div>
                    <div className='height-35' style={{ width: '100%', marginTop: '35px' }} >
                        <p className='new-screen-title' style={{ textAlign: 'center', }}>{selectedLanguage?.title_Goal}</p>
                    </div>
                    <div className='divFaqDiv' style={{ marginTop: '35px' }}>
                        {faqSettingData?.FaqArrayGrourp?.Goal?.map((v, i) => {
                            return (
                                <>
                                    <div
                                        key={1 + i}
                                        style={{
                                            padding: '20px',
                                            width: '100%',
                                            marginTop: '20px',
                                            boxShadow: 'white 0px 2px 5px -4px',
                                            borderRadius: '8px',
                                            background: '#000000a3',
                                            paddingTop: '30px'
                                        }}>
                                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                                            <p style={{ width: '90%', margin: '0px', padding: '0px', lineBreak: 'anywhere', color: 'white' }}>
                                                {Sl_lan === 'netherlands' ? v?.titleDutch
                                                    : Sl_lan === 'italy' ? v?.titleItaly
                                                        : Sl_lan === 'spain' ? v?.titleSpain
                                                            : Sl_lan === 'egypt' ? v?.titleEgypt
                                                                : Sl_lan === 'russain' ? v?.titleRussain
                                                                    : Sl_lan === 'german' ? v?.titleGerman
                                                                        : v?.titleEnglish}
                                            </p>
                                            {readingGoals !== v?.titleEnglish ?
                                                <IoIosArrowDown color='white' style={{ cursor: 'pointer' }} size={25} onClick={() => {
                                                    setReadingGoals(v?.titleEnglish)
                                                }} /> :
                                                <IoIosArrowUp color='white' style={{ cursor: 'pointer' }} size={25} onClick={() => {
                                                    setReadingGoals('')
                                                }} />}
                                        </div>
                                        {readingGoals === v?.titleEnglish ?
                                            <div>
                                                <br />
                                                <pre style={{ width: '100%', margin: '0px', padding: '0px', lineBreak: 'anywhere', color: 'white' }}>
                                                    {Sl_lan === 'netherlands' ? v?.DiscriptionDutch
                                                        : Sl_lan === 'italy' ? v?.DiscriptionItaly
                                                            : Sl_lan === 'spain' ? v?.DiscriptionSpain
                                                                : Sl_lan === 'egypt' ? v?.DiscriptionEgypt
                                                                    : Sl_lan === 'russain' ? v?.DiscriptionRussain
                                                                        : Sl_lan === 'german' ? v?.DiscriptionGerman
                                                                            : v?.DiscriptionEnglish}
                                                </pre>
                                            </div> : null}
                                    </div>
                                </>
                            )
                        })
                        }
                    </div>
                </div>
            }
            {faqSettingData?.FaqArrayGrourp?.Tournaments?.length &&
                <div>
                    <div className='height-35' style={{ width: '100%', marginTop: '35px' }} >
                        <p className='new-screen-title' style={{ textAlign: 'center', }}>{selectedLanguage?.title_Tournaments}</p>
                    </div>
                    <div className='divFaqDiv' style={{ marginTop: '35px' }}>
                        {faqSettingData?.FaqArrayGrourp?.Tournaments?.map((v, i) => {
                            return (
                                <>
                                    <div
                                        key={1 + i}
                                        style={{
                                            padding: '20px',
                                            width: '100%',
                                            marginTop: '20px',
                                            boxShadow: 'white 0px 2px 5px -4px',
                                            borderRadius: '8px',
                                            background: '#000000a3',
                                            paddingTop: '30px'
                                        }}>
                                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                                            <p style={{ width: '90%', margin: '0px', padding: '0px', lineBreak: 'anywhere', color: 'white' }}>
                                                {Sl_lan === 'netherlands' ? v?.titleDutch
                                                    : Sl_lan === 'italy' ? v?.titleItaly
                                                        : Sl_lan === 'spain' ? v?.titleSpain
                                                            : Sl_lan === 'egypt' ? v?.titleEgypt
                                                                : Sl_lan === 'russain' ? v?.titleRussain
                                                                    : Sl_lan === 'german' ? v?.titleGerman
                                                                        : v?.titleEnglish}
                                            </p>
                                            {readingTournaments !== v?.titleEnglish ?
                                                <IoIosArrowDown color='white' style={{ cursor: 'pointer' }} size={25} onClick={() => {
                                                    setReadingTournaments(v?.titleEnglish)
                                                }} /> :
                                                <IoIosArrowUp color='white' style={{ cursor: 'pointer' }} size={25} onClick={() => {
                                                    setReadingTournaments('')
                                                }} />}
                                        </div>
                                        {readingTournaments === v?.titleEnglish ?
                                            <div>
                                                <br />
                                                <pre style={{ width: '100%', margin: '0px', padding: '0px', lineBreak: 'anywhere', color: 'white' }}>
                                                    {Sl_lan === 'netherlands' ? v?.DiscriptionDutch
                                                        : Sl_lan === 'italy' ? v?.DiscriptionItaly
                                                            : Sl_lan === 'spain' ? v?.DiscriptionSpain
                                                                : Sl_lan === 'egypt' ? v?.DiscriptionEgypt
                                                                    : Sl_lan === 'russain' ? v?.DiscriptionRussain
                                                                        : Sl_lan === 'german' ? v?.DiscriptionGerman
                                                                            : v?.DiscriptionEnglish}
                                                </pre>
                                            </div> : null}
                                    </div>
                                </>
                            )
                        })
                        }
                    </div>
                </div>
            }

        </div>
    )
}

export default SideMenuFaq