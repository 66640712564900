import { MENU_NONE } from '../types'

const SetMenuNone = (menuNone) => {
    return {
        type: MENU_NONE,
        menuNone
    }
}
export {
    SetMenuNone,
}