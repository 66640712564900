import React, { useEffect, useState } from 'react'
import { Button, Popconfirm, Table, Tooltip } from 'antd'
import axios from 'axios'
import { GET, POST } from '../../../utils/apis'
import { getUserData, successNotification, errorMessage, convertNumber } from '../../../utils/helpers'
import AddCashback from './AddCashback'
import moment from 'moment'
import { currencySign } from '../../../utils/constants'

const PlayerCashBack = (props) => {
  const { user, userActions, selectedLanguage, isEnglish } = props
  const [spin, setSpin] = useState({})
  const [form, setForm] = useState([])
  const [cashBackAmount, setCashBackAmount] = useState()
  const [loading, setLoading] = useState(false)
  const wallet = user?.walletBalance
  useEffect(() => {
    getUserData(user?._id, userActions);
    getCashBackForm()
  }, [])

  const getCashBackForm = () => {
    setLoading(true)
    axios.get(`${GET.GET_USER_CASH_BACK}/${user?._id}`)
      .then((res) => {
        const { data } = res
        setForm(data?.data || [])
        setLoading(false)
      })
      .catch(() => {
        setLoading(false)
      })

    axios.get(`${GET.GET_USER_CASH_BACK_AMOUNT}/${user?._id}`)
      .then((res) => {
        const { data } = res
        setCashBackAmount(data?.data || 0)
        setLoading(false)
      })
      .catch(() => {
        setLoading(false)
      })
  }

  const columns = [
    {
      title: selectedLanguage?.text_FormId,
      dataIndex: '_id',
      ellipsis: true
    },
    {
      title: selectedLanguage?.text_Amount,
      dataIndex: 'amount',
      key: 'amount',
      sorter: (a, b) => a.amount - b.amount,
      ellipsis: true,
      render: (e) => <p style={{ paddingTop: 15, marginTop: -5 }}> {currencySign} {convertNumber(e)}</p>
    },
    {
      title: selectedLanguage?.txt_Status,
      key: 'isPaid',
      dataIndex: 'isPaid',
      sorter: (a, b) => a.isPaid - b.isPaid,
      ellipsis: true,
      render: (e) => <span>{e ? selectedLanguage?.text_Approved : selectedLanguage?.text_Pending}</span>
    },
    {
      title: selectedLanguage?.text_Created_At,
      key: 'created',
      dataIndex: 'created',
      ellipsis: true,
      render: (e) => <span>{moment(e).utcOffset(0, true).format('DD-MM-YYYY')}</span>
    },
  ]

  return (
    <div className='new-main-component-screen-div'>
      <h1 className='new-screen-title'> {selectedLanguage?.text_Cashback}</h1>
      <span style={{ marginTop: '20px' }} className='button-div-flex-end'>
        <AddCashback getCashBackForm={getCashBackForm} reRender={() => { getUserData(user?._id, userActions) }} {...props} />
      </span>

      <div style={{ marginTop: '20px' }}>
        <div className="custom-table-container">
          <Table
            locale={{ emptyText: selectedLanguage?.txt_NoDataFound }}
            className='new-layout-table'
            loading={loading}
            dataSource={form}
            columns={columns}
            pagination={{ hideOnSinglePage: true }}
            scroll={{ x: 800 }}
          />
        </div>
      </div>
    </div>
  )
}

export default PlayerCashBack