import moment from 'moment-timezone'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { GET, POST, ADMIN } from '../../../utils/apis'
import axios from 'axios'
import { errorMessage, successMessage, getAllUserData, convertRoundNumber, createCashBackReport, assignMessage } from '../../../utils/helpers'
import { Table, Button, DatePicker, Modal, Tooltip, Select, Spin } from 'antd'
import IconButton from '@mui/material/IconButton'
import _ from 'lodash'

import DoneAllIcon from '@mui/icons-material/DoneAll'
import CloseIcon from '@mui/icons-material/Close';
import { currencySign } from '../../../utils/constants'

const { RangePicker } = DatePicker
const AdminCashback = (props) => {
    const { user, userActions, selectedLanguage, language } = props
    const [loading, setLoading] = useState(false)
    const [showDraw, setShowDraw] = useState(false)
    const [showDetails, setShowDetails] = useState(false)
    const [showSubmitModal, setShowSubmitModal] = useState({})
    const [bankDetails, setBankDetails] = useState({})
    const [usrDetail, setUsrDetail] = useState({})
    const [forms, setForms] = useState([])
    const [filteredArray, setfilteredArray] = useState([])
    const [pendingRequest, setPendingRequest] = useState([])


    const [dateRange, setDateRange] = useState({
        date1: moment().add(-1, 'months').format('YYYY-MM-DD'),
        date2: moment().format('YYYY-MM-DD')
    })
    useEffect(() => {
        getAllForms()
    }, [dateRange])
    useEffect(() => {
        setfilteredArray(_.uniqBy(buemailArray, 'text'))
        setPendingRequest(forms.filter(item => item.isPaid === false))
    }, [forms])

    const getAllForms = () => {
        setLoading(true)
        let obj = { date1: dateRange?.date1, date2: dateRange?.date2 }
        axios.post(`${ADMIN.GET_ALL_CASH_BACKS}`, obj)
            .then((res) => {
                const { data } = res
                setForms(data?.data || [])
                setLoading(false)
                setShowSubmitModal({})
            })
            .catch((e) => {
                setLoading(false)
            })
    }
    const approveCashBackStatus = (e) => {
        let obj = {
            _id: e?._id,
            isPaid: true,
            adminId: user?._id
        }
        setLoading(true)
        axios.post(ADMIN.CONFIRM_CASH_BACK, obj)
            .then((res) => {
                const { data } = res
                setLoading(false)
                if (data.success) {
                    getAllForms()
                    successMessage(assignMessage(data?.message, language?.language))
                }
                else {
                    errorMessage(assignMessage(data?.message, language?.language))
                }
            })
            .catch((e) => {
                setLoading(false)
            })
    }
    const columns = [
        {
            title: selectedLanguage?.title_PlayerName,
            dataIndex: 'playerId',
            key: 'playerId',
            render: (e) => <span>{e?.name}</span>
        },
        {
            title: selectedLanguage?.text_PlayerEmail,
            dataIndex: 'playerId',
            key: 'playerId',
            filters: filteredArray,
            onFilter: (value, record) => record.playerId?.email.indexOf(value) === 0,
            render: (e) => <span>{e?.email}</span>
        },
        {
            title: selectedLanguage?.text_Amount,
            dataIndex: 'amount',
            key: 'amount',
            sorter: (a, b) => a.amount - b.amount,
            render: (e) => <p style={{ paddingTop: 15, marginTop: -5 }}> $ {convertRoundNumber(e)}</p>
        },
        {
            title: selectedLanguage?.text_ServiceCharge,
            dataIndex: 'serviceCharge',
            key: 'serviceCharge',
            render: (e) => <p style={{ paddingTop: 15, marginTop: -5 }}> $ {convertRoundNumber(e)}</p>
        },
        {
            title: selectedLanguage?.text_CreateDate,
            dataIndex: 'created',
            key: 'created',
            render: (e) => <p style={{ paddingTop: 15, marginTop: -5 }}> {moment(e).utcOffset(0, true).format('DD-MM-YYYY')}</p>
        },
        {
            title: selectedLanguage?.txt_Status,
            sorter: (a, b) => a.action - b.action,
            render: (e) => (
                <div style={{ display: 'flex', width: '100px', justifyContent: 'space-between' }}>
                    <p style={{ width: '50px', marginTop: '10px' }}>{e?.isPaid ? selectedLanguage?.text_paid : selectedLanguage?.pending}</p>
                    {
                        e?.isPaid === true ?
                            < IconButton style={{ color: 'green' }} disabled >
                                <DoneAllIcon />
                            </IconButton > :
                            < IconButton style={{ color: 'red' }} onClick={() => { setShowSubmitModal(e) }} >
                                <CloseIcon />
                            </IconButton >}
                </div>
            )
        },
        {
            render: (e) => (
                <Button onClick={() => { setBankDetails(e?.playerId); setUsrDetail(e); setShowDetails(true) }}>{selectedLanguage?.text_Details}</Button>
            )
        }
    ]

    const createReportRange = (d) => {
        setDateRange({
            date1: d[0].format('YYYY-MM-DD'),
            date2: d[1].format('YYYY-MM-DD')
        })
        getAllForms()
    }

    const buemailArray = forms.map((v, i) => {
        return {
            text: v?.playerId?.email,
            value: v?.playerId?.email
        }
    })

    const handleOk = () => {
        setShowDetails(false)
        setUsrDetail({})
        setBankDetails({})
    }

    return (
        <div className='new-main-component-screen-div'>
            <h1 className='new-screen-title' >
                {selectedLanguage?.text_CashbackRequests}
            </h1>

            <div className='flex-mobile' style={{ display: 'flex', marginTop: '20px', }}>
                <RangePicker
                    defaultValue={[moment().add(-1, 'months'), moment()]}
                    onChange={createReportRange}
                    // style={{ marginBottom: 10 }}
                />

                <Button
                    loading={loading}
                    size='small'
                    style={{ marginLeft: '15px' }}
                    type='primary'
                    onClick={() => createCashBackReport(user, dateRange, pendingRequest)}
                >
                    {selectedLanguage?.txt_Report}
                </Button>
            </div>


            <div className="custom-table-container">
                <Table
                    locale={{ emptyText: selectedLanguage?.txt_NoDataFound }}
                    className='new-layout-table'
                    loading={loading}
                    dataSource={forms}
                    columns={columns}
                    pagination={{ hideOnSinglePage: true }}
                    scroll={{ x: 800 }}
                />
                <Modal
                    open={showDetails}
                    onOk={handleOk}
                    onCancel={handleOk}
                    className='pop-info-modal'
                    footer={null}>
                    <div>
                        <p className='p-question'>{selectedLanguage?.text_BankDetails}</p>
                        <ul style={{ padding: '20px' }}>
                            <li>{selectedLanguage?.text_fullName}: {bankDetails?.name} </li>
                            <li>{selectedLanguage?.text_bankAccount}: {bankDetails?.bankAccount}</li>
                            <li>{selectedLanguage?.text_IBAN}: {bankDetails?.IBAN}</li>
                            <p>{selectedLanguage?.txt_RequestedAmount}: {currencySign} {convertRoundNumber(usrDetail?.amount)}</p>
                        </ul>
                    </div>
                </Modal>

                {showSubmitModal?._id &&
                    <Modal
                        open={showSubmitModal?._id}
                        onCancel={() => setShowSubmitModal({})}
                        onOk={() => setShowSubmitModal({})}
                        className='pop-info-modal'
                        footer={null}
                    >
                        <div>
                            <p className='p-question'>{selectedLanguage?.text_CashbackConfirmation}</p>
                            <p className='p-opt'>{`${selectedLanguage?.text_AreYouSureYouPaid} $ ${showSubmitModal?.amount} ${selectedLanguage?.text_to}${showSubmitModal?.playerId?.name} `}</p>
                            <p className='p-opt'>{selectedLanguage?.tetx_IfYouPaidPressYes}</p>
                        </div>
                        <div style={{ display: 'flex', marginTop: 10 }}>
                            <button className='cancel-btn' onClick={() => setShowSubmitModal({})}>{selectedLanguage?.text_Cancel}</button>
                            <button className='confirm-btn' onClick={() => approveCashBackStatus(showSubmitModal)}>
                                {loading && <Spin />}{selectedLanguage?.text_YesiPaid}</button>
                        </div>
                    </Modal>}
            </div>
        </div>
    )
}

export default AdminCashback
