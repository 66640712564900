import { UploadOutlined } from '@ant-design/icons'
import { Button, DatePicker, Form, Input, InputNumber, Select, Switch, Upload } from 'antd'
import axios from 'axios'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { FiPercent } from "react-icons/fi"
import { useSelector } from 'react-redux'
import { ADMIN } from '../../../utils/apis'
import { assignMessage, errorMessage, getGeneralData, successMessage } from "../../../utils/helpers"
import { currencySign } from '../../../utils/constants'
const { RangePicker } = DatePicker;

const CreateCompetition = (props) => {
    const { Option } = Select
    const { generalActions, selectedLanguage, language, user, edit, editSuccess } = props
    const games = useSelector(state => state.generalReducer.games)
    const allCompetitions = useSelector(state => state.generalReducer.allCompetitions)
    const [loading, setLoading] = useState(false)
    const [type, setType] = useState(edit?.gameId?.gameType || '1-1 Match Game')
    const [old, setOld] = useState(edit?.old ? edit?.old : false)
    const [selectedCompetition, setSelectedCompetition] = useState(null)
    const [form] = Form.useForm()

    useEffect(() => {
        getGeneralData(generalActions)
    }, [])
    const rangeConfig = {
        rules: [
            {
                type: 'array',
                required: true,
                message: 'Please select range!',
            },
        ],
    };
    const onFinish = (fieldsValue) => {

        const rangeValue = fieldsValue['competitionDuration'];
        console.log('fieldsValue', fieldsValue)
        const values = {
            ...fieldsValue,
            'competitionDuration': JSON.stringify({ date1: rangeValue[0].format('YYYY-MM-DD'), date2: rangeValue[1].format('YYYY-MM-DD') }),
            file: fieldsValue?.file?.[0]?.url ? JSON.stringify(fieldsValue?.file?.[0]) : fieldsValue?.file?.[0]?.originFileObj,
            adminId: user?._id,
        }
        console.log('values', values)

        let formData = new FormData()

        for (var [k, v] of Object.entries(values)) {
            if (k && v) {
                console.log('k', k, 'v', v)
                formData.append(k, v)
            }
        }
        formData.append('old', old ? true : false)

        setLoading(true)
        axios.post(ADMIN.CREATE_COMPETITION, formData)
            .then((res) => {
                const { data } = res
                setLoading(false)
                if (data?.success) {
                    successMessage(assignMessage(data?.message, language?.language))
                    form.resetFields()
                    edit?._id && editSuccess()
                }
                else {
                    errorMessage(assignMessage(data?.message, language?.language))
                }
            }).catch((e) => {
                setLoading(false)
                console.log(e)
                errorMessage(assignMessage(e?.message, language?.language))
            })
    }

    const onChangeSwitchBox = (e) => {
        setOld(e)

        if (!e) {
            setSelectedCompetition(null)

            form.setFieldsValue({
                level: 1
            })
        }

        form.setFieldsValue({
            competitionName: null,
        })
    }
    const normFile = (e) => {
        if (Array.isArray(e)) {
            return e;
        }
        return e?.fileList;
    }

    const competitionChange = (e) => {

        let competitionSelected = allCompetitions?.find((v) => v?.competitionName === e)
        setSelectedCompetition(competitionSelected)
        form.setFieldsValue({
            gameId: competitionSelected?.gameId?._id,
            file: [competitionSelected?.imageObj],
            level: competitionSelected?.level + 1
        })
    }

    const disabledDate = (current) => {

        let competitionDate = moment(selectedCompetition?.competitionDuration?.date2, 'YYYY-MM-DD').add(1, 'days')

        if (!selectedCompetition) {
            return current && current.isBefore(moment(), 'day')
        } else {
            return current && current.isBefore(moment(), 'day') || current.isBefore(competitionDate, 'day')
        }
    }
    return (
        <div className='new-main-component-screen-div' style={{ background: 'black' }}>

            <div>
                {!edit?._id &&
                    <h1 className='new-screen-title orang-color' >
                        {selectedLanguage?.tittle_CreateCompetition}
                    </h1>
                }

                <div style={{ marginTop: '20px' }}>
                    <Form
                        name='form'
                        form={form}
                        onFinish={onFinish}
                        layout={'vertical'}
                    >




                        <div className='form-two-input-flex'>
                            <div className='form-two-input-flex-inner-input '>
                                <Form.Item
                                    label={<p className='p-admin-title-text'>{selectedLanguage?.CreatePrivateit}</p>}
                                    name='old'
                                    onChange={onChangeSwitchBox}
                                    valuePropName='checked'>
                                    <div
                                        className='form-input-new-design-big-slam'
                                        style={{
                                            display: 'flex', alignItems: 'center'
                                        }}
                                    >
                                        <p
                                            className='p-admin-title-text'
                                            style={{
                                                margin: '0px 10px'
                                            }}>{selectedLanguage?.text_New}
                                        </p>
                                        <Switch
                                            onChange={onChangeSwitchBox}
                                            checked={old}
                                            checkedChildren={''}
                                            unCheckedChildren={''}
                                        />
                                        <p
                                            className='p-admin-title-text'
                                            style={{
                                                margin: '0px 10px'
                                            }}>{selectedLanguage?.text_Old}
                                        </p>
                                    </div>
                                </Form.Item>
                            </div>
                        </div>



                        <div className='form-input-single-input'>

                            <Form.Item
                                name='file'
                                label={<p className='p-admin-title-text'>{selectedLanguage?.text_IMG}</p>}
                                valuePropName='fileList'
                                getValueFromEvent={normFile}
                                style={{ width: '100%' }}
                            >
                                <Upload disabled={old}
                                    style={{ width: '100%' }}
                                    className='ant-upload-new-big-slaim'
                                    name='file'
                                    accept='image/*'
                                    beforeUpload={() => false}
                                    action='/upload.do'
                                    listType='text'
                                >    <Button style={{ width: '100%', marginTop: '17px' }} disabled={old} icon={<UploadOutlined size={20} />}>{selectedLanguage?.ClickToUpload}</Button>
                                    <br />
                                </Upload>
                            </Form.Item>
                        </div>

                        <div className='form-two-input-flex'>
                            {old ?
                                <div className='form-two-input-flex-inner-input'>
                                    <Form.Item
                                        name='competitionName'
                                        hasFeedback
                                        label={<p className='p-admin-title-text'>{selectedLanguage?.label_CompetitionName}</p>}
                                        rules={[
                                            {
                                                required: true,
                                                message: selectedLanguage?.error_pleaseEnterCompetitionName
                                            }
                                        ]}
                                    >
                                        <Select
                                            notFoundContent={selectedLanguage?.txt_NoDataFound}
                                            onChange={competitionChange}
                                            placeholder={selectedLanguage?.text_SelectCompetition}
                                            className='form-input-new-design-big-slam'
                                            style={{ width: '100%', marginBottom: '20px', marginTop: '5px' }}
                                        >
                                            {allCompetitions?.map((v, i) => (<Option value={v?.competitionName}>{v?.competitionName} - {v?.gameId?.name}</Option>))}
                                        </Select>
                                    </Form.Item>
                                </div>
                                : <div className='form-two-input-flex-inner-input'>
                                    <Form.Item
                                        name='competitionName'
                                        hasFeedback
                                        label={<p className='p-admin-title-text'>{selectedLanguage?.label_CompetitionName}</p>}
                                        rules={[
                                            {
                                                required: true,
                                                message: selectedLanguage?.error_pleaseEnterCompetitionName
                                            }
                                        ]}
                                    >
                                        <Input
                                            className='form-input-new-design-big-slam'
                                            placeholder={selectedLanguage?.placeHolder_EnterCompetitionNameHere}
                                        />
                                    </Form.Item>
                                </div>

                            }
                            <div className='form-two-input-flex-inner-input'>
                                <Form.Item name='competitionDuration' label={<p className='p-admin-title-text'>{selectedLanguage?.label_CompetitionRange}</p>} {...rangeConfig}>
                                    <RangePicker
                                        disabledDate={disabledDate}
                                        style={{ width: '100%' }}
                                        className='form-input-new-design-big-slam'
                                    />
                                </Form.Item>
                            </div>
                        </div>


                        <div className='form-two-input-flex'>
                            <div className='form-two-input-flex-inner-input'>
                                <p style={{ padding: '0px', margin: '0px', marginBottom: '4px', marginLeft: '4px' }}
                                    className='p-admin-title-text'
                                >{selectedLanguage?.text_SelectGameType}</p>
                                <Select
                                    disabled={old}
                                    defaultValue={type}
                                    placeholder={selectedLanguage?.text_SelectGameType}
                                    className='form-input-new-design-big-slam'
                                    onChange={(e) => { setType(e) }}
                                    style={{ width: '100%', marginBottom: '20px', marginTop: '5px' }}
                                >
                                    <Option value={'1-1 Match Game'}>{selectedLanguage?.text_OneByOneMatchGame}</Option>
                                    <Option value={'Team Match Game'}>{selectedLanguage?.text_TeamMatchGame}</Option>
                                </Select>
                            </div>
                            <div className='form-two-input-flex-inner-input'>
                                <Form.Item
                                    name='gameId'
                                    hasFeedback
                                    initialValue={edit?.gameId?._id}
                                    label={<p className='p-admin-title-text'>{selectedLanguage?.label_SelectGame}</p>}
                                    rules={[
                                        {
                                            required: true,
                                            message: selectedLanguage?.error_pleaseSelectGame
                                        }
                                    ]}
                                >
                                    <Select
                                        disabled={old}
                                        className='form-input-new-design-big-slam'
                                        notFoundContent={selectedLanguage?.txt_NoDataFound}
                                        placeholder={selectedLanguage?.placeHolder_SelectGameforCompetition}>
                                        {games?.length && games?.map((v, i) => {
                                            return (
                                                v?.gameType === type &&
                                                <Option value={v?._id}>
                                                    {`${v?.name} - ${v?.gameType} - ${v?.description} `}
                                                </Option>
                                            )
                                        })}
                                    </Select>
                                </Form.Item>
                            </div>
                        </div>

                        <div className='form-two-input-flex'>
                            <div className='form-two-input-flex-inner-input newInpuHeightFull'>
                                <Form.Item
                                    name='level'
                                    hasFeedback
                                    label={<p className='p-admin-title-text'>{selectedLanguage?.label_Levels}</p>}
                                    rules={[
                                        {
                                            required: true,
                                            message: selectedLanguage?.placeHolder_EnterLevels
                                        },
                                        ({ getFieldValue }) => ({
                                            validator(rule, value) {
                                                if (value < 1) {
                                                    return Promise.reject(selectedLanguage?.text_EnterLevelsmoreThen)
                                                }
                                                else if (value > 100) {
                                                    return Promise.reject(selectedLanguage?.txt_Value_must_Less_then + ' 100')
                                                }
                                                return Promise.resolve()
                                            }
                                        })
                                    ]}
                                    initialValue={1}
                                >
                                    <InputNumber
                                        disabled={true}
                                        max={100}
                                        min={1}
                                        step={1}
                                        type='number'
                                        style={{ width: '100%' }}
                                        className='form-input-new-design-big-slam'
                                        placeholder={selectedLanguage?.placeHolder_EnterLevels}
                                    />
                                </Form.Item>
                            </div>
                            <div className='form-two-input-flex-inner-input newInpuHeightFull'>
                                <Form.Item
                                    name='totalPrize'
                                    hasFeedback
                                    label={<p className='p-admin-title-text'>{selectedLanguage?.label_TotalPrize}</p>}
                                    rules={[
                                        {
                                            required: true,
                                            message: selectedLanguage?.placeHolder_EnterTotalPrize
                                        },
                                        ({ getFieldValue }) => ({
                                            validator(_, value) {
                                                if (value > 1) {
                                                    return Promise.resolve();
                                                }
                                                return Promise.reject(selectedLanguage?.txt_Value_must_more_then + ' 1');
                                            },
                                        }),
                                    ]}
                                >
                                    <Input
                                        controls={false}
                                        min={1}
                                        step={1}
                                        precision={2}
                                        type='number'
                                        style={{ width: '100%' }}
                                        prefix={<p style={{ color: 'white', margin: '0px' }}>{currencySign}</p>}
                                        className='form-input-new-design-big-slam'
                                        placeholder={selectedLanguage?.placeHolder_EnterTotalPrize}
                                    />
                                </Form.Item>
                            </div>
                        </div>
                        <div className='form-two-input-flex'>
                            <div className='form-two-input-flex-inner-input newInpuHeightFull'>
                                <Form.Item
                                    name='maximumPlayer'
                                    hasFeedback
                                    initialValue={edit?.maximumPlayer}
                                    label={<p className='p-admin-title-text'>{selectedLanguage?.label_Maximumplayer}</p>}
                                    rules={[
                                        {
                                            required: true,
                                            message: selectedLanguage?.error_pleaseEnteraMaxPlayer
                                        },
                                        ({ getFieldValue }) => ({
                                            validator(rule, value) {
                                                if (value > 64) {
                                                    return Promise.reject(selectedLanguage?.txt_Value_must_Less_then + ' 65')
                                                }
                                                if (value === null || value > 1) {
                                                    return Promise.resolve()
                                                }
                                                else if (value === 0) {
                                                    return Promise.reject(selectedLanguage?.text_MaximumPlayerMustBeOrMmoreThen)
                                                }

                                                else {
                                                    return Promise.reject(selectedLanguage?.text_MaximumPlayerMustBeOrMmoreThen)
                                                }
                                            },
                                        })
                                    ]}
                                >
                                    <Input
                                        // Number
                                        max={64}
                                        min={2}
                                        step={1}
                                        type='number'
                                        style={{ width: '100%' }}
                                        className='form-input-new-design-big-slam'
                                        placeholder={selectedLanguage?.placeHolder_EnterMaximumPlayerhere}
                                    />
                                </Form.Item>
                            </div>

                            <div className='form-two-input-flex-inner-input newInpuHeightFull'>
                                <Form.Item
                                    name='participationMoney'
                                    initialValue={edit?.participationMoney}
                                    hasFeedback
                                    label={<p className='p-admin-title-text'>{selectedLanguage?.label_ParticipationfeeMoney}</p>}
                                    rules={[
                                        {
                                            required: true,
                                            message: selectedLanguage?.error_PleaseEnterParticipationFeeMoney
                                        },
                                        ({ getFieldValue }) => ({
                                            validator(rule, value) {
                                                if (value < 1) {
                                                    return Promise.reject(selectedLanguage?.text_EnterLevelsmoreThen)
                                                }
                                                return Promise.resolve()
                                            }
                                        })
                                    ]}
                                >
                                    <Input
                                        /* Number*/
                                        min={1}
                                        step={0.5}
                                        type='number'
                                        style={{ width: '100%' }}
                                        className='form-input-new-design-big-slam'
                                        placeholder={selectedLanguage?.placeHolder_EnterParticipationFeeMoney}
                                    />
                                </Form.Item>
                            </div>
                        </div>
                        <div className='form-two-input-flex'>
                            <div className='form-two-input-flex-inner-input newInpuHeightFull'>
                                <Form.Item
                                    name='promoters'
                                    hasFeedback
                                    label={<p className='p-admin-title-text'>{selectedLanguage?.label_Promoters}</p>}
                                    rules={[
                                        {
                                            required: true,
                                            message: selectedLanguage?.placeHolder_EnternumberofPromoters
                                        },
                                        ({ getFieldValue }) => ({
                                            validator(rule, value) {
                                                if (value < 1) {
                                                    return Promise.reject(`${selectedLanguage?.text_EnterPromotersmoreThen} ${1}`)
                                                }
                                                else if (value > 100) {
                                                    return Promise.reject(selectedLanguage?.txt_Value_must_Less_then + ' 101')
                                                }
                                                return Promise.resolve()

                                            }
                                        })
                                    ]}
                                >
                                    <Input
                                        max={100}
                                        min={1}
                                        step={1}
                                        type='number'
                                        style={{ width: '100%' }}
                                        prefix={<p style={{ color: 'white', margin: '0px' }}>{<FiPercent />}</p>}
                                        className='form-input-new-design-big-slam'
                                        placeholder={selectedLanguage?.placeHolder_EnternumberofPromoters}
                                    />
                                </Form.Item>
                            </div>
                            <div className='form-two-input-flex-inner-input newInpuHeightFull'>
                                <Form.Item
                                    name='degraders'
                                    hasFeedback
                                    label={<p className='p-admin-title-text'>{selectedLanguage?.label_Degraders}</p>}
                                    rules={[
                                        {
                                            required: true,
                                            message: selectedLanguage?.placeHolder_EnternumberofDegraders
                                        },
                                        ({ getFieldValue }) => ({
                                            validator(rule, value) {
                                                if (value < 1) {
                                                    return Promise.reject(`${selectedLanguage?.text_EnterDegradersmoreThen} ${1}`)
                                                }
                                                else if (value > 100) {
                                                    return Promise.reject(selectedLanguage?.txt_Value_must_Less_then + ' 101')
                                                }
                                                return Promise.resolve()
                                            }
                                        })
                                    ]}
                                >
                                    <Input
                                        // Number 
                                        max={100}
                                        min={1}
                                        step={1}
                                        type='number'
                                        style={{ width: '100%' }}
                                        className='form-input-new-design-big-slam'
                                        prefix={<p style={{ color: 'white', margin: '0px' }}>{<FiPercent />}</p>}
                                        placeholder={selectedLanguage?.placeHolder_EnternumberofDegraders}
                                    />
                                </Form.Item>
                            </div>
                        </div>

                        <div className='form-two-input-flex'>
                            <div className='form-two-input-flex-inner-input'>
                                <Form.Item
                                    name='prizeOne'
                                    hasFeedback
                                    label={<p className='p-admin-title-text'>{selectedLanguage?.label_Prizewinner}</p>}
                                    initialValue={edit?.prizeOne || '40'}
                                    rules={[
                                        {
                                            required: true,
                                            message: selectedLanguage?.label_Prizewinner
                                        },
                                        ({ getFieldValue }) => ({
                                            validator(rule, value) {
                                                if (value < 1) {
                                                    return Promise.reject(selectedLanguage?.text_EnterLevelsmoreThen)
                                                }
                                                else if (value > 100) {
                                                    return Promise.reject(selectedLanguage?.txt_Value_must_Less_then + ' 101')
                                                }
                                                return Promise.resolve()
                                            }
                                        })
                                    ]}
                                >
                                    <Input
                                        // Number 
                                        max={100}
                                        min={1}
                                        step={0.5}
                                        type='number'
                                        style={{ width: '100%' }}
                                        className='form-input-new-design-big-slam'
                                        prefix={<p style={{ color: 'white', margin: '0px' }}>{<FiPercent />}</p>}
                                        placeholder={selectedLanguage?.label_Prizewinner}
                                    />
                                </Form.Item>
                            </div>
                            <div className='form-two-input-flex-inner-input'>
                                <Form.Item
                                    name='prizeTwo'
                                    hasFeedback
                                    label={<p className='p-admin-title-text'>{selectedLanguage?.label_Prizefinalist}</p>}
                                    initialValue={edit?.prizeTwo || '20'}
                                    rules={[
                                        {
                                            required: true,
                                            message: selectedLanguage?.label_Prizefinalist
                                        },
                                        ({ getFieldValue }) => ({
                                            validator(rule, value) {
                                                if (value < 1) {
                                                    return Promise.reject(selectedLanguage?.text_EnterLevelsmoreThen)
                                                }
                                                else if (value > 100) {
                                                    return Promise.reject(selectedLanguage?.txt_Value_must_Less_then + ' 101')
                                                }
                                                return Promise.resolve()
                                            }
                                        })
                                    ]}
                                >
                                    <Input
                                        // Number 
                                        max={100}
                                        min={1}
                                        step={0.5}
                                        type='number'
                                        style={{ width: '100%' }}
                                        className='form-input-new-design-big-slam'
                                        prefix={<p style={{ color: 'white', margin: '0px' }}>{<FiPercent />}</p>}
                                        placeholder={selectedLanguage?.label_Prizefinalist}
                                    />
                                </Form.Item>
                            </div>
                        </div>

                        <div className='form-two-input-flex'>
                            <div className='form-two-input-flex-inner-input'>
                                <Form.Item
                                    name='prizeThree'
                                    hasFeedback
                                    label={<p className='p-admin-title-text'>{selectedLanguage?.label_PrizenoThree}</p>}
                                    initialValue={edit?.prizeThree || '15'}
                                    rules={[
                                        {
                                            required: true,
                                            message: selectedLanguage?.label_PrizenoThree
                                        },
                                        ({ getFieldValue }) => ({
                                            validator(rule, value) {
                                                if (value < 1) {
                                                    return Promise.reject(selectedLanguage?.text_EnterLevelsmoreThen)
                                                }
                                                else if (value > 100) {
                                                    return Promise.reject(selectedLanguage?.txt_Value_must_Less_then + ' 101')
                                                }
                                                return Promise.resolve()
                                            }
                                        })
                                    ]}
                                >
                                    <Input
                                        // Number
                                        max={100}
                                        min={1}
                                        step={0.5}
                                        type='number'
                                        style={{ width: '100%' }}
                                        className='form-input-new-design-big-slam'
                                        prefix={<p style={{ color: 'white', margin: '0px' }}>{<FiPercent />}</p>}
                                        placeholder={selectedLanguage?.label_PrizenoThree}
                                    />
                                </Form.Item>
                            </div>
                        </div>

                        <Form.Item>
                            <Button
                                type='primary'
                                htmlType='submit'
                                loading={loading}
                            >
                                {edit?._id ? selectedLanguage?.button_updateTournament : selectedLanguage?.btntext_Createtournament}
                            </Button>
                        </Form.Item>
                    </Form>
                </div >
            </div >
        </div >
    )
}

export default CreateCompetition