import React, { useEffect, useState } from "react";
import { Button, Form, Input, InputNumber, Modal } from "antd";
import { assignMessage, errorMessage, successMessage } from '../../../utils/helpers'
import { ADMIN } from '../../../utils/apis'
import axios from "axios";

const TournamentOrganizePointSetting = (props) => {
    const { user, language, selectedLanguage } = props
    const [visible, setVisible] = useState(false)
    const [form] = Form.useForm()
    const [loading, setLoading] = useState(false)
    const [tournamenOrganizerPoint, setTournamenOrganizerPoint] = useState(null)

    const gettournamentOrganizerPoint = () => {
        axios.get(ADMIN?.GET_TOURNAMENT_ORGANIZER_POINT)
            .then((res) => {
                const { data } = res
                setTournamenOrganizerPoint(data?.data?.[0] || [])
            })
            .catch((e) => {
                errorMessage(assignMessage(e?.message, language?.language))
            })
    }


    const onFinish = (values) => {
        values.adminId = user?._id
        tournamenOrganizerPoint?.point && (values._id = tournamenOrganizerPoint?._id)
        axios.post(tournamenOrganizerPoint?._id ? ADMIN?.EDIT_TOURNAMENT_ORGANIZER_POINT : ADMIN?.ADD_TOURNAMENT_ORGANIZER_POINT, values)
            .then((res) => {
                const { data } = res
                if (data?.success) {
                    successMessage(assignMessage(data?.message, language?.language))
                    gettournamentOrganizerPoint()
                }
            })
            .catch((e) => {
                errorMessage(assignMessage(e?.message, language?.language))
            })
    }

    useEffect(() => {
        gettournamentOrganizerPoint()
    }, [])

    const handleOk = () => {
        setVisible(false)
    }


    return (
        <>
            <div className="reward-point-box">
                <p className="p-none-margin-bolder-p">{selectedLanguage?.text_WhenplayerCreatetournamentandtournamentfinishedsuccessfully}</p>
                <p className="p-none-margin-bolder-p">
                    {selectedLanguage?.text_PlayerGet} ( {tournamenOrganizerPoint?.point || '---'} )
                    {selectedLanguage?.text_PointAsReward}</p>
                <div style={{ marginTop: '20px' }} className='button-div-flex-end'>
                    <Button
                        type="primary"
                        onClick={() => { setVisible(true) }} > {selectedLanguage?.setting_UpdateTournamnetOrganizerReward}</Button>
                </div>
            </div>
            {/* --------Modal----- */}

            <Modal
                open={visible}
                onOk={handleOk}
                onCancel={handleOk}
                className='pop-info-modal'
                footer={null}>
                <div style={{ marginTop: '10px' }}>
                    <p className='p-question'>{selectedLanguage?.text_UpdateTournamentOrganizepoints}</p>

                    <div style={{ display: 'flex', flexDirection: 'column' }} className='form-div-in-modal'>
                        <Form
                            name='form'
                            form={form}
                            onFinish={onFinish}
                            layout={'vertical'}
                        >
                            <div className='form-two-input-flex-inner-input newInpuHeightFull' style={{ width: '100%' }}>
                                <Form.Item
                                    name='point'
                                    initialValue={tournamenOrganizerPoint?.point}
                                    hasFeedback
                                    label={<p className='p-admin-title-text'>{selectedLanguage?.text_Tournamentorganizerpointsreward}</p>}
                                    rules={[
                                        {
                                            required: true,
                                            message: selectedLanguage?.text_pleaseenterpoint
                                        }
                                    ]}
                                >
                                    <Input
                                        // Number
                                        step={0.5}    // Set the step to 0.01 for two decimal places
                                        precision={2}
                                        type='number'
                                        style={{ width: '100%' }}
                                        className='form-input-new-design-big-slam'
                                        placeholder={selectedLanguage?.text_EnterPointHere}
                                    />
                                </Form.Item>
                            </div>

                            <div style={{ display: 'flex', justifyContent: 'flex-end', height: '40px', paddingRight: '5px' }}>
                                <Form.Item>
                                    <Button
                                        type="primary"
                                        htmlType='submit'
                                        loading={loading}
                                    >
                                        {tournamenOrganizerPoint?.point ? selectedLanguage?.text_UpdatePointSetting : selectedLanguage?.text_AddPointSetting}
                                    </Button>
                                </Form.Item>
                            </div>
                        </Form>
                    </div>
                </div >
            </Modal >
        </>
    )
}



export default TournamentOrganizePointSetting