import { Button, Popconfirm, Table } from 'antd'
import React, { useEffect, useState } from 'react'
import AddNewGameSetting from './AddNewGameSetting'
import axios from 'axios'
import { GET, POST } from '../../../utils/apis'
import { assignMessage, convertTitle, errorMessage, getGeneralData, getUserData, successMessage } from '../../../utils/helpers'
import { useSelector } from 'react-redux'


const PlayerGameSetting = (props) => {
    const { selectedLanguage, language, user, generalActions, userActions } = props
    const games = useSelector(state => state?.generalReducer?.games)
    const gameSettings = useSelector(state => state?.userReducer?.gameSettings)
    const [AddGame, setAddGame] = useState(false)
    const [edit, setEdit] = useState({})
    const [deleteLoading, setDeleteLoading] = useState({})
    useEffect(() => {
        getGeneralData(generalActions)
        getUserData(user?._id, userActions)
    }, [])

    const deletePlayerGameSetting = (id) => {
        setDeleteLoading({ [id]: true })
        let obj = {
            id
        }
        axios.post(POST.DELETE_PLAYER_GAME_SETTING, obj)
            .then((res) => {
                const { data } = res
                setDeleteLoading({})
                if (data?.success) {
                    successMessage(assignMessage(data?.message, language?.language))
                    handleOk()
                }
                else {
                    errorMessage(assignMessage(data?.message, language?.language))
                    console.log('run')
                }
            })
            .catch((e) => {
                errorMessage(assignMessage(e?.message, language?.language))
                setDeleteLoading({})
            })
    }

    const handleOk = () => {
        getGeneralData(generalActions)
        getUserData(user?._id, userActions)
        setAddGame(false)
        setEdit({})
    }

    const columns = [
        {
            title: selectedLanguage?.text_gameName,
            dataIndex: 'gameId',
            key: 'gameName',
            render: (e) => {
                return (
                    <p>{e?.name || '---'}</p>
                )
            }
        },
        {
            title: selectedLanguage?.text_gameNickName,
            dataIndex: 'nickName',
            key: 'nickName',
        },
        {
            title: '',
            render: (e) => {
                return (
                    <span style={{ display: 'flex', }}>
                        <Button
                            onClick={() => {
                                setAddGame(true)
                                setEdit(e)
                            }}
                            type='primary'
                        >{selectedLanguage?.buttonText_Edit}
                        </Button>
                        &nbsp;
                        &nbsp;
                        <Popconfirm
                            title={`${selectedLanguage?.textDelete} ${convertTitle(`${e?.gameId?.name} `)} ${selectedLanguage?.text_gameSuggestion}?`}
                            okText={selectedLanguage?.textDelete}
                            cancelText={selectedLanguage?.textCancel}
                            okButtonProps={{
                                type: 'primary',
                                className: 'form-button'
                            }}
                            cancelButtonProps={{ style: { borderRadius: 4 } }}
                            onConfirm={() => {
                                deletePlayerGameSetting(e?._id)
                            }}
                        ><Button
                            type='primary'
                            loading={deleteLoading[e?._id]}
                            onClick={() => {
                            }}
                        >{selectedLanguage?.buttonText_Delete}</Button>
                        </Popconfirm>
                    </span>
                )
            }
        },
    ]

    return (
        <>
            <div className='new-main-component-screen-div'>
                <h1 className='new-screen-title' >
                    {selectedLanguage?.title_GameSetting}
                </h1>

                <div className='button-div-flex-end'>
                    <Button
                        type='primary'
                        onClick={() => {
                            setAddGame(true)
                        }}
                    >{selectedLanguage?.button_AddNewGameSetting}
                    </Button>
                </div>

                <div className="custom-table-container">
                    <Table
                        locale={{ emptyText: selectedLanguage?.txt_NoDataFound }}
                        className='new-layout-table'
                        dataSource={gameSettings}
                        columns={columns}
                        pagination={false}
                        scroll={{ x: 400 }}
                    />
                </div>
            </div>

            {AddGame &&
                <AddNewGameSetting myGameSetting={gameSettings} allGames={games} handleOk={handleOk} AddGame edit={edit}  {...props} />
            }
        </>
    )
}

export default PlayerGameSetting