import React, { useState } from 'react'
import { AutoComplete, Button, Checkbox, Form, Input, InputNumber, Radio, Select, Space, Upload } from 'antd'
import allCountries from '../../../utils/allCountries'
import { ADMIN } from '../../../utils/apis'
import axios from 'axios'
import { assignMessage, errorMessage, successNotification } from '../../../utils/helpers'
import { loginUser } from '../../../Redux/actions/authActions'
import { useDispatch } from 'react-redux'

const AdminProfile = (props) => {
    const { Option } = Select
    const { selectedLanguage, language, user } = props
    const [form] = Form.useForm()
    const dispatch = useDispatch()
    const [loading, setLoading] = useState(false)

    const onFinish = (values) => {
        values._id = user?._id
        setLoading(true)
        axios.post(ADMIN.EDIT_ADMIN_INFO, values)
            .then((res) => {
                const { data } = res
                setLoading(false)
                if (data.success) {
                    successNotification(assignMessage(data?.message, language?.language))
                    dispatch(loginUser(data?.admin))
                    return window.location.reload()
                }
                else {
                    setLoading(false)
                    errorMessage(assignMessage(data?.message, language?.language))
                }
            })
            .catch((e) => {
                setLoading(false)
                errorMessage(assignMessage(e?.message, language?.language))
            })
    }

    return (
        <div className='new-main-component-screen-div' style={{ background: 'black' }}>
            <h1 className='new-screen-title orang-color' >
                {selectedLanguage?.title_AccountSettings}
            </h1>
            <div style={{ marginTop: '20px' }}>
                <Form
                    name='form'
                    form={form}
                    onFinish={onFinish}
                    layout={'vertical'}
                >
                    <div className='form-two-input-flex'>
                        <div className='form-two-input-flex-inner-input'>
                            <Form.Item
                                name='name'
                                hasFeedback
                                initialValue={user?.name}
                                label={<p className='p-admin-title-text'>{selectedLanguage?.title_Name}</p>}
                                rules={[
                                    {
                                        required: true,
                                        message: selectedLanguage?.error_PleaseInputYourName
                                    }
                                ]}
                            >
                                <Input
                                    disabled
                                    className='form-input-new-design-big-slam'
                                    placeholder={selectedLanguage?.placeHolder_InputYourNameHere}
                                />
                            </Form.Item>
                        </div>
                        <div className='form-two-input-flex-inner-input'>
                            <Form.Item
                                name='email'
                                label={<p className='p-admin-title-text'>{selectedLanguage?.label_Email}</p>}
                                hasFeedback
                                initialValue={user?.email}
                                rules={[
                                    {
                                        required: true,
                                        message: selectedLanguage?.error_PleaseEnterYourEmail
                                    },
                                    {
                                        type: 'email',
                                        message: selectedLanguage?.error_EmailIsNotValid
                                    }
                                ]}
                            >
                                <Input
                                    disabled
                                    className='form-input-new-design-big-slam'
                                    placeholder={selectedLanguage?.placeHolder_PleaseEnterYourEmail}
                                />
                            </Form.Item>
                        </div>
                    </div>
                    <div className='form-two-input-flex'>
                        <div className='form-two-input-flex-inner-input'>
                            <Form.Item
                                name='postalcode'
                                label={<p className='p-admin-title-text'>{selectedLanguage?.label_PostalCode}</p>}
                                hasFeedback
                                initialValue={user?.postalcode}
                                rules={[
                                    {
                                        required: true,
                                        message: selectedLanguage?.error_PleaseEnterPostalCode
                                    }
                                ]}
                            >
                                <Input
                                    className='form-input-new-design-big-slam'
                                    placeholder={selectedLanguage?.pleaceHolder_EnterPostalCode}
                                />
                            </Form.Item>
                        </div>
                        <div className='form-two-input-flex-inner-input'>
                            <Form.Item
                                name='tellno'
                                label={<p className='p-admin-title-text'>{selectedLanguage?.label_tellNo}</p>}
                                hasFeedback
                                initialValue={user?.tellno}
                                rules={[
                                    {
                                        required: true,
                                        message: selectedLanguage?.error_PleaseEntertellno
                                    }
                                ]}
                            >
                                <Input
                                    className='form-input-new-design-big-slam'
                                    placeholder={selectedLanguage?.pleaceHolder_Entertellno}
                                />
                            </Form.Item>
                        </div>
                    </div>
                    <div className='form-two-input-flex'>
                        <div className='form-two-input-flex-inner-input'>
                            <Form.Item
                                name='country'
                                label={<p className='p-admin-title-text'>{selectedLanguage?.label_Country}</p>}
                                hasFeedback
                                initialValue={user?.country}
                                rules={[
                                    {
                                        required: true,
                                        message: selectedLanguage?.error_PleaseSelectYourCountry
                                    }
                                ]}
                            >
                                <Select
                                    showSearch
                                    showArrow
                                    allowClear={false}
                                    notFoundContent={selectedLanguage?.txt_NoDataFound}
                                    className='input-none form-input-new-design-big-slam'
                                    placeholder={selectedLanguage?.placeHolder_SelectYourCountry}
                                    optionFilterProp='children'
                                    filterOption={(input, option) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                >
                                    {allCountries?.map((v, i) => <Option value={v} key={i}>{v}</Option>)}
                                </Select>
                            </Form.Item>
                        </div>
                        <div className='form-two-input-flex-inner-input'>
                            <Form.Item
                                name='city'
                                hasFeedback
                                initialValue={user?.city}
                                label={<p className='p-admin-title-text'>{selectedLanguage?.label_City}</p>}
                                rules={[
                                    {
                                        required: true,
                                        message: selectedLanguage?.error_PleaseInputYourCity
                                    }
                                ]}
                            >
                                <Input
                                    className='form-input-new-design-big-slam'
                                    placeholder={selectedLanguage?.placeHolder_InputYourCityHere}
                                />
                            </Form.Item>
                        </div>
                    </div>
                    <div className='form-two-input-flex'>
                        <div className='form-two-input-flex-inner-input'>
                            <Form.Item
                                name='houseno'
                                hasFeedback
                                initialValue={user?.houseno}
                                label={<p className='p-admin-title-text'>{selectedLanguage?.label_houseno}</p>}
                                rules={[
                                    {
                                        required: true,
                                        message: selectedLanguage?.error_PleaseInputYourhouseno
                                    }
                                ]}
                            >
                                <Input
                                    className='form-input-new-design-big-slam'
                                    placeholder={selectedLanguage?.placeHolder_InputYourhousenoHere}
                                />
                            </Form.Item>
                        </div>
                        <div className='form-two-input-flex-inner-input'>
                            <Form.Item
                                name='street'
                                hasFeedback
                                initialValue={user?.street}
                                label={<p className='p-admin-title-text'>{selectedLanguage?.label_street}</p>}
                                rules={[
                                    {
                                        required: true,
                                        message: selectedLanguage?.error_PleaseInputYourstreet
                                    }
                                ]}
                            >
                                <Input
                                    className='form-input-new-design-big-slam'
                                    placeholder={selectedLanguage?.placeHolder_InputYourstreetHere}
                                />
                            </Form.Item>
                        </div>
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'flex-end', }}>
                        <Form.Item>
                            <Button
                                type='primary'
                                htmlType='submit'
                                loading={loading}
                            >
                                {selectedLanguage?.ButtonText_Submit}
                            </Button>
                        </Form.Item>
                    </div>
                </Form>
            </div>
        </div>
    )
}

export default AdminProfile