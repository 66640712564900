import { GAMES, PROMOTER, ADMIN_TOURNAMENT, ALL_COMPETITIONS } from '../types'

const addAllGames = (games) => {
    return {
        type: GAMES,
        games
    }
}

const addAllPromoter = (promoter) => {
    return {
        type: PROMOTER,
        promoter
    }
}

const adminTournament = (adminTournament) => {
    return {
        type: ADMIN_TOURNAMENT,
        adminTournament
    }
}
const allCompetitions = (allCompetitions) => {
    return {
        type: ALL_COMPETITIONS,
        allCompetitions
    }
}

export {
    addAllGames,
    addAllPromoter,
    adminTournament,
    allCompetitions
}