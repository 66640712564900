import { Button, Form, Input, InputNumber, Modal, Select } from 'antd'
import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { ADMIN, } from '../../../utils/apis'
import { assignMessage, errorMessage, getUserData, successMessage } from '../../../utils/helpers'
const AddFaq = (props) => {
    const { Option } = Select
    const { selectedLanguage, userActions, language, user, editData, getFun, handleOK } = props
    const [loading, setLoading] = useState(false)
    const [type, setType] = useState(editData?.type || 'How it works?')
    const [catagrie, setCatagrie] = useState(editData?.catagrie || 'Tournaments')
    const [form] = Form.useForm()

    const onFinish = (values) => {
        editData?._id && (values._id = editData?._id)
        values.adminId = user?._id
        values.type = type
        type === "FAQ's" && (values.catagrie = catagrie)
        console.log(values, "values")
        axios.post(editData?._id ? ADMIN.EDIT_FAQ_QUESTION : ADMIN.ADD_FAQ_QUESTION, values)
            .then((res) => {
                const { data } = res
                setLoading(false)
                if (data.success) {
                    successMessage(assignMessage(data?.message, language?.language))
                    form.resetFields()
                    editData?._id && (getFun())
                    editData?._id && (handleOK())
                }
                else {
                    errorMessage(assignMessage(data?.message, language?.language))
                }
            })
            .catch((e) => {
                setLoading(false)
                errorMessage(assignMessage(e.message, language?.language))
            })
    }

    return (
        <div className='new-main-component-screen-div' style={{ background: 'black' }}>
            <h1 className='new-screen-title orang-color' >
                {selectedLanguage?.text_AddFaq}
            </h1>

            {editData?._id &&
                <span style={{ marginTop: '10px' }} className='button-div-flex-end'>
                    <Button type='primary' onClick={handleOK}>{selectedLanguage?.faq_Cancel}</Button>
                </span>
            }

            <div style={{ marginTop: '35px' }}>
                <Form
                    name='form'
                    form={form}
                    onFinish={onFinish}
                    layout={'vertical'}
                >
                    {/* title */}
                    <div className='form-two-input-flex'>
                        <div className='form-two-input-flex-inner-input'>
                            <Form.Item
                                name='titleEnglish'
                                hasFeedback
                                label={<p className='p-admin-title-text'>{selectedLanguage?.faq_TitleEnglish}</p>}
                                initialValue={editData?.titleEnglish}
                                rules={[
                                    {
                                        required: true,
                                        message: selectedLanguage?.faq_pleaseEnterEnglishTitle
                                    }
                                ]}
                            >
                                <Input
                                    className='form-input-new-design-big-slam'
                                    placeholder={selectedLanguage?.faq_EnterEnglishTitleHere}
                                />
                            </Form.Item>
                        </div>
                        <div className='form-two-input-flex-inner-input'>
                            <Form.Item
                                name='titleDutch'
                                hasFeedback
                                label={<p className='p-admin-title-text'>{selectedLanguage?.faq_TitleNl}</p>}
                                initialValue={editData?.titleDutch}
                                rules={[
                                    {
                                        required: true,
                                        message: selectedLanguage?.faq_pleaseEnterNlTitleHere
                                    }
                                ]}
                            >
                                <Input
                                    className='form-input-new-design-big-slam'
                                    placeholder={selectedLanguage?.faq_EnterNlTitleHere}
                                />
                            </Form.Item>
                        </div>
                    </div>

                    <div className='form-two-input-flex'>
                        <div className='form-two-input-flex-inner-input'>
                            <Form.Item
                                name='titleSpain'
                                hasFeedback
                                label={<p className='p-admin-title-text'>{selectedLanguage?.faq_TitleSpain}</p>}
                                initialValue={editData?.titleSpain}
                                rules={[
                                    {
                                        required: true,
                                        message: selectedLanguage?.faq_pleaseEnterTitleSpain
                                    }
                                ]}
                            >
                                <Input
                                    className='form-input-new-design-big-slam'
                                    placeholder={selectedLanguage?.faq_EnterSpainTitleHere}
                                />
                            </Form.Item>
                        </div>
                        <div className='form-two-input-flex-inner-input'>
                            <Form.Item
                                name='titleItaly'
                                hasFeedback
                                label={<p className='p-admin-title-text'>{selectedLanguage?.faq_TitleItaly}</p>}
                                initialValue={editData?.titleItaly}
                                rules={[
                                    {
                                        required: true,
                                        message: selectedLanguage?.faq_pleaseEnterTitleItaly
                                    }
                                ]}
                            >
                                <Input
                                    className='form-input-new-design-big-slam'
                                    placeholder={selectedLanguage?.faq_EnterItalyTitleHere}
                                />
                            </Form.Item>
                        </div>

                    </div>

                    <div className='form-two-input-flex'>
                        <div className='form-two-input-flex-inner-input'>
                            <Form.Item
                                name='titleGerman'
                                hasFeedback
                                label={<p className='p-admin-title-text'>{selectedLanguage?.faq_TitleGerman}</p>}
                                initialValue={editData?.titleGerman}
                                rules={[
                                    {
                                        required: true,
                                        message: selectedLanguage?.faq_pleaseEnterTitleGerman
                                    }
                                ]}
                            >
                                <Input
                                    className='form-input-new-design-big-slam'
                                    placeholder={selectedLanguage?.faq_EnterGermanTitleHere}
                                />
                            </Form.Item>
                        </div>

                        <div className='form-two-input-flex-inner-input'>
                            <Form.Item
                                name='titleEgypt'
                                hasFeedback
                                label={<p className='p-admin-title-text'>{selectedLanguage?.faq_TitleEgypt}</p>}
                                initialValue={editData?.titleEgypt}
                                rules={[
                                    {
                                        required: true,
                                        message: selectedLanguage?.faq_pleaseEnterTitleEgypt
                                    }
                                ]}
                            >
                                <Input
                                    className='form-input-new-design-big-slam'
                                    placeholder={selectedLanguage?.faq_EnterEgyptTitleHere}
                                />
                            </Form.Item>
                        </div>
                    </div>

                    <div className='form-two-input-flex'>
                        <div className='form-two-input-flex-inner-input'>
                            <Form.Item
                                name='titleRussain'
                                hasFeedback
                                label={<p className='p-admin-title-text'>{selectedLanguage?.faq_TitleRussain}</p>}
                                initialValue={editData?.titleRussain}
                                rules={[
                                    {
                                        required: true,
                                        message: selectedLanguage?.faq_pleaseEnterTitleRussain
                                    }
                                ]}
                            >
                                <Input
                                    className='form-input-new-design-big-slam'
                                    placeholder={selectedLanguage?.faq_EnterRussainTitleHere}
                                />
                            </Form.Item>
                        </div>
                    </div>

                    {/* title */}

                    {/* Desc */}

                    <div className='form-two-input-flex'>
                        <div className='form-two-input-flex-inner-input'>
                            <Form.Item
                                name='DiscriptionEnglish'
                                initialValue={editData?.DiscriptionEnglish}
                                hasFeedback
                                label={<p className='p-admin-title-text'>{selectedLanguage?.faq_DiscriptionEnglish}</p>}
                                rules={[
                                    {
                                        required: true,
                                        message: selectedLanguage?.faq_pleaseEnterEnglishDiscription
                                    }
                                ]}
                            >
                                <Input.TextArea
                                    className='form-input-new-design-big-slam'
                                    placeholder={selectedLanguage?.faq_EnterEnglishDiscriptionHere}
                                />
                            </Form.Item>
                        </div>
                        <div className='form-two-input-flex-inner-input'>
                            <Form.Item
                                name='DiscriptionDutch'
                                initialValue={editData?.DiscriptionDutch}
                                hasFeedback
                                label={<p className='p-admin-title-text'>{selectedLanguage?.faq_DiscriptionNl}</p>}
                                rules={[
                                    {
                                        required: true,
                                        message: selectedLanguage?.faq_pleaseEnterNlDiscriptionHere
                                    }
                                ]}
                            >
                                <Input.TextArea
                                    className='form-input-new-design-big-slam'
                                    placeholder={selectedLanguage?.faq_EnterNlDiscriptionHere}
                                />
                            </Form.Item>
                        </div>
                    </div>

                    <div className='form-two-input-flex'>
                        <div className='form-two-input-flex-inner-input'>
                            <Form.Item
                                name='DiscriptionSpain'
                                initialValue={editData?.DiscriptionSpain}
                                hasFeedback
                                label={<p className='p-admin-title-text'>{selectedLanguage?.faq_DiscriptionSpain}</p>}
                                rules={[
                                    {
                                        required: true,
                                        message: selectedLanguage?.faq_pleaseEnterSpainDiscription
                                    }
                                ]}
                            >
                                <Input.TextArea
                                    className='form-input-new-design-big-slam'
                                    placeholder={selectedLanguage?.faq_EnterSpainDiscriptionHere}
                                />
                            </Form.Item>
                        </div>
                        <div className='form-two-input-flex-inner-input'>
                            <Form.Item
                                name='DiscriptionItaly'
                                initialValue={editData?.DiscriptionItaly}
                                hasFeedback
                                label={<p className='p-admin-title-text'>{selectedLanguage?.faq_DiscriptionItaly}</p>}
                                rules={[
                                    {
                                        required: true,
                                        message: selectedLanguage?.faq_pleaseEnterItalyDiscription
                                    }
                                ]}
                            >
                                <Input.TextArea
                                    className='form-input-new-design-big-slam'
                                    placeholder={selectedLanguage?.faq_EnterItalyDiscriptionHere}
                                />
                            </Form.Item>
                        </div>
                    </div>

                    <div className='form-two-input-flex'>
                        <div className='form-two-input-flex-inner-input'>
                            <Form.Item
                                name='DiscriptionGerman'
                                initialValue={editData?.DiscriptionGerman}
                                hasFeedback
                                label={<p className='p-admin-title-text'>{selectedLanguage?.faq_DiscriptionGerman}</p>}
                                rules={[
                                    {
                                        required: true,
                                        message: selectedLanguage?.faq_pleaseEnterGermanDiscription
                                    }
                                ]}
                            >
                                <Input.TextArea
                                    className='form-input-new-design-big-slam'
                                    placeholder={selectedLanguage?.faq_EnterGermanDiscriptionHere}
                                />
                            </Form.Item>
                        </div>
                        <div className='form-two-input-flex-inner-input'>
                            <Form.Item
                                name='DiscriptionEgypt'
                                initialValue={editData?.DiscriptionEgypt}
                                hasFeedback
                                label={<p className='p-admin-title-text'>{selectedLanguage?.faq_DiscriptionEgypt}</p>}
                                rules={[
                                    {
                                        required: true,
                                        message: selectedLanguage?.faq_pleaseEnterEgyptDiscription
                                    }
                                ]}
                            >
                                <Input.TextArea
                                    className='form-input-new-design-big-slam'
                                    placeholder={selectedLanguage?.faq_EnterEgyptDiscriptionHere}
                                />
                            </Form.Item>
                        </div>
                    </div>

                    <div className='form-two-input-flex'>
                        <div className='form-two-input-flex-inner-input'>
                        <Form.Item
                                name='DiscriptionRussain'
                                initialValue={editData?.DiscriptionRussain}
                                hasFeedback
                                label={<p className='p-admin-title-text'>{selectedLanguage?.faq_DiscriptionRussain}</p>}
                                rules={[
                                    {
                                        required: true,
                                        message: selectedLanguage?.faq_pleaseEnterRussainDiscription
                                    }
                                ]}
                            >
                                <Input.TextArea
                                    className='form-input-new-design-big-slam'
                                    placeholder={selectedLanguage?.faq_EnterRussainDiscriptionHere}
                                />
                            </Form.Item>
                        </div>
                    </div>

                    {/* Desc */}

                    <div className='form-two-input-flex'>
                        <div className='form-two-input-flex-inner-input'>
                            <p style={{ padding: '0px', margin: '0px', marginBottom: '4px', marginLeft: '4px' }}
                                className='p-admin-title-text'
                            >{selectedLanguage?.faq_SelectType}</p>
                            <Select defaultValue={type}
                                placeholder={selectedLanguage?.faq_SelectType}
                                className='form-input-new-design-big-slam'
                                onChange={(e) => { setType(e) }}
                                style={{ width: '100%', marginBottom: '20px', marginTop: '5px' }}
                            >
                                <Option value={'How it works?'}>{selectedLanguage?.faq_HowItWork + ' ' + '?'}</Option>
                                <Option value={"FAQ's"}>{selectedLanguage?.faq_FAQS}</Option>
                            </Select>
                        </div>
                    </div>


                    {type === "FAQ's" &&
                        <div className='form-two-input-flex'>
                            <div className='form-two-input-flex-inner-input'>
                                <p style={{ padding: '0px', margin: '0px', marginBottom: '4px', marginLeft: '4px' }}>{selectedLanguage?.faq_SelectCatagrie}</p>
                                <Select defaultValue={catagrie}
                                    placeholder={selectedLanguage?.faq_SelectCatagrie}
                                    className='form-input-new-design-big-slam'
                                    onChange={(e) => { setCatagrie(e) }}
                                    style={{ width: '100%', marginBottom: '20px', marginTop: '5px' }}
                                >
                                    <Option value={"Tournaments"}>{selectedLanguage?.faq_Tournaments}</Option>
                                    <Option value={'Participation'}>{selectedLanguage?.faq_Participation}</Option>
                                    <Option value={"Goal"}>{selectedLanguage?.faq_Goal}</Option>
                                </Select>
                            </div>
                        </div>
                    }

                    <div className='form-two-input-flex'>
                        <div className='form-two-input-flex-inner-input'>
                            <Form.Item
                                name='priority'
                                initialValue={editData?.priority}
                                hasFeedback
                                label={<p className='p-admin-title-text'>{selectedLanguage?.faq_priority}</p>}
                                rules={[
                                    {
                                        required: true,
                                        message: selectedLanguage?.faq_pleasePriorityHere
                                    }
                                ]}
                            >
                                <InputNumber
                                    step={1}
                                    type='number'
                                    style={{ width: '100%' }}
                                    className='form-input-new-design-big-slam'
                                    placeholder={selectedLanguage?.faq_Enterpriority}
                                />
                            </Form.Item>
                        </div>
                    </div>

                    <div style={{ display: 'flex', justifyContent: 'flex-end', }}>
                        <Form.Item>
                            <Button
                                type='primary'
                                htmlType='submit'
                                loading={loading}
                            >
                                {editData?._id ? selectedLanguage?.faq_UpdateFaq : selectedLanguage?.faq_AddFaq}
                            </Button>
                        </Form.Item>
                    </div>
                </Form>
            </div>
        </div >
    )
}
export default AddFaq