import React, { useState } from 'react'
import { Button, Form, Input, Select } from 'antd'
import axios from 'axios'
import { POST } from '../../../utils/apis'
import { assignMessage, errorMessage, successMessage } from '../../../utils/helpers'

const AddGameSuggestion = (props) => {
    const { Option } = Select

    const { selectedLanguage, language, user } = props
    const [form] = Form.useForm()
    const [loading, setLoading] = useState(false)

    let gameTypes = [
        { name: selectedLanguage?.text_OneByOneMatchGame, value: '1-1 Match Game' },
        { name: selectedLanguage?.text_TeamMatchGame, value: 'Team Match Game' },
    ]

    const onFinish = (values) => {
        let obj = {
            userId: user?._id,
            name: values?.name?.trim(),
            description: values?.description?.trim(),
            playingTime: values?.playingTime?.trim(),
            gameType: values?.gameType?.trim(),
        }

        axios.post(POST.CREATE_GAME_SUGGESTION, obj)
            .then((res) => {
                const { data } = res
                setLoading(false)
                if (data.success) {
                    successMessage(assignMessage(data?.message, language?.language))
                    form.resetFields()
                }
                else {
                    errorMessage(assignMessage(data?.message, language?.language))
                }
            })
            .catch((e) => {
                setLoading(false)
                errorMessage(assignMessage(e?.message, language?.language))
            })
    }

    return (
        <div>
            <div style={{ marginTop: '20px' }}>
                <Form
                    name='form'
                    form={form}
                    onFinish={onFinish}
                    layout={'vertical'}
                >
                    <div className='form-two-input-flex'>
                        <div className='form-two-input-flex-inner-input'>
                            <Form.Item
                                name='name'
                                hasFeedback
                                label={<p className='p-admin-title-text'>{selectedLanguage?.title_Name}</p>}
                                rules={[
                                    {
                                        required: true,
                                        message: selectedLanguage?.error_PleaseInputYourName
                                    }
                                ]}
                            >
                                <Input
                                    className='form-input-new-design-big-slam'
                                    placeholder={selectedLanguage?.placeHolder_InputYourNameHere}
                                />
                            </Form.Item>
                        </div>
                        <div className='form-two-input-flex-inner-input'>
                            <Form.Item
                                name='gameType'
                                hasFeedback
                                label={<p className='p-admin-title-text'>{selectedLanguage?.label_selectGameType}</p>}
                                rules={[
                                    {
                                        required: true,
                                        message: selectedLanguage?.error_selectGameType
                                    }
                                ]}
                            >
                                <Select
                                    notFoundContent={selectedLanguage?.txt_NoDataFound}
                                    className='form-input-new-design-big-slam'
                                    placeholder={selectedLanguage?.placeHolder_selectGameType}>
                                    {gameTypes?.length ?
                                        gameTypes?.map((v, i) => {
                                            return (
                                                <Option value={v?.value}>{`${v?.name}`}</Option>
                                            )
                                        })
                                        : null}
                                </Select>
                            </Form.Item>
                        </div>
                    </div>
                    <div className='form-two-input-flex'>
                        <div className='form-two-input-flex-inner-input'>
                            <Form.Item
                                name='description'
                                hasFeedback
                                label={<p className='p-admin-title-text'>{selectedLanguage?.title_Description}</p>}
                                rules={[
                                    {
                                        required: true,
                                        message: selectedLanguage?.error_Description
                                    }
                                ]}
                            >
                                <Input
                                    className='form-input-new-design-big-slam'
                                    placeholder={selectedLanguage?.placeHolder_Description}
                                />
                            </Form.Item>
                        </div>
                        <div className='form-two-input-flex-inner-input'>
                            <Form.Item
                                name='playingTime'
                                hasFeedback
                                label={<p className='p-admin-title-text'>{selectedLanguage?.title_Playingtimeavg}</p>}
                                rules={[
                                    {
                                        required: true,
                                        message: selectedLanguage?.error_Playingtimeavg
                                    },
                                    ({ getFieldValue }) => ({
                                        validator(rule, value) {
                                            if (value < 1) {
                                                return Promise.reject(selectedLanguage?.txt_Value_must_more_then + ' 0')
                                            }
                                            else if (value > 120) {
                                                return Promise.reject(selectedLanguage?.txt_Value_must_Less_then + ' 121')
                                            }
                                            return Promise.resolve()
                                        }
                                    })
                                ]}
                            >
                                <Input
                                    min={0}
                                    max={120}
                                    type='number'
                                    pattern='[0-9]*'
                                    className='form-input-new-design-big-slam'
                                    placeholder={`${selectedLanguage?.placeHolder_Playingtimeavg}   (1 - 120)`}
                                />
                            </Form.Item>
                        </div>
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <Form.Item>
                            <Button
                                type='primary'
                                htmlType='submit'
                                loading={loading}
                            >
                                {selectedLanguage?.textbtn_Addgamesuggestion}
                            </Button>
                        </Form.Item>
                    </div>
                </Form>
            </div>
        </div>

    )
}

export default AddGameSuggestion