import React from "react";
import { FaFacebookF, FaTwitter, FaInstagram } from 'react-icons/fa'
import { TbWorldWww } from 'react-icons/tb'
import { IoIosSend } from "react-icons/io";
import { IoMail } from "react-icons/io5";
import { FaPhone } from "react-icons/fa6";
import WGPwhite from '../../assets/WGP-white.png'
import { useSelector } from "react-redux";


const NewFooter = (props) => {
    const {  selectedLanguage } = props
    
    return (
        <div className="new-footer-main">
            <div className="first-footer">
                <div className="footer-inner-card">
                    <span className="title-inner-footer">{selectedLanguage?.txt_Contactinfo}</span>
                    <span className="footer-inner-span-flex-icon-tetx">
                        <IoIosSend size={20} color="white" />
                        <span className="footer-inner-span-flex-tetx">{selectedLanguage?.txt12mainstreetlondon}</span>
                    </span>
                    <span className="footer-inner-span-flex-icon-tetx">
                        <IoMail size={20} color="white" />
                        <span className="footer-inner-span-flex-tetx">coffeecup1967@gmail.com</span>
                    </span>
                    <span className="footer-inner-span-flex-icon-tetx">
                        <FaPhone size={20} color="white" />
                        <span className="footer-inner-span-flex-tetx">+92-3440249687</span>
                    </span>
                    <p className="footer-icon-main" style={{ marginTop: 30, marginLeft: -5 }}>
                        <span className="footer-header-icon"><FaFacebookF size={18} /></span>
                        <span className="footer-header-icon"><FaTwitter size={18} /></span>
                        <span className="footer-header-icon"><FaInstagram size={18} /></span>
                        <span className="footer-header-icon"><TbWorldWww size={18} /></span>
                    </p>
                </div>

                <div className="footer-inner-card">
                    <span className="title-inner-footer">{selectedLanguage?.txt_Recentpost}</span>
                    <span className="span-bottom-recent-post">
                        {selectedLanguage?.txt_GalleryPostFormat}
                    </span>
                    <span className="span-bottom-recent-post">
                        {selectedLanguage?.txt_Possession_of_my_entire_soul}
                    </span>
                    <span className="span-bottom-recent-post">
                        {selectedLanguage?.txt_Audio_Post_Format}
                    </span>
                    <span className="span-bottom-recent-post">
                        {selectedLanguage?.txt_Quote_Post_Format}
                    </span>
                </div>

                <div className="footer-inner-card">
                    <span className="title-inner-footer">{selectedLanguage?.txt_Recentwork}</span>
                    <span className="span-bottom-recent-post">
                        {selectedLanguage?.txt_GalleryPostFormat}
                    </span>
                    <span className="span-bottom-recent-post">
                        {selectedLanguage?.txt_Possession_of_my_entire_soul}
                    </span>
                    <span className="span-bottom-recent-post">
                        {selectedLanguage?.txt_Audio_Post_Format}
                    </span>
                    <span className="span-bottom-recent-post">
                        {selectedLanguage?.txt_Quote_Post_Format}
                    </span>
                </div>

                <div className="footer-inner-card" >
                    <div className="center">
                        <img src={WGPwhite} className="footer-logo-end" />
                        <br />
                        <br />
                        <span className="footer-logo-decs-end">
                            {selectedLanguage?.Even_the_gateway_to_the_grid_is_expected_to_be_large_and_soft_Fusce_dapibus_terus_and_cursus_commodo_tortor_mauris}
                        </span>
                    </div>
                </div>
            </div>

            <div className="second-footer">
                <p className="footer-icon-main">
                    <span className="footer-nav">{selectedLanguage?.txt_Home}</span>
                    <span className="footer-nav">{selectedLanguage?.txt_About}</span>
                    <span className="footer-nav">{selectedLanguage?.txt_Contact}</span>
                    <span className="footer-nav">{selectedLanguage?.txt_Music}</span>
                </p>
                <span className="last-footer-txt">
                    {selectedLanguage?.txt_Copyright_2023_WGPplaza_All_Right_Reserved}
                </span>
            </div>
        </div>
    )
}

export default NewFooter