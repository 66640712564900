import React, { useEffect, useState } from 'react'
import { Button, Table } from 'antd'
import ClaimDetailModal from './ClaimDetail'
import axios from 'axios'
import { ADMIN } from '../../../utils/apis'
import io from 'socket.io-client'
import { assignMessage, errorMessage, pastMatches } from '../../../utils/helpers'
import PlayerClaimDetail from '../../PlayerScreen/Claim/PlayerClaimDetail'
import { currencySign } from '../../../utils/constants'

const AdminClaim = (props) => {
    const { selectedLanguage, language, user } = props
    const [loading, setLoading] = useState(false)
    const [claimDetail, setClaimDetail] = useState({})
    const [data, setData] = useState([])
    const [tournamentData, setTournamentData] = useState([])
    const [showTournamentData, setShowTournamentData] = useState(false)

    useEffect(() => {
        getSocketInfo()
        setLoading(true)
        getData()
        getTournamentData()
    }, [])

    let origin = 'https://wgpplaza.com'
    if (process.env.NODE_ENV === 'development') {
        origin = `http://localhost:4000`
    }

    console.log('origin', origin)
    const socket = io(origin, { reconnect: true });

    socket.on('connect', () => {
        console.log('connected')
    })

    const getSocketInfo = () => {
        socket.on('matchRequestResponse', (d) => {
            const { documentKey, operationType, updateDescription } = d
            getData()
        })
    }

    const getData = () => {
        axios.get(`${ADMIN?.GET_CLAIM_ARGUES}/${user?._id}`)
            .then((res) => {
                const { data } = res
                setLoading(false)
                if (data?.success) {
                    setData(data?.data)
                }
                else {
                    setData([])
                }
            })
            .catch((e) => {
                setLoading(false)
                errorMessage(assignMessage(e?.message, language?.language))
            })
    }
    const getTournamentData = () => {
        axios.get(`${ADMIN?.GET_TOURNAMENT_CLAIM_ARGUES}/${user?._id}`)
            .then((res) => {
                const { data } = res
                setLoading(false)
                if (data?.success) {
                    setTournamentData(pastMatches(data?.data))

                }
                else {
                    setTournamentData([])
                }
            })
            .catch((e) => {
                setLoading(false)
                errorMessage(assignMessage(e?.message, language?.language))
            })
    }

    const columns = [
        {
            title: selectedLanguage?.title_Game,
            dataIndex: 'gameId',
            render: (e) => {
                return (
                    <p className='table_p' style={{ width: '110px' }}>{e?.name || '-----'}</p>
                )
            }
        },
        {
            title: selectedLanguage?.text_GameType,
            dataIndex: 'gameId',
            render: (e) => {
                return (
                    <p className='table_p' style={{ width: '110px' }}>{e?.name || '-----'}</p>
                )
            }
        },
        {
            title: selectedLanguage?.text_MatchDateTime,
            render: (e) => {
                return (
                    <p className='table_p' style={{ width: '150px' }}>{e?.matchDate || '----'}|{e?.matchTime}</p>
                )
            }
        },
        {
            title: selectedLanguage?.text_SenderOfMatchRequest,
            dataIndex: 'senderId',
            render: (e) => {
                return (
                    <p className='table_p' style={{ width: '150px' }}>{e?.name}</p>
                )
            }
        },
        {
            title: selectedLanguage?.text_ReceiverOfMatchRequest,
            dataIndex: 'receiverId',
            render: (e) => {
                return (
                    <p className='table_p' style={{ width: '150px' }}>{e?.name}</p>
                )
            }
        },
        {
            title: `${selectedLanguage?.text_PointsOfMatch}/${selectedLanguage?.invoice_money}`,
            align: 'center',
            render: (e) => {
                return (
                    <p className='table_p' style={{ width: '150px' }}>{e?.isStake ? `${currencySign} ${e?.money}` : e?.points}</p>
                )
            }
        },
        {
            title: selectedLanguage?.text_viewDetails,
            render: (e) => {
                return (
                    <button
                        onClick={() => setClaimDetail(e)}
                        className='i-won-button'
                        style={{ marginLeft: '10px' }}
                    >
                        {selectedLanguage?.text_viewDetails}
                    </button>
                )
            }
        },
    ]

    const handleOk = () => {
        setClaimDetail({})
    }
    return (
        <div className='new-main-component-screen-div'>
            <h1 className='new-screen-title' >
                {selectedLanguage?.title_ClaimFromPlayers}
            </h1>

            <span style={{ marginTop: '20px' }} className='button-div-flex-end'>
                {showTournamentData ?
                    <Button
                        type='primary'
                        onClick={() => setShowTournamentData(false)}
                    >
                        {selectedLanguage?.text_OnetOne_Matches}
                    </Button>
                    :
                    <Button
                        type='primary'
                        onClick={() => setShowTournamentData(true)}
                    >
                        {selectedLanguage?.text_tournament_Matches}
                    </Button>}
            </span>


            <div className="custom-table-container">
                <Table
                    locale={{ emptyText: selectedLanguage?.txt_NoDataFound }}
                    className='new-layout-table'
                    dataSource={showTournamentData ? tournamentData : data}
                    columns={columns}
                    pagination={false}
                    loading={loading}
                    scroll={{ x: 400 }}
                />
            </div>

            {
                claimDetail?._id && !claimDetail?.tournament?._id &&
                <ClaimDetailModal data={claimDetail} onCancel={() => setClaimDetail({})} visible={claimDetail._id ? true : false} handleOk={handleOk} {...props} />
            }
            {
                claimDetail?._id && claimDetail?.tournament?._id &&
                <PlayerClaimDetail data={claimDetail} onCancel={() => setClaimDetail({})} visible={claimDetail._id ? true : false} handleOk={handleOk} {...props} />
            }
        </div>
    )
}

export default AdminClaim