import React, { useState, useEffect } from 'react';

const TextAnimationsComponent = (props) => {
    const {  selectedLanguage } = props

    return (
        <div className='div-for-text-transition'>
            <h1 className="text-transition">

                <div className='div-flex-colum'>
                    <span className='smaill-1st-heaader-text'>{selectedLanguage?.txt_MeettheRookee}</span>
                    <div className='margin-mins-20'>
                        <span className='smaill-2nd-orange-heaader-text' > {'Jhon'} </span> <span className='smaill-2nd-white-heaader-text'>{'Smith'} </span>
                    </div>
                    <span className='smaill-3rd-white-heaader-text'>{selectedLanguage?.txt_Most_Scroing_Player_2017}</span>
                </div>
            </h1>
        </div>
    );
};

export default TextAnimationsComponent;
