import { Button, Table } from "antd";
import React, { useEffect, useState } from "react";
import SendInvitation from "./SendInviteFriend";
import axios from 'axios'
import { ADMIN, GET, POST } from '../../../utils/apis'
import moment from "moment";
import { assignMessage, errorMessage, getUserData } from "../../../utils/helpers";
import { useSelector } from "react-redux";
import NoData from '../../../assets/noDataImae.png'

const InviteFriend = (props) => {
    const { selectedLanguage, user, userActions, language } = props
    const gameSettings = useSelector(state => state?.userReducer?.gameSettings)
    const [loading, setLoading] = useState(false)
    const [visible, setVisible] = useState(false)
    const [invitedContatct, setInvitedContatct] = useState([])
    const [refferalPoints, setRefferalPoints] = useState(null)

    useEffect(() => {
        getUserData(user?._id, userActions)
        getInviteFriend()
        getRefferalPoint()
    }, [])

    const columns = [
        {
            title: selectedLanguage?.txt_Name,
            dataIndex: 'name',
            render: (e) => {
                return (
                    <p className='table_p' style={{ width: '110px' }}>{e || '-----'}</p>
                )
            }
        },
        {
            title: selectedLanguage?.txt_Email,
            dataIndex: 'email',
            render: (e) => {
                return (
                    <p className='table_p' style={{ width: '220px' }}>{e || '-----'}</p>
                )
            }
        },
        {
            title: selectedLanguage?.txt_InvitationDate,
            render: (e) => {
                return (
                    <p className='table_p' style={{ width: '80px' }}>{moment(e?.created).utc().format('DD-MM-YYYY') || '-----'}</p>
                )
            }
        },
        {
            title: selectedLanguage?.txt_Status,
            render: (e) => {
                return (
                    <>
                        <>
                            {e?.isRegistered ?
                                <p className='accept-status'>{selectedLanguage?.text_Registered}</p>
                                :
                                <p className='pending-stats'>{selectedLanguage?.text_Pending}</p>
                            }
                        </>
                    </>
                )
            }
        },
    ]

    const getRefferalPoint = () => {
        axios.get(ADMIN?.GET_REFERRAL_POINTS)
            .then((res) => {
                const { data } = res
                if (data?.success) {
                    setRefferalPoints(data?.data[0])
                }
                else {
                    setRefferalPoints(null)
                }
            })
            .catch((e) => {
                errorMessage(assignMessage(e?.message, language?.language))
            })

    }
    const getInviteFriend = () => {
        setLoading(true)
        axios.get(`${GET.GET_INVITED_FRIEND}/${user?._id}`)
            .then((res) => {
                const { data } = res
                setLoading(false)
                if (data.success) {
                    setInvitedContatct(data?.data || [])
                }
                else {
                    setInvitedContatct([])
                }
            })
            .catch((e) => {
                setLoading(false)
                console.log('error')
            })
    }
    const handleOk = () => {
        setVisible(false)
    }
    const handleSuccess = () => {
        getInviteFriend()
        handleOk()
    }
    return (

        <div className='new-main-component-screen-div'>
            <h1 className='new-screen-title' >
                {selectedLanguage?.title_MyInvitedFriend}
            </h1>

            <span
                style={{ marginTop: '20px' }}
                className='button-div-flex-end'
            >
                <Button
                    type="primary"
                    onClick={() => setVisible(true)}>{selectedLanguage?.text_InviteFriend}</Button>
            </span>

            <div className="custom-table-container">
                <Table
                    locale={{ emptyText: selectedLanguage?.txt_NoDataFound }}
                    className='new-layout-table'
                    dataSource={invitedContatct}
                    columns={columns}
                    pagination={false}
                    loading={loading}
                    scroll={{ x: 400 }}
                />
            </div>

            {visible &&
                <SendInvitation
                    refferalPoints={refferalPoints}
                    visible={visible}
                    handleOk={handleOk}
                    handleSuccess={handleSuccess}
                    myGameSetting={gameSettings}
                    {...props} />
            }
        </div>
    )
}

export default InviteFriend