import React, { useEffect } from 'react'
import RecivedMatchRequest from './RecivedMatchRequest'
import SentMatchRequest from './SentMatchRequest'
import io from 'socket.io-client'
import { get1_1_MatchData } from '../../../utils/helpers'
import { useSelector } from 'react-redux'

const MatchRequestScreen = (props) => {
    const { selectedLanguage, user, userActions } = props

    const sentMatches = useSelector(state => state.userReducer.sentMatches)
    const receiveMatches = useSelector(state => state.userReducer.receiveMatches)

    useEffect(() => {
        get1_1_MatchData(user?._id, userActions)
        getSocketInfo()
    }, [])
    console.log('sentMatches', sentMatches)
    console.log('receiveMatches', receiveMatches)
    let origin = `https://wgpplaza.com`
    if (process.env.NODE_ENV === 'development') {
        origin = `http://localhost:4000`
    }

    console.log('origin', origin)
    const socket = io(origin, { reconnect: true });

    socket.on('connect', () => {
        console.log('connected')
    })

    const getSocketInfo = () => {
        socket.on('matchRequestResponse', (d) => {
            const { documentKey, operationType, updateDescription } = d
            get1_1_MatchData(user?._id, userActions)
        })
    }

    return (
        <div className='new-main-component-screen-div'>
            <SentMatchRequest sentMatches={sentMatches} {...props} />
            <RecivedMatchRequest receiveMatches={receiveMatches} {...props} />
        </div>
    )
}

export default MatchRequestScreen