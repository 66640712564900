import { FAQ } from "../types"

const AddFaqSetting = (faqSetting) => {
    return {
        type: FAQ,
        faqSetting
    }
}


export {
    AddFaqSetting
 }