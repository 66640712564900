const adminApi = `/api/admin`
const authApi = `/api/user`
const getApi = `/api/get`
const postApi = `/api/post`

const AUTH = {
    LOGIN: `${authApi}/login`,
    SIGNUP: `${authApi}/signup`,
    FORGOT_PASSWORD: `${authApi}/forgot-password`,
    UPDATE_PASSWORD: `${authApi}/update-password`,
    UPDATE_SETTINGS: `${authApi}/update-settings`,
    CHANGE_PASSWORD: `${authApi}/change-password`,
}
const GET = {
    USER_BY_ID: `${getApi}/get-user`,
    VERIFY_TOKEN: `${getApi}/verifytoken`,
    VERIFY_EMAIL: `${getApi}/verifyemail`,
    GET_PLAYER_GAME_SUGGESTION: `${getApi}/get-player-game-suggestion`,
    GET_PLAYER_GAME_SETTING: `${getApi}/get-player-game-setting`,
    GET_ALL_PLAYER: `${getApi}/get-all-player`,
    GET_PLAYER_CONTACT: `${getApi}/get-player-contact`,
    GET_ALL_TOURNAMENT: `${getApi}/get-all-tournament`,
    GET_PLAYER_RANKS: `${getApi}/get-player-ranks`,
    GET_GAME_RANKS: `${getApi}/get-game-ranks`,
    GET_PLAYER_GAME_RANK: `${getApi}/get-player-game-rank`,
    GET_GAME_SETTING: `${getApi}/get-game-setting`,
    GET_SENT_MATCH_REQUEST: `${getApi}/get-sent-match-request`,
    GET_RECIVE_MATCH_REQUEST: `${getApi}/get-recive-match-request`,
    GET_APPROVED_MATCH_REQUEST: `${getApi}/get-match-reqest-approved`,
    GET_PLAYER_CLAIMS_ARGUES: `${getApi}/get-claims-argues-player`,
    GET_INVITED_FRIEND: `${getApi}/get-invited-friend`,
    GET_TOURNAMENT_MATCHES: `${getApi}/get-tournament-matches`,
    PAYMENT_STATUS: `${getApi}/get-payment-status`,
    GET_TOURNAMENT_CLAIMS_ARGUE: `${getApi}/get-tournament-claims-argues`,
    GET_USER_CASH_BACK: `${getApi}/get-cash-back`,
    GET_USER_CASH_BACK_AMOUNT: `${getApi}/get-user-cash-back-amount`,
    GET_WALLET_TRANSACTION: `${getApi}/get-walet-transaction`,
    GET_ALL_COMPETITIONS: `${getApi}/get-all-competitions`,
    GET_COMPETITION_RANKS: `${getApi}/get-competition-ranks`,
    GET_PARTICULAR_COMPETITION_RANKS: `${getApi}/get-particular-competition-ranks`,
    GET_SENT_MATCH_REQUEST_COMPETITION: `${getApi}/get-sent-match-request-competition`,
    GET_RECIVE_MATCH_REQUEST_COMPETITION: `${getApi}/get-recive-match-request-competition`,
    GET_PLAYER_COMPETITION_SUBSCRIPTION: `${getApi}/get-player-competition-subscription`,
    GET_COMPETITION_APPROVED_MATCH_REQUEST: `${getApi}/get-competition-accepted-matches`,
    GET_SUBSCRIBED_COMPETITIONS: `${getApi}/get-subscribed-competitions`
}

const POST = {
    CREATE_GAME_SUGGESTION: `${postApi}/create-game-suggestion`,
    CREATE_PLAYER_GAME_SETTING: `${postApi}/create-player-game-setting`,
    DELETE_PLAYER_GAME_SETTING: `${postApi}/delete-player-game-setting`,
    EDIT_PLAYER_GAME_SETTING: `${postApi}/edit-player-game-setting`,
    CREATE_CONTACT: `${postApi}/create-contact`,
    DELETE_CONTACT: `${postApi}/delete-contact`,
    CREATE_TOURNAMENT: `${postApi}/create-tournament`,
    DELETE_TOURNAMENT: `${postApi}/delete-tournament`,
    EDIT_TOURNAMENT: `${postApi}/edit-tournament`,
    TOURNAMENT_PARTICIPATE: `${postApi}/tournament-participate`,
    CANCEL_PARTICIPATE: `${postApi}/cancel-participate`,
    CREATE_MATCH_REQUEST: `${postApi}/create-match-request`,
    DELETE_MATCH_REQUEST: `${postApi}/delete-match-request`,
    REJECT_MATCH_REQUEST: `${postApi}/reject-match-request`,
    ACCEPT_MATCH_REQUEST: `${postApi}/accept-match-request`,
    GET_FILE_URL: `${postApi}/get-file-url`, /* Post File */
    TOURNAMENT_PARTICIPATE_WITH_COUPON: `${postApi}/tournament-participate-with-coupon`,
    DOLLER_TOURNAMENT_PARTICIPATE: `${postApi}/doller-tournament-participate`,

    USER_LOST_MATCH: `${postApi}/user-match-lost`,
    SUBMIT_WIN_PROOF: `${postApi}/submit-win-proof`,
    INVITE_FRIEND_TO_WGP: `${postApi}/invite-friend-to-wgp`,
    USER_MATCH_DRAW: `${postApi}/user-match-draw`,
    USER_CONFIRM_MATCH_DRAW: `${postApi}/user-confirm-match-draw`,

    TOURNAMENT_LOST_MATCH: `${postApi}/tournament-loss-match`,

    WALLET_RECHARGE: `${postApi}/wallet-recharge`,
    RESOLVE_TOURNAMENT_ARGUE: `${postApi}/resolve-tournament-argue`,

    CASH_BACK_REQUEST: `${postApi}/cash-back-request`,
    COMPETITION_SUBSCRIPTION: `${postApi}/competition-subscription`,
    CREATE_COMPETITION_MATCH: `${postApi}/create-competition-match-request`,
    
    // competition matches
    DELETE_COMPETITION_MATCH_REQUEST: `${postApi}/delete-competition-match-request`,
    REJECT_COMPETITION_MATCH_REQUEST: `${postApi}/reject-competition-match-request`,
    ACCEPT_COMPETITION_MATCH_REQUEST: `${postApi}/accept-competition-match-request`,
    
    USER_COMPETITION_LOST_MATCH: `${postApi}/user-competition-match-lost`,

    USER_POINT_SUBSRIPTION: `${postApi}/point-subscription`,
}

const ADMIN = {
    LOGIN: `${adminApi}/login`,
    ADMIN_SIGNUP: `${adminApi}/admin-signup`,
    EDIT_ADMIN_INFO: `${adminApi}/admin-edit-info`,
    ADD_NEW_GAME: `${adminApi}/add-new-game`,
    GET_ALL_GAME: `${adminApi}/get-all-games`,
    EDIT_GAME: `${adminApi}/edit-game`,
    DELETE_GAME: `${adminApi}/delete-game`,
    GET_GAME_SUGGESTION: `${adminApi}/get-game-suggestion`,
    DELETE_GAME_SUGGESTION: `${adminApi}/delete-game-suggestion`,
    APPROVE_GAME_SUGGESTION: `${adminApi}/approve-game-suggestion`,
    CREATE_TOURNAMENT: `${adminApi}/create-tournament`,
    GET_ALL_TOURNAMENT: `${adminApi}/get-all-tournament`,
    DELETE_TOURNAMENT: `${adminApi}/delete-tournament`,
    EDIT_TOURNAMENT: `${adminApi}/edit-tournament`,
    CREATE_PROMOTER: `${adminApi}/create-promoter`,
    GET_ALL_PROMOTER: `${adminApi}/get-promoter`,
    DELETE_PROMOTER: `${adminApi}/delete-promoter`,
    EDIT_PROMOTER: `${adminApi}/edit-promoter`,
    ADD_REFERRAL_POINTS: `${adminApi}/add-referral-points`,
    GET_REFERRAL_POINTS: `${adminApi}/get-referral-points`,
    EDIT_REFERRAL_POINTS: `${adminApi}/edit-referral-points`,
    GET_SELECTED_GAME_SETTING: `${adminApi}/get-selected-game-setting`,
    GET_PLAYER_NUMBER_OF_MATCHES: `${adminApi}/get-player-number-of-matches`,
    GET_CLAIM_ARGUES: `${adminApi}/get-claim-argues`,
    RESOLVE_ARGUES: `${adminApi}/resolve-argue`,
    ADD_TOURNAMENT_ORGANIZER_POINT: `${adminApi}/add-tournament-organizer-points`,
    EDIT_TOURNAMENT_ORGANIZER_POINT: `${adminApi}/edit-tournament-organizer-points`,
    GET_TOURNAMENT_ORGANIZER_POINT: `${adminApi}/get-tournament-organizer-points`,
    CREATE_TOURNAMENT_COUPOUN: `${adminApi}/create-tournament-coupons`,
    GET_TOURNAMENT_COUPOUN: `${adminApi}/get-tournament-coupons`,
    ADD_WITHDRAWAL_POINTS: `${adminApi}/add-withdrawal-points`,
    GET_WITHDRAWAL_POINTS: `${adminApi}/get-withdrawal-points`,
    EDIT_WITHDRAWAL_POINTS: `${adminApi}/edit-withdrawal-points`,
    GET_GAME_DATA: `${adminApi}/get-game-data`,
    ADD_TOURNAMENT_ORGANIZER_PAY_COST_POINT: `${adminApi}/add-tournament-organizer-pay-cost-points`,
    EDIT_TOURNAMENT_ORGANIZER_PAY_COST_POINT: `${adminApi}/edit-tournament-organizer-pay-cost-points`,
    GET_TOURNAMENT_ORGANIZER_PAY_COST_POINT: `${adminApi}/get-tournament-organizer-pay-cost-points`,
    ADD_SUBSCRIPTION_FEE_FOR_DOLLER_TOURNAMENT: `${adminApi}/add-subscription-fee-doller-tournament`,
    EDIT_SUBSCRIPTION_FEE_FOR_DOLLER_TOURNAMENT: `${adminApi}/edit-subscription-fee-doller-tournament`,
    GET_SUBSCRIPTION_FEE_FOR_DOLLER_TOURNAMENT: `${adminApi}/get-subscription-fee-doller-tournament`,
    ADD_SEND_DOLLER_MATCH_REQ_FEE: `${adminApi}/add-send-doller-match-req-fee`,
    EDIT_SEND_DOLLER_MATCH_REQ_FEE: `${adminApi}/edit-send-doller-match-req-fee`,
    GET_SEND_DOLLER_MATCH_REQ_FEE: `${adminApi}/get-send-doller-match-req-fee`,
    GET_ALL_PLAYERS: `${adminApi}/get-all-players`,
    GET_TOURNAMENT_CLAIM_ARGUES: `${adminApi}/get-tournament-claims-argues`,
    ADD_CASHBACK_SERVICE_FEE: `${adminApi}/add-cashback-service-fee`,
    EDIT_CASHBACK_SERVICE_FEE: `${adminApi}/edit-cashback-service-fee`,
    GET_CASHBACK_SERVICE_FEE: `${adminApi}/get-cashback-service-fee`,
    GET_ALL_CASH_BACKS: `${adminApi}/get-all-cash-backs`,
    CONFIRM_CASH_BACK: `${adminApi}/confirm-cash-back`,
    GET_SUBSCRIPTION_REPORT: `${adminApi}/get-subscription-report`,
    ADD_FAQ_QUESTION: `${adminApi}/add-faq-question`,
    GET_FAQ_QUESTION: `${adminApi}/get-faq-question`,
    DELETE_FAQ_QUESTION: `${adminApi}/delete-faq-question`,
    EDIT_FAQ_QUESTION: `${adminApi}/edit-faq-question`,
    CREATE_COMPETITION: `${adminApi}/create-competition`,
    ADD_COMPETITION_SUB_SERVICE_FEE: `${adminApi}/add-competition-sub-service-fee`,
    EDIT_COMPETITION_SUB_SERVICE_FEE: `${adminApi}/edit-competition-sub-service-fee`,
    GET_COMPETITION_SUB_SERVICE_FEE: `${adminApi}/get-competition-sub-service-fee`,
    
}


export {
    AUTH,
    GET,
    ADMIN,
    POST
}